// https://github.com/twilio/twilio-video.js/blob/master/CHANGELOG.md#new-features-4
import { Logger } from 'twilio-video';

import { LogLevel } from './types';
import { VideoforceLogger } from './VideoforceLogger';

export function setupTwilioLoggerBridge(
  logger: VideoforceLogger,
  level?: LogLevel,
) {
  const their = Logger.getLogger('twilio-video');
  const our = logger.child('twilio-video', level);

  // const originalFactory = their.methodFactory;
  their.methodFactory = function (_methodName, lvl, _loggerName) {
    // const originalMethod = originalFactory(methodName, level, loggerName);
    return function (datetime, logLevel, component, message, data) {
      switch (lvl) {
        case 0:
        case 1:
          our.debug(component, message, data);
          break;
        case 2:
          our.info(component, message, data);
          break;
        case 3:
          our.warn(component, message, data);
          break;
        case 4:
          our.error(component, message, data);
          break;
      }
    };
  };
  their.setLevel('debug');
}
