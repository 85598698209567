import React from 'react';

interface Props {
  className?: string;
}

const IconVideoCamOn: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 5C2.79086 5 1 6.79086 1 9V21C1 23.2091 2.79086 25 5 25H18C20.2091 25 22 23.2091 22 21V17.6667L27.5689 22.0754C28.5523 22.8539 30 22.1535 30 20.8993V8.10064C30 6.84644 28.5523 6.14608 27.5689 6.92457L22 11.3333V9C22 6.79086 20.2091 5 18 5H5Z"
        fill="white"
      />
    </svg>
  );
};

export default IconVideoCamOn;
