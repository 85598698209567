import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import BlockTitles from 'components/BlockTitles';
import VideoModal from 'components/VideoModal';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import { VideoDetailsFragment } from '../../videoDetails.generated';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    wrapper: {
      padding: '8px',
      border: '1px solid #979797',
      boxSizing: 'border-box',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '43px',
      marginTop: spacing(2),
      position: 'relative',
      maxWidth: '244px',
      '& img': {
        borderRadius: '8px',
        border: '1px solid #979797',
      },
      '& img:hover': {
        cursor: 'pointer',
      },
    },
    wrapperDialog: {
      border: 'none',
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      '& svg path': {
        fill: '#C4C4C4',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    center: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }),
);

interface VideoPreviewProps {
  video?: VideoDetailsFragment | null;
  text: string;
  isDialog?: boolean;
  setShowDeleteDialog?: Dispatch<SetStateAction<boolean>>;
}

export const VideoPreview: FC<VideoPreviewProps> = ({
  video,
  text,
  isDialog,
  setShowDeleteDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [open, toggleOpen] = useToggle(false);

  if (!video) {
    return null;
  }

  if (!video.jpg) {
    return <p>No thumb</p>;
  }

  return (
    <div className={clsx({ [classes.center]: isDialog })}>
      <BlockTitles>{t(`components:video.uploadDialog.${text}`)}</BlockTitles>
      <div
        className={clsx(classes.wrapper, {
          [classes.wrapperDialog]: isDialog,
        })}
      >
        <img src={video.jpg} width="200" onClick={toggleOpen} />

        {!isDialog && (
          <span
            className={classes.close}
            onClick={() => setShowDeleteDialog?.(true)}
          >
            <CloseIcon />
          </span>
        )}
      </div>
      {open && <VideoModal url={video.mp4} onClose={toggleOpen} />}
    </div>
  );
};
