import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ButtonProgress from 'components/ButtonProgress';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: 480,
      maxWidth: 480,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      padding: 26,
      '&:first-child': {
        paddingTop: 10,
        paddingBottom: 0,
      },
      overflowX: 'hidden',
      '&::-webkit-scrollbar ': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '25px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '25px',
        height: '20px',
      },
    },
    leftButton: {
      marginRight: 'auto',
    },
    footer: {
      padding: 26,
      borderTop: '1px solid #DBDBDB',
    },
    submitButton: {
      width: '100%',
    },
  }),
);

interface ScenarioDialogLayoutProps {
  className?: string;
  children?: ReactNode;
  onSubmit?: () => void;
  isSubmitting?: boolean;
  isDirty?: boolean;
  onCancel?: () => void;
  onAddNewScenario?: (fromSelecting: boolean) => void;
  onSelectScenario?: () => void;
}

export const ScenarioDialogLayout: FC<ScenarioDialogLayoutProps> = ({
  children,
  className,
  onSubmit,
  isSubmitting,
  isDirty,
  onCancel,
  onAddNewScenario,
  onSelectScenario,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <DialogContent className={clsx(className, classes.dialogContent)}>
        {children}
      </DialogContent>

      <DialogActions className={classes.footer}>
        {!!onSubmit && (
          <ButtonProgress
            loading={isSubmitting}
            className={classes.submitButton}
          >
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              disabled={!isDirty || isSubmitting}
              onClick={onSubmit}
            >
              {t('components:scenario.dialog.buttons.sumbit')}
            </Button>
          </ButtonProgress>
        )}

        {!!onAddNewScenario && (
          <Button
            className={classes.leftButton}
            variant="contained"
            color="primary"
            onClick={() => onAddNewScenario(true)}
          >
            {t('components:scenario.dialog.buttons.create')}
          </Button>
        )}

        {!!onCancel && (
          <Button variant="contained" onClick={onCancel}>
            {t('components:scenario.dialog.buttons.cancel')}
          </Button>
        )}

        {!!onSelectScenario && (
          <Button
            variant="contained"
            color="primary"
            onClick={onSelectScenario}
          >
            {t('components:scenario.dialog.buttons.select')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};
