import { ScenarioStepButtonType } from '__generated__/types';
import { ScenarioStep, ScenarioStepButton } from 'components/scenario/types';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface StepButtonActionInfoProps {
  button: ScenarioStepButton;
  steps?: ScenarioStep[];
}

export const StepButtonActionInfo: React.FC<StepButtonActionInfoProps> = ({
  button,
  steps,
}) => {
  const { t } = useTranslation();

  const followStep = steps?.find((step) => {
    if (button.type === ScenarioStepButtonType.FollowStep) {
      return step.order === button.step;
    } else {
      return undefined;
    }
  });

  if (
    button.type !== ScenarioStepButtonType.FinishScenario &&
    button.type !== ScenarioStepButtonType.FollowStep
  ) {
    return t(
      `components:scenario.dialog.step.button.selectedAction.${button.type}`,
    );
  }

  return (
    <>
      {button.type === ScenarioStepButtonType.FollowStep && (
        <div>{followStep?.name}</div>
      )}

      {button.type === ScenarioStepButtonType.FinishScenario &&
        t(
          `components:scenario.dialog.step.button.selectedAction.${ScenarioStepButtonType.FinishScenario}`,
        )}
    </>
  );
};
