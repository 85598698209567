import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StateScenarioDialog } from 'components/scenario/useScenario';
import { StateUploadDialog } from 'components/video';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TabsVariant } from './VideoLibraryPage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '40px',
      justifyContent: 'space-between',
    },
    recordingButton: {
      marginLeft: 'auto',
      marginRight: '10px',
    },
  }),
);

interface VideoLibraryHeaderProps {
  toggleVideoDialogOpened: (nextValue?: any) => void;
  toggleScenarioDialog: (nextValue?: any) => void;
  setInitialStateVideoDialog: React.Dispatch<
    React.SetStateAction<StateUploadDialog>
  >;
  selectedTab: TabsVariant;
  setInitialStateScenario: React.Dispatch<
    React.SetStateAction<StateScenarioDialog>
  >;
}

export const VideoLibraryHeader: React.FC<VideoLibraryHeaderProps> = ({
  toggleVideoDialogOpened,
  toggleScenarioDialog,
  setInitialStateVideoDialog,
  selectedTab,
  setInitialStateScenario,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const videoControlButton = selectedTab === TabsVariant.Video;

  return (
    <div className={classes.root}>
      <Typography variant="h1">{t('routes:videoLibrary.header')}</Typography>

      {videoControlButton ? (
        <>
          <Button
            className={classes.recordingButton}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              setInitialStateVideoDialog({ state: 'recording' });
              toggleVideoDialogOpened();
            }}
          >
            {t('routes:videoLibrary.tabs.video.recording')}
          </Button>

          <Button
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              setInitialStateVideoDialog({ state: 'selecting' });
              toggleVideoDialogOpened();
            }}
          >
            {t('routes:videoLibrary.tabs.video.buttonLoad')}
          </Button>
        </>
      ) : (
        <Button
          color="primary"
          variant="contained"
          disableElevation
          size="small"
          onClick={() => {
            setInitialStateScenario({ state: 'create_scenario' });
            toggleScenarioDialog();
          }}
        >
          {t('routes:videoLibrary.tabs.scenario.buttonCreate')}
        </Button>
      )}
    </div>
  );
};
