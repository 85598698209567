import { Auth, CognitoUser } from '@aws-amplify/auth';
import { setFromPromo } from 'utils/promoUsers';

// Promocode is erased after promo dialog is displayed, so it's only displayed once
export default async function resetPromocode() {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();

  // Save the fact that this user came with promocode;
  // This is hacky solution to vary intro_tour for Jivo users
  setFromPromo(user);

  return new Promise<void>((resolve, reject) => {
    user.updateAttributes(
      [
        {
          Name: 'custom:promocode',
          Value: '',
        },
      ],
      (err, _result) => {
        if (err) {
          reject(err);
        } else {
          resolve(undefined);
        }
      },
    );
  });
}
