/* eslint-disable @typescript-eslint/no-namespace */

export enum CallStatus {
  INCOMING = 'INCOMING',
  DECLINED = 'DECLINED',
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  TIMED_OUT = 'TIMED_OUT',
}

export enum WSMessages {
  C_ACCEPT_CALL = 'accept_call',
  C_ACCEPT_REQUEST = 'accept_request',
  C_CLAIM_CONVERSATION = 'claim_conversation',
  C_CREATE_CONVERSATION = 'create_conversation',
  C_DECLINE_CALL = 'decline_call',
  C_END_CALL = 'end_call',
  C_DECLINE_REQUEST = 'decline_request',
  C_INIT_OPERATOR = 'init_operator',
  C_START_CALL = 'start_call',
  C_START_CALL_WITH_CONFIRMATION = 'start_call_with_confirmation',
  C_ADD_DEVICE_TOKEN = 'add_device_token',
  C_UPDATE_CHAT_MESSAGE_ATTRIBUTE = 'update_chat_message_attribute',
  C_DELETE_DEVICE_TOKEN = 'delete_device_token',
  C_REFRESH_VIEWER = 'refresh_viewer',
  C_ADD_RATING = 'add_rating',
  C_START_VOIP = 'start_voip',
  S_CALL_ACCEPTED = 'call_accepted',
  S_CALL_WITH_CONFIRMATION_ACCEPTED = 'call_with_confirmation_accepted',
  S_CALL_COMPLETED = 'call_completed',
  S_CALL_DECLINED = 'call_declined',
  S_CALL_WITH_CONFIRMATION_DECLINED = 'call_with_confirmation_declined',
  S_CALL_WITH_CONFIRMATION_TIMEOUT = 'call_with_confirmation_timeout',
  S_REQUEST_ACCEPTED = 'request_accepted',
  S_REQUEST_DECLINED = 'request_declined',
  S_CONVERSATION_CLAIMED = 'conversation_claimed',
  S_CONVERSATION_CREATED = 'conversation_created',
  S_END_CALL_SUCCESS = 'end_call_success',
  S_ERROR = 'error',
  S_INCOMING_CALL = 'incoming_call',
  S_INCOMING_CALL_WITH_CONFIRMATION = 'incoming_call_with_confirmation',
  S_INIT_CALL_SUCCESS = 's_init_call_success',
  S_OPERATOR_INITIALIZED = 'operator_initialized',
  S_VIEWER_REFRESHED = 'viewer_refreshed',
  S_START_VOIP_SUCCESS = 'start_voip_success',
}

export enum CallDeclineReason {
  STREAMER_DECLINED = 'streamer_declined',
  STREAMER_OFFLINE = 'streamer_offline',
  STREAMER_BUSY = 'streamer_busy',
  NO_STREAMERS_AVAILABLE = 'no_streamers_available',
  VIEWER_CANCELED = 'viewer_canceled',
  TIME_OUT = 'time_out',
  SYSTEM_ERROR = 'system_error',
  RACE_CONDITION = 'race_condition',
}

export enum ErrorCode {
  E_CALL_NOT_FOUND = 'E_CALL_NOT_FOUND',
  E_CANNOT_CREATE_CONVERSATION = 'E_CANNOT_CREATE_CONVERSATION',
  E_CONVERSATION_NOT_FOUND = 'E_CONVERSATION_NOT_FOUND',
  E_WIDGET_NOT_FOUND = 'E_WIDGET_NOT_FOUND',
  E_VIEWIER_NOT_FOUND = 'E_VIEWIER_NOT_FOUND',
  E_CALL_TYPE_ERROR = 'E_CALL_TYPE_ERROR',
}

export namespace ClientMessages {
  /**
   * Sent by operator or viewer to end video call
   */
  export interface EndCall {
    type: WSMessages.C_END_CALL;
    callId: string;
  }

  export interface StartCall {
    type: WSMessages.C_START_CALL;
    /**
     * Unique call id, generated on client side at the beginning
     */
    callId: string;
    /**
     * Twilio conversatio SID
     */
    conversationSid: string;
    /**
     * Id of videoforce operator whom we call
     * Or undefined for impersonal calls
     */
    operatorId?: string;
    /**
     * Widget id
     */
    widgetId: string;
    /**
     * Viewer id
     */
    viewerId: string;
    /**
     * Webpage URL where widget is used from
     * document.location.href
     */
    pageURL?: string;
  }

  export interface UpdateChatMessageAttribute {
    type: WSMessages.C_UPDATE_CHAT_MESSAGE_ATTRIBUTE;
    conversationSid: string;
  }

  export interface StartVoip {
    type: WSMessages.C_START_VOIP;
    callId: string;
  }

  export interface StartCallWithConfirmation {
    type: WSMessages.C_START_CALL_WITH_CONFIRMATION;
    /**
     * Unique call id, generated on client side at the beginning
     */
    callId: string;
    /**
     * Twilio conversatio SID
     */
    conversationSid: string;
    /**
     * Id of videoforce operator whom we call
     * Or undefined for impersonal calls
     */
    operatorId?: string;
    /**
     * Widget id
     */
    widgetId: string;
    /**
     * Viewer id
     */
    viewerId: string;
    /**
     * Webpage URL where widget is used from
     * document.location.href
     */
    pageURL?: string;
  }

  export interface AcceptRequest {
    type: WSMessages.C_ACCEPT_REQUEST;
    callId: string;
  }
  export interface InitOperator {
    type: WSMessages.C_INIT_OPERATOR;
  }

  /**
   * Sent by streamer to accept incoming call
   */
  export interface AcceptCall {
    type: WSMessages.C_ACCEPT_CALL;
    callId: string;
  }

  /**
   * Sent by streamer to decline incoming call
   * Sent by viewer to cancel outgoing call
   */
  export interface DeclineCall {
    type: WSMessages.C_DECLINE_CALL;
    callId: string;
  }

  export interface DeclineRequest {
    type: WSMessages.C_DECLINE_REQUEST;
    callId: string;
  }

  /**
   * Sent by viewer to start conversation
   */
  export interface CreateConversation {
    type: WSMessages.C_CREATE_CONVERSATION;
    widgetId: string;
    /**
     * Undefined if we want to start impersonal conversation
     */
    operatorId?: string;
    viewerId: string;
    pageURL?: string;
  }

  /**
   * Sent by operator to claim impersonal conversation
   */
  export interface ClaimConversation {
    type: WSMessages.C_CLAIM_CONVERSATION;
    conversationSid: string;
  }

  export interface AddDeviceToken {
    type: WSMessages.C_ADD_DEVICE_TOKEN;
    deviceToken: string;
  }

  export interface DeleteDeviceToken {
    type: WSMessages.C_DELETE_DEVICE_TOKEN;
    deviceToken: string;
  }

  /**
   * Sent by viewer to refresh tokens
   */
  export interface RefreshViewer {
    type: WSMessages.C_REFRESH_VIEWER;
    viewerId: string;
    widgetId: string;
  }

  export interface AddRating {
    type: WSMessages.C_ADD_RATING;
    callId: string;
    rating?: number;
    review?: string;
  }
}

export type ClientMessages =
  | ClientMessages.AcceptCall
  | ClientMessages.EndCall
  | ClientMessages.ClaimConversation
  | ClientMessages.CreateConversation
  | ClientMessages.DeclineCall
  | ClientMessages.StartCall
  | ClientMessages.StartVoip
  | ClientMessages.StartCallWithConfirmation
  | ClientMessages.AcceptRequest
  | ClientMessages.DeclineRequest
  | ClientMessages.InitOperator
  | ClientMessages.AddDeviceToken
  | ClientMessages.DeleteDeviceToken
  | ClientMessages.RefreshViewer
  | ClientMessages.AddRating
  | ClientMessages.UpdateChatMessageAttribute;

export namespace ServerMessages {
  /**
   * Sent to operator in response to init
   */
  export interface OperatorInitialized {
    type: WSMessages.S_OPERATOR_INITIALIZED;
    activeCall: {
      token: string;
      callId: string;
      streamerId: string;
      viewerId: string;
    } | null;
    incomingCall: {
      durationLimit?: number;
      callId: string;
      widgetId: string;
      widgetName: string;
      sourcePage?: string;
    } | null;
  }

  /**
   * Sent to streamer when viewer calls him
   */
  export interface IncomingCall {
    type: WSMessages.S_INCOMING_CALL;
    // In minutes
    durationLimit?: number;
    callId: string;
    widgetId: string;
    widgetName: string;
    sourcePage?: string;
  }

  export interface IncomingCallWithConfirmation {
    type: WSMessages.S_INCOMING_CALL_WITH_CONFIRMATION;
    durationLimit?: number;
    callId: string;
    widgetId: string;
    widgetName: string;
    sourcePage?: string;
    callConfirmationTimeout: number;
    isImpersonalCall: boolean;
  }

  export interface InitCallSuccess {
    type: WSMessages.S_INIT_CALL_SUCCESS;
    callId: string;
  }

  /**
   * Sent to both streamer and viewer
   */
  export interface CallAccepted {
    type: WSMessages.S_CALL_ACCEPTED;
    token: string;
    callId: string;
    streamerId: string;
    viewerId: string;
  }

  export interface CallWithConfirmationAccepted {
    type: WSMessages.S_CALL_WITH_CONFIRMATION_ACCEPTED;
    token: string;
    callId: string;
    streamerId: string;
    viewerId: string;
  }

  /**
   * Call was declined
   * Sent to both streamer and viewer
   **/
  export interface CallDeclined {
    type: WSMessages.S_CALL_DECLINED;
    callId: string;
    reason: CallDeclineReason;
  }

  export interface CallWithConfirmationDeclined {
    type: WSMessages.S_CALL_WITH_CONFIRMATION_DECLINED;
    callId: string;
    reason: CallDeclineReason;
  }

  export interface RequestDeclined {
    type: WSMessages.S_REQUEST_DECLINED;
    callId: string;
    reason: CallDeclineReason;
  }

  export interface RequestAccepted {
    type: WSMessages.S_REQUEST_ACCEPTED;
    callId: string;
    operatorId: string;
    widgetName: string;
    sourcePage?: string;
  }

  /**
   * Sent to both streamer and viewer
   */
  export interface CallCompleted {
    type: WSMessages.S_CALL_COMPLETED;
    callId: string;
    streamerId: string;
    viewerId: string;
    endedBy: 'streamer' | 'viewer' | 'system';
  }

  /**
   * Sent to viewer in response to C_CREATE_CONVERSATION
   */
  export interface ConversationCreated {
    type: WSMessages.S_CONVERSATION_CREATED;
    conversationSid: string;
  }

  export interface EndCallSuccess {
    type: WSMessages.S_END_CALL_SUCCESS;
    callId: string;
    endedBy: 'operator' | 'viewer';
    operatorId: string;
    viewerId: string;
  }

  export interface Error {
    type: WSMessages.S_ERROR;
    code: ErrorCode;
    /**
     * Error id to search in server logs
     */
    id: string;
    message: string;
  }

  export interface ConversationClaimed {
    type: WSMessages.S_CONVERSATION_CLAIMED;
    conversationSid: string;
    success: boolean;
  }

  export interface ViewerRefreshed {
    type: WSMessages.S_VIEWER_REFRESHED;
    viewerId: string;
    widgetId: string;
    conversationsToken: string;
  }

  export interface StartVoipSuccess {
    type: WSMessages.S_START_VOIP_SUCCESS;
    callId: string;
  }
}

export type ServerMessages =
  | ServerMessages.CallAccepted
  | ServerMessages.CallWithConfirmationAccepted
  | ServerMessages.CallCompleted
  | ServerMessages.CallDeclined
  | ServerMessages.ConversationCreated
  | ServerMessages.ConversationClaimed
  | ServerMessages.EndCallSuccess
  | ServerMessages.Error
  | ServerMessages.IncomingCall
  | ServerMessages.IncomingCallWithConfirmation
  | ServerMessages.InitCallSuccess
  | ServerMessages.OperatorInitialized
  | ServerMessages.ViewerRefreshed
  | ServerMessages.RequestAccepted
  | ServerMessages.RequestDeclined
  | ServerMessages.CallWithConfirmationDeclined;

/**
 * Wrapper around system messages sent via twilio.conversations
 */
export interface ConversationEnvelop<
  T extends ServerMessages = ServerMessages,
> {
  recipients: string[];
  payload: T;
}
