import { ScenarioStepButtonType } from '__generated__/types';
import { Menu, MenuItem } from '@material-ui/core';
import { ScenarioStep, ScenarioStepCtaButton } from 'components/scenario/types';
import { bindPopover } from 'material-ui-popup-state';
import { PopupState } from 'material-ui-popup-state/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface CtaButtonMenuProps {
  popupState: PopupState;
  index: number;
  button: ScenarioStepCtaButton;
}

export const CtaButtonMenu: React.FC<CtaButtonMenuProps> = ({
  popupState,
  index,
  button,
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<ScenarioStep>();

  const addedButtonsList = watch('buttons');
  const isVideochatButtonExist = addedButtonsList.find(
    (button) => button.type === ScenarioStepButtonType.OpenVideochat,
  );

  return (
    <Menu
      getContentAnchorEl={undefined}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <MenuItem
        onClick={() => {
          setValue(`buttons.${index}`, {
            id: button.id,
            type: ScenarioStepButtonType.CssSelector,
            name: button.name,
            selector: '',
          });
          popupState.close();
        }}
      >
        {t(
          `components:scenario.dialog.step.button.selectedAction.${ScenarioStepButtonType.CssSelector}`,
        )}
      </MenuItem>

      <MenuItem
        onClick={() => {
          setValue(`buttons.${index}`, {
            id: button.id,
            type: ScenarioStepButtonType.FollowUrl,
            name: button.name,
            url: '',
          });
          popupState.close();
        }}
      >
        {t(
          `components:scenario.dialog.step.button.selectedAction.${ScenarioStepButtonType.FollowUrl}`,
        )}
      </MenuItem>

      {!isVideochatButtonExist && (
        <MenuItem
          onClick={() => {
            setValue(`buttons.${index}`, {
              id: button.id,
              type: ScenarioStepButtonType.OpenVideochat,
              name: button.name,
            });
            popupState.close();
          }}
        >
          {t(
            `components:scenario.dialog.step.button.selectedAction.${ScenarioStepButtonType.OpenVideochat}`,
          )}
        </MenuItem>
      )}
    </Menu>
  );
};
