import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      justifyContent: 'center',
    },
  }),
);

export const SubscriptionAddedDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const closeDialog = () => {
    navigate({ ...location, search: undefined }, { replace: true });
  };

  return (
    <Dialog open>
      <DialogTitle>{t('components:shopifyModal.success.title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('components:shopifyModal.success.description')}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button color="primary" variant="contained" onClick={closeDialog}>
          {t('components:shopifyModal.success.button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
