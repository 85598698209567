/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CloseAlertMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
}>;


export type CloseAlertMutation = { __typename?: 'Mutation', closeAlert: { __typename: 'CloseAlertSuccess', alert: { __typename?: 'AlertPromoActivated', id: string, closed?: boolean | null } } | { __typename: 'Error', id: string, message: string } | { __typename: 'NotFoundError', id: string, message: string } };


export const CloseAlertDocument = gql`
    mutation closeAlert($id: ID!) {
  closeAlert(id: $id) {
    __typename
    ... on IError {
      id
      message
    }
    ... on CloseAlertSuccess {
      alert {
        id
        closed
      }
    }
  }
}
    `;
export type CloseAlertMutationFn = Apollo.MutationFunction<CloseAlertMutation, CloseAlertMutationVariables>;

/**
 * __useCloseAlertMutation__
 *
 * To run a mutation, you first call `useCloseAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeAlertMutation, { data, loading, error }] = useCloseAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCloseAlertMutation(baseOptions?: Apollo.MutationHookOptions<CloseAlertMutation, CloseAlertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseAlertMutation, CloseAlertMutationVariables>(CloseAlertDocument, options);
      }
export type CloseAlertMutationHookResult = ReturnType<typeof useCloseAlertMutation>;
export type CloseAlertMutationResult = Apollo.MutationResult<CloseAlertMutation>;
export type CloseAlertMutationOptions = Apollo.BaseMutationOptions<CloseAlertMutation, CloseAlertMutationVariables>;