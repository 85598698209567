import {
  useClaimConversation,
  useConversationOperator,
} from '@videoforce/client';
import { Env } from 'config/env';
import { useState } from 'react';

export const useAddClaimConversation = (): {
  addClaimConversation: () => Promise<boolean | null>;
  claimConversationLoading: boolean;
} => {
  const { conversation } = useConversationOperator();
  const initClaimConversation = useClaimConversation();
  const [claimConversationLoading, setClaimConversationLoading] =
    useState(false);

  const addClaimConversation = async (): Promise<boolean | null> => {
    if (conversation) {
      setClaimConversationLoading(true);
      const success = await initClaimConversation(
        conversation?.sid,
        Env.httpApiURL,
      );
      setClaimConversationLoading(false);
      return success;
    }

    return null;
  };

  return { addClaimConversation, claimConversationLoading };
};
