import { IntroTourStep } from './constants';

export interface IntroTourState {
  stepIndex: IntroTourStep;
  run: boolean;
}

export const INTRO_TOUR_DEFAULT_STATE: IntroTourState = {
  stepIndex: IntroTourStep.CREATE_WIDGET,
  run: false,
};

export type IntroTourAction =
  | {
      type: 'intro:loading';
      step: IntroTourStep;
    }
  | {
      type: 'intro:ready';
      step: IntroTourStep;
      // Means that we do not need to pause and wait till step ui is rendered
      skipLoading?: true;
    }
  | {
      type: 'intro:next';
    }
  | { type: 'intro:end' };

export function introTourReducer(
  state: IntroTourState,
  action: IntroTourAction,
): IntroTourState {
  switch (action.type) {
    case 'intro:loading': {
      return state.stepIndex + 1 === action.step
        ? { run: false, stepIndex: action.step }
        : state;
    }
    case 'intro:ready': {
      if (action.skipLoading) {
        return state.stepIndex + 1 === action.step
          ? { stepIndex: action.step, run: true }
          : state;
      }
      return state.stepIndex === action.step && state.run === false
        ? { ...state, run: true }
        : state;
    }
    case 'intro:next': {
      return { ...state, stepIndex: state.stepIndex + 1 };
    }
    case 'intro:end':
      return { ...state, run: false };
    default:
      return state;
  }
}

export const introTourActions = {
  loading: (step: IntroTourStep): IntroTourAction => ({
    type: 'intro:loading',
    step,
  }),
  ready: (step: IntroTourStep, skipLoading?: true): IntroTourAction => ({
    type: 'intro:ready',
    step,
    skipLoading,
  }),
  next: (): IntroTourAction => ({ type: 'intro:next' }),
  end: (): IntroTourAction => ({ type: 'intro:end' }),
};
