import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHideJivo } from 'utils/useHideJivo';

type JivoCtx = () => void;

const JivoContext = createContext<JivoCtx>(() => {});

/**
 * Jivo widget is installed on our site, but is completely hidden
 */
export const JivoProvider: FC<PropsWithChildren> = ({ children }) => {
  const [hidden, setHidden] = useState(true);
  useHideJivo(hidden);

  const openJivo = useCallback(() => {
    (window as any)?.jivo_api?.open();
    setHidden(false);
  }, [setHidden]);

  // When clicking on Jivo close button, hide widget again
  useEffect(() => {
    const onJivoClose = (e: any) => {
      const closeButton = document.getElementById('jivo_close_button');
      if (closeButton?.contains(e.target)) {
        setHidden(true);
      }
    };
    document.addEventListener('click', onJivoClose);
    return () => {
      document.removeEventListener('click', onJivoClose);
    };
  }, [setHidden]);

  return (
    <JivoContext.Provider value={openJivo}>{children}</JivoContext.Provider>
  );
};

export const useOpenJivo = () => useContext(JivoContext);
