import { Claims } from './types';

export default function getClaimsFromSession(session: any): Claims {
  const payload = session.getIdToken().payload;
  const groups = payload['cognito:groups'];
  const result: Claims = {
    id: payload.sub,
    name: payload.nickname || 'Anonymous',
    locale: payload.locale ?? 'ru',
    accountId: payload.accountId,
    owner: payload.accountId === payload.sub,
    admin: groups?.includes('admins'),
    manager: payload?.manager === 'true',
    streamer: payload?.streamer?.split(','),
    promocode: payload?.['custom:promocode'],
    conversationsToken: payload.conversationsToken,
  };
  if (groups?.includes('testers')) {
    result.tester = true;
  }
  return result;
}
