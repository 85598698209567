import React from 'react';

interface Props {
  className?: string;
}

const IconConversations: React.FC<Props> = () => {
  return (
    <svg
      width="32"
      height="24"
      viewBox="0 0 32 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C7.34315 0 6 1.34315 6 3V4.5H17C19.4853 4.5 21.5 6.51472 21.5 9V17C21.5 17.3437 21.4615 17.6784 21.3885 18H23C24.6569 18 26 16.6569 26 15V12.2L29.563 15.0504C30.5451 15.8361 32 15.1368 32 13.8791V4.12094C32 2.86318 30.5451 2.16392 29.563 2.94964L26 5.8V3C26 1.34315 24.6569 0 23 0H9ZM19.8293 18C19.4175 19.1652 18.3062 20 17 20H9.15625L5.72954 23.6552C5.10952 24.3165 4 23.8778 4 22.9712V20H3C1.34315 20 0 18.6569 0 17V9C0 7.34315 1.34315 6 3 6H6H17C18.6569 6 20 7.34315 20 9V17C20 17.3506 19.9398 17.6872 19.8293 18Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconConversations;
