import React from 'react';

interface Props {
  className?: string;
}

const IconMicOff: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3743 6.08969V5.3742C20.3743 2.40611 17.9682 0 15.0001 0C12.032 0 9.6259 2.40611 9.6259 5.37419L9.6259 13.2508C9.6259 14.2769 9.91349 15.2359 10.4125 16.0515L20.3743 6.08969ZM20.3743 13.1608L14.9108 18.6243C14.9405 18.6247 14.9703 18.625 15.0001 18.625C17.9682 18.625 20.3743 16.2189 20.3743 13.2508V13.1608ZM9.1015 17.3625C8.58017 16.5339 8.29496 15.5923 8.16152 14.5977C8.04643 13.7398 7.36293 13.0345 6.49736 13.0345C5.6318 13.0345 4.91912 13.7382 5.00974 14.599C5.20295 16.4343 5.76184 18.1687 6.83858 19.6254L9.1015 17.3625ZM10.7947 22.7403L13.2854 20.2496C13.843 20.3863 14.4188 20.4569 15.0019 20.4569C16.9416 20.4569 18.8019 19.6749 20.1735 18.2829C21.1761 17.2655 21.6564 15.983 21.8423 14.5977C21.9574 13.7398 22.6409 13.0345 23.5064 13.0345C24.372 13.0345 25.0847 13.7382 24.994 14.599C24.7589 16.8324 23.9823 18.9163 22.3899 20.5323C20.8094 22.1363 18.7747 23.1733 16.5925 23.5144V27H18.4994C19.3278 27 19.9994 27.6716 19.9994 28.5C19.9994 29.3284 19.3278 30 18.4994 30H11.4994C10.671 30 9.99942 29.3284 9.99942 28.5C9.99942 27.6716 10.671 27 11.4994 27H13.4119V23.5145C12.5069 23.3731 11.6272 23.112 10.7947 22.7403Z"
        fill="white"
      />
      <path
        d="M2 28L28 2"
        stroke="#FF5A44"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconMicOff;
