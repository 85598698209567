import { createStyles, makeStyles } from '@material-ui/core';
import { Conversation, useConversationMessages } from '@videoforce/client';
import { useAuth } from 'auth';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChatInput } from '../ChatInput';
import { Messages } from '../Messages';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      minWidth: '360px',
      maxWidth: '360px',
      height: '416px',
      borderRadius: '16px',
      boxShadow: '0px 8px 69px -7px rgba(0, 0, 0, 0.15)',
      overflow: 'hidden',
    },
    header: {
      minHeight: '64px',
      padding: '23px 25px',
      fontSize: '16px',
      color: '#171717',
      background: '#FFFFFF',
    },
    body: {
      backgroundColor: '#F3F5F8',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '70%',
      boxSizing: 'border-box',
    },
    chatInputOverride: {
      marginTop: 0,
      borderTop: 'none',
    },
  }),
);

interface ChatCompactProps {
  conversation?: Conversation | null;
}

interface ConversationAttributes {
  nickname?: string[];
}

export const ChatCompact: React.FC<ChatCompactProps> = (props) => {
  const { conversation } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const messages = useConversationMessages(conversation);
  const auth = useAuth();
  const myId = auth?.claims.id;

  if (!conversation) {
    return null;
  }

  let nickname = conversation.sid;

  if (conversation.attributes) {
    const { nickname: attributesNickname } =
      conversation.attributes as ConversationAttributes;
    if (attributesNickname?.length) {
      nickname = t(attributesNickname[0]) + ' ' + t(attributesNickname[1]);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {conversation.friendlyName ?? nickname}
      </div>

      <div className={classes.body}>
        <Messages messages={messages} myId={myId} />
      </div>

      <ChatInput
        className={classes.chatInputOverride}
        conversation={conversation}
      />
    </div>
  );
};
