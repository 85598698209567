import { Tab, Tabs } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { StateScenarioDialog } from 'components/scenario/useScenario';
import { StateUploadDialog } from 'components/video';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import { ScenarioTab } from './scenario';
import { VideoLibraryTab } from './video';
import { VideoLibraryHeader } from './VideoLibraryHeader';
import { VideoLibraryTabPanel } from './VideoLibraryTabPanel';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    layout: {
      flex: 1,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
    },
    page: {
      flexGrow: 1,
      padding: '60px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      overflowY: 'auto',
      position: 'relative',
    },
    paper: {
      backgroundColor: '#fafafa',
      marginBottom: spacing(5),
    },
  }),
);

export enum TabsVariant {
  Video,
  Scenario,
}

const VideoLibraryPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(TabsVariant.Video);
  const [initialStateVideoDialog, setInitialStateVideoDialog] =
    useState<StateUploadDialog>({
      state: 'selecting',
    });
  const [videoDialogOpened, toggleVideoDialogOpened] = useToggle(false);
  const [scenarioDialogOpened, toggleScenarioDialog] = useToggle(false);
  const [initialStateScenario, setInitialStateScenario] =
    useState<StateScenarioDialog>({ state: 'create_scenario' });

  return (
    <div className={classes.layout}>
      <div className={classes.page}>
        <VideoLibraryHeader
          toggleVideoDialogOpened={toggleVideoDialogOpened}
          toggleScenarioDialog={toggleScenarioDialog}
          setInitialStateVideoDialog={setInitialStateVideoDialog}
          setInitialStateScenario={setInitialStateScenario}
          selectedTab={selectedTab}
        />

        <Paper square elevation={0} className={classes.paper}>
          <Tabs
            indicatorColor="primary"
            value={selectedTab}
            onChange={(_, newValue) => setSelectedTab(newValue)}
          >
            <Tab
              label={t('routes:videoLibrary.tabs.video.title')}
              id="library-tab-1"
            />
            <Tab
              label={t('routes:videoLibrary.tabs.scenario.title')}
              id="library-tab-2"
            />
          </Tabs>
        </Paper>

        <VideoLibraryTabPanel
          selectedTab={selectedTab}
          index={TabsVariant.Video}
        >
          <VideoLibraryTab
            initialState={initialStateVideoDialog}
            videoDialogOpened={videoDialogOpened}
            toggleVideoDialogOpened={toggleVideoDialogOpened}
          />
        </VideoLibraryTabPanel>

        <VideoLibraryTabPanel
          selectedTab={selectedTab}
          index={TabsVariant.Scenario}
        >
          <ScenarioTab
            initialStateScenario={initialStateScenario}
            setInitialStateScenario={setInitialStateScenario}
            scenarioDialogOpened={scenarioDialogOpened}
            toggleScenarioDialog={toggleScenarioDialog}
          />
        </VideoLibraryTabPanel>
      </div>
    </div>
  );
};

export default VideoLibraryPage;
