import React from 'react';

interface Props {
  className?: string;
}

const IconCallEnd: React.FC<Props> = () => {
  return (
    <svg
      width="37"
      height="14"
      viewBox="0 0 37 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.614 4.34849C21.1216 2.8861 16.2505 2.89662 11.7475 4.3485L11.7475 8.97767C11.7475 9.5458 11.4214 10.0613 10.9269 10.3033C9.35929 11.0924 7.87585 12.155 6.57126 13.4596C5.99262 14.0382 5.04574 14.0382 4.46709 13.4596L0.795314 9.78778C0.216666 9.20913 0.216666 8.26225 0.795314 7.68361C10.6744 -2.19548 26.6871 -2.19548 36.5662 7.68361C37.1449 8.26225 37.1449 9.20913 36.5662 9.78778L32.8839 13.4701C32.3053 14.0487 31.3584 14.0487 30.7798 13.4701C29.4647 12.155 27.9917 11.1029 26.4241 10.3138C25.9402 10.0613 25.614 9.56684 25.614 8.97767V4.34849Z"
        fill="white"
      />
    </svg>
  );
};

export default IconCallEnd;
