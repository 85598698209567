import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useFlipCameraToggle,
  useLocalAudioToggle,
  useLocalVideoToggle,
  useOperatorCallActions,
  useOperatorCallState,
  useTwilioVideo,
} from '@videoforce/client';
import clsx from 'clsx';
import Spinner, { SpinnerColorEnum } from 'components/Spinner';
import fscreen from 'fscreen';
import React from 'react';
import { isAndroid, isDesktop, isIOS, isMobile } from 'react-device-detect';

import IconCallEnd from '../icons/IconCallEnd';
import IconChatOff from '../icons/IconChatOff';
import IconChatOn from '../icons/IconChatOn';
import IconFullScreenOff from '../icons/IconFullScreenOff';
import IconFullScreenOn from '../icons/IconFullScreenOn';
import IconMicOff from '../icons/IconMicOff';
import IconMicOn from '../icons/IconMicOn';
import IconScreenShareOff from '../icons/IconScreenShareOff';
import IconScreenShareOn from '../icons/IconScreenShareOn';
import IconToggleCamera from '../icons/IconToggleCamera';
import IconVideoCamOff from '../icons/IconVideoCamOff';
import IconVideoCamOn from '../icons/IconVideoCamOn';
import CallControlButton from './CallControlButton';
import { CallViewProps } from './types';

const useStyles = makeStyles((theme) =>
  createStyles({
    show: {
      visibility: 'visible',
    },
    showMobile: {
      visibility: 'visible',
    },
    hideMobile: {
      visibility: 'hidden',
    },
    buttons: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      alignItems: 'start',
      justifyContent: 'center',
      paddingTop: theme.spacing(2),
      paddingBottom: '10px',
      '& .MuiIconButton-label': {
        color: 'white',
      },
    },
    button: {
      width: '66px',
      height: '66px',
      backgroundColor: 'rgba(203, 203, 203, 0.2)',
      backdropFilter: 'blur(5px)',
      borderRadius: '66px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        background: 'rgba(203, 203, 203, 0.32)',
      },
      '&:active': {
        background: 'rgba(203, 203, 203, 0.45)',
      },
      '&:hover + .buttonHint': {
        visibility: 'visible',
      },
    },
    buttonMobile: {
      width: '56px',
      height: '56px',
    },
    callEnd: {
      backgroundColor: '#F53D31',
      '&:hover': {
        backgroundColor: '#DB3B31',
      },
      '&:active': {
        backgroundColor: '#C83229',
      },
    },
    buttonZoom: {
      position: 'absolute',
      top: '25px',
      right: '25px',
      background: 'none',
    },
    buttonZoomMobile: {
      top: '50px',
      right: '10px',
    },
    buttonToggleCameraIos: {
      position: 'fixed',
      top: '50px',
      right: '25px',
    },
    buttonToggleCameraAndroid: {
      position: 'relative',
    },
    buttonToggleChat: {
      position: 'fixed',
      top: '50px',
      left: '25px',
      marginLeft: 0,
    },
  }),
);

interface Props extends CallViewProps {
  chatVisible: boolean;
  setChatVisible: React.Dispatch<React.SetStateAction<boolean>>;
  audioTrackClient?: boolean;
  isShowButtons: boolean;
}

const CallControls = React.memo((props: Props) => {
  const { chatVisible, setChatVisible, fullscreenHandle, isShowButtons } =
    props;

  const { activeCall } = useOperatorCallState();
  const { endCall } = useOperatorCallActions();
  const { isSharingScreen, toggleScreenShare } = useTwilioVideo();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { toggleFacingMode, flipCameraSupported } = useFlipCameraToggle();

  const classes = useStyles();
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={clsx({
        // данные css классы определяют - показывать / скрывать кнопки управления вызовом
        // на декстопе класс show - по умолчанию данный компонент показываем
        // класс showMobile - отображает кнопки, если мобильный и был сделан тап по экрану (mobileClick из CallView)
        // класс hideMobile - по умолчанию скрывает кнопки, если мобильный или был сделан тап по экрану после true (mobileClick из CallView)
        [classes.show]: isDesktop,
        [classes.showMobile]: isMobile && isShowButtons,
        [classes.hideMobile]: isMobile && !isShowButtons,
      })}
    >
      <div className={classes.buttons}>
        <CallControlButton
          className={clsx({ [classes.buttonToggleChat]: isMobile })}
          enabled={chatVisible}
          hint="chat"
        >
          <IconButton
            className={clsx(classes.button, {
              [classes.buttonMobile]: isMobile,
            })}
            onClick={() => setChatVisible((v) => !v)}
          >
            {chatVisible ? <IconChatOn /> : <IconChatOff />}
          </IconButton>
        </CallControlButton>
        {flipCameraSupported && isMobile && (
          <CallControlButton
            className={clsx({
              [classes.buttonToggleCameraIos]: isIOS,
              [classes.buttonToggleCameraAndroid]: isAndroid,
            })}
            hint="toggleCamera"
          >
            <IconButton
              className={clsx(classes.button, {
                [classes.buttonMobile]: isMobile,
              })}
              onClick={toggleFacingMode}
            >
              <IconToggleCamera />
            </IconButton>
          </CallControlButton>
        )}
        <CallControlButton enabled={isVideoEnabled} hint="video">
          <IconButton
            className={clsx(classes.button, {
              [classes.buttonMobile]: isMobile,
            })}
            onClick={toggleVideoEnabled}
          >
            {isVideoEnabled ? <IconVideoCamOn /> : <IconVideoCamOff />}
          </IconButton>
        </CallControlButton>
        )
        <CallControlButton enabled={isAudioEnabled} hint="audio">
          <IconButton
            className={clsx(classes.button, {
              [classes.buttonMobile]: isMobile,
            })}
            onClick={toggleAudioEnabled}
          >
            {isAudioEnabled ? <IconMicOn /> : <IconMicOff />}
          </IconButton>
        </CallControlButton>
        )
        {!isMobile && !!toggleScreenShare && (
          <CallControlButton enabled={isSharingScreen} hint="demonstration">
            <IconButton
              className={clsx(classes.button, {
                [classes.buttonMobile]: isMobile,
              })}
              onClick={toggleScreenShare}
            >
              {isSharingScreen ? <IconScreenShareOff /> : <IconScreenShareOn />}
            </IconButton>
          </CallControlButton>
        )}
        <CallControlButton hint="callEnd">
          <IconButton
            className={clsx(classes.callEnd, classes.button, {
              [classes.buttonMobile]: isMobile,
            })}
            onClick={() => {
              if (activeCall) {
                endCall(activeCall);
              }
            }}
          >
            {activeCall?.ending ? (
              <Spinner color={SpinnerColorEnum.GRAY} />
            ) : (
              <IconCallEnd />
            )}
          </IconButton>
        </CallControlButton>
      </div>

      {fscreen.fullscreenEnabled && fullscreenHandle && (
        <IconButton
          className={clsx(classes.button, classes.buttonZoom, {
            [classes.buttonMobile]: isMobile,
            [classes.buttonZoomMobile]: isMobile,
          })}
          onClick={
            fullscreenHandle?.active
              ? fullscreenHandle?.exit
              : fullscreenHandle?.enter
          }
        >
          {fullscreenHandle?.active ? (
            <IconFullScreenOff />
          ) : (
            <IconFullScreenOn />
          )}
        </IconButton>
      )}
    </div>
  );
});

export default CallControls;
