import { Fade, Typography } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useEffect } from 'react';
import useToggle from 'react-use/lib/useToggle';

import { TEST_MODE } from '../config/env';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    container: {
      width: '100%',
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inner: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      '& > * + *': {
        marginTop: spacing(2),
      },
    },
  }),
);

const fadeStyle = { transitionDelay: TEST_MODE ? '0ms' : '500ms' };

interface LoadingProps {
  title?: string;
  className?: string;
}

const Loading: FC<LoadingProps> = ({ title, className }) => {
  const classes = useStyles();
  const [mounted, toggleMounted] = useToggle(false);

  useEffect(() => {
    toggleMounted();
    return () => toggleMounted();
  }, [toggleMounted]);

  return (
    <div className={clsx(classes.container, className)}>
      <Fade in={mounted} style={fadeStyle}>
        <div className={classes.inner}>
          <CircularProgress />
          {!!title && <Typography variant="subtitle2">{title}</Typography>}
        </div>
      </Fade>
    </div>
  );
};

export default Loading;
