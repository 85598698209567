import { useAuth } from 'auth';
import React from 'react';
import { Navigate, PathRouteProps, useLocation } from 'react-router-dom';

interface PrivateRouteProps extends PathRouteProps {
  admin?: boolean;
  owner?: boolean;
  manager?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const { admin, owner, manager } = props;
  const location = useLocation();
  const user = useAuth();

  const allowed =
    user &&
    (!admin || user.claims.admin) &&
    (!owner || user.claims.owner || user.claims.admin) &&
    (!manager || user.claims.admin || user.claims.manager || user.claims.owner);

  const pathname = user ? '/' : '/auth';

  return allowed ? <>{props.children}</> : <Navigate to={pathname} />;
};

export default PrivateRoute;
