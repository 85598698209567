import Dialog from '@material-ui/core/Dialog';
import React, { FC } from 'react';

import { SelectVideoParams } from '../select';
import StepRecording from './recordedVideo/StepRecording';
import StepComplete from './StepComplete';
import StepCompleteVideoList from './StepCompleteVideoList';
import StepEncoding from './StepEncoding';
import StepSaving from './StepSaving';
import StepSelecting from './StepSelecting';
import StepUploading from './StepUploading';
import useUploadDialog, { StateUploadDialog } from './useUploadDialog';

export enum UploadVideoDialogStart {
  LIST = 'LIST',
  WIDGET = 'WIDGET',
  USER = 'USER',
}

export interface UploadVideoDialogProps {
  widgetId?: string;
  onClose: () => void;
  selectVideo?: (params: SelectVideoParams) => Promise<void> | void;
  startFrom?: UploadVideoDialogStart;
  initialState: StateUploadDialog;
  initialAudioValue?: boolean;
}

const UploadVideoDialog: FC<UploadVideoDialogProps> = ({
  widgetId,
  onClose,
  selectVideo,
  startFrom,
  initialState,
  initialAudioValue,
}) => {
  const [state, dispatch] = useUploadDialog(initialState);

  return (
    <Dialog open>
      {state.state === 'selecting' && (
        <StepSelecting
          onSuccess={(file) => dispatch({ type: 'selected', file })}
          onCancel={onClose}
        />
      )}

      {state.state === 'uploading' && (
        <StepUploading
          file={state.file}
          onSuccess={(s3key, name) =>
            dispatch({ type: 'uploaded', s3key, name })
          }
          onCancel={onClose}
        />
      )}

      {state.state === 'saving' && (
        <StepSaving
          onCancel={onClose}
          s3key={state.s3Key}
          name={state.name}
          onSuccess={(videoId) => dispatch({ type: 'saved', videoId })}
        />
      )}

      {state.state === 'encoding' && (
        <StepEncoding
          videoId={state.videoId}
          onCancel={onClose}
          onSuccess={(video) => dispatch({ type: 'encoded', video })}
        />
      )}

      {state.state === 'recording' && (
        <StepRecording
          onCancel={
            startFrom === UploadVideoDialogStart.LIST
              ? onClose
              : () => dispatch({ type: 'recorded' })
          }
          onSuccess={(file) => dispatch({ type: 'selected', file })}
          initialAudioValue={initialAudioValue}
        />
      )}

      {state.state === 'complete' &&
        startFrom === UploadVideoDialogStart.LIST && (
          <StepCompleteVideoList video={state.video} onDone={onClose} />
        )}

      {state.state === 'complete' &&
        startFrom !== UploadVideoDialogStart.LIST && (
          <StepComplete
            onDone={onClose}
            widgetId={widgetId}
            selectVideo={selectVideo}
            onAddNewVideo={() => dispatch({ type: 'init' })}
            onRecordingVideo={() => dispatch({ type: 'record' })}
          />
        )}
    </Dialog>
  );
};

export default UploadVideoDialog;
