import { ScenarioStepType } from '__generated__/types';
import { ScenarioStepCtaButton } from 'components/scenario/types';
import { Reorder, useDragControls } from 'framer-motion';
import { bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { StepButton } from '../StepButton';
import { CtaButtonMenu } from './CtaButtonMenu';
import { CtaButtonSettings } from './CtaButtonSettings';

interface CtaButtonsProps {
  button: ScenarioStepCtaButton;
  index: number;
}

export const CtaButton: React.FC<CtaButtonsProps> = ({ button, index }) => {
  const dragControls = useDragControls();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'answerButton',
  });

  const triggerProps = bindTrigger(popupState);
  const trigger = (e: React.MouseEvent) => triggerProps.onClick(e);

  return (
    <Reorder.Item
      key={button.id}
      value={button}
      dragListener={false}
      dragControls={dragControls}
    >
      <StepButton
        key={button.id}
        button={button}
        index={index}
        actionTitle={ScenarioStepType.Cta}
        trigger={trigger}
        dragControls={dragControls}
      >
        <CtaButtonSettings button={button} index={index} key={button.id} />
      </StepButton>

      <CtaButtonMenu popupState={popupState} index={index} button={button} />
    </Reorder.Item>
  );
};
