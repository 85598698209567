import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ScenarioFormData } from 'components/scenario/mutations';
import { ScenarioIntention, ScenarioStep } from 'components/scenario/types';
import React from 'react';
import { UseFieldArrayReplace } from 'react-hook-form';

import { CreatedStepCard } from './CreatedStepCard';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    wrapper: {
      width: 'auto',
      marginRight: '36px',
    },
    root: {
      display: 'flex',
      overflowX: 'visible',
      overflowY: 'hidden',
      marginBottom: spacing(2),
      '&::-webkit-scrollbar ': {
        height: '8px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '10px',
        height: '10px',
      },
    },
  }),
);

interface CreatedStepsProps {
  stepsList: ScenarioStep[];
  updateStep: (step: ScenarioStep, intention: ScenarioIntention) => void;
  replace: UseFieldArrayReplace<ScenarioFormData, 'steps'>;
  intention: ScenarioIntention;
}

export const CreatedStepsList: React.FC<CreatedStepsProps> = ({
  stepsList,
  updateStep,
  replace,
  intention,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        {stepsList.map((step, index) => {
          return (
            <CreatedStepCard
              key={step.order}
              index={index}
              step={step}
              updateStep={updateStep}
              intention={intention}
              replace={replace}
              stepsList={stepsList}
            />
          );
        })}
      </div>
    </div>
  );
};
