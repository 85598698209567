import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useOperatorCallActions,
  useOperatorCallState,
} from '@videoforce/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: '16px',
        width: 379,
      },
    },
    modal: {
      maxWidth: 530,
      padding: spacing(4),
      [breakpoints.up('sm')]: {
        minWidth: 360,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: '20px',
    },
    headerItem: {
      fontWeight: 600,
      fontSize: '20px',
      color: '#171717',
      width: '80%',
      textAlign: 'center',
    },
  }),
);

export const DeclineViewerDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { declinedRequest } = useOperatorCallState();
  const { endedCallOperator } = useOperatorCallActions();

  if (!declinedRequest) {
    return null;
  }

  return (
    <Dialog open className={classes.dialog}>
      <DialogContent className={classes.modal}>
        <div className={classes.header}>
          <div className={classes.headerItem}>
            {t('components:requestCall.declineViewerDialog.title')}
          </div>
        </div>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={endedCallOperator}
        >
          {t('components:requestCall.declineViewerDialog.button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
};
