import { useEffect, useState } from 'react';

import { isShouldUseSw } from './isShouldUseSw';
import { SWIcoming } from './types';

export function useServiceWorkerVersion() {
  const [version, setVersion] = useState('');

  useEffect(() => {
    const listener = (e: MessageEvent) => {
      if (e.data.version) {
        setVersion(`(${e.data.version})`);
      }
    };
    if (isShouldUseSw) {
      navigator.serviceWorker.addEventListener('message', listener);
      navigator.serviceWorker.ready.then((reg) => {
        reg.active?.postMessage({ type: SWIcoming.GET_VERSION });
      });
    }

    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener('message', listener);
      }
    };
  }, [setVersion]);

  return version;
}
