import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import BugReportIcon from '@material-ui/icons/BugReport';
import ChatIcon from '@material-ui/icons/Chat';
import EmojiObjectsSharp from '@material-ui/icons/EmojiObjectsSharp';
import InfoIcon from '@material-ui/icons/Info';
import { useOpenJivo } from 'components/JivoProvider';
import { bindPopover } from 'material-ui-popup-state';
import { PopupState } from 'material-ui-popup-state/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useServiceWorkerVersion } from 'sw';

interface Props {
  popupState: PopupState;
  helpURL: string;
}

const HelpPopover: React.FC<Props> = ({ popupState, helpURL }) => {
  const { t } = useTranslation();
  const swVersion = useServiceWorkerVersion();
  const openJivo = useOpenJivo();

  return (
    <Menu
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'left',
      }}
    >
      <MenuItem
        href="https://forms.gle/71xKDJWHGrKXFuDB9"
        target="_blank"
        component="a"
      >
        <ListItemIcon>
          <BugReportIcon />
        </ListItemIcon>
        <ListItemText>
          {t('components:sidebar.helpPopover.bugLink')}
        </ListItemText>
      </MenuItem>

      <MenuItem
        href="https://forms.gle/u1r7k8CtkCLbQ1uQ6"
        target="_blank"
        component="a"
      >
        <ListItemIcon>
          <EmojiObjectsSharp />
        </ListItemIcon>
        <ListItemText>
          {t('components:sidebar.helpPopover.ideaLink')}
        </ListItemText>
      </MenuItem>

      <MenuItem href={helpURL} target="_blank" component="a">
        <ListItemIcon>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText>
          {t('components:sidebar.helpPopover.helpLink')}
        </ListItemText>
      </MenuItem>

      {!isMobile && (
        <MenuItem
          // href="https://jivo.chat/k5XmTsV39j"
          href="https://t.me/Artur_Azbukhanov"
          target="_blank"
          component="a"
        >
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('components:sidebar.helpPopover.helpChat')}
          />
        </MenuItem>
      )}

      <MenuItem>
        <Typography variant="caption">{`${t(
          'components:sidebar.helpPopover.version',
        )}: ${process.env.REACT_APP_VERSION} ${swVersion}`}</Typography>
      </MenuItem>
    </Menu>
  );
};

export default HelpPopover;
