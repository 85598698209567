/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { VideoDetailsFragmentDoc } from '../videoDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetVideoQueryVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']>;
}>;


export type GetVideoQuery = { __typename?: 'Query', getVideo?: { __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, createdAt: string, duration?: number | null, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null } | null };


export const GetVideoDocument = gql`
    query getVideo($id: ID) {
  getVideo(id: $id) {
    ...VideoDetails
  }
}
    ${VideoDetailsFragmentDoc}`;

/**
 * __useGetVideoQuery__
 *
 * To run a query within a React component, call `useGetVideoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVideoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVideoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVideoQuery(baseOptions?: Apollo.QueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
      }
export function useGetVideoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVideoQuery, GetVideoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVideoQuery, GetVideoQueryVariables>(GetVideoDocument, options);
        }
export type GetVideoQueryHookResult = ReturnType<typeof useGetVideoQuery>;
export type GetVideoLazyQueryHookResult = ReturnType<typeof useGetVideoLazyQuery>;
export type GetVideoQueryResult = Apollo.QueryResult<GetVideoQuery, GetVideoQueryVariables>;