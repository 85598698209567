import { useAuth } from 'auth';
import { Step } from 'react-joyride';
import { isFromPromo } from 'utils/promoUsers';

import { IntroTourStep, introTourTarget } from './constants';

interface ExtraStepData {
  nextButton: boolean;
  navHash?: string;
}

export type RichStep = Step & ExtraStepData;

export const RAW_STEPS: Record<IntroTourStep, Partial<Step> & ExtraStepData> = {
  [IntroTourStep.CREATE_WIDGET]: {
    nextButton: false,
  },
  [IntroTourStep.RENAME_WIDGET]: {
    nextButton: true,
  },
  [IntroTourStep.ADD_SELF_STREAMER_1]: {
    nextButton: false,
    navHash: 'streamers',
  },
  [IntroTourStep.ADD_SELF_STREAMER_2]: {
    nextButton: false,
  },
  [IntroTourStep.ADD_SELF_STREAMER_3]: {
    nextButton: false,
  },
  [IntroTourStep.SHOW_MINIATURE_SETTING_1]: {
    nextButton: false,
  },
  [IntroTourStep.SHOW_MINIATURE_SETTING_2]: {
    nextButton: true,
  },
  [IntroTourStep.INSTALL_CODE_1]: {
    nextButton: true,
    navHash: 'install',
  },
  [IntroTourStep.INSTALL_CODE_2]: {
    nextButton: true,
  },
  [IntroTourStep.SHOW_HELP]: {
    nextButton: false,
    placement: 'right',
  },
};

export function useSteps(): RichStep[] {
  const auth = useAuth();
  // hacky solution to customize jivo into
  const fromJivo = isFromPromo(auth?.claims.id);
  return Object.values(IntroTourStep)
    .filter((v) => typeof v === 'number')
    .map((step) => {
      // hacky solution to customize jivo into
      const customizeJivoInstall =
        fromJivo && step === IntroTourStep.INSTALL_CODE_2;
      const key = customizeJivoInstall ? `${step}.jivo` : step;
      let target = introTourTarget(step as IntroTourStep, true);
      if (customizeJivoInstall) {
        target = target + '-jivo';
      }

      return {
        ...RAW_STEPS[step as IntroTourStep],
        disableBeacon: true,
        target,
        title: `components:tours.intro.${key}.title`,
        content: `components:tours.intro.${key}.content`,
      };
    });
}
