import Alert from '@material-ui/lab/Alert';
import {FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogLayout from './DialogLayout';
import VideoDropzone from './VideoDropzone';

const MAX_FILE_SIZE = 700 * 1024 * 1024;

interface StepDropProps {
  onCancel: () => void;
  onSuccess: (file: File) => void;
}

const StepSelecting: FC<StepDropProps> = ({ onSuccess, onCancel }) => {
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const onFileDropped = useCallback(
    (file: File) => {
      // TODO: validate file size and type
      if (file.size > MAX_FILE_SIZE) {
        setError(
          t('components:video.uploadDialog.fileTooLarge', {
            value: MAX_FILE_SIZE,
          }),
        );
      } else {
        onSuccess(file);
      }
    },
    [onSuccess, setError, t],
  );

  return (
    <DialogLayout onCancel={onCancel}>
      <VideoDropzone onFileDropped={onFileDropped} />
      {!!error && <Alert severity="error">{error}</Alert>}
    </DialogLayout>
  );
};

export default StepSelecting;
