import React from 'react';

interface Props {
  className?: string;
}

const IconTariff: React.FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 25 25" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.52457 13.6256C4.53193 14.054 4.70539 14.4628 5.00837 14.7658L13.466 23.2234C14.1114 23.8688 15.1578 23.8688 15.8032 23.2234L23.2234 15.8032C23.8687 15.1578 23.8687 14.1114 23.2234 13.466L14.7657 5.00841C14.4628 4.70543 14.054 4.53197 13.6255 4.52461L6.07561 4.39488C5.14078 4.37882 4.37878 5.14082 4.39485 6.07565L4.52457 13.6256ZM8.95925 9.02751C8.43167 9.55509 7.5763 9.55509 7.04872 9.02751C6.52114 8.49994 6.52114 7.64456 7.04872 7.11698C7.5763 6.5894 8.43167 6.5894 8.95925 7.11698C9.48683 7.64456 9.48683 8.49994 8.95925 9.02751Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconTariff;
