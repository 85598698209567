import {
  Conversation,
  isConversationWithClaimedOperator as checkIsConversationWithClaimedOperator,
} from '@videoforce/client';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useAddClaimConversation } from './useAddClaimConversation';

export const useSendMessage = (
  conversation?: Conversation,
): [string, Dispatch<SetStateAction<string>>, () => void, boolean] => {
  const [textValue, setTextValue] = useState('');
  const { addClaimConversation, claimConversationLoading } =
    useAddClaimConversation();
  const isConversationWithClaimedOperator =
    checkIsConversationWithClaimedOperator(conversation);

  const simpleSendMessage = useCallback(() => {
    const message = textValue.trim();

    let oldAttributes = conversation?.attributes;
    const hasChatMessages =
      !!oldAttributes &&
      typeof oldAttributes === 'object' &&
      !Array.isArray(oldAttributes) &&
      !!oldAttributes.hasChatMessages;

    if (conversation && oldAttributes && !hasChatMessages) {
      conversation.updateAttributes({
        ...(oldAttributes as any),
        hasChatMessages: true,
      });
    }

    if (message) {
      conversation?.sendMessage(message);
    }
    setTextValue('');
  }, [textValue, conversation]);

  const sendMessageInIncomingConversation = async () => {
    const success = await addClaimConversation();
    if (success) {
      simpleSendMessage();
    }
  };

  const sendMessage = () => {
    if (isConversationWithClaimedOperator) {
      simpleSendMessage();
    } else {
      sendMessageInIncomingConversation();
    }
  };

  return [textValue, setTextValue, sendMessage, claimConversationLoading];
};
