import { Conversation } from '@twilio/conversations';

export type ConversationType = 'MY' | 'INCOMING' | 'CALLS_ONLY';

export const identifyConversationType = (
  conversation?: Conversation | null,
): ConversationType => {
  const attributes = conversation?.attributes as any;
  const claimedBy = attributes.claimedBy ?? null;
  const hasChatMessages = attributes.hasChatMessages ?? true;

  if (hasChatMessages) {
    if (claimedBy) {
      return 'MY';
    } else {
      return 'INCOMING';
    }
  }
  return 'CALLS_ONLY';
};
