import React from 'react';

import { VideoDetailsFragment } from '../videoDetails.generated';
import DialogLayout from './DialogLayout';
import { VideoPreview } from './video-preview';

interface StepCompleteVideoListProps {
  video: VideoDetailsFragment | null;
  onDone: () => void;
}

const StepCompleteVideoList: React.FC<StepCompleteVideoListProps> = ({
  video,
  onDone,
}) => {
  return (
    <DialogLayout onSubmit={onDone}>
      <VideoPreview
        video={video}
        text="createdVideoTitleDialog"
        isDialog={true}
      />
    </DialogLayout>
  );
};

export default StepCompleteVideoList;
