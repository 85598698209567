import { ScenarioStepButtonType, ScenarioStepType } from '__generated__/types';
import {
  CssSelectorButton,
  FinishScenarioButton,
  ScenarioStep,
} from 'components/scenario/types';
import { t } from 'i18next';
import { v4 } from 'uuid';

export const getDefaultStep = (order: number): ScenarioStep => ({
  type: ScenarioStepType.AnswerButtons,
  name: t('components:scenario.dialog.step.defaultValue'),
  order: order + 1,
  buttons: [
    {
      id: v4(),
      type: ScenarioStepButtonType.FinishScenario,
      name: t('components:scenario.dialog.buttons.defaultValue'),
    },
  ],
  videoId: undefined,
  video: undefined,
});

export const getDefaultAnswerButton = (): FinishScenarioButton => ({
  id: v4(),
  type: ScenarioStepButtonType.FinishScenario,
  name: t('components:scenario.dialog.buttons.defaultValue'),
});

export const getDefaultCtaButton = (): CssSelectorButton => ({
  id: v4(),
  type: ScenarioStepButtonType.CssSelector,
  name: t('components:scenario.dialog.buttons.defaultValue'),
  selector: '',
});

export const MAX_COUNT_STEP_BUTTONS = 3;
