export enum IntroTourStep {
  CREATE_WIDGET = 0,
  RENAME_WIDGET,
  ADD_SELF_STREAMER_1,
  ADD_SELF_STREAMER_2,
  ADD_SELF_STREAMER_3,
  SHOW_MINIATURE_SETTING_1,
  SHOW_MINIATURE_SETTING_2,
  INSTALL_CODE_1,
  INSTALL_CODE_2,
  SHOW_HELP,
}

export function introTourTarget(
  stepIndex: IntroTourStep | number,
  dot = false,
) {
  return `${dot ? '.' : ''}intro-tour-${stepIndex}`;
}
