import React from 'react';
import { Route, Routes } from 'react-router-dom';

import VideoLibraryPage from './VideoLibraryPage';

const VideoLibraryRoute: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<VideoLibraryPage />} />
    </Routes>
  );
};

export default VideoLibraryRoute;
