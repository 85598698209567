import React from 'react';

interface Props {
  className?: string;
}

const IconMembers: React.FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 26 16" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8459 3.42857C11.8459 5.32571 10.3259 6.85714 8.42871 6.85714C6.53157 6.85714 5.00014 5.32571 5.00014 3.42857C5.00014 1.53143 6.53157 0 8.42871 0C10.3259 0 11.8459 1.53143 11.8459 3.42857ZM20.9887 3.42857C20.9887 5.32571 19.4687 6.85714 17.5716 6.85714C15.6744 6.85714 14.143 5.32571 14.143 3.42857C14.143 1.53143 15.6744 0 17.5716 0C19.4687 0 20.9887 1.53143 20.9887 3.42857ZM8.42871 9.14286C5.76585 9.14286 0.428711 10.48 0.428711 13.1429V15.5C0.428711 15.7761 0.652569 16 0.928711 16H15.9287C16.2049 16 16.4287 15.7761 16.4287 15.5V13.1429C16.4287 10.48 11.0916 9.14286 8.42871 9.14286ZM16.7307 9.1786C17.0312 9.15653 17.315 9.14286 17.5713 9.14286C20.2342 9.14286 25.5713 10.48 25.5713 13.1429V15.5C25.5713 15.7761 25.3475 16 25.0713 16H19.2142C18.938 16 18.7142 15.7761 18.7142 15.5V13.1429C18.7142 11.5445 17.8875 10.3033 16.6778 9.36143C16.6047 9.30451 16.6383 9.18538 16.7307 9.1786Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconMembers;
