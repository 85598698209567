import { BlockReason, ShopifyIntegration } from '__generated__/types';
import {
  SHOPIFY_SUBSCRIPTION_ADDED_QS,
  SHOPIFY_WELCOME_QS,
} from '@videoforce/commons';
import { useAuth } from 'auth';
import React from 'react';
import { useLocation } from 'react-router-dom';

import { RequestSubscriptionDialog } from './RequestSubscriptionDialog';
import { ShopifyWelcomeDialog } from './ShopifyWelcomeDialog';
import { SubscriptionAddedDialog } from './SubscriptionAddedDialog';

/**
 * This component shows various Shopify dialogs if needed:
 * - Welcome
 * - Trial expired
 * - Subscription successfully purchased
 */
export const ShopifyModal: React.FC = () => {
  const auth = useAuth();
  const { search } = useLocation();
  const isSuccess = !!search?.includes(SHOPIFY_SUBSCRIPTION_ADDED_QS);
  const isWelcome = !!search?.includes(SHOPIFY_WELCOME_QS);

  const shopify = auth?.profile.integrations.find(
    (i): i is ShopifyIntegration => i.__typename === 'ShopifyIntegration',
  );

  // Do not display this dialog unless user has shopify integration
  if (!shopify) {
    return null;
  }

  // When redirected from shopify after installing the app for the first time
  if (isWelcome) {
    return <ShopifyWelcomeDialog />;
  }

  // When redirected from shopify after successfully confirming subscription, show success dialog
  if (isSuccess) {
    return <SubscriptionAddedDialog />;
  }

  // Trial is over (because balance is negative), but no shopify subscription purchased
  // Show dialog to pucrahse shopify subscription
  if (
    auth?.profile.blockReason === BlockReason.InsufficientBalance &&
    !shopify.appSubscriptionId
  ) {
    return <RequestSubscriptionDialog />;
  }

  return null;
};
