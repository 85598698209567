import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application
    },
  },
  typography: {
    fontFamily: "'Manrope', sans-serif",
    h1: {
      fontSize: '34px',
      fontWeight: 700,
      lineHeight: '46px',
    },
    h2: {
      fontSize: '27px',
      fontWeight: 400,
      lineHeight: '36px',
    },
    h3: {
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '28px',
    },
    h4: {
      fontSize: '17px',
      fontWeight: 600,
      lineHeight: '24px',
    },
    h5: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
    },
    h6: {
      fontSize: '11px',
      fontWeight: 400,
      lineHeight: '16px',
    },
  },
  palette: {
    primary: {
      light: '#5abbe8',
      main: '#3198E3',
      dark: '#22769e',
      contrastText: '#fff',
    },
    success: {
      light: '#7bd84f',
      main: '#5ACF23',
      dark: '#3e9018',
      contrastText: '#000',
    },
    error: {
      light: '#f7635a',
      main: '#F2554E',
      dark: '#ab2a22',
      contrastText: '#fff',
    },
    grey: {
      '100': '#EFEFEF',
      '200': '#CACACA',
      '400': '#9C9C9C',
      '600': '#666666',
      '800': '#333333',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: '8px',
        boxShadow: 'none',
        disableRipple: true,
      },
      label: {
        textTransform: 'none',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.MuiTypography-h1.MuiTypography-gutterBottom': {
          marginBottom: '1em',
        },
        '.MuiTypography-h2.MuiTypography-gutterBottom': {
          marginBottom: '1em',
        },
        '.MuiTypography-h3.MuiTypography-gutterBottom': {
          marginBottom: '1em',
        },
        '.MuiTypography-h4.MuiTypography-gutterBottom': {
          marginBottom: '1em',
        },
        '.MuiButton-root': {
          '&.MuiButton-contained.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.26)',
            boxShadow: 'none',
            backgroundColor: '#DCDCDC',
          },
          '&.MuiButton-contained.MuiButton-containedPrimary': {
            boxShadow: 'none',
            backgroundColor: '#3198E3',
            color: 'white',
            '&:hover': {
              backgroundColor: '#2C81BE',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#2471A9',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              boxShadow: 'none',
              backgroundColor: '#DCDCDC',
            },
          },
          '&.MuiButton-contained': {
            color: '#333',
            backgroundColor: '#ECF0F6',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#D9E2F0',
              boxShadow: 'none',
            },
            '&:active': {
              backgroundColor: '#C2CEDF',
              boxShadow: 'none',
            },
          },
          '&.MuiButton-containedSizeLarge': {
            padding: '16px 56px',
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: '600',
          },
          '&.MuiButton-textPrimary': {
            boxShadow: 'none',
            color: '#3198E3',
            '&:hover': {
              backgroundColor: '#E4F4FF',
            },
            '&:active': {
              backgroundColor: '#D5E6F2',
            },
          },
          '&.MuiButton-textSizeSmall': {
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '20px',
            padding: '6.5px 20px',
          },
          '&.MuiButton-textSizeLarge': {
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
            padding: '16px 0',
          },
          '&.MuiButton-textSecondary': {
            boxShadow: 'none',
            color: '#F2554E',
            '&:hover': {
              backgroundColor: '#FDECEA',
            },
            '&:active': {
              backgroundColor: '#FFD4CF',
            },
          },
          '&.MuiButton-containedSizeSmall': {
            padding: '6.5px 20px',
          },
        },
        '.MuiCheckbox-colorSecondary': {
          '&.Mui-checked': {
            color: '#39ABE1',
            '&:hover': {
              backgroundColor: 'rgba(0, 120, 201, 0.1)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 120, 201, 0.1)',
          },
        },
        '.MuiSwitch-switchBase.MuiSwitch-colorSecondary': {
          '&.Mui-checked': {
            color: '#39ABE1',
            '&:hover': {
              backgroundColor: 'rgba(0, 120, 201, 0.1)',
            },
          },
          '&:hover': {
            backgroundColor: 'rgba(0, 120, 201, 0.1)',
          },
        },
        '.MuiSwitch-switchBase.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track':
          {
            backgroundColor: '#39ABE1',
          },
        '.MuiCircularProgress-circle': {
          color: '#39ABE1',
        },
        '.MuiFilledInput-root': {
          'border-top-left-radius': 0,
          'border-top-right-radius': 0,
          'border-radius': '4px',
        },
        '.MuiInputBase-root': {
          '& .MuiInputBase-input': {
            fontWeight: '500',
            lineHeight: '22px',
            paddingLeft: '16px',
          },
          '&.MuiFilledInput-underline:hover:before': {
            borderBottom: '2px solid #535353',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            left: '1.2px',
            right: '1.2px',
            maxHeight: '2px',
          },
          '&.MuiFilledInput-underline:before': {
            borderBottom: '2px solid rgba(0, 0, 0, 0.42)',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            left: '1.2px',
            right: '1.2px',
            maxHeight: '2px',
          },
          '&.MuiFilledInput-underline:after': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            left: '1.2px',
            right: '1.2px',
          },
          '&.MuiFilledInput-underline.Mui-focused:after': {
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            left: '1.2px',
            right: '1.2px',
            maxHeight: '2px',
          },
          '&.MuiOutlinedInput-root': {
            borderRadius: '8px',
            '& .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #979797',
              '& span': {
                paddingRight: '11px',
              },
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              border: '1px solid #3198E3',
            },
          },
        },
        '.MuiFormLabel-root.MuiInputLabel-root': {
          fontWeight: '500',
        },

        '.MuiFormLabel-root.MuiInputLabel-formControl': {
          left: '4px',
        },

        '.MuiInputLabel-filled.MuiInputLabel-shrink': {
          fontWeight: '600',
        },
      },
    },
  },
});
