import { Language } from './languages';
import {
  OpenLobbyButtonVariant,
  WidgetPosition,
  WidgetSettings,
} from './types';

export const DEFAULT_WIDGET_SETTINGS: WidgetSettings = {
  flavour: 'CLASSIC',
  theme: 'LIGHT',
  accentColor: '#31AAE3',
  textColor: '#FFFFFF',
  position: WidgetPosition.BOTTOM_RIGHT,
  preferMiniature: true,
  greetingTimeout: null,
  enableImpersonalCall: null,
  hideWhenNoStreamers: false,
  locale: Language.ru,
  jivoId: null,
  jivoButtonVariant: null,
  visibilityRules: [],
  cta: null,
  offer: null,
  policy: null,
  disabledOn: [],
  hideLabel: false,
  metricId: null,
  metricActions: [],
  defaultVisibility: true,
  openLobbyButton: {
    variant: OpenLobbyButtonVariant.On,
    selector: null,
  },
  ratingEnabled: false,
  saveRecording: true,
  displayTextChatButton: true,
  enableCallConfirmation: false,
  callConfirmationTimeout: null,
};
