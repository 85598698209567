/* eslint-disable @typescript-eslint/no-explicit-any */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  JSON: { [key: string]: any };
};

export type AlertPromoActivated = IAlert & {
  __typename?: 'AlertPromoActivated';
  /** If true, alert should no longer be displayed */
  closed?: Maybe<Scalars['Boolean']>;
  /** Alert's UUID */
  id: Scalars['ID'];
  /** Used promocode */
  promocode: Promocode;
  /** Expiration date (next payment date) */
  trialEnds: Scalars['DateTime'];
};

export enum AnalyticsEventType {
  /** When user seen 10% of recorded video */
  Vf1_10Seen = 'VF1_10_SEEN',
  /** When user seen 20% of recorded video */
  Vf1_20Seen = 'VF1_20_SEEN',
  /** When user see 30% of recorded video */
  Vf1_30Seen = 'VF1_30_SEEN',
  /** When user seen 40% of recorded video */
  Vf1_40Seen = 'VF1_40_SEEN',
  /** When user seen 50% of recorded video */
  Vf1_50Seen = 'VF1_50_SEEN',
  /** When user seen 60% of recorded video */
  Vf1_60Seen = 'VF1_60_SEEN',
  /** When user seen 70% of recorded video */
  Vf1_70Seen = 'VF1_70_SEEN',
  /** When user seen 80% of recorded video */
  Vf1_80Seen = 'VF1_80_SEEN',
  /** When user seen 90% of recorded video */
  Vf1_90Seen = 'VF1_90_SEEN',
  /** When user close widget */
  Vf1Close = 'VF1_CLOSE',
  /** When user clicked on widget button */
  Vf1CtaClick = 'VF1_CTA_CLICK',
  /** When user hide widget */
  Vf1DisFocus = 'VF1_DIS_FOCUS',
  /** When user open widget */
  Vf1Focus = 'VF1_FOCUS',
  /** When user fully seen recorded video */
  Vf1FullSeen = 'VF1_FULL_SEEN',
  /** When call start */
  VfCalling = 'VF_CALLING',
  /** When consultant decline call */
  VfCallConsDismiss = 'VF_CALL_CONS_DISMISS',
  /** When user dislined */
  VfCallUserDismiss = 'VF_CALL_USER_DISMISS',
  /** When user open lobby */
  VfChooseConsultant = 'VF_CHOOSE_CONSULTANT',
  /** When consultant accept call */
  VfConversation = 'VF_CONVERSATION',
  /** When call end */
  VfEndcall = 'VF_ENDCALL',
  /** When user seen widget(full size) */
  VfFullWidgetStart = 'VF_FULL_WIDGET_START',
  /** When opened greeting element */
  VfInvitation = 'VF_INVITATION',
  /** When widget loaded */
  VfLoad = 'VF_LOAD',
  /** When user seen widget(mini size) */
  VfMiniWidgetStart = 'VF_MINI_WIDGET_START'
}

export type ArchivePromocodeResult = Error | ForbiddenError | NotFoundError | Promocode;

/** BillinInfo represents account's billing settings */
export type BillingInfo = {
  __typename?: 'BillingInfo';
  /** If true, payment will be withdrawn every month */
  autopaymentEnabled?: Maybe<Scalars['Boolean']>;
  /**
   * Account balance in rubles
   * Balance must be strictly positive, otherwise account is blocked
   */
  balance: Scalars['Float'];
  /** same as account id */
  id: Scalars['ID'];
  /** selected payment plan */
  plan: Plan;
  planExpires?: Maybe<Scalars['DateTime']>;
  /** End of trial period, null if not applicable to current plan or if the trial period has ended */
  trialEnds?: Maybe<Scalars['DateTime']>;
};

export type BillingInfoResult = BillingInfo | Error | ForbiddenError | NotFoundError;

export enum BlockReason {
  /** Account blocked by global admin */
  AdminBlock = 'ADMIN_BLOCK',
  /** Non-positive balance */
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  /**
   * Effective plan doesn't allow current number of widgets and/or streamer
   * This can happen when user switches from paid plan to basic plan
   */
  LimitsExceeded = 'LIMITS_EXCEEDED'
}

/**
 * Describes user's business hours.
 * User can receive notifications during this time
 */
export type BusinessHours = {
  __typename?: 'BusinessHours';
  /**
   * Business hours are used mostly to receive notifications
   * Set to false to avoid receiving notifications
   */
  enabled: Scalars['Boolean'];
  /**
   * Simple configuration (one interval per day, no holidays)
   * Sunday is at index 0, monday is at 1
   * E.g. [null, ["9:00", "18:00"], ["9:00", "18:00"], ["9:00", "18:00"], ["9:00", "18:00"], ["9:00", "18:00"], null]
   */
  simple?: Maybe<Array<Maybe<Array<Scalars['String']>>>>;
};

export type BusinessHoursInput = {
  enabled: Scalars['Boolean'];
  simple?: InputMaybe<Array<InputMaybe<Array<Scalars['String']>>>>;
};

export type CtaSettings = {
  __typename?: 'CTASettings';
  blank?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  link?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  variant: CtaVariant;
};

export type CtaSettingsInput = {
  blank?: InputMaybe<Scalars['Boolean']>;
  label?: InputMaybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  link?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
  variant: CtaVariant;
};

export enum CtaVariant {
  Click = 'CLICK',
  Link = 'LINK',
  None = 'NONE'
}

export type Call = {
  __typename?: 'Call';
  /** Status */
  compositionStatus?: Maybe<CompositionCallStatus>;
  /** When call ended */
  createdAt: Scalars['DateTime'];
  /** Duration in seconds. */
  duration?: Maybe<Scalars['Int']>;
  /** Unique id */
  id: Scalars['ID'];
  /** Media, if available */
  media?: Maybe<CallMedia>;
  /** Additional metadata which can be added using JS API */
  meta?: Maybe<CallMeta>;
  /** Operator */
  operator?: Maybe<User>;
  /** Page where widget was placed */
  pageURL?: Maybe<Scalars['String']>;
  /** Call rating, if enabled in widget settings */
  rating?: Maybe<Scalars['Int']>;
  /** Request confirmed, if call with confirnamation */
  requestConfirmed?: Maybe<Scalars['Boolean']>;
  /** Call review, if enabled in widget settings */
  review?: Maybe<Scalars['String']>;
  status: CallStatus;
  /** Call type */
  type: CallType;
  /** Viewer id (cognito unauthenticated user identity id) */
  viewerId: Scalars['String'];
  /** Widget, can be null if the widget was deleted */
  widget?: Maybe<Widget>;
};

export type CallMedia = {
  __typename?: 'CallMedia';
  /** Same as composition sid */
  id: Scalars['ID'];
  /** Size in bytes */
  size: Scalars['Int'];
  /** Video file URL, or null when forbidden */
  url?: Maybe<Scalars['String']>;
};

/** Additional metadata which can be added using JS API */
export type CallMeta = {
  __typename?: 'CallMeta';
  /** Describes metadata associated with particular call instance */
  call?: Maybe<Scalars['JSON']>;
  /** Describes widget viewer. E.g. can be used to set customer's internal user id */
  viewer?: Maybe<Scalars['JSON']>;
};

export enum CallStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Declined = 'DECLINED',
  Incoming = 'INCOMING',
  TimedOut = 'TIMED_OUT'
}

export enum CallType {
  Simple = 'SIMPLE',
  WithConfirmation = 'WITH_CONFIRMATION'
}

export type CallsFilter = {
  /** Optionally filter by call status */
  callStatus?: InputMaybe<CallStatus>;
  /** Optionally filter by interval(end date) */
  endedAt?: InputMaybe<Scalars['DateTime']>;
  /** Optionally filter by operator */
  operatorId?: InputMaybe<Scalars['String']>;
  /** Optionally filter by operator name */
  operatorName?: InputMaybe<Scalars['String']>;
  /** Optionally filter by page url */
  pageURL?: InputMaybe<Scalars['String']>;
  /** Optionally filter by date/interval(start date) */
  startedAt?: InputMaybe<Scalars['DateTime']>;
  /** Optionally filter by widget name */
  widgetName?: InputMaybe<Scalars['String']>;
};

/** [YooKassa cancelation details](https://yookassa.ru/developers/api#payment_object_cancellation_details) */
export type CancellationDetails = {
  __typename?: 'CancellationDetails';
  party: Scalars['String'];
  reason: Scalars['String'];
};

export type ChangePasswordError = Error | ForbiddenError | NotFoundError;

export type CloseAlertResult = CloseAlertSuccess | Error | NotFoundError;

export type CloseAlertSuccess = {
  __typename?: 'CloseAlertSuccess';
  alert: IAlert;
};

export type CompleteTourResult = Error | ForbiddenError | NotFoundError | User;

export enum CompositionCallStatus {
  /** Current plan does not support saving recordings */
  MediaNotSupported = 'MEDIA_NOT_SUPPORTED',
  /** Twilio composition webhook hasn't been called yet */
  MediaPending = 'MEDIA_PENDING',
  /** Twilio is processing composition */
  MediaProcessing = 'MEDIA_PROCESSING',
  /** Media is ready and file is in our S3 storage */
  MediaReady = 'MEDIA_READY',
  /** Due to some reason media is unavailable */
  MediaUnavailable = 'MEDIA_UNAVAILABLE'
}

export type CreatePromocodeResult = Error | ForbiddenError | Promocode | PromocodeExistsError;

export type CreateScenarioInput = {
  name: Scalars['String'];
  steps: Array<ScenarioStepInput>;
};

export type CreateScenarioResult = Error | ForbiddenError | Scenario;

export type CreateVideoError = IError & {
  __typename?: 'CreateVideoError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  reason: CreateVideoErrorReason;
};

export enum CreateVideoErrorReason {
  /** AWS MediaConvert doesn't like our createJob reequest */
  BadRequest = 'BAD_REQUEST',
  /** AWS MediaConvert doesn't want to process our request for some other reason */
  InternalError = 'INTERNAL_ERROR',
  /** S3 key provided doesn't match naming convention, or cannot be accepted by MediaConvert */
  InvalidKey = 'INVALID_KEY',
  /** ffprobe found something that we don't like about the video (e.g. invalid codec, dimensions or missing video stream) */
  ProbeFailed = 'PROBE_FAILED',
  /** AWS MediaConvert says that we send too many requests */
  TooManyRequests = 'TOO_MANY_REQUESTS'
}

export type CreateVideoResult = CreateVideoError | Error | ForbiddenError | NotFoundError | Video;

export type CreateWidgetResult = Error | ForbiddenError | LimitsError | Widget;

/** Currency codes used in videoforce */
export enum Currency {
  Rub = 'RUB',
  Usd = 'USD'
}

export type DeleteEmployeeError = Error | ForbiddenError | NotFoundError;

export type DeleteScenarioError = Error | ForbiddenError | NotFoundError;

export type DeleteUserError = Error | ForbiddenError | NotFoundError;

export type DeleteVideoResult = Error | ForbiddenError | NotFoundError;

export type DeleteWidgetError = DeleteWidgetIntegrationError | Error | ForbiddenError | NotFoundError;

/** Error that happens when integration prevents user from deleting widget */
export type DeleteWidgetIntegrationError = IError & {
  __typename?: 'DeleteWidgetIntegrationError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
};

export enum DeviceType {
  Desktop = 'DESKTOP',
  Mobile = 'MOBILE'
}

/** User and his role in relation to account */
export type Employee = {
  __typename?: 'Employee';
  /** Is used for Apollo Client Cache identity, and consists of employer and employee ids mixed together */
  id: Scalars['ID'];
  /** Account-level roles of this employee */
  roles: Array<EmployeeRole>;
  /** Employee status */
  status: EmployeeStatus;
  /** Employed user */
  user: User;
};

export enum EmployeeRole {
  Manager = 'MANAGER',
  Owner = 'OWNER'
}

export type EmployeeSettings = {
  /** Business hours */
  businessHours?: InputMaybe<BusinessHoursInput>;
};

export enum EmployeeStatus {
  /** Employee is active */
  Active = 'ACTIVE',
  /** Invitation has been sent, but employee hasn't accepted it yet */
  Invited = 'INVITED'
}

/** Generic error that doesn't fall into any specific category */
export type Error = IError & {
  __typename?: 'Error';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
};

export type ForbiddenError = IError & {
  __typename?: 'ForbiddenError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  /** the reason why this action is forbidden */
  reason: ForbiddenReason;
};

export enum ForbiddenReason {
  /** user doesn't have permissions to perform desired action */
  AccessDenied = 'ACCESS_DENIED',
  /** user's account is blocked by admin */
  AccountBlocked = 'ACCOUNT_BLOCKED',
  /** insufficient balance */
  InsufficientBalance = 'INSUFFICIENT_BALANCE',
  /** Current operation in not permitted on current integration (or absence of one) */
  InvalidIntegration = 'INVALID_INTEGRATION',
  /** Requested operation is not supported by current payment plan */
  NotOnCurrentPlan = 'NOT_ON_CURRENT_PLAN'
}

export type GenerateCouponResult = Error | ForbiddenError | GenerateCouponSuccess;

export type GenerateCouponSuccess = {
  __typename?: 'GenerateCouponSuccess';
  coupon: Scalars['String'];
};

export type GetCallResult = Call | Error | ForbiddenError;

export type GetEmployeeResult = Employee | Error | ForbiddenError;

export type GetScenarioResult = Error | ForbiddenError | Scenario;

export type GetUserResult = Error | ForbiddenError | User;

/** result of getWidget query */
export type GetWidgetResult = Error | ForbiddenError | Widget;

export type IAlert = {
  /** If true, alert should no longer be displayed */
  closed?: Maybe<Scalars['Boolean']>;
  /** Alert's UUID */
  id: Scalars['ID'];
};

export type IError = {
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
};

export type IIntegration = {
  /** Whether this integration can be installed on the same account with other non-exclusive integrations */
  exclusive: Scalars['Boolean'];
  /**
   * unique indentifier of opposite side of integration
   * e.g. for shopify it's store domain 'myshop.shopify.com'"
   */
  id: Scalars['ID'];
};

export type Integration = ShopifyIntegration;

export type InvalidCouponError = IError & {
  __typename?: 'InvalidCouponError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  /** why is it invalid */
  reason: InvalidCouponReason;
};

export enum InvalidCouponReason {
  Expired = 'EXPIRED',
  Other = 'OTHER',
  Used = 'USED'
}

export type Invitation = {
  email: Scalars['String'];
  name: Scalars['String'];
};

export type InviteEmployeesResult = Error | ForbiddenError | InviteEmployeesSuccess;

export type InviteEmployeesSuccess = {
  __typename?: 'InviteEmployeesSuccess';
  employees: Array<Employee>;
};

export enum JivoButtonVariant {
  /** Chat and video buttons are equally weighted */
  Default = 'DEFAULT',
  /** Chat button is emphasized */
  PreferChat = 'PREFER_CHAT',
  /** Video button is emphasized */
  PreferVideo = 'PREFER_VIDEO'
}

export enum LimitType {
  /** Maximum number of streamers per widget */
  StreamerPerWidget = 'STREAMER_PER_WIDGET',
  /** Maximum number of visibility rules per widget */
  VisibilityRulesPerWidget = 'VISIBILITY_RULES_PER_WIDGET',
  /** Maximum number of widgets per account */
  Widgets = 'WIDGETS'
}

export type LimitsError = IError & {
  __typename?: 'LimitsError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** Enum error code */
  limit?: Maybe<LimitType>;
  /** human-readable message */
  message: Scalars['String'];
  /** Value of limit that has been reached */
  value: Scalars['Float'];
};

export type ListCallsResult = Error | ForbiddenError | ListCallsSuccess;

export type ListCallsSuccess = {
  __typename?: 'ListCallsSuccess';
  calls: Array<Call>;
  count: Scalars['Int'];
};

export type ListEmployeesResult = Error | ForbiddenError | ListEmployeesSuccess;

export type ListEmployeesSuccess = {
  __typename?: 'ListEmployeesSuccess';
  employees: Array<Employee>;
};

export type ListPromocodesResult = Error | ForbiddenError | ListPromocodesSuccess;

export type ListPromocodesSuccess = {
  __typename?: 'ListPromocodesSuccess';
  promocodes: Array<Promocode>;
};

export type ListScenariosResult = Error | ForbiddenError | ListScenariosSuccess;

export type ListScenariosSuccess = {
  __typename?: 'ListScenariosSuccess';
  count: Scalars['Int'];
  scenarios: Array<Scenario>;
};

export type ListUsersResult = Error | ForbiddenError | ListUsersSuccess;

export type ListUsersSuccess = {
  __typename?: 'ListUsersSuccess';
  count: Scalars['Int'];
  users: Array<User>;
};

/** result of listVideos query */
export type ListVideosResult = Error | ForbiddenError | ListVideosSuccess;

/** result of listVideos successful query */
export type ListVideosSuccess = {
  __typename?: 'ListVideosSuccess';
  count: Scalars['Int'];
  videos: Array<Video>;
};

/** result of listWidgets query */
export type ListWidgetsResult = Error | ForbiddenError | ListWidgetsSuccess;

/** result of listWidgets successful query */
export type ListWidgetsSuccess = {
  __typename?: 'ListWidgetsSuccess';
  widgets: Array<Widget>;
};

export type MetricAction = {
  __typename?: 'MetricAction';
  goalId?: Maybe<Scalars['String']>;
  type: AnalyticsEventType;
};

export type MetricActionInput = {
  goalId?: InputMaybe<Scalars['String']>;
  type: AnalyticsEventType;
};

/** A monetary value with currency */
export type MonetaryValue = {
  __typename?: 'MonetaryValue';
  /** Decimal money amount */
  amount: Scalars['Float'];
  /** Currency of the money */
  currency: Currency;
};

/** A monetary value with currency */
export type MonetaryValueInput = {
  /** Currency of the money */
  currency: Currency;
  /** Decimal money amount */
  value: Scalars['Float'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Deletes user and all the associated data (widgets, integrations, etc)
   * Only global admins can execute this
   * Returns null on success
   */
  adminDeleteUser?: Maybe<DeleteUserError>;
  /** Archives promocode */
  archivePromocode: ArchivePromocodeResult;
  /**
   * Changes user's password
   * Can be called by user or by account manager/owner
   * Returns null on success
   */
  changePassword?: Maybe<ChangePasswordError>;
  /** Marks alert as seen by user and stops displaying it */
  closeAlert: CloseAlertResult;
  /**
   * Mark tour as completed for current user
   * Returns partial user in case of success
   */
  completeTour?: Maybe<CompleteTourResult>;
  /** Creates new promocode */
  createPromocode: CreatePromocodeResult;
  /** Creates a new scenario */
  createScenario: CreateScenarioResult;
  /**
   * Starts the video conversion process and saves the video in our db with SUBMITTED status
   * Only account managers/owners can do this
   */
  createVideo: CreateVideoResult;
  /** Creates a new widget with default settings in current account */
  createWidget: CreateWidgetResult;
  /** Deletes employee from current account */
  deleteEmployee?: Maybe<DeleteEmployeeError>;
  /**
   * Deletes a scenario
   * Returns null when deleted successfully
   */
  deleteScenario?: Maybe<DeleteScenarioError>;
  /**
   * Deletes video.
   * Only account owner and managers can do it
   * Returns null when deleted successfully
   */
  deleteVideo?: Maybe<DeleteVideoResult>;
  /**
   * Deletes a widget
   * Returns null when deleted successfully
   */
  deleteWidget?: Maybe<DeleteWidgetError>;
  /** Disables auto-payment */
  disableAutoPayment: BillingInfoResult;
  /**
   * Generates coupon (optionally locked to specified account)
   * Admin-only mutation
   */
  generateCoupon: GenerateCouponResult;
  /** Invites users (new or already signed up) to become employee in current account */
  inviteEmployees: InviteEmployeesResult;
  /**
   * Request enterprise plan
   * This will send message to our telegram channel
   */
  requestEnterprisePlan?: Maybe<RequestEnterprisePlanResult>;
  /** Initiates YooKassa payment for new or existing plan. */
  switchPlan: SwitchPlanResult;
  /** Toggle account manager on/off for given employee and current account */
  toggleManager: ToggleManagerResult;
  /** Adds or removes employee from this widget's streamers */
  toggleStreamer: ToggleStreamerResult;
  /** Top up user balance by YooKassa payment */
  topUpBalance?: Maybe<TopUpBalanceResult>;
  /** Changes user profile settings */
  updateProfile: UpdateProfileResult;
  /** Creates scenario */
  updateScenario: UpdateScenarioResult;
  /** Updates widget settings */
  updateWidget: UpdateWidgetResult;
  /**
   * Uses coupon to top up user's balance and (possible) switch payment plan
   * Available only to account's owner
   */
  useCoupon: UseCouponResult;
};


export type MutationAdminDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationArchivePromocodeArgs = {
  id: Scalars['ID'];
};


export type MutationChangePasswordArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  newPassword: Scalars['String'];
  oldPassword?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationCloseAlertArgs = {
  id: Scalars['ID'];
};


export type MutationCompleteTourArgs = {
  skipped?: InputMaybe<Scalars['Boolean']>;
  tour: Tour;
};


export type MutationCreatePromocodeArgs = {
  input: PromocodeInput;
};


export type MutationCreateScenarioArgs = {
  settings: CreateScenarioInput;
};


export type MutationCreateVideoArgs = {
  s3key: Scalars['String'];
  settings?: InputMaybe<VideoSettings>;
};


export type MutationDeleteEmployeeArgs = {
  employeeId: Scalars['ID'];
};


export type MutationDeleteScenarioArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteVideoArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWidgetArgs = {
  id: Scalars['ID'];
};


export type MutationGenerateCouponArgs = {
  accountId?: InputMaybe<Scalars['ID']>;
  amount: Scalars['Float'];
};


export type MutationInviteEmployeesArgs = {
  invitations: Array<Invitation>;
};


export type MutationRequestEnterprisePlanArgs = {
  comment?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};


export type MutationSwitchPlanArgs = {
  idempotenceKey?: InputMaybe<Scalars['String']>;
  newPlan?: InputMaybe<PlanType>;
};


export type MutationToggleManagerArgs = {
  employeeId: Scalars['ID'];
  enabled: Scalars['Boolean'];
};


export type MutationToggleStreamerArgs = {
  employeeId: Scalars['ID'];
  enabled: Scalars['Boolean'];
  widgetId: Scalars['ID'];
};


export type MutationTopUpBalanceArgs = {
  amount: MonetaryValueInput;
  idempotenceKey?: InputMaybe<Scalars['String']>;
};


export type MutationUpdateProfileArgs = {
  settings: ProfileSettings;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpdateScenarioArgs = {
  id: Scalars['ID'];
  settings: UpdateScenarioInput;
};


export type MutationUpdateWidgetArgs = {
  id: Scalars['ID'];
  settings: WidgetSettingsInput;
};


export type MutationUseCouponArgs = {
  coupon: Scalars['String'];
  newPlan?: InputMaybe<PlanType>;
};

export type NotFoundError = IError & {
  __typename?: 'NotFoundError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
};

export type OpenLobbyButtonSettings = {
  __typename?: 'OpenLobbyButtonSettings';
  selector?: Maybe<Scalars['String']>;
  variant: OpenLobbyButtonVariant;
};

export type OpenLobbyButtonSettingsInput = {
  selector?: InputMaybe<Scalars['String']>;
  variant: OpenLobbyButtonVariant;
};

export enum OpenLobbyButtonVariant {
  Custom = 'CUSTOM',
  Off = 'OFF',
  On = 'ON'
}

/** Payment represents payment made via YooKassa */
export type Payment = {
  __typename?: 'Payment';
  amount: MonetaryValue;
  /** Details, if canceled */
  cancellationDetails?: Maybe<CancellationDetails>;
  createdAt: Scalars['DateTime'];
  /** id as returned by YooKassa */
  id: Scalars['ID'];
  plan: Plan;
  status: PaymentStatus;
};

export type PaymentResult = Error | ForbiddenError | Payment;

export enum PaymentStatus {
  Canceled = 'CANCELED',
  Pending = 'PENDING',
  Succeeded = 'SUCCEEDED',
  WaitingForCapture = 'WAITING_FOR_CAPTURE'
}

export type Plan = {
  __typename?: 'Plan';
  /** features available on this plan */
  features: Array<PlanFeature>;
  /**
   * currently same as type
   * currently this is used only for apollo client caching
   */
  id: Scalars['ID'];
  /** plan limitations */
  limits: PlanLimits;
  /** plan name */
  name: Scalars['String'];
  /** plan price per month */
  price: MonetaryValue;
  /** enum plan name */
  type: PlanType;
};

export enum PlanFeature {
  /** Enable calls in widget */
  CallsEnable = 'CALLS_ENABLE',
  /** Save recorded videos */
  CallRecording = 'CALL_RECORDING',
  /** Widget will maximize after some time */
  Greeting = 'GREETING',
  /** Hide label */
  HideLabel = 'HIDE_LABEL',
  /** Impersonal calls */
  ImpersonalCalls = 'IMPERSONAL_CALLS',
  /** JS API */
  JsApi = 'JS_API',
  /** Widget visibility rules */
  VisibilityRules = 'VISIBILITY_RULES',
  /** Calling webhooks */
  Webhooks = 'WEBHOOKS',
  /** Notify about various widget events (such as when no streamers are online) */
  WidgetNotifications = 'WIDGET_NOTIFICATIONS'
}

/** Represents plan limits */
export type PlanLimits = {
  __typename?: 'PlanLimits';
  /** maximum call duration in minutes */
  callDuration: Scalars['Int'];
  /** maximum number of streamers in widget */
  streamerPerWidget: Scalars['Int'];
  /** Maximum number of visibility rules in widget */
  visibilityRulesPerWidget: Scalars['Int'];
  /** limit on number of widgets in this account */
  widgets: WidgetLimits;
};

export enum PlanType {
  Advanced = 'ADVANCED',
  Basic = 'BASIC',
  /** No limits, no payments. Can be turned by global admins only */
  Internal = 'INTERNAL',
  /** Advanced plan with increased limit of widgets */
  MaxWidget = 'MAX_WIDGET',
  /** Like basic, but very cheap. For production testing. */
  ProductionTest = 'PRODUCTION_TEST',
  /** Recorded widgets only */
  RecordedOnly = 'RECORDED_ONLY',
  /** Shopify basic */
  ShopifyBasic = 'SHOPIFY_BASIC'
}

/** This input contains editable user attributes */
export type ProfileSettings = {
  accountName?: InputMaybe<Scalars['String']>;
  /** Business hours */
  businessHours?: InputMaybe<BusinessHoursInput>;
  /** If true, enable browser notification for new message from conversations */
  enableMessageBrowserNotification?: InputMaybe<Scalars['Boolean']>;
  /** If true, enable sound notification for new message from conversations */
  enableMessageSoundNotification?: InputMaybe<Scalars['Boolean']>;
  /** User online/offline state */
  isUnavailable?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  timezone?: InputMaybe<Scalars['String']>;
  /**
   * Video ID
   * Set to null to unlink video
   */
  videoId?: InputMaybe<Scalars['ID']>;
};

export type Promocode = {
  __typename?: 'Promocode';
  /**
   * We do not truly delete promocodes
   * We archive them
   */
  archived: Scalars['Boolean'];
  /**
   * Users who register with this promocode starts with this amount of money on their balance
   * If null, defaults to Plan's monthly price
   */
  balance?: Maybe<Scalars['Float']>;
  /** Unique string id, e.g. 'free_month' */
  id: Scalars['ID'];
  /** Users who register with this promocode starts with this plan */
  plan: Plan;
  /** Optional referrer (aka promocode owner) */
  referrer?: Maybe<User>;
  /**
   * Promocode duration in days. At the end of the period payment's plan monthly cost will be withdrawn from user balance.
   * If null, defaults to one month
   */
  trialDays?: Maybe<Scalars['Int']>;
  /** Promocode type */
  type: PromocodeType;
  /**
   * Null for regular promocodes
   * False for one-time promocode until it's used to register user. True afterwards.
   */
  used?: Maybe<Scalars['Boolean']>;
  /**
   * After this moment promocode becomes invalid (user cannot use it to register)
   * Null for eternal promocodes
   */
  validUntil?: Maybe<Scalars['DateTime']>;
};

export type PromocodeExistsError = IError & {
  __typename?: 'PromocodeExistsError';
  /** i18n code */
  code: Scalars['String'];
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
};

export type PromocodeInput = {
  /**
   * Users who register with this promocode starts with this amount of money on their balance
   * If null, defaults to Plan's monthly price
   */
  balance?: InputMaybe<Scalars['Float']>;
  /** Uniques string id, e.g. 'free_month' */
  id: Scalars['ID'];
  /** True for one-time promocode */
  oneTime?: InputMaybe<Scalars['Boolean']>;
  /** Users who register with this promocode starts with this plan */
  plan: PlanType;
  /** Optional referrer (aka promocode owner) */
  referrerId?: InputMaybe<Scalars['ID']>;
  /** Promocode duration in days. At the end of the period payment's plan monthly cost will be withdrawn from user balance. */
  trialDays: Scalars['Int'];
  /**
   * After this moment promocode becomes invalid (user cannot use it to register)
   * Null for eternal promocodes
   */
  validUntil?: InputMaybe<Scalars['DateTime']>;
};

export enum PromocodeType {
  OneTime = 'ONE_TIME',
  Regular = 'REGULAR'
}

export type PromocodesFilter = {
  referrerId?: InputMaybe<Scalars['ID']>;
};

export type Query = {
  __typename?: 'Query';
  /**
   * Returns user account's billing info
   * Available only to account owner
   */
  getBillingInfo: BillingInfoResult;
  /**
   * Gets call by id
   * Returns null if call is not found
   */
  getCall?: Maybe<GetCallResult>;
  /**
   * Gets employee by id
   * Only employee himself and his superiors have access to this
   */
  getEmployee?: Maybe<GetEmployeeResult>;
  /**
   * Returns information about specified payment
   * Returns latest payment if id is not specified
   * Returns null if no payment was ever made
   */
  getPayment?: Maybe<PaymentResult>;
  getScenario?: Maybe<GetScenarioResult>;
  /**
   * Gets user by id
   * If id is not provided, returns current user
   * Only user and his superiors have access to this
   */
  getUser?: Maybe<GetUserResult>;
  /**
   * Returns video by id, or null if not found, or when id was not provided
   * Everyone in the account can get any video
   */
  getVideo?: Maybe<Video>;
  /** Get widget by id */
  getWidget?: Maybe<GetWidgetResult>;
  /** Lists calls */
  listCalls: ListCallsResult;
  /** Lists employees in current account */
  listEmployees: ListEmployeesResult;
  /** Lists available plans for current account */
  listPlans: Array<Plan>;
  /** Lists existing non-archived promocodes */
  listPromocodes: ListPromocodesResult;
  /** List all scenarios in current account */
  listScenarios: ListScenariosResult;
  /** Lists users. Global admin only */
  listUsers: ListUsersResult;
  /** Lists all videos in current account */
  listVideos: ListVideosResult;
  /** List all widgets in current account */
  listWidgets: ListWidgetsResult;
  /**
   * returns url to confirm shopify subscription
   * see https://shopify.dev/tutorials/create-an-annual-subscription#how-annual-billing-works-with-the-graphql-admin-api
   */
  shopifyConfirmationURL: ShopifyConfirmationUrlResult;
};


export type QueryGetCallArgs = {
  id: Scalars['ID'];
};


export type QueryGetEmployeeArgs = {
  id: Scalars['ID'];
};


export type QueryGetPaymentArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetScenarioArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetVideoArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryGetWidgetArgs = {
  id: Scalars['ID'];
};


export type QueryListCallsArgs = {
  filter?: InputMaybe<CallsFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryListPromocodesArgs = {
  filter?: InputMaybe<PromocodesFilter>;
};


export type QueryListScenariosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<UsersFilter>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryListVideosArgs = {
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};

export type RequestEnterprisePlanResult = Error | ForbiddenError;

export type Scenario = {
  __typename?: 'Scenario';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  name: Scalars['String'];
  steps: Array<ScenarioStep>;
};

export type ScenarioStep = {
  __typename?: 'ScenarioStep';
  buttons?: Maybe<Array<ScenarioStepActionButton>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  order: Scalars['Int'];
  type: ScenarioStepType;
  video?: Maybe<Video>;
};

export type ScenarioStepActionButton = ScenarioStepCssSelectorButton | ScenarioStepFinishScenarioButton | ScenarioStepFollowStepButton | ScenarioStepFollowUrlButton | ScenarioStepOpenVideochatButton;

export enum ScenarioStepButtonType {
  CssSelector = 'CSS_SELECTOR',
  FinishScenario = 'FINISH_SCENARIO',
  FollowStep = 'FOLLOW_STEP',
  FollowUrl = 'FOLLOW_URL',
  OpenVideochat = 'OPEN_VIDEOCHAT'
}

export type ScenarioStepCssSelectorButton = {
  __typename?: 'ScenarioStepCssSelectorButton';
  name: Scalars['String'];
  selector: Scalars['String'];
};

export type ScenarioStepFinishScenarioButton = {
  __typename?: 'ScenarioStepFinishScenarioButton';
  name: Scalars['String'];
};

export type ScenarioStepFollowStepButton = {
  __typename?: 'ScenarioStepFollowStepButton';
  name: Scalars['String'];
  step: Scalars['Int'];
};

export type ScenarioStepFollowUrlButton = {
  __typename?: 'ScenarioStepFollowUrlButton';
  name: Scalars['String'];
  url: Scalars['String'];
};

export type ScenarioStepInput = {
  buttons?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  type: ScenarioStepType;
  videoId?: InputMaybe<Scalars['String']>;
};

export type ScenarioStepOpenVideochatButton = {
  __typename?: 'ScenarioStepOpenVideochatButton';
  name: Scalars['String'];
};

export enum ScenarioStepType {
  /** This step may contain buttons with features of css selector, open videochat and url redirect */
  AnswerButtons = 'ANSWER_BUTTONS',
  /** This step contain buttons with feature of redirect to another step */
  Cta = 'CTA',
  /** This step contain contact form with name and phone fields */
  ShowContactForm = 'SHOW_CONTACT_FORM'
}

export type ShopifyConfirmationUrl = {
  __typename?: 'ShopifyConfirmationURL';
  url: Scalars['String'];
};

export type ShopifyConfirmationUrlResult = Error | ForbiddenError | ShopifyConfirmationUrl;

export type ShopifyIntegration = IIntegration & {
  __typename?: 'ShopifyIntegration';
  /**
   * The ID of shopify subscription, or null if not yet subscribed
   * https://shopify.dev/docs/admin-api/graphql/reference/billing/appsubscription
   */
  appSubscriptionId?: Maybe<Scalars['ID']>;
  /** Always true */
  exclusive: Scalars['Boolean'];
  /** Shopify store domain e.g. 'myshop.shopify.com' */
  id: Scalars['ID'];
};

/**
 * Streamer represents user as widget's streamer
 * Additional attributes might emerge here later (e.g. widget-specific username)
 */
export type Streamer = {
  __typename?: 'Streamer';
  status: StreamerStatus;
  user: User;
};

/** Streamer status for calls */
export enum StreamerStatus {
  Available = 'AVAILABLE',
  Busy = 'BUSY',
  Offline = 'OFFLINE'
}

export type SwitchPlanError = IError & {
  __typename?: 'SwitchPlanError';
  /** Details, if canceled */
  cancellationDetails?: Maybe<CancellationDetails>;
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  /** Enum error code */
  type?: Maybe<SwitchPlanErrorType>;
};

export enum SwitchPlanErrorType {
  /** YooKassa returned canceled payment istead of pending */
  InvocationCanceled = 'INVOCATION_CANCELED',
  /** Failed to invoke YooKassa API */
  InvocationFailed = 'INVOCATION_FAILED',
  /** Confirmation token not found in YooKassa response */
  NoConfirmationToken = 'NO_CONFIRMATION_TOKEN'
}

/** SwitchPlanInfo info contains information required to display YooKassa widget */
export type SwitchPlanInfo = {
  __typename?: 'SwitchPlanInfo';
  /** User account containing block reason */
  account: User;
  billing: BillingInfo;
  /**
   * Token for YooKassa widget.
   * Null when user has sufficient balance to switch plan
   */
  confirmationToken?: Maybe<Scalars['String']>;
  /**
   * Payment info (should be in pending status)
   * Null when user has sufficient balance to switch plan
   */
  payment?: Maybe<Payment>;
};

export type SwitchPlanResult = Error | ForbiddenError | NotFoundError | SwitchPlanError | SwitchPlanInfo;

export type ToggleManagerResult = Employee | Error | ForbiddenError | NotFoundError;

export type ToggleStreamerResult = Error | ForbiddenError | LimitsError | NotFoundError | Widget;

export type TopUpBalanceError = IError & {
  __typename?: 'TopUpBalanceError';
  /** Details, if canceled */
  cancellationDetails?: Maybe<CancellationDetails>;
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  /** Enum error code */
  type?: Maybe<TopUpBalanceErrorType>;
};

export enum TopUpBalanceErrorType {
  /** YooKassa returned canceled payment istead of pending */
  InvocationCanceled = 'INVOCATION_CANCELED',
  /** Failed to invoke YooKassa API */
  InvocationFailed = 'INVOCATION_FAILED',
  /** Confirmation token not found in YooKassa response */
  NoConfirmationToken = 'NO_CONFIRMATION_TOKEN'
}

export type TopUpBalanceInfo = {
  __typename?: 'TopUpBalanceInfo';
  /** User account containing block reason */
  account: User;
  billing: BillingInfo;
  /** Token for YooKassa widget. */
  confirmationToken?: Maybe<Scalars['String']>;
  /** Payment info (should be in pending status) */
  payment?: Maybe<Payment>;
};

export type TopUpBalanceResult = Error | ForbiddenError | NotFoundError | TopUpBalanceError | TopUpBalanceInfo;

/** Guided tours around app */
export enum Tour {
  /** Owner onboarding */
  OwnerIntro = 'OWNER_INTRO'
}

export type UpdateProfileResult = Error | ForbiddenError | NotFoundError | User | ValidationError;

export type UpdateScenarioInput = {
  name?: InputMaybe<Scalars['String']>;
  steps?: InputMaybe<Array<ScenarioStepInput>>;
};

export type UpdateScenarioResult = Error | ForbiddenError | NotFoundError | Scenario | ValidationError;

export type UpdateWidgetResult = Error | ForbiddenError | NotFoundError | ValidationError | Widget;

export type UseCouponResult = Error | ForbiddenError | InvalidCouponError | UseCouponSuccess;

export type UseCouponSuccess = {
  __typename?: 'UseCouponSuccess';
  /** User account containing block reason */
  account: User;
  billing: BillingInfo;
};

/** User is generic entity that represents both employees and employers ('root accounts') */
export type User = {
  __typename?: 'User';
  /** user's own organization name, defaults to user's name */
  accountName: Scalars['String'];
  /** Alerts that should be displayed to user */
  alerts: Array<IAlert>;
  /** User's firebase device tokens */
  androidDeviceTokens?: Maybe<Array<Scalars['String']>>;
  /** Null if the account is not blocked */
  blockReason?: Maybe<BlockReason>;
  /** Business hours */
  businessHours?: Maybe<BusinessHours>;
  /** Tours which user completed */
  completedTours: Array<Tour>;
  /**
   * user's email from cognito user pool
   * visible to user itself and managers
   */
  email?: Maybe<Scalars['String']>;
  /**
   * list of other user accounts where this user is employed at
   * visible to self only
   */
  employedAt: Array<User>;
  /** If true, enable browser notification for new message from conversations */
  enableMessageBrowserNotification: Scalars['Boolean'];
  /** If true, enable sound notification for new message from conversations */
  enableMessageSoundNotification: Scalars['Boolean'];
  /** user's id from cognito user pool */
  id: Scalars['ID'];
  /** which integrations are enabled for this account */
  integrations: Array<Integration>;
  /** User's callkit device tokens */
  iosDeviceTokens?: Maybe<Array<Scalars['String']>>;
  /**
   * If true, enabled on account 'do not disturb mode' analog,
   * when user not available for any calls
   */
  isUnavailable: Scalars['Boolean'];
  /** User's locale, represented as a BCP47 [RFC5646] language tag */
  locale: Scalars['String'];
  /**
   * user's name from cognito user pool
   * visible to everyone in the same account
   */
  name: Scalars['String'];
  /** User's phone */
  phone?: Maybe<Scalars['String']>;
  /** current payment plan */
  plan: Plan;
  /** User's timezone */
  timezone: Scalars['String'];
  /** User's video */
  video?: Maybe<Video>;
};

/** Used to search users */
export type UsersFilter = {
  /** Partial email */
  email?: InputMaybe<Scalars['String']>;
};

export type ValidationError = IError & {
  __typename?: 'ValidationError';
  /** unique error id to look up in backend logs */
  id: Scalars['ID'];
  /** human-readable message */
  message: Scalars['String'];
  /** Field errors */
  validationErrors?: Maybe<Scalars['JSON']>;
};

/** Uploaded video */
export type Video = {
  __typename?: 'Video';
  /** Video creation time */
  createdAt: Scalars['DateTime'];
  /** Video duration in milliseconds, if status is COMPLETE */
  duration?: Maybe<Scalars['Int']>;
  /** Video UUID, generated client-side when uploading file */
  id: Scalars['ID'];
  /** Encoded and resized jpg thumb, if status is COMPLETE */
  jpg?: Maybe<Scalars['String']>;
  /** Encoded and resized mp4 video, if status is COMPLETE */
  mp4?: Maybe<Scalars['String']>;
  /** Arbitrary video name/label for user convenience, can be null! */
  name?: Maybe<Scalars['String']>;
  /** Resolution of final resized video, if status is COMPLETE */
  resolution?: Maybe<VideoResolution>;
  /** Current status of this video */
  status: VideoStatus;
};

export type VideoResolution = {
  __typename?: 'VideoResolution';
  height: Scalars['Int'];
  width: Scalars['Int'];
};

export type VideoSettings = {
  /** Arbitrary video name/label for user convenience, can be null! */
  name?: InputMaybe<Scalars['String']>;
};

/**
 * Video status matches AWS MediaConvert job status:
 * https://docs.aws.amazon.com/mediaconvert/latest/ug/how-mediaconvert-jobs-progress.html
 */
export enum VideoStatus {
  Canceled = 'CANCELED',
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Progressing = 'PROGRESSING',
  Submitted = 'SUBMITTED'
}

export type VisibilityRule = {
  __typename?: 'VisibilityRule';
  condition: Scalars['JSON'];
  contentType: VisibilityRuleContentType;
  id: Scalars['ID'];
  scenario?: Maybe<Scenario>;
  video?: Maybe<Video>;
};

export enum VisibilityRuleContentType {
  None = 'NONE',
  Scenario = 'SCENARIO',
  Video = 'VIDEO'
}

export type VisibilityRuleInput = {
  condition: Scalars['JSON'];
  contentType: VisibilityRuleContentType;
  scenarioId?: InputMaybe<Scalars['String']>;
  videoId?: InputMaybe<Scalars['String']>;
};

export type VisibilityRules = {
  __typename?: 'VisibilityRules';
  visibilityRules: Array<VisibilityRule>;
};

export type Webhook = {
  __typename?: 'Webhook';
  url: Scalars['String'];
};

export type WebhookInput = {
  url: Scalars['String'];
};

export type Widget = {
  __typename?: 'Widget';
  /** widget accent color */
  accentColor: Scalars['String'];
  /**
   * Timeout in seconds, after which call with confirmation should be declined
   * Null if this behaviour is disabled
   */
  callConfirmationTimeout?: Maybe<Scalars['Int']>;
  /** If true, widget can receive calls */
  callsEnabled: Scalars['Boolean'];
  /** CTA settings, for pre-precorded widget variant */
  cta?: Maybe<CtaSettings>;
  /** Display widget */
  defaultVisibility?: Maybe<Scalars['Boolean']>;
  /**
   * Setting this prevents widget from being loaded in desktop and/or mobile browsers
   * This logic is implemented in widget loader
   */
  disabledOn: Array<DeviceType>;
  /** If true, display text chat buttons in widget */
  displayTextChatButton?: Maybe<Scalars['Boolean']>;
  /** list of domains to which this widget is restricted, if any */
  domains?: Maybe<Array<Scalars['String']>>;
  /** If true, activate call confirmation mechanism in widget */
  enableCallConfirmation?: Maybe<Scalars['Boolean']>;
  /** If true, widget will be displayed in impersonal call mode */
  enableImpersonalCall?: Maybe<Scalars['Boolean']>;
  /** Flavour determines which js bundle is loaded by widget */
  flavour: WidgetFlavour;
  /**
   * Timeout in seconds, after which widget should do something to attract user's attention
   * Null if this behaviour is disabled
   */
  greetingTimeout?: Maybe<Scalars['Int']>;
  /** If true, hide Videoforce label from all widget screens */
  hideLabel?: Maybe<Scalars['Boolean']>;
  /** completely hide widget when there are no available streamers */
  hideWhenNoStreamers: Scalars['Boolean'];
  /** generated uuid */
  id: Scalars['ID'];
  /** piece of code to install widget on site */
  installCode: Scalars['String'];
  /**
   * In case of Jivo integration, this settings determines which part of button (chat/video) should be emphasized
   * Without Jivo integration this defaults to null
   */
  jivoButtonVariant?: Maybe<JivoButtonVariant>;
  /** optional jivo widget id */
  jivoId?: Maybe<Scalars['String']>;
  /** widget locale, represented as a BCP47 [RFC5646] language tag */
  locale: Scalars['String'];
  /**
   * Videoforce action and Yandex.Metrika goal id pair array,
   * When action happens, counter of goal increases
   */
  metricActions?: Maybe<Array<MetricAction>>;
  /** Yandex.Metrica counter ID */
  metricId?: Maybe<Scalars['String']>;
  /** widget name */
  name: Scalars['String'];
  /**
   * String displayed in offer block, or null
   * See issue #354
   */
  offer?: Maybe<Scalars['String']>;
  /** Open widget lobby button settings, for prerecorded widgets */
  openLobbyButton?: Maybe<OpenLobbyButtonSettings>;
  /** owner of this widget */
  owner: User;
  /**
   * String displayed in policy block, or null
   * See issue #354
   */
  policy?: Maybe<Scalars['String']>;
  /** the corner of the screen where to display the widget initially */
  position: WidgetPosition;
  /** Should widget be displayed as miniature or button. Default to miniature. */
  preferMiniature: Scalars['Boolean'];
  /** If true, widget calls can receive rating and review */
  ratingEnabled?: Maybe<Scalars['Boolean']>;
  /** If true, widget calls can save calls recoding */
  saveRecording?: Maybe<Scalars['Boolean']>;
  /** Scenario, for pre-precorded widget variant */
  scenario?: Maybe<Scenario>;
  /** list of streamers */
  streamers: Array<Streamer>;
  /** text and elements color */
  textColor: Scalars['String'];
  /** current theme, default to light */
  theme: WidgetTheme;
  /** video, for pre-precorded widget variant */
  video?: Maybe<Video>;
  /** Visibility rules, if exist */
  visibilityRules?: Maybe<Array<VisibilityRule>>;
  /** Webhook URL */
  webhook?: Maybe<Webhook>;
};

export enum WidgetFlavour {
  Classic = 'CLASSIC',
  E1 = 'E1',
  Hausdorf = 'HAUSDORF',
  Marktstein = 'MARKTSTEIN',
  Recorded = 'RECORDED'
}

export type WidgetLimits = {
  __typename?: 'WidgetLimits';
  /** Maximum total number of widgets */
  total: Scalars['Int'];
};

export enum WidgetPosition {
  BottomLeft = 'BOTTOM_LEFT',
  BottomRight = 'BOTTOM_RIGHT',
  Left = 'LEFT',
  Right = 'RIGHT'
}

/** This input represents widget properties that can be changed by users */
export type WidgetSettingsInput = {
  /** widget accent color */
  accentColor?: InputMaybe<Scalars['String']>;
  /**
   * Timeout in seconds, after which call with confirmation should be declined
   * Null if this behaviour is disabled
   */
  callConfirmationTimeout?: InputMaybe<Scalars['Int']>;
  /** If true, widget can receive calls */
  callsEnabled?: InputMaybe<Scalars['Boolean']>;
  /** CTA settings, for pre-precorded widget variant */
  cta?: InputMaybe<CtaSettingsInput>;
  /** Display widget */
  defaultVisibility?: InputMaybe<Scalars['Boolean']>;
  /**
   * Setting this prevents widget from being loaded in desktop or mobile browsers
   * This logic is implemented in widget loader
   */
  disabledOn?: InputMaybe<Array<DeviceType>>;
  /** If true, display text chat buttons in widget */
  displayTextChatButton?: InputMaybe<Scalars['Boolean']>;
  /** list of domains to which this widget is restricted, if any */
  domains?: InputMaybe<Array<Scalars['String']>>;
  /** If true, activate call confirmation mechanism in widget */
  enableCallConfirmation?: InputMaybe<Scalars['Boolean']>;
  /** If true, widget will be displayed in impersonal call mode */
  enableImpersonalCall?: InputMaybe<Scalars['Boolean']>;
  /** which widget design to use */
  flavour?: InputMaybe<WidgetFlavour>;
  /**
   * Timeout in seconds, after which widget should do something to attract user's attention
   * Null if this behaviour is disabled
   */
  greetingTimeout?: InputMaybe<Scalars['Int']>;
  /** If true, hide Videoforce label from all widget screens */
  hideLabel?: InputMaybe<Scalars['Boolean']>;
  /** completely hide widget when there are no available streamers */
  hideWhenNoStreamers?: InputMaybe<Scalars['Boolean']>;
  /**
   * In case of Jivo integration, this settings determines which part of button (chat/video) should be emphasized
   * Without Jivo integration this defaults to null
   */
  jivoButtonVariant?: InputMaybe<JivoButtonVariant>;
  /** optional jivo widget id */
  jivoId?: InputMaybe<Scalars['String']>;
  /** widget locale, represented as a BCP47 [RFC5646] language tag */
  locale?: InputMaybe<Scalars['String']>;
  /**
   * Videoforce action and Yandex.Metrika goal id pair array,
   * When action happens, counter of goal increases
   */
  metricActions?: InputMaybe<Array<MetricActionInput>>;
  /** Yandex.Metrica counter ID */
  metricId?: InputMaybe<Scalars['String']>;
  /** widget name */
  name?: InputMaybe<Scalars['String']>;
  /**
   * String displayed in offer block, or null
   * See issue #354
   */
  offer?: InputMaybe<Scalars['String']>;
  /** Open widget lobby button settings, for prerecorded widgets */
  openLobbyButton?: InputMaybe<OpenLobbyButtonSettingsInput>;
  /**
   * String displayed in policy block, or null
   * See issue #354
   */
  policy?: InputMaybe<Scalars['String']>;
  /** the corner of the screen where to display the widget initially */
  position?: InputMaybe<WidgetPosition>;
  /** Should widget be displayed as miniature or button. Default to miniature. */
  preferMiniature?: InputMaybe<Scalars['Boolean']>;
  /** If true, widget can receive rating and review */
  ratingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** If true, widget calls can save calls recoding */
  saveRecording?: InputMaybe<Scalars['Boolean']>;
  /** Scenario Id, for pre-precorded widget variant */
  scenarioId?: InputMaybe<Scalars['ID']>;
  /** text and elements color */
  textColor?: InputMaybe<Scalars['String']>;
  /** current theme, default to light */
  theme?: InputMaybe<WidgetTheme>;
  /** Video Id */
  videoId?: InputMaybe<Scalars['ID']>;
  visibilityRules?: InputMaybe<Array<VisibilityRuleInput>>;
  /** Webhook URL */
  webhook?: InputMaybe<WebhookInput>;
};

export enum WidgetTheme {
  Dark = 'DARK',
  Light = 'LIGHT'
}
