import { useEffect, useState } from 'react';

import { unreadMessagesObject } from './useUnreadMessages';

export const useUnreadConversations = (
  unreadMessages: unreadMessagesObject,
): {
  countUnreadMyConversations: number;
  countUnreadIncomingConversations: number;
  countUnreadCallsOnlyConversations: number;
} => {
  const [countUnreadMyConversations, setCountUnreadMyConversations] =
    useState(0);

  const [
    countUnreadIncomingConversations,
    setCountUnreadIncomingConversations,
  ] = useState(0);

  const [
    countUnreadCallsOnlyConversations,
    setCountUnreadCallsOnlyConversations,
  ] = useState(0);

  useEffect(() => {
    let countUnreadMyConversations = 0;
    let countUnreadIncomingConversations = 0;
    let countUnreadCallsOnlyConversations = 0;

    for (const id in unreadMessages) {
      if (unreadMessages[id].type === 'MY') {
        if (unreadMessages[id].count) {
          countUnreadMyConversations = countUnreadMyConversations + 1;
        }
      } else if (unreadMessages[id].type === 'INCOMING') {
        if (unreadMessages[id].count) {
          countUnreadIncomingConversations =
            countUnreadIncomingConversations + 1;
        }
      } else if (unreadMessages[id].type === 'CALLS_ONLY') {
        if (unreadMessages[id].count) {
          countUnreadCallsOnlyConversations =
            countUnreadCallsOnlyConversations + 1;
        }
      }
    }

    setCountUnreadMyConversations(countUnreadMyConversations);
    setCountUnreadIncomingConversations(countUnreadIncomingConversations);
    setCountUnreadCallsOnlyConversations(countUnreadCallsOnlyConversations);
  }, [unreadMessages]);

  return {
    countUnreadMyConversations,
    countUnreadIncomingConversations,
    countUnreadCallsOnlyConversations,
  };
};
