import {
  BodyPixSubtractor,
  useFilterStream,
  usePersistentFilter,
} from '@videoforce/background';
import { useMemo, useState } from 'react';

const constraints = { audio: false, video: { width: 480, height: 320 } };

export default function useBackgroundFilter() {
  const subtractor = usePersistentFilter(
    (enabled) =>
      // new ScreenShareFilter({
      //   canvasId: 'fopdfpsdf',
      //   videoInputId: 'aasdgg',
      //   enabled: true,
      // }),
      new BodyPixSubtractor({
        canvasId: 'lobby-filter-canvas',
        videoInputId: 'lobby-filter-video',
        config: {
          architecture: 'MobileNetV1',
          outputStride: 16,
          multiplier: 0.75,
          quantBytes: 2,
        },
        enabled,
      }),
    '@videoforce/hideBackground',
  );

  const { stream, isPermissionDeniedVideo } = useFilterStream(
    subtractor,
    constraints,
  );
  const [hideBackground, setHideBackground] = useState(subtractor.enabled);

  return useMemo(
    () => ({
      stream,
      isPermissionDeniedVideo,
      hideBackground,
      setHideBackground: (e: React.ChangeEvent<HTMLInputElement>) => {
        subtractor.setEnabled(e.target.checked).then(setHideBackground);
      },
    }),
    [
      subtractor,
      stream,
      isPermissionDeniedVideo,
      hideBackground,
      setHideBackground,
    ],
  );
}
