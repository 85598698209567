import { Scenario } from '__generated__/types';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import useBoolean from 'react-use/lib/useBoolean';

import { ScenarioDialogHeader } from './dialog-header';
import {
  CreatingScenario,
  CreatingStep,
  SelectingScenario,
  UpdatingStep,
} from './dialog-steps';
import { useScenarioForm } from './mutations';
import { ScenarioIntention, ScenarioStep } from './types';
import useScenario, { StateScenarioDialog } from './useScenario';

export enum ScenarioDialogIntention {
  Select = 'SELECT',
  Create = 'CREATE',
  Update = 'UPDATE',
}

export interface ScenarioDialogProps {
  initialState: StateScenarioDialog;
  onClose: () => void;
  scenario?: Scenario;
  selectScenario?: (
    onDone: () => void,
    scenario: Scenario,
  ) => Promise<void> | void;
}

const ScenarioDialog: React.FC<ScenarioDialogProps> = ({
  initialState,
  onClose,
  scenario,
  selectScenario,
}) => {
  const [state, dispatch] = useScenario(initialState);
  const { form, onCreateScenario, onUpdateScenario } =
    useScenarioForm(scenario);

  const [stepChanged, setStepChanged] = useBoolean(false);

  return (
    <Dialog open>
      <FormProvider {...form}>
        <ScenarioDialogHeader
          onDone={
            state.state === 'create_scenario' && state.fromSelecting
              ? () => dispatch({ type: 'select_scenario' })
              : onClose
          }
          state={state}
          action={
            (state.state === 'create_step' &&
              state.intention === ScenarioIntention.CREATE_SCENARIO) ||
            (state.state === 'update_step' &&
              state.intention === ScenarioIntention.CREATE_SCENARIO)
              ? () => dispatch({ type: 'creating_scenario' })
              : () => dispatch({ type: 'updating_scenario' })
          }
          stepChanged={stepChanged}
          scenarioChanged={form.formState.isDirty}
        />

        {state.state === 'create_scenario' && (
          <CreatingScenario
            createStep={(intention: ScenarioIntention) =>
              dispatch({ type: 'creating_step', intention })
            }
            updateStep={(step: ScenarioStep, intention: ScenarioIntention) =>
              dispatch({ type: 'updating_step', step, intention })
            }
            onSubmit={onCreateScenario}
            onDone={onClose}
            intention={ScenarioIntention.CREATE_SCENARIO}
          />
        )}

        {state.state === 'update_scenario' && (
          <CreatingScenario
            createStep={(intention: ScenarioIntention) =>
              dispatch({ type: 'creating_step', intention })
            }
            updateStep={(step: ScenarioStep, intention: ScenarioIntention) =>
              dispatch({ type: 'updating_step', step, intention })
            }
            onSubmit={onUpdateScenario}
            onDone={onClose}
            intention={ScenarioIntention.UPDATE_SCENARIO}
          />
        )}

        {state.state === 'create_step' && (
          <CreatingStep
            onDone={
              state.intention === ScenarioIntention.CREATE_SCENARIO
                ? () => dispatch({ type: 'creating_scenario' })
                : () => dispatch({ type: 'updating_scenario' })
            }
            setStepChanged={setStepChanged}
          />
        )}

        {state.state === 'update_step' && (
          <UpdatingStep
            step={state.step}
            onDone={
              state.intention === ScenarioIntention.CREATE_SCENARIO
                ? () => dispatch({ type: 'creating_scenario' })
                : () => dispatch({ type: 'updating_scenario' })
            }
            setStepChanged={setStepChanged}
          />
        )}

        {state.state === 'selecting_scenario' && (
          <SelectingScenario
            selectScenario={selectScenario}
            onDone={onClose}
            onAddNewScenario={(fromSelecting: boolean) =>
              dispatch({ type: 'creating_scenario', fromSelecting })
            }
          />
        )}
      </FormProvider>
    </Dialog>
  );
};

export default ScenarioDialog;
