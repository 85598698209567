import React from 'react';

interface Props {
  className?: string;
}

const IconChatOff: React.FC<Props> = () => {
  return (
    <svg
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.526653 4.06216C0.1908 4.68271 0 5.3928 0 6.14668V25.8361C0 26.7686 1.05458 27.2943 1.79899 26.7626L8.05636 22.2981C8.58142 21.924 9.19903 21.726 9.84397 21.726H18.1905L12.0052 15.5407H7.2505C6.62226 15.5407 6.11238 15.0316 6.11238 14.4026C6.11238 13.7743 6.62226 13.2645 7.2505 13.2645H9.72897L0.526653 4.06216ZM19.0763 15.5407H21.3337C21.9619 15.5407 22.4718 15.0316 22.4718 14.4026C22.4718 13.7743 21.9619 13.2645 21.3337 13.2645H16.8L13.7651 10.2295H21.3337C21.9619 10.2295 22.4718 9.72037 22.4718 9.09137C22.4718 8.46313 21.9619 7.95325 21.3337 7.95325H11.4888L5.2891 1.75354H24.2667C26.3285 1.75354 28 3.42501 28 5.48688V17.3329C28 19.259 26.7544 20.8993 25.0263 21.4908L19.0763 15.5407Z"
        fill="white"
      />
      <path
        d="M0.999999 1L24 24"
        stroke="#FF5A44"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconChatOff;
