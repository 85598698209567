import { BlockReason } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { useAuth } from 'auth';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    popup: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '10px 80px 10px 60px',
      background: palette.error.main,
      color: palette.common.white,
    },
    title: {
      margin: '0 20px',
    },
  }),
);

const AccountBlockAlert: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const { pathname } = useLocation();
  let blockReason = auth?.profile.blockReason;

  const accountId = auth?.claims.accountId;
  const userId = auth?.profile.id;
  if (accountId !== userId) {
    const employer = auth?.profile.employedAt.find((x) => x.id === accountId);
    blockReason = employer ? employer.blockReason : auth?.profile.blockReason;
  }

  if (!blockReason) {
    return null;
  }

  if (
    blockReason === BlockReason.InsufficientBalance &&
    pathname.startsWith('/plans')
  ) {
    return null;
  }

  return (
    <div className={classes.popup}>
      <ErrorOutlineIcon />
      <p className={classes.title}>
        {t(`components:accountBlockAlert.${auth?.profile.blockReason}`)}
      </p>
      <Link to="/plans">
        <ArrowRightAltIcon />
      </Link>
    </div>
  );
};

export default AccountBlockAlert;
