import React from 'react';

interface Props {
  className?: string;
}

const IconVideoCamOff: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.548 5.91643C19.8561 5.34399 18.9682 5 18 5H5C2.79086 5 1 6.79086 1 9V21C1 22.2228 1.54869 23.3174 2.4133 24.0512L20.548 5.91643ZM8.53553 25H18C20.2091 25 22 23.2091 22 21V17.6667L27.5689 22.0754C28.5523 22.8539 30 22.1535 30 20.8993V8.10064C30 6.84644 28.5523 6.14608 27.5689 6.92457L22.9706 10.5649L8.53553 25Z"
        fill="white"
      />
      <path
        d="M2 28L28 2"
        stroke="#FF5A44"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default IconVideoCamOff;
