import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    main: {
      fontWeight: 700,
      fontSize: '16px',
      lineHeight: '22px',
      color: '#000000',
    },
  }),
);

interface BlockTitlesProps {
  className?: string;
  children?: ReactNode;
}

const BlockTitles: React.FC<BlockTitlesProps> = ({ className, children }) => {
  const classes = useStyles();
  return <span className={clsx(classes.main, className)}>{children}</span>;
};

export default BlockTitles;
