import {
  ClientMessages,
  ServerMessages,
  WSMessages,
} from '@videoforce/commons';
import { Auth } from 'aws-amplify';

export class HTTPApi {
  private _logger = window.VideoforceLogger.child('http_api');
  private _httpApiURL: string;

  constructor(httpApiURL: string) {
    this._httpApiURL = httpApiURL;
  }

  protected async post(payload: ClientMessages): Promise<unknown> {
    const session = await Auth.currentSession();
    const token = session.getIdToken().getJwtToken();
    const raw = await fetch(this._httpApiURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : '',
      },
      body: JSON.stringify(payload),
    });
    const resp = await raw.json();

    return resp;
  }

  public async claimConversation(
    conversationSid: string,
  ): Promise<ServerMessages.ConversationClaimed> {
    this._logger.debug('conversationClaimed', conversationSid);
    const data: ClientMessages.ClaimConversation = {
      type: WSMessages.C_CLAIM_CONVERSATION,
      conversationSid,
    };
    const result = await this.post(data);
    return result as ServerMessages.ConversationClaimed;
  }

  public async acceptCall(
    callId: string,
  ): Promise<ServerMessages.CallAccepted> {
    this._logger.debug('accept call', callId);
    const data: ClientMessages.AcceptCall = {
      type: WSMessages.C_ACCEPT_CALL,
      callId,
    };
    const result = await this.post(data);
    return result as ServerMessages.CallAccepted;
  }

  public async declineCall(
    callId: string,
  ): Promise<ServerMessages.CallDeclined> {
    this._logger.debug('decline call', callId);
    const data: ClientMessages.DeclineCall = {
      type: WSMessages.C_DECLINE_CALL,
      callId,
    };
    const result = await this.post(data);
    return result as ServerMessages.CallDeclined;
  }

  public async endCall(callId: string): Promise<ServerMessages.EndCallSuccess> {
    this._logger.debug('endCall', callId);
    const data: ClientMessages.EndCall = {
      callId,
      type: WSMessages.C_END_CALL,
    };
    const result = await this.post(data);
    return result as ServerMessages.EndCallSuccess;
  }

  public async acceptRequest(
    callId: string,
  ): Promise<ServerMessages.RequestAccepted> {
    this._logger.debug('acceptRequest', callId);
    const data: ClientMessages.AcceptRequest = {
      type: WSMessages.C_ACCEPT_REQUEST,
      callId,
    };
    const result = await this.post(data);
    return result as ServerMessages.RequestAccepted;
  }

  public async declineRequest(
    callId: string,
  ): Promise<ServerMessages.RequestDeclined> {
    this._logger.debug('declineRequest', callId);
    const data: ClientMessages.DeclineRequest = {
      type: WSMessages.C_DECLINE_REQUEST,
      callId,
    };
    const result = await this.post(data);
    return result as ServerMessages.RequestDeclined;
  }
}
