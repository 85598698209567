import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useOperatorCallState } from '@videoforce/client';
import React from 'react';

import LatestCallLimitedDialog from './LatestCallLimitedDialog';
import OperatorCallView from './OperatorCallView';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      position: 'fixed',
      zIndex: 1000,
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    switchModal: {
      backgroundColor: theme.palette.grey[700],
    },
  }),
);

const clearLatestCall = () => {};

export const CallModal: React.FC = () => {
  const classes = useStyles();
  const { activeCall } = useOperatorCallState();

  // TODO: this was removed when we added conversations
  const latestCallLimited = false;

  if (activeCall) {
    return (
      <div className={classes.modal}>
        <OperatorCallView />
      </div>
    );
  }

  if (latestCallLimited) {
    return <LatestCallLimitedDialog onClose={clearLatestCall} />;
  }

  return null;
};
