import useSnackbarError from 'components/useSnackbarError';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { useUpdateUnavailableForCallsMutation } from './updateUnavailableForCalls.generated';

export default function useUpdateUnavailableForCalls(userId?: string) {
  const displayError = useSnackbarError();

  const [mutate] = useUpdateUnavailableForCallsMutation();

  const [, update] = useAsyncFn(
    async (isUnavailable: boolean) => {
      const result = await mutate({
        variables: { userId, isUnavailable },
      });
      if (result.data?.updateProfile.__typename !== 'User') {
        displayError(result.data?.updateProfile);
      }
    },
    [userId, mutate, displayError],
  );

  return update;
}
