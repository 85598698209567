import useSnackbarError from 'components/useSnackbarError';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { useCloseAlertMutation } from './closeAlert.generated';

export default function useCloseAlert(id?: string) {
  const displayError = useSnackbarError();

  const [mutate] = useCloseAlertMutation();

  const [_, closeAlert] = useAsyncFn(async () => {
    if (!id) {
      return;
    }
    const result = await mutate({
      variables: { id },
      optimisticResponse: {
        closeAlert: {
          __typename: 'CloseAlertSuccess',
          alert: { id, closed: true },
        },
      },
    });
    if (result.data?.closeAlert.__typename !== 'CloseAlertSuccess') {
      displayError(result.data?.closeAlert);
    }
  }, [id, mutate, displayError]);

  return closeAlert;
}
