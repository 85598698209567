import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Message, useConversationOperator } from '@videoforce/client';
import React, { useEffect, useRef } from 'react';

import MessagesItem from './MessagesItem';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      overflowY: 'auto',
      '&::-webkit-scrollbar ': {
        width: '10px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '25px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'rgba(0, 0, 0, 0.3)',
        borderRadius: '25px',
        height: '20px',
      },
    },
    content: {
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      flexGrow: 1,
      fontFamily: 'Manrope, sans-serif',
    },
  }),
);

export interface ChatMessageProps {
  myId?: string;
  messages?: Message[];
}

export const Messages: React.FC<ChatMessageProps> = ({ messages, myId }) => {
  const classes = useStyles();
  const contentEndRef = useRef<HTMLDivElement>(null);
  const { conversation } = useConversationOperator();

  useEffect(() => {
    if (contentEndRef.current) {
      contentEndRef.current.scrollIntoView(true);
      if (conversation) {
        conversation?.getMessagesCount().then((count) => {
          conversation?.updateLastReadMessageIndex(count);
        });
      }
    }
  }, [conversation, messages]);

  return (
    <div className={classes.content}>
      <div className={classes.root}>
        {messages?.map((m) => (
          <MessagesItem key={m.index} myId={myId} message={m} />
        ))}
        <div ref={contentEndRef} />
      </div>
    </div>
  );
};
