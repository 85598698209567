import { Exact, InputMaybe } from '__generated__/types';
import { ApolloQueryResult } from '@apollo/client';
import Loading from 'components/Loading';
import React, { PropsWithChildren, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPaymentQuery } from 'routes/plans/payment/payment.generated';

import { useMyProfileQuery } from './myProfile.generated';
import refreshSession from './refreshSession';
import resetPromocode from './resetPromocode';
import signOut from './signOut';
import switchAccount from './switchAccount';
import { Claims, UserProfile } from './types';
import useAuthEvents from './useAuthEvents';

export interface AuthCtx {
  claims: Claims;
  profile: UserProfile;
  switchAccount: (accountId: string) => Promise<void>;
  resetPromocode: () => Promise<void>;
  refreshSession: () => Promise<void>;
  signOut: () => void;
  refetch: (
    variables?:
      | Partial<
          Exact<{
            id?: InputMaybe<string> | undefined;
          }>
        >
      | undefined,
  ) => Promise<ApolloQueryResult<GetPaymentQuery>>;
}

export const AuthContext = React.createContext<AuthCtx | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { claims, loading } = useAuthEvents();
  const { t } = useTranslation();

  const query = useMyProfileQuery({
    fetchPolicy: 'cache-and-network',
    // this will prevent network requests from firing on cache changes
    // see https://github.com/apollographql/apollo-client/issues/6760#issuecomment-668188727
    nextFetchPolicy: 'cache-first',
    skip: !claims,
  });
  const { data, refetch } = query;
  const profileLoading = query.loading && data === undefined;

  const value = useMemo((): AuthCtx | null => {
    if (data?.getUser?.__typename !== 'User' || !claims) {
      return null;
    }
    return {
      claims,
      profile: data.getUser,
      switchAccount,
      signOut,
      resetPromocode,
      refreshSession,
      refetch,
    };
  }, [data, claims, refetch]);

  return (
    <AuthContext.Provider value={value}>
      {loading || profileLoading ? (
        <Loading
          title={
            loading
              ? t('components:authProvider.loading')
              : t('components:authProvider.profileLoading')
          }
        />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
