import { useMemo } from 'react';

import { VideoNode } from './types';
import UploadsCache from './UploadsCache';

export default function useVideoNode(
  src?: string | Blob | MediaStream | { mp4: string } | null,
): Pick<VideoNode, 'stream' | 'video'> | undefined {
  return useMemo(() => {
    if (!src) {
      return undefined;
    }
    if (typeof src === 'string') {
      // check cache
      const loc = UploadsCache.get(src);
      return { video: { mp4: loc ?? src } };
    }
    if (src instanceof Blob) {
      return { video: { mp4: URL.createObjectURL(src) } };
    }
    if (src instanceof MediaStream) {
      return { stream: src };
    }
    return { video: src };
  }, [src]);
}
