import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import {
  useOperatorCallActions,
  useOperatorCallState,
} from '@videoforce/client';
import { clsx } from 'clsx';
import ButtonProgress from 'components/ButtonProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import { DeclineDialog } from '../decline';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    root: {
      maxWidth: 457,
      padding: spacing(4),
      borderRadius: '16px',
      background: '#FFFFFF',
      [breakpoints.up('sm')]: {
        minWidth: 360,
      },
    },
    header: {
      display: 'flex',
      marginBottom: '20px',
    },
    title: { fontWeight: 600, fontSize: '20px', color: palette.primary.main },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '130px',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    acceptLoading: {
      flex: 1,
      display: 'flex',
      marginRight: spacing(1),
      [breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: spacing(1),
      },
    },
    controlButton: {
      flex: 1,
      height: '43px',
      fontWeight: 700,
      fontSize: '16px',
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: spacing(1),
      },
      '& svg': {
        marginRight: '12px',
        maxWidth: '15px',
      },
    },
    decline: {
      '&.MuiButton-root': {
        color: palette.common.white,
        backgroundColor: palette.error.main,
        '&:hover': {
          backgroundColor: palette.error.dark,
        },
      },
    },
    accept: {
      '&.MuiButton-root': {
        color: palette.common.white,
        background: palette.success.main,
        '&:hover': {
          backgroundColor: palette.success.dark,
        },
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    info: {
      padding: '12px 16px',
      background: '#F5F5F5',
      borderRadius: '8px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#787878',
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },
    detailsItem: {
      display: 'flex',
      gap: '3px',
    },
    detailsItemTitle: {
      color: '#A5A5A5',
    },
    pageUrlData: {
      color: palette.primary.main,
    },
    operatorData: {
      color: '#333333',
    },
  }),
);

interface SimpleCallControlProps {
  callId: string;
}

export const SimpleCallControl: React.FC<SimpleCallControlProps> = (props) => {
  const { callId } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { acceptedRequest } = useOperatorCallState();
  const { acceptRequestedCall, declineCall } = useOperatorCallActions();

  const [isOpenDeclineDialog, toggleOpenDeclineDialog] = useToggle(false);

  const onDeclineCall = () => {
    declineCall(callId);
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.title}>
            {t('components:requestCall.simpleCallControl.title')}
          </div>
        </div>

        <div className={classes.body}>
          <div className={classes.info}>
            {t('components:requestCall.simpleCallControl.info')}
          </div>

          <div className={classes.details}>
            <div className={classes.detailsItem}>
              <Typography variant="h5" className={classes.detailsItemTitle}>
                {t(
                  'components:requestCall.simpleCallControl.details.pageTitle',
                )}
              </Typography>

              <Typography variant="h5" className={classes.pageUrlData}>
                {acceptedRequest?.sourcePage}
              </Typography>
            </div>
          </div>
        </div>

        <div className={classes.controls}>
          <ButtonProgress className={classes.acceptLoading}>
            <Button
              variant="contained"
              className={clsx(classes.decline, classes.controlButton)}
              onClick={toggleOpenDeclineDialog}
            >
              <CallEndIcon />
              {t('components:requestCall.simpleCallControl.button.decline')}
            </Button>
          </ButtonProgress>

          <ButtonProgress className={classes.acceptLoading}>
            <Button
              variant="contained"
              className={clsx(classes.accept, classes.controlButton)}
              onClick={() => acceptRequestedCall(callId)}
            >
              <CallIcon />
              {t('components:requestCall.simpleCallControl.button.accept')}
            </Button>
          </ButtonProgress>
        </div>
      </div>

      <DeclineDialog
        isOpened={isOpenDeclineDialog}
        onConfirm={onDeclineCall}
        onCancel={toggleOpenDeclineDialog}
        title={t('components:requestCall.incoming.declineDialog.call.title')}
        confirmButton={t(
          'components:requestCall.incoming.declineDialog.call.button.confirm',
        )}
        cancelButton={t(
          'components:requestCall.incoming.declineDialog.button.return',
        )}
      />
    </>
  );
};
