import { Auth, CognitoUser } from '@aws-amplify/auth';

import getClaimsFromSession from './getClaimsFromSession';
import { Claims } from './types';

export default async function getClaims(): Promise<Claims | null> {
  try {
    const user: CognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.userSession(user);
    const result = await new Promise<Claims | null>((resolve, reject) => {
      user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
        if (err) {
          reject(err);
          return;
        }
        resolve(getClaimsFromSession(session));
      });
    });
    return result;
  } catch {
    return null;
  }
}
