/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateScenarioMutationVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  settings: Types.UpdateScenarioInput;
}>;


export type UpdateScenarioMutation = { __typename?: 'Mutation', updateScenario: { __typename: 'Error' } | { __typename: 'ForbiddenError' } | { __typename: 'NotFoundError' } | { __typename: 'Scenario', id: string, name: string, createdAt: string, steps: Array<{ __typename?: 'ScenarioStep', id: string, name: string, type: Types.ScenarioStepType, order: number }> } | { __typename: 'ValidationError' } };


export const UpdateScenarioDocument = gql`
    mutation updateScenario($id: ID!, $settings: UpdateScenarioInput!) {
  updateScenario(id: $id, settings: $settings) {
    __typename
    ... on Scenario {
      id
      name
      steps {
        id
        name
        type
        order
      }
      createdAt
    }
  }
}
    `;
export type UpdateScenarioMutationFn = Apollo.MutationFunction<UpdateScenarioMutation, UpdateScenarioMutationVariables>;

/**
 * __useUpdateScenarioMutation__
 *
 * To run a mutation, you first call `useUpdateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateScenarioMutation, { data, loading, error }] = useUpdateScenarioMutation({
 *   variables: {
 *      id: // value for 'id'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateScenarioMutation, UpdateScenarioMutationVariables>(UpdateScenarioDocument, options);
      }
export type UpdateScenarioMutationHookResult = ReturnType<typeof useUpdateScenarioMutation>;
export type UpdateScenarioMutationResult = Apollo.MutationResult<UpdateScenarioMutation>;
export type UpdateScenarioMutationOptions = Apollo.BaseMutationOptions<UpdateScenarioMutation, UpdateScenarioMutationVariables>;