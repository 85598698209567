import React from 'react';

interface Props {
  className?: string;
}

const IconToggleCamera: React.FC<Props> = () => {
  return (
    <svg
      width="34"
      height="26"
      viewBox="0 0 34 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.18182 6.5L12.3636 13H7.72727C7.72727 15.5859 8.70422 18.0658 10.4432 19.8943C12.1822 21.7228 14.5407 22.75 17 22.75C18.5455 22.75 20.0445 22.3437 21.3273 21.6125L23.5836 23.985C21.6827 25.2525 19.4264 26 17 26C13.721 26 10.5762 24.6304 8.25759 22.1924C5.93896 19.7544 4.63636 16.4478 4.63636 13H0L6.18182 6.5ZM26.2727 13C26.2727 10.4141 25.2958 7.93419 23.5568 6.10571C21.8178 4.27723 19.4593 3.25 17 3.25C15.4545 3.25 13.9555 3.65625 12.6727 4.3875L10.4164 2.015C12.3173 0.7475 14.5736 0 17 0C20.279 0 23.4238 1.36964 25.7424 3.80761C28.061 6.24558 29.3636 9.55219 29.3636 13H34L27.8182 19.5L21.6364 13"
        fill="white"
      />
    </svg>
  );
};

export default IconToggleCamera;
