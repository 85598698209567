import { TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BlockTitles from 'components/BlockTitles';
import registerInput from 'components/forms/registerInput';
import { ScenarioStep } from 'components/scenario/types';
import translateInputError from 'i18n/helpers/translateInputError';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { SelectingStepType } from './type';
import { ScenarioStepVideo } from './video';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    title: {
      marginBottom: spacing(),
    },
    videoSelect: {
      marginTop: spacing(3),
    },
    typeSelect: {
      marginTop: spacing(3),
      width: '272px',
    },
  }),
);

interface ScenarioStepFormProps {
  steps: ScenarioStep[];
}

export const ScenarioStepForm: React.FC<ScenarioStepFormProps> = ({
  steps,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { formState, register } = useFormContext();

  return (
    <>
      <BlockTitles className={classes.title}>
        {t(`components:scenario.dialog.step.name.title`)}
      </BlockTitles>

      <TextField
        {...registerInput(register, 'name', { required: true })}
        variant="outlined"
        type="text"
        required
        placeholder={t('components:scenario.dialog.step.name.inputPlaceholder')}
        autoComplete="off"
        {...translateInputError(t, formState.errors, 'name')}
      />

      <ScenarioStepVideo />

      <SelectingStepType className={classes.typeSelect} steps={steps} />
    </>
  );
};
