import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, shape }) =>
  createStyles({
    root: {
      alignSelf: 'stretch',
      flex: 1,
      borderStyle: 'dashed',
      borderRadius: shape.borderRadius,
      borderWidth: 2,
      borderColor: palette.grey[300],
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: '#F2F7FA',
        borderColor: '#7C7C7C',
      },
    },
    textOverlay: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      width: '50%',
    },
    info: {
      fontSize: '20px',
      fontWeight: 700,
      textAlign: 'center',
    },
    size: {
      marginTop: '30px',
      fontSize: '14px',
      fontWeight: 700,
      color: '#999999',
    },
  }),
);

interface VideoDropzoneProps {
  onFileDropped: (file: File) => void;
  className?: string;
}

const VideoDropzone: FC<VideoDropzoneProps> = ({
  onFileDropped,
  className,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const onDrop = useCallback(
    (files: File[]) => {
      onFileDropped(files[0]);
    },
    [onFileDropped],
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()} className={clsx(classes.root, className)}>
      <input {...getInputProps()} />
      <div className={classes.textOverlay}>
        <span className={classes.info}>
          {t('components:video.uploadDialog.dropzone')}
        </span>
        <span className={classes.size}>
          {t('components:video.uploadDialog.fileMaxSize')}
        </span>
      </div>
    </div>
  );
};

export default VideoDropzone;
