import { ScenarioStepType } from '__generated__/types';
import {
  ScenarioStep,
  ScenarioStepAnswerButton,
} from 'components/scenario/types';
import { Reorder, useDragControls } from 'framer-motion';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';

import { StepButton } from '../StepButton';
import { AnswerButtonMenu } from './AnswerButtonMenu';

interface AnswerButtonsProps {
  button: ScenarioStepAnswerButton;
  index: number;
  steps: ScenarioStep[];
}

export const AnswerButton: React.FC<AnswerButtonsProps> = ({
  button,
  index,
  steps,
}) => {
  const dragControls = useDragControls();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'answerButton',
  });

  const triggerProps = bindTrigger(popupState);
  const trigger = (e: React.MouseEvent) => triggerProps.onClick(e);

  return (
    <Reorder.Item
      key={button.id}
      value={button}
      dragListener={false}
      dragControls={dragControls}
    >
      <StepButton
        key={button.id}
        button={button}
        index={index}
        actionTitle={ScenarioStepType.AnswerButtons}
        trigger={trigger}
        dragControls={dragControls}
        steps={steps}
      />

      <AnswerButtonMenu
        popupState={popupState}
        index={index}
        button={button}
        steps={steps}
      />
    </Reorder.Item>
  );
};
