import { Language, LANGUAGES } from '@videoforce/commons';
import resources from '@videoforce/translations/admin';
import formatDate from 'date-fns/format';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const initI18next = () => {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns: ['commons', 'components', 'errors', 'routes', 'yup'],
      supportedLngs: LANGUAGES,
      fallbackLng: Language.ru,
      debug: process.env.NODE_ENV === 'development',
      resources,
      interpolation: {
        escapeValue: false, // react already safes from xss
        format: function (value, format, _lng) {
          if (format === 'uppercase') return value.toUpperCase();
          if (value instanceof Date && !!format)
            return formatDate(value, format);
          return value;
        },
      },
    });
};

export const changeLanguage = (lng: string) => {
  i18n.changeLanguage(lng);
};
