import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';

import logger from './logger';
import { SWIcoming } from './types';
import { useServiceWorkerState } from './useServiceWorkerState';
interface ServiceWorkerCtx {
  isUpdateAvailable: boolean;
  update: () => void;
}

const ServiceWorkerContext = createContext<ServiceWorkerCtx>({
  isUpdateAvailable: false,
  update: () => {},
});

export const ServiceWorkerProvider: FC<PropsWithChildren> = ({ children }) => {
  const { isUpdateAvailable, waitingServiceWorker } = useServiceWorkerState();

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an incosistent state
    waitingServiceWorker?.addEventListener('statechange', (event) => {
      if ((event.target as ServiceWorker).state === 'activated') {
        logger.debug('reloading window');
        window.location.reload();
      }
    });
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      update: () => {
        logger.debug('sending SKIP_WAITING');
        // We send the SKIP_WAITING message to tell the Service Worker
        // to update its cache and flush the old one
        waitingServiceWorker?.postMessage({ type: SWIcoming.SKIP_WAITING });
      },
    }),
    [isUpdateAvailable, waitingServiceWorker],
  );

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  );
};

export const useServiceWorkerUpdates = () => useContext(ServiceWorkerContext);
