import { Scenario } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import Loading from 'components/Loading';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useListScenariosQuery } from './listScenario.generated';
import { ScenarioCard } from './ScenarioCard';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  }),
);

interface ScenarioListProps {
  selectedScenario: Scenario | undefined;
  setSelectedScenario: React.Dispatch<
    React.SetStateAction<Scenario | undefined>
  >;
  className?: string;
}

export const ScenarioList: React.FC<ScenarioListProps> = ({
  selectedScenario,
  setSelectedScenario,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, loading, error } = useListScenariosQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data?.listScenarios) {
    return <Loading />;
  }

  if (error) {
    return <Alert severity="error">{t('errors:default')}</Alert>;
  }

  if (data?.listScenarios.__typename !== 'ListScenariosSuccess') {
    return <Alert severity="error">{t('errors:default')}</Alert>;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {data.listScenarios.scenarios.map((scenario) => (
        <ScenarioCard
          key={scenario.id}
          scenario={scenario}
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
        />
      ))}
    </div>
  );
};
