import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      '& .MuiBackdrop-root': {
        background: 'none',
      },
    },
    modal: {
      maxWidth: 360,
      background: 'rgba(0, 0, 0, 0.7)',
      color: palette.common.white,
    },
    content: {
      padding: spacing(2, 4),
      borderBottom: '1px solid #DBDBDB',
    },
    actions: {
      padding: spacing(2, 4),
      display: 'flex',
      justifyContent: 'space-between',
    },
    h3: {
      fontWeight: 600,
      color: palette.common.white,
    },
    text: {
      '&:last-child': {
        marginBottom: 0,
      },
    },
    button: {
      flexGrow: 1,
      color: palette.common.white,
    },
  }),
);

export interface Props {
  onClose?: () => void;
}

const CallDurationLimitWarning: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        root: classes.root,
        paper: classes.modal,
      }}
      onClose={onClose}
      open={true}
    >
      <div className={classes.content}>
        <Typography variant="h3" className={classes.h3}>
          {t('components:callLimitDurationWarning.title')}
        </Typography>

        <p className={classes.text}>
          {t('components:callLimitDurationWarning.text1')}
        </p>
        <p className={classes.text}>
          {t('components:callLimitDurationWarning.text2')}
        </p>
      </div>

      <DialogActions className={classes.actions}>
        <Button className={classes.button} onClick={onClose}>
          {t('commons:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CallDurationLimitWarning;
