import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import SendIcon from '@material-ui/icons/Send';
import { Conversation } from '@videoforce/client';
import { clsx } from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TextareaAutosize from 'react-textarea-autosize';

import { useSendMessage } from './useSendMessage';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignSelf: 'stretch',
      justifyContent: 'flex-start',
      alignItems: 'flex-end',
      marginTop: theme.spacing(1),
      padding: '20px 16px 20px 16px',
      height: 'auto',
      width: '100%',
      background: '#FFFFFF',
      boxSizing: 'border-box',
      fontFamily: 'Manrope, sans-serif',
      borderTop: '1px solid #C9CACB',
    },
    button: {
      display: 'flex',
      color: theme.palette.primary.main,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    input: {
      width: '100%',
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      resize: 'none',
      fontSize: '16px',
      lineHeight: '18px',
      fontWeight: 400,
      fontFamily: 'Manrope, sans-serif',
      maxHeight: '300px',
      '&::-webkit-scrollbar': {
        width: 0,
      },
    },
  }),
);

interface ChatInputProps {
  conversation?: Conversation;
  className?: string;
}

export const ChatInput: React.FC<ChatInputProps> = ({
  conversation,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [textValue, setTextValue, sendMessage, loading] =
    useSendMessage(conversation);

  return (
    <div className={clsx(classes.container, className)}>
      <TextareaAutosize
        placeholder={t('components:modalCall.chat.placeHolder')}
        className={classes.input}
        value={textValue}
        minRows={1}
        maxRows={20}
        onChange={(e) => {
          setTextValue(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.ctrlKey && !e.shiftKey) {
            sendMessage();
            e.preventDefault();
          }
        }}
      />
      {textValue && (
        <div className={classes.button} onClick={sendMessage}>
          {loading ? (
            <CircularProgress style={{ width: '25px', height: '25px' }} />
          ) : (
            <SendIcon />
          )}
        </div>
      )}
    </div>
  );
};
