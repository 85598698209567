import { Auth } from '@aws-amplify/auth';
import S3 from 'aws-sdk/clients/s3';
import { Env } from 'config/env';
import { v4 } from 'uuid';

export default async function uploadToS3(file: File): Promise<string> {
  const credentials = await Auth.currentCredentials();
  const extension = file.name.split('.').pop();

  const s3 = new S3({
    params: { Bucket: Env.uploadsBucket },
    region: Env.region,
    credentials: Auth.essentialCredentials(credentials),
  });
  const upload = s3.upload({
    Bucket: Env.uploadsBucket,
    // This generated UUID later becomes db id of video
    Key: `temp/video/${v4()}.${extension}`,
    Body: file,
    ContentType: file.type,
  });
  // upload?.on('httpUploadProgress', listener);

  return new Promise((resolve, reject) => {
    upload.send((err, res) => {
      if (err) {
        reject(err);
      } else {
        const s3key = res.Location.split(Env.uploadsBucket + '/').pop();
        if (s3key) {
          resolve(s3key);
        } else {
          reject(
            new Error(
              `s3 key cannot be extracted from location "${res.Location}"`,
            ),
          );
        }
      }
    });
  });
}
