import Button from '@material-ui/core/Button';
import ButtonProgress from 'components/ButtonProgress';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useShopifyConfirmationUrlQuery } from './shopifyConfirmationURL.generated';

const ShopifyConfirmationButton: FC = () => {
  const { t } = useTranslation();

  const { loading, data } = useShopifyConfirmationUrlQuery({
    fetchPolicy: 'network-only',
  });
  let url = '#';

  if (data?.shopifyConfirmationURL.__typename === 'ShopifyConfirmationURL') {
    url = data.shopifyConfirmationURL.url;
  }

  return (
    <ButtonProgress loading={loading}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={loading}
        href={url}
      >
        {t(`components:shopifyModal.request.button`)}
      </Button>
    </ButtonProgress>
  );
};

export default ShopifyConfirmationButton;
