import { Scenario } from '__generated__/types';
import { MutationResult } from '@apollo/client';
import useSnackbarError from 'components/useSnackbarError';
import useSnackbarSuccess from 'components/useSnackbarSuccess';
import React from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import {
  DeleteScenarioMutation,
  useDeleteScenarioMutation,
} from './deleteScenario.generated';

export default function useDeleteScenario(
  id: string,
  setselectedStep: React.Dispatch<React.SetStateAction<Scenario | undefined>>,
): [() => Promise<void>, MutationResult<DeleteScenarioMutation>] {
  const displaySuccess = useSnackbarSuccess();
  const displayError = useSnackbarError();

  const [mutate, data] = useDeleteScenarioMutation({
    variables: { id },
    refetchQueries: ['listScenarios'],
  });

  const [, deleteScenario] = useAsyncFn(async () => {
    const result = await mutate();
    if (result.data?.deleteScenario === null) {
      setselectedStep(undefined);
      displaySuccess(
        'routes:videoLibrary.tabs.scenario.deleting.displaySuccess',
      );
    } else {
      displayError(result.data?.deleteScenario);
    }
  }, [mutate]);

  return [deleteScenario, data];
}
