import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import permissionAlertImage from './permissionAlertImage.png';


const useStyles = makeStyles(() =>
  createStyles({
    popup: {
      position: 'fixed',
      top: '10px',
      left: 'calc(50% - 347px / 2)',
      padding: '26px 28px 0 26px',
      background: '#FFFFFF',
      width: '347px',
      boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.3)',
      borderRadius: '15px',
      zIndex: 1000,
    },
    popupMobile: {
      width: '259px',
      left: 'calc(50% - 259px / 2)',
    },
    title: {
      fontWeight: 700,
      fontSize: '18px',
      lineHeight: '21px',
      color: '#333333',
      marginBottom: '13px',
    },
    subtitle: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#696969',
      paddingBottom: '62px',
    },
    subtitleShort: {
      paddingBottom: '22px',
    },
    subtitleMobile: {
      paddingBottom: '25px',
    },
    button: {
      position: 'absolute',
      left: '16px',
      bottom: '16px',
      '&.MuiButton-root.MuiButton-textSizeSmall': {
        padding: '6.5px 11px',
      },
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      borderRadius: '24px',
      width: '24px',
      height: '24px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
        background: 'rgba(105, 105, 105, 0.1)',
      },
      '& svg': {
        fontSize: '18px',
        color: '#696969',
      },
    },
    details: {
      paddingBottom: '24px',
    },
    detailsImg: {
      paddingBottom: '24px',
    },
    detailsText: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '18px',
      color: '#696969',
    },
  }),
);

interface Props {
  showAlert: boolean;
  toggleShowAlert: () => void;
}

export const PermissionAlert: React.FC<Props> = ({
  showAlert,
  toggleShowAlert,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [showDetails, toggleShowDetails] = useToggle(false);

  if (!showAlert) {
    return null;
  }

  return (
    <div
      className={clsx(classes.popup, {
        [classes.popupMobile]: isMobile,
      })}
    >
      <div className={classes.title}>
        {t('components:popupAboutPermission.title')}
      </div>
      <div
        className={clsx(classes.subtitle, {
          [classes.subtitleShort]: showDetails,
          [classes.subtitleMobile]: isMobile,
        })}
      >
        {t('components:popupAboutPermission.subtitle')}
      </div>

      {!isMobile && !showDetails && (
        <Button
          onClick={toggleShowDetails}
          className={classes.button}
          color="primary"
          size="small"
        >
          {t('components:popupAboutPermission.moreDetailedButton')}
          <ExpandMoreIcon />
        </Button>
      )}

      <div className={classes.close} onClick={toggleShowAlert}>
        <CloseIcon />
      </div>

      {!isMobile && showDetails && (
        <div className={classes.details}>
          <img
            src={permissionAlertImage}
            className={classes.detailsImg}
            alt=""
          />
          <div className={classes.detailsText}>
            {t('components:popupAboutPermission.moreDetailedText')}
          </div>
        </div>
      )}
    </div>
  );
};
