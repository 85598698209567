import { Scenario, Video } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { VideoMiniature } from 'components/video';
import React from 'react';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      border: '1px solid #EFEFEF',
      boxSizing: 'border-box',
      borderRadius: '12px',
      padding: spacing(2),
      margin: '0 20px 20px 0',
      alignItems: 'flex-start',
      position: 'relative',
      '&:hover': {
        border: '1px solid #CACACA',
      },
      width: '225px',
      height: '106px',
    },
    rootSelected: {
      background: '#E8F4FD',
      border: '1px solid #3198E3',
      '&:hover': {
        border: '1px solid #3198E3',
      },
    },
    title: {
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '22px',
      marginBottom: spacing(),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '95%',
    },
    list: {
      display: 'flex',
      gap: '0 6px',
    },
    video: {
      minWidth: '33px',
      maxWidth: '33px',
      height: '43px',
    },
    checkIcon: {
      position: 'absolute',
      bottom: '0px',
      right: '6px',
      '& svg path': {
        fill: '#3198E3',
      },
    },
    plug: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minWidth: '33px',
      maxWidth: '33px',
      height: '43px',
      borderRadius: '4px',
      background: 'rgba(156, 156, 156, 0.15)',
      color: '#9C9C9C',
      fontWeight: 500,
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      '& svg path': {
        fill: '#C4C4C4',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
  }),
);

interface ScenarioCardProps {
  scenario: Scenario;
  selectedScenario?: Scenario | undefined;
  setSelectedScenario?: React.Dispatch<
    React.SetStateAction<Scenario | undefined>
  >;
  setShowUnselectDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ScenarioCard: React.FC<ScenarioCardProps> = ({
  scenario,
  selectedScenario,
  setSelectedScenario,
  setShowUnselectDialog,
}) => {
  const classes = useStyles();
  const { id } = scenario;

  const isSelected = selectedScenario?.id === id;

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootSelected]: isSelected,
      })}
      onClick={() => {
        if (!selectedScenario) {
          setSelectedScenario?.(scenario);
        } else if (selectedScenario.id !== id) {
          setSelectedScenario?.(scenario);
        } else {
          setSelectedScenario?.(undefined);
        }
      }}
    >
      <div className={classes.title}>{scenario.name}</div>
      <div className={classes.list}>
        {scenario.steps
          .filter((_, index) => index < 4)
          .map((step) => (
            <VideoMiniature
              key={step.id}
              video={step.video as Video}
              className={classes.video}
              isHideDuration={true}
            />
          ))}

        {scenario.steps.length > 4 && (
          <div className={classes.plug}>+{scenario.steps.length - 4}</div>
        )}
      </div>

      {isSelected && (
        <div className={classes.checkIcon}>
          <CheckCircleIcon />
        </div>
      )}

      {!!setShowUnselectDialog && (
        <span
          className={classes.close}
          onClick={() => setShowUnselectDialog?.(true)}
        >
          <CloseIcon />
        </span>
      )}
    </div>
  );
};
