export { setupTwilioLoggerBridge } from './setupTwilioLoggerBridge';
export { LogLevel } from './types';
export { VideoforceLogger } from './VideoforceLogger';
import { isLocalhost } from './isLocalhost';
import { LogLevel } from './types';
import { VideoforceLogger } from './VideoforceLogger';

declare global {
  interface Window {
    VideoforceLogger: VideoforceLogger;
  }
}

const root = new VideoforceLogger(
  'vf',
  isLocalhost ? LogLevel.DEBUG : LogLevel.INFO,
);

if (typeof window !== 'undefined') {
  window.VideoforceLogger = root;
}

export default root;
