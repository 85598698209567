import React from 'react';

interface Props {
  className?: string;
}

const IconChatOn: React.FC<Props> = () => {
  return (
    <svg
      width="28"
      height="26"
      viewBox="0 0 28 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 4.48688C28 2.42501 26.3285 0.75354 24.2667 0.75354H4.39314C1.97046 0.75354 0 2.724 0 5.14668V24.8361C0 25.7686 1.05458 26.2943 1.79899 25.7626L8.05636 21.2981C8.58142 20.924 9.19903 20.726 9.84397 20.726H23.6069C26.0295 20.726 28 18.7555 28 16.3329V4.48688ZM21.3337 14.5407H7.2505C6.62226 14.5407 6.11238 14.0316 6.11238 13.4026C6.11238 12.7743 6.62226 12.2645 7.2505 12.2645H21.3337C21.9619 12.2645 22.4718 12.7743 22.4718 13.4026C22.4718 14.0316 21.9619 14.5407 21.3337 14.5407ZM21.3337 9.22949H7.2505C6.62226 9.22949 6.11238 8.72037 6.11238 8.09137C6.11238 7.46313 6.62226 6.95325 7.2505 6.95325H21.3337C21.9619 6.95325 22.4718 7.46313 22.4718 8.09137C22.4718 8.72037 21.9619 9.22949 21.3337 9.22949Z"
        fill="white"
      />
    </svg>
  );
};

export default IconChatOn;
