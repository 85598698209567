import { useCallback, useState } from 'react';
import Video, { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';

import { getVideoParams } from '../utils/getVideoParams';

export default function useLocalTracks() {
  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();

  const getLocalAudioTrack = useCallback(async () => {
    const newTrack = await Video.createLocalAudioTrack();
    setAudioTrack(newTrack);
    return newTrack;
  }, []);

  const getLocalVideoTrack = useCallback(async () => {
    const newTrack = await Video.createLocalVideoTrack({
      ...getVideoParams(),
      name: 'camera',
    });
    setVideoTrack(newTrack);
    return newTrack;
  }, []);

  const removeLocalAudioTrack = useCallback(() => {
    if (audioTrack) {
      audioTrack.stop();
      setAudioTrack(undefined);
    }
  }, [audioTrack]);

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  const setAudioAndVideoTracks = useCallback(
    (localTracks: Video.LocalTrack[]) => {
      localTracks.forEach((track) => {
        const newVideoTrack = track.kind === 'video';
        const newAudioTrack = track.kind === 'audio';

        if (newVideoTrack) {
          setVideoTrack(track);
        }
        if (newAudioTrack) {
          setAudioTrack(track);
        }
      });
    },
    [],
  );

  const localTracks = [audioTrack, videoTrack].filter(
    (track) => track !== undefined,
  ) as (LocalAudioTrack | LocalVideoTrack)[];

  return {
    audioTrack,
    videoTrack,
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    setAudioAndVideoTracks,
  };
}
