import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useServiceWorkerUpdates } from './ServiceWorkerProvider';

export const ServiceWorkerUpdateDialog: FC = () => {
  const { t } = useTranslation();
  const { isUpdateAvailable, update } = useServiceWorkerUpdates();

  return (
    <Dialog open={isUpdateAvailable} disableEscapeKeyDown>
      <DialogTitle>
        {t('components:serviceWorkerUpdateDialog.title')}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('components:serviceWorkerUpdateDialog.description')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={update} color="primary" autoFocus>
          {t('components:serviceWorkerUpdateDialog.updateButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
