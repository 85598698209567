import { Video } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from 'clsx';
import VideoModal from 'components/VideoModal';
import React, { useRef } from 'react';
import useHoverDirty from 'react-use/lib/useHoverDirty';
import useToggle from 'react-use/lib/useToggle';
import durationToString from 'utils/durationToString';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      '& img': {
        borderRadius: '4px',
        maxWidth: '100%',
        maxHeight: '100%',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '& video': {
        maxWidth: '100%',
        borderRadius: '4px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    duration: {
      zIndex: 0,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '60%',
      color: 'white',
      width: '100%',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
      pointerEvents: 'none',
      fontSize: '12px',
    },
    durationWithoutPhoto: {
      top: '65%',
      color: 'black',
    },
    play: {
      zIndex: 0,
      position: 'absolute',
      top: 'calc(50% - 12px)',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      pointerEvents: 'none',
      '& svg path': {
        fill: '#ffffff',
      },
    },
    playBlack: {
      '& svg path': {
        fill: '#000000',
      },
    },
    fit: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
);

interface VideoMiniatureProps {
  video: Video | undefined;
  className?: string;
  isHideDuration?: boolean;
}
/** Component using for VideoCard and ScenarioCard */
export const VideoMiniature: React.FC<VideoMiniatureProps> = ({
  video,
  className,
  isHideDuration,
}) => {
  const classes = useStyles();
  const hoverableRef = useRef(null);
  const hover = useHoverDirty(hoverableRef);
  const [openVideo, toggleOpenVideo] = useToggle(false);

  if (!video) {
    return null;
  }

  return (
    <>
      <div className={clsx(classes.root, className)}>
        {video.jpg ? (
          <img
            className={classes.fit}
            src={video.jpg}
            alt=""
            ref={hoverableRef}
            onClick={(e) => {
              toggleOpenVideo();
              e.stopPropagation();
            }}
          />
        ) : (
          <video ref={hoverableRef} className={classes.fit}>
            <source
              type="video/mp4"
              src={video.mp4!}
              onClick={(e) => {
                toggleOpenVideo();
                e.stopPropagation();
              }}
            />
          </video>
        )}

        {hover ? (
          <div className={classes.play}>
            <PlayArrowIcon />
          </div>
        ) : (
          <span
            className={clsx(classes.duration, {
              [classes.hidden]: isHideDuration,
            })}
          >
            {durationToString(video.duration)}
          </span>
        )}
      </div>

      {openVideo && <VideoModal url={video.mp4} onClose={toggleOpenVideo} />}
    </>
  );
};
