import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface RecordingCheckboxAudioProps {
  isAudioEnabled: boolean;
  setAudioEnabled?: (nextValue?: any) => void;
  isHidden: boolean;
}

const RecordingCheckboxAudio: React.FC<RecordingCheckboxAudioProps> = ({
  isAudioEnabled,
  setAudioEnabled,
  isHidden,
}) => {
  const { t } = useTranslation();

  if (isHidden) {
    return null;
  }

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={isAudioEnabled}
          onChange={setAudioEnabled}
          color="primary"
        />
      }
      label={t('components:video.uploadDialog.recording.checkboxAudio')}
    />
  );
};

export default RecordingCheckboxAudio;
