import { Conversation, Paginator } from '@twilio/conversations';
import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useConversationsClient } from './ConversationsClientProvider';
import logger from './logger';

export interface ConversationsContext {
  initialized: boolean;
  conversations: Conversation[];
}

export const ConversationsCtx = createContext<ConversationsContext>({
  initialized: false,
  conversations: [],
});

export interface ConversationsProviderProps {
  children?: ReactNode;
}

export const ConversationsProvider: FC<ConversationsProviderProps> = ({
  children,
}) => {
  const client = useConversationsClient();
  const [initialized, setInitialized] = useState(false);
  const [conversations, setConversations] = useState<Conversation[]>([]);

  const getPaginatorItems = useCallback(
    async (paginator: Paginator<Conversation>): Promise<Conversation[]> => {
      if (paginator.hasNextPage) {
        const nextPaginator = await paginator.nextPage();
        return [
          ...paginator.items,
          ...(await getPaginatorItems(nextPaginator)),
        ];
      }

      return [...paginator.items];
    },
    [],
  );

  useEffect(() => {
    if (initialized) {
      client?.on('conversationAdded', (c) => {
        logger.debug('conversation added', c);
        setConversations((list) => [c, ...list]);
      });
    }
  }, [client, initialized]);

  useEffect(() => {
    const onClientInitialized = () => {
      logger.info('conversations client is initialized');

      client?.on('conversationRemoved', (c) => {
        logger.debug('conversation removed', c);
        setConversations((list) => list.filter((i) => i.sid !== c.sid));
      });

      client?.on('conversationJoined', (c) => {
        logger.debug('conversation joined', c);
      });

      client?.on('conversationLeft', (c) => {
        logger.debug('conversation left', c);
      });

      client
        ?.getSubscribedConversations()
        .then((paginator) => {
          logger.debug('fetched subscribed conversations');
          const paginatorItems = getPaginatorItems(paginator);
          paginatorItems.then((items) => {
            if (items) {
              logger.debug(`count subscribed conversations ${items.length}`);
              setConversations([...items]);
            }
          });
          setInitialized(true);
        })
        .catch(() => logger.debug('fetched subscribed conversations failed'));

      client?.on('connectionStateChanged', (state) => {
        if (state === 'disconnected') {
          setConversations([]);
          setInitialized(false);
        }
      });
    };

    client?.on('stateChanged', (state) => {
      logger.debug('state changed', state);

      if (state === 'initialized') {
        onClientInitialized();
      }
      if (state === 'failed') {
        logger.error('conversations client initialization failed');
      }
    });
  }, [client, setInitialized, setConversations, getPaginatorItems]);

  return (
    <ConversationsCtx.Provider value={{ initialized, conversations }}>
      {children}
    </ConversationsCtx.Provider>
  );
};

export const useConversations = () => useContext(ConversationsCtx);
