import { useEffect, useState } from 'react';
import {
  RemoteAudioTrack,
  RemoteParticipant,
  RemoteVideoTrack,
} from 'twilio-video';

import usePublications from './usePublications';

/** Получаем опубликованные треки втрого участника */
export const useParticipantTracks = (
  participant: RemoteParticipant | null,
): {
  remoteAudioTrack: RemoteAudioTrack | null;
  remoteVideoTrack: RemoteVideoTrack | null;
  remoteScreenTrack: RemoteVideoTrack | null;
} => {
  const publications = usePublications(participant);

  const [remoteAudioTrack, setRemoteAudioTrack] =
    useState<RemoteAudioTrack | null>(null);
  const [remoteVideoTrack, setRemoteVideoTrack] =
    useState<RemoteVideoTrack | null>(null);
  const [remoteScreenTrack, setRemoteScreenTrack] =
    useState<RemoteVideoTrack | null>(null);

  const setTrack = (track: RemoteVideoTrack | RemoteAudioTrack) => {
    if (track.kind === 'video' && track.name.includes('screen')) {
      setRemoteScreenTrack(track);
    }
    if (track.kind === 'video' && !track.name.includes('screen')) {
      setRemoteVideoTrack(track);
    }

    if (track.kind === 'audio') {
      setRemoteAudioTrack(track);
    }
  };

  const removeTrack = (track: RemoteVideoTrack | RemoteAudioTrack) => {
    if (track.kind === 'video' && track.name.includes('screen')) {
      setRemoteScreenTrack(null);
    }
    if (track.kind === 'video' && !track.name.includes('screen')) {
      setRemoteVideoTrack(null);
    }
    if (track.kind === 'audio') {
      setRemoteAudioTrack(null);
    }
  };

  useEffect(() => {
    if (publications) {
      publications.forEach((publication) => {
        if (publication.track) {
          setTrack(publication.track as RemoteAudioTrack | RemoteVideoTrack);
        }

        publication.on('subscribed', setTrack);
        publication.on('unsubscribed', removeTrack);
        return () => {
          publication.removeListener('subscribed', setTrack);
          publication.removeListener('unsubscribed', removeTrack);
        };
      });
    }
  }, [publications]);

  return { remoteAudioTrack, remoteVideoTrack, remoteScreenTrack };
};
