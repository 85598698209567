import { useReducer } from 'react';

import { VideoDetailsFragment } from '../videoDetails.generated';

export type StateUploadDialog =
  | { state: 'selecting'; error?: string | null }
  | { state: 'uploading'; file: File; error?: string | null }
  | { state: 'saving'; s3Key: string; name: string; error?: string | null }
  | { state: 'encoding'; videoId: string; error?: string | null }
  | { state: 'recording' }
  | { state: 'complete'; video: VideoDetailsFragment | null };

type Action =
  | { type: 'init' }
  | { type: 'selected'; file: File }
  | { type: 'uploaded'; s3key: string; name: string }
  | { type: 'saved'; videoId: string }
  | { type: 'record' }
  | { type: 'recorded' }
  | { type: 'encoded'; video: VideoDetailsFragment };

const reducer = (
  state: StateUploadDialog,
  action: Action,
): StateUploadDialog => {
  switch (action.type) {
    case 'init': {
      return { state: 'selecting' };
    }
    case 'selected': {
      return { state: 'uploading', file: action.file };
    }
    case 'uploaded': {
      return { state: 'saving', s3Key: action.s3key, name: action.name };
    }
    case 'saved': {
      return { state: 'encoding', videoId: action.videoId };
    }
    case 'encoded': {
      return { state: 'complete', video: action.video };
    }
    case 'record': {
      return { state: 'recording' };
    }
    case 'recorded': {
      return { state: 'complete', video: null };
    }
    default:
      return state;
  }
};

export default (initialState: StateUploadDialog) =>
  useReducer(reducer, initialState);
