import React from 'react';

interface Props {
  className?: string;
}

const IconMicOn: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0001 0C17.9682 0 20.3743 2.40611 20.3743 5.3742L20.3743 13.2508C20.3743 16.2189 17.9682 18.625 15.0001 18.625C12.032 18.625 9.6259 16.2189 9.6259 13.2508L9.6259 5.37419C9.6259 2.40611 12.032 0 15.0001 0ZM13.4119 23.5145C11.2295 23.1735 9.19452 22.1364 7.61384 20.5323C6.02149 18.9163 5.24485 16.8324 5.00974 14.599C4.91912 13.7382 5.6318 13.0345 6.49736 13.0345C7.36293 13.0345 8.04643 13.7398 8.16152 14.5977C8.34737 15.983 8.82765 17.2655 9.83025 18.2829C11.2019 19.6749 13.0622 20.4569 15.0019 20.4569C16.9416 20.4569 18.8019 19.6749 20.1735 18.2829C21.1761 17.2655 21.6564 15.983 21.8423 14.5977C21.9574 13.7398 22.6409 13.0345 23.5064 13.0345C24.372 13.0345 25.0847 13.7382 24.994 14.599C24.7589 16.8324 23.9823 18.9163 22.3899 20.5323C20.8094 22.1363 18.7747 23.1733 16.5925 23.5144V27H18.4994C19.3278 27 19.9994 27.6716 19.9994 28.5C19.9994 29.3284 19.3278 30 18.4994 30H11.4994C10.671 30 9.99942 29.3284 9.99942 28.5C9.99942 27.6716 10.671 27 11.4994 27H13.4119V23.5145Z"
        fill="white"
      />
    </svg>
  );
};

export default IconMicOn;
