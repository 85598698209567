import { Auth } from '@aws-amplify/auth';
import { SHOPIFY_WELCOME_QS } from '@videoforce/commons';

import { Env } from './env';

function configureAmplify() {
  const domainPrefix = Env.REACT_APP_DOMAIN.split('.')[0];
  // Shopify install or reinstall
  const isShopifyInstall = document.location.search?.includes(
    SHOPIFY_WELCOME_QS,
  );
  // Amplify won't authenticate unless redirectSignIn matches actual url we were redirected to
  const shopifyWelcome = isShopifyInstall ? `?${SHOPIFY_WELCOME_QS}` : '';
  const shopifySignout = isShopifyInstall
    ? `/integrations/shopify/auth/signin?${SHOPIFY_WELCOME_QS}`
    : '';
  Auth.configure({
    region: Env.region,
    identityPoolId: Env.identityPoolId,
    identityPoolRegion: Env.region,
    userPoolId: Env.userPoolId,
    userPoolWebClientId: Env.webClientId,
    oauth: {
      domain: `${domainPrefix}.auth.${Env.region}.amazoncognito.com`,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin', 'profile'],
      redirectSignIn: `https://app.${Env.REACT_APP_DOMAIN}/widgets${shopifyWelcome}`,
      redirectSignOut: `https://app.${Env.REACT_APP_DOMAIN}${shopifySignout}`,
      responseType: 'code',
    },
  });
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';
}

export default configureAmplify;
