import { useAuth } from 'auth';
import React, { FC, useMemo } from 'react';

import AlertPromoActivated from './promo-activated';
import useCloseAlert from './useCloseAlert';

const Alert: FC = () => {
  const auth = useAuth();

  const alert = useMemo(() => {
    return auth?.profile.alerts.find((a) => !a.closed);
  }, [auth]);

  const closeAlert = useCloseAlert(alert?.id);

  if (!alert) {
    return null;
  }

  switch (alert.__typename) {
    case 'AlertPromoActivated':
      return <AlertPromoActivated alert={alert} onClose={closeAlert} />;
    default:
      return null;
  }
};

export default Alert;
