import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: 520,
      maxWidth: 520,
      minHeight: 360,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: 10,
      '&:first-child': {
        paddingTop: 10,
        paddingBottom: 0,
      },
    },
    leftButton: {
      marginRight: 'auto',
    },
  }),
);

interface DialogLayoutProps {
  onCancel?: () => void;
  onSubmit?: () => void | Promise<void>;
  onAddNewVideo?: () => void;
  onRecordingVideo?: () => void;
  className?: string;
  children?: ReactNode;
}

const DialogLayout: FC<DialogLayoutProps> = ({
  onCancel,
  onSubmit,
  onAddNewVideo,
  onRecordingVideo,
  className,
  children,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <>
      <DialogContent className={clsx(classes.dialogContent, className)}>
        {children}
      </DialogContent>

      <DialogActions>
        {!!onAddNewVideo && (
          <Button onClick={onAddNewVideo} variant="contained" color="primary">
            {t('components:video.uploadDialog.addedNewVideo')}
          </Button>
        )}

        {!!onRecordingVideo && (
          <Button
            className={classes.leftButton}
            onClick={onRecordingVideo}
            variant="contained"
            color="primary"
          >
            {t('components:video.uploadDialog.recording.recordingVideo')}
          </Button>
        )}

        {!!onCancel && (
          <Button onClick={onCancel} variant="contained">
            {t('commons:cancel')}
          </Button>
        )}

        {!!onSubmit && (
          <Button onClick={onSubmit} variant="contained" color="primary">
            {t('components:video.uploadDialog.updateVideo')}
          </Button>
        )}
      </DialogActions>
    </>
  );
};

export default DialogLayout;
