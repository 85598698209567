import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
  createStyles({
    buttonWrapper: {
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      maxWidth: '66px',
      alignItems: 'center',
      textAlign: 'center',
      marginLeft: '35px',
      '&:nth-child(1)': {
        marginLeft: '0',
      },
      '& .buttonHint': {
        color: '#fff',
        fontSize: '11px',
        margin: '0',
        visibility: 'hidden',
        marginTop: '8px',
      },
      [theme.breakpoints.between(320, 370)]: {
        maxWidth: '55px',
      },
    },
    buttonWrapperIos: {
      marginLeft: '16px',
      '&:nth-child(3)': {
        marginLeft: '0',
      },
    },
    buttonWrapperAndroid: {
      marginLeft: '22px',
      '&:nth-child(2)': {
        marginLeft: '0',
      },
    },
  }),
);

interface Props {
  className?: string;
  enabled?: boolean;
  hint?: string;
  children?: ReactNode;
}

const CallControlButton: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div
      className={clsx(classes.buttonWrapper, props.className, {
        [classes.buttonWrapperIos]: isIOS,
        [classes.buttonWrapperAndroid]: isAndroid,
      })}
    >
      {props.children}

      {props.enabled ? (
        <div className="buttonHint">
          {t(`components:modalCall.controlButton.${props.hint}Off`)}
        </div>
      ) : (
        <div className="buttonHint">
          {t(`components:modalCall.controlButton.${props.hint}`)}
        </div>
      )}
    </div>
  );
};

export default CallControlButton;
