import { Message } from '@videoforce/client';
import React from 'react';

import { SimpleMessage } from './SimpleMessage';
import SystemMessage from './SystemMessage';

interface Props {
  myId?: string;
  message: Message;
}

const MessagesItem: React.FC<Props> = ({ myId, message }) => {
  return message.author === 'system' ? (
    <SystemMessage message={message} myId={myId} />
  ) : (
    <SimpleMessage message={message} myId={myId} />
  );
};

export default MessagesItem;
