import { BusinessHours } from '../types';

const padTo2Digits = (number: number) => {
  return number.toString().padStart(2, '0');
};

export const calculateOperatorAvailabilityByBusinessHours = (
  businessHours: BusinessHours | null,
  timezone: string,
) => {
  if (!businessHours?.enabled) {
    return true;
  } else if (businessHours?.simple?.length !== 7) {
    return false;
  }

  const operatorDateNow = new Date(
    new Date().toLocaleString('en-US', {
      timeZone: timezone,
    }),
  );

  const operatorDateNowDay = operatorDateNow.getDay();
  const businessHoursCurrentDay = businessHours.simple[operatorDateNowDay];
  if (!businessHoursCurrentDay) {
    return false;
  }

  const operatorWorkStartTime = businessHoursCurrentDay[0];
  const operatorWorkEndTime = businessHoursCurrentDay[1];
  const operatorHoursAndMinutes =
    padTo2Digits(operatorDateNow.getHours()) +
    ':' +
    padTo2Digits(operatorDateNow.getMinutes());

  return (
    operatorHoursAndMinutes >= operatorWorkStartTime &&
    operatorHoursAndMinutes <= operatorWorkEndTime
  );
};
