/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import { VideoDetailsFragmentDoc } from '../videoDetails.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateVideoMutationVariables = Types.Exact<{
  s3key: Types.Scalars['String'];
  name?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type CreateVideoMutation = { __typename?: 'Mutation', createVideo: { __typename?: 'CreateVideoError', id: string, message: string, reason: Types.CreateVideoErrorReason } | { __typename?: 'Error', id: string, message: string } | { __typename?: 'ForbiddenError', id: string, message: string } | { __typename?: 'NotFoundError', id: string, message: string } | { __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, createdAt: string, duration?: number | null, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null } };


export const CreateVideoDocument = gql`
    mutation createVideo($s3key: String!, $name: String) {
  createVideo(s3key: $s3key, settings: {name: $name}) {
    ... on IError {
      id
      message
    }
    ... on CreateVideoError {
      reason
    }
    ... on Video {
      ...VideoDetails
    }
  }
}
    ${VideoDetailsFragmentDoc}`;
export type CreateVideoMutationFn = Apollo.MutationFunction<CreateVideoMutation, CreateVideoMutationVariables>;

/**
 * __useCreateVideoMutation__
 *
 * To run a mutation, you first call `useCreateVideoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVideoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVideoMutation, { data, loading, error }] = useCreateVideoMutation({
 *   variables: {
 *      s3key: // value for 's3key'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateVideoMutation(baseOptions?: Apollo.MutationHookOptions<CreateVideoMutation, CreateVideoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateVideoMutation, CreateVideoMutationVariables>(CreateVideoDocument, options);
      }
export type CreateVideoMutationHookResult = ReturnType<typeof useCreateVideoMutation>;
export type CreateVideoMutationResult = Apollo.MutationResult<CreateVideoMutation>;
export type CreateVideoMutationOptions = Apollo.BaseMutationOptions<CreateVideoMutation, CreateVideoMutationVariables>;