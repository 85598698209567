import { IconButton } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    pageClose: {
      position: 'absolute',
      top: '60px',
      right: '90px',
      backgroundColor: palette.grey[100],
      color: palette.grey[600],
      '&:hover': {
        backgroundColor: palette.grey[200],
      },
    },
  }),
);

interface CloseButtonProps {
  className?: string;
  onClick?: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ className, onClick }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <IconButton
      className={clsx(classes.pageClose, className)}
      onClick={() => {
        if (onClick) {
          onClick();
        } else {
          navigate(-1);
        }
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

export default CloseButton;
