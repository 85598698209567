export type Integrations = 'shopify';

/**
 * Presence of this query string indicates that the user comes from shopify and it's his first visit
 */
export const SHOPIFY_WELCOME_QS = 'shopify_welcome=true';

/**
 * Presence of this quuery string indicates that the user was redirected to app after successfully purchasing shopify subscription
 */
export const SHOPIFY_SUBSCRIPTION_ADDED_QS = 'shopify_subscription_added=true';
