import { createStyles, makeStyles } from '@material-ui/core/styles';
import BlockTitles from 'components/BlockTitles';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '20px',
    },
    name: {
      marginTop: '18px',
      marginBottom: '18px',
    },
    size: {
      fontWeight: 600,
      '& span': {
        marginRight: '3px',
      },
    },
  }),
);

interface FileInfo {
  file: File;
}

const FileInfo: React.FC<FileInfo> = ({ file }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const fileSize = file.size / 1024 / 1024;

  return (
    <div className={classes.root}>
      <BlockTitles>{t('components:video.uploadDialog.fileInfo')}</BlockTitles>
      <span className={classes.name}>{file.name}</span>
      <span className={classes.size}>
        <span>{fileSize.toFixed(2)}</span>
        {t('components:video.uploadDialog.mByte')}
      </span>
    </div>
  );
};

export default FileInfo;
