import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import {
  useAudioLoop,
  useOperatorCallActions,
  useOperatorCallState,
} from '@videoforce/client';
import ButtonProgress from 'components/ButtonProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    modal: {
      padding: spacing(3),
      [breakpoints.up('sm')]: {
        minWidth: 360,
      },
    },
    header: {
      display: 'flex',
      paddingTop: spacing(1),
    },
    row: {
      display: 'flex',
    },
    controls: {
      marginTop: spacing(6),
      display: 'flex',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    acceptLoading: {
      flex: 1,
      display: 'flex',
      marginRight: spacing(1),
      [breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: spacing(1),
      },
    },
    buttonWrapper: {
      flex: 1,
    },
    accept: {
      flex: 1,
      '&.MuiButton-root': {
        color: palette.common.white,
        background: palette.success.main,
        '&:hover': {
          backgroundColor: palette.success.dark,
        },
      },
    },
    decline: {
      marginLeft: spacing(1),
      flex: 1,
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: spacing(1),
      },
      '&.MuiButton-root': {
        color: palette.common.white,
        backgroundColor: palette.error.main,
        '&:hover': {
          backgroundColor: palette.error.dark,
        },
      },
    },
  }),
);

export const IncomingCallDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { incomingCall } = useOperatorCallState();
  const { acceptCall, declineCall } = useOperatorCallActions();

  useAudioLoop(process.env.PUBLIC_URL + '/phone_ringing.mp3', !!incomingCall);

  if (!incomingCall) {
    return null;
  }

  const { widgetName, accepted } = incomingCall;

  return (
    <Dialog open>
      <DialogContent className={classes.modal}>
        <div className={classes.header}>
          <div>
            <Typography variant="h3" gutterBottom>
              {t('components:incomingCallDialog.title')}
            </Typography>
            <Typography variant="h5">
              {t('components:incomingCallDialog.description', { widgetName })}
            </Typography>
          </div>
        </div>

        <div className={classes.controls}>
          <div className={classes.buttonWrapper}>
            <ButtonProgress
              className={classes.acceptLoading}
              loading={accepted}
            >
              <Button
                variant="contained"
                autoFocus
                disabled={accepted}
                className={classes.accept}
                onClick={() => acceptCall?.(incomingCall.callId, widgetName)}
              >
                <CallIcon />
              </Button>
            </ButtonProgress>
          </div>

          <div className={classes.buttonWrapper}>
            <Button
              fullWidth
              variant="contained"
              disabled={accepted}
              className={classes.decline}
              onClick={() => declineCall?.(incomingCall.callId)}
            >
              <CallEndIcon />
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
