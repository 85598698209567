/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ShopifyConfirmationUrlQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ShopifyConfirmationUrlQuery = { __typename?: 'Query', shopifyConfirmationURL: { __typename?: 'Error', id: string, message: string } | { __typename?: 'ForbiddenError', id: string, message: string, reason: Types.ForbiddenReason } | { __typename?: 'ShopifyConfirmationURL', url: string } };


export const ShopifyConfirmationUrlDocument = gql`
    query shopifyConfirmationURL {
  shopifyConfirmationURL {
    ... on IError {
      id
      message
    }
    ... on ForbiddenError {
      reason
    }
    ... on ShopifyConfirmationURL {
      url
    }
  }
}
    `;

/**
 * __useShopifyConfirmationUrlQuery__
 *
 * To run a query within a React component, call `useShopifyConfirmationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useShopifyConfirmationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShopifyConfirmationUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useShopifyConfirmationUrlQuery(baseOptions?: Apollo.QueryHookOptions<ShopifyConfirmationUrlQuery, ShopifyConfirmationUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShopifyConfirmationUrlQuery, ShopifyConfirmationUrlQueryVariables>(ShopifyConfirmationUrlDocument, options);
      }
export function useShopifyConfirmationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShopifyConfirmationUrlQuery, ShopifyConfirmationUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShopifyConfirmationUrlQuery, ShopifyConfirmationUrlQueryVariables>(ShopifyConfirmationUrlDocument, options);
        }
export type ShopifyConfirmationUrlQueryHookResult = ReturnType<typeof useShopifyConfirmationUrlQuery>;
export type ShopifyConfirmationUrlLazyQueryHookResult = ReturnType<typeof useShopifyConfirmationUrlLazyQuery>;
export type ShopifyConfirmationUrlQueryResult = Apollo.QueryResult<ShopifyConfirmationUrlQuery, ShopifyConfirmationUrlQueryVariables>;