import { Scenario } from '__generated__/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { ScenarioStep } from 'components/scenario/types';
import useSnackbarError from 'components/useSnackbarError';
import useSnackbarSuccess from 'components/useSnackbarSuccess';
import { Resolver, useForm } from 'react-hook-form';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import { convertDefaultValues } from './convertsDefaultValues';
import { convertSteps } from './convertStep';
import { useCreateScenarioMutation } from './createScenario.generated';
import { schema } from './shemas';
import { useUpdateScenarioMutation } from './updateScenario.generated';

export interface ScenarioFormData {
  id: string;
  name: string;
  steps: ScenarioStep[];
}

export const useScenarioForm = (scenario: Scenario | undefined) => {
  const form = useForm<ScenarioFormData>({
    defaultValues: convertDefaultValues(scenario),
    resolver: yupResolver(schema) as Resolver<ScenarioFormData>,
  });

  const displaySuccess = useSnackbarSuccess();
  const displayError = useSnackbarError();

  const [create, { error: createError }] = useCreateScenarioMutation({
    refetchQueries: ['listScenario'],
  });
  const [update, { error: updateError }] = useUpdateScenarioMutation({
    refetchQueries: ['listScenario'],
  });

  const [, createScenario] = useAsyncFn(
    async (data: ScenarioFormData, onDone: () => void) => {
      const result = await create({
        variables: {
          settings: {
            name: data.name,
            steps: convertSteps(data.steps),
          },
        },
      });

      if (result.data?.createScenario.__typename !== 'Scenario') {
        displayError(result.data?.createScenario);
      } else {
        onDone();
        displaySuccess('components:scenario.mutations.success.created');
      }
    },
    [update, displayError],
  );

  const [, updateScenario] = useAsyncFn(
    async (data: ScenarioFormData, onDone: () => void) => {
      const result = await update({
        variables: {
          id: data.id,
          settings: {
            name: data.name,
            steps: convertSteps(data.steps),
          },
        },
      });

      if (result.data?.updateScenario.__typename !== 'Scenario') {
        displayError(result.data?.updateScenario);
      } else {
        onDone();
        displaySuccess('components:scenario.mutations.success.updated');
      }
    },
    [update, displayError],
  );

  const formSubmit = (
    executor: (data: ScenarioFormData, onDone: () => void) => Promise<void>,
    onDone: () => void,
  ) => {
    const sumbit = form.handleSubmit((data) => {
      executor(data, onDone);
    });

    sumbit();
  };

  return {
    form,
    errors: {
      updateError,
      createError,
    },
    onCreateScenario: (onDone: () => void) =>
      formSubmit(createScenario, onDone),
    onUpdateScenario: (onDone: () => void) =>
      formSubmit(updateScenario, onDone),
  };
};
