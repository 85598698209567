import commons from './commons.json';
import components from './components.json';
import errors from './errors.json';
import nickname from './nickname.json';
import routes from './routes.json';
import yup from './yup.json';

export default {
  commons,
  components,
  nickname,
  errors,
  routes,
  yup,
};
