import { Tooltip, TooltipProps } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import React, { Children, cloneElement, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  text?: string;
  onCopy?: (text: string) => void;
  placement?: TooltipProps['placement'];
  children: React.ReactElement<ButtonProps>;
  className?: string;
}

const CopyButton: React.FC<Props> = (props) => {
  const { onCopy, text, placement, children, className } = props;
  const { t } = useTranslation();
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const onClick = useCallback(() => {
    if (text) {
      navigator.clipboard.writeText(text);
      setTooltipOpen(true);
      setTimeout(() => setTooltipOpen(false), 700);
      onCopy?.(text);
    }
  }, [text, onCopy, setTooltipOpen]);

  return (
    <Tooltip
      className={className}
      placement={placement}
      open={tooltipOpen}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      title={t('commons:copied') ?? ''}
    >
      {cloneElement(Children.only(children), { onClick })}
    </Tooltip>
  );
};

export default CopyButton;
