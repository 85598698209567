import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    navText: {
      fontSize: '12px',
      fontWeight: 400,
      textAlign: 'center',
      visibility: 'hidden',
      opacity: '0',
      transition: 'visibility 0.3s linear,opacity 0.3s linear',
    },
    navItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer',
      color: palette.grey['400'],
      padding: '10px 10px 5px',
      marginBottom: '20px',
      transition: 'all 250ms ease-out',
      '&:hover': {
        color: palette.grey['800'],
        '& span': {
          fontWeight: '600',
        },
      },
      '&.active': {
        color: palette.primary.main,
        '& span': {
          color: palette.grey['800'],
          fontWeight: '600',
        },
      },
      '& svg': {
        width: '26px',
        marginBottom: '12px',
      },
    },
  }),
);

interface Props {
  path?: string;
  label?: string;
  disabled?: boolean;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  children?: ReactNode;
}

const SidebarIcon: React.FC<Props> = (props) => {
  const { label, path, className, onClick, children } = props;
  const classes = useStyles();
  const { pathname } = useLocation();

  return (
    <div
      className={clsx(classes.navItem, className, {
        active: !!path && pathname.startsWith(path),
      })}
      onClick={onClick}
    >
      {children}
      {!!label && <span className={classes.navText}>{label}</span>}
    </div>
  );
};

export default SidebarIcon;
