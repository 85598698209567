import { createStyles, makeStyles } from '@material-ui/core/styles';
import PopoverInfo from 'components/PopoverInfo';
import React from 'react';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    text: {
      fontSize: '11px',
      margin: '6px 0 16px',
      color: palette.grey[400],
      '&:last-child': {
        marginBottom: 0,
      },
    },
  }),
);

interface StepButtonInfoProps {
  text: string;
  className?: string;
}

export const StepButtonInfo: React.FC<StepButtonInfoProps> = ({
  text,
  className,
}) => {
  const classes = useStyles();

  return (
    <PopoverInfo className={className}>
      <p className={classes.text}>{text}</p>
    </PopoverInfo>
  );
};
