import { useEffect, useState } from 'react';

export const useRequestLimit = (callConfirmationTimeout?: number) => {
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (callConfirmationTimeout) {
      setTimeLeft(callConfirmationTimeout);
    }
  }, [callConfirmationTimeout]);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft((prev) => prev - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft - minutes * 60;
  const timeInFormat = `${minutes}:${seconds === 0 ? '00' : seconds}`;

  return timeInFormat;
};
