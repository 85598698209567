import { t } from 'i18next';

import { StateScenarioDialog } from '../useScenario';

export const getHeaderTitle = (state: StateScenarioDialog) => {
  switch (state.state) {
    case 'create_scenario':
      return t(`components:scenario.dialog.header.title.createScenario`);
    case 'update_scenario':
      return t(`components:scenario.dialog.header.title.updateScenario`);
    case 'selecting_scenario':
      return t(`components:scenario.dialog.header.title.selectingScenario`);
    case 'create_step':
      return t(`components:scenario.dialog.header.title.editStep`);
    case 'update_step':
      return t(`components:scenario.dialog.header.title.editStep`);
  }
};
