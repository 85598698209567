import { Tour } from '__generated__/types';
import { useAuth } from 'auth';
import React from 'react';

import { useIntroTourState } from './IntroTourProvider';
import JoyrideTooltip from './JoyrideTooltip';
import { useSteps } from './tour';

const Joyride = React.lazy(() => import('react-joyride'));

export const IntroTour: React.FC = () => {
  const state = useIntroTourState();
  const auth = useAuth();
  const steps = useSteps();

  if (
    !auth?.claims.owner ||
    auth.profile.completedTours.includes(Tour.OwnerIntro)
  ) {
    return null;
  }

  return (
    <React.Suspense fallback={null}>
      <Joyride
        {...state}
        steps={steps}
        tooltipComponent={JoyrideTooltip}
        debug={process.env.REACT_APP_LOG_LEVEL === 'debug'}
        spotlightClicks
        disableCloseOnEsc
        disableOverlayClose
        disableScrollParentFix
        showSkipButton={false}
      />
    </React.Suspense>
  );
};
