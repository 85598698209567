/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateScenarioMutationVariables = Types.Exact<{
  settings: Types.CreateScenarioInput;
}>;


export type CreateScenarioMutation = { __typename?: 'Mutation', createScenario: { __typename: 'Error', id: string, message: string } | { __typename: 'ForbiddenError', id: string, message: string } | { __typename: 'Scenario', id: string, name: string, createdAt: string, steps: Array<{ __typename?: 'ScenarioStep', id: string, name: string, type: Types.ScenarioStepType, video?: { __typename?: 'Video', id: string } | null, buttons?: Array<{ __typename: 'ScenarioStepCssSelectorButton', name: string, selector: string } | { __typename: 'ScenarioStepFinishScenarioButton', name: string } | { __typename: 'ScenarioStepFollowStepButton', step: number, name: string } | { __typename: 'ScenarioStepFollowUrlButton', name: string, url: string } | { __typename: 'ScenarioStepOpenVideochatButton', name: string }> | null }> } };


export const CreateScenarioDocument = gql`
    mutation createScenario($settings: CreateScenarioInput!) {
  createScenario(settings: $settings) {
    __typename
    ... on IError {
      id
      message
    }
    ... on Scenario {
      id
      name
      steps {
        id
        name
        video {
          id
        }
        type
        buttons {
          __typename
          ... on ScenarioStepCssSelectorButton {
            name
            selector
          }
          ... on ScenarioStepFollowUrlButton {
            name
            url
          }
          ... on ScenarioStepFollowStepButton {
            step
            name
          }
          ... on ScenarioStepOpenVideochatButton {
            name
          }
          ... on ScenarioStepFinishScenarioButton {
            name
          }
        }
      }
      createdAt
    }
  }
}
    `;
export type CreateScenarioMutationFn = Apollo.MutationFunction<CreateScenarioMutation, CreateScenarioMutationVariables>;

/**
 * __useCreateScenarioMutation__
 *
 * To run a mutation, you first call `useCreateScenarioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScenarioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScenarioMutation, { data, loading, error }] = useCreateScenarioMutation({
 *   variables: {
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useCreateScenarioMutation(baseOptions?: Apollo.MutationHookOptions<CreateScenarioMutation, CreateScenarioMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateScenarioMutation, CreateScenarioMutationVariables>(CreateScenarioDocument, options);
      }
export type CreateScenarioMutationHookResult = ReturnType<typeof useCreateScenarioMutation>;
export type CreateScenarioMutationResult = Apollo.MutationResult<CreateScenarioMutation>;
export type CreateScenarioMutationOptions = Apollo.BaseMutationOptions<CreateScenarioMutation, CreateScenarioMutationVariables>;