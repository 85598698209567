// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default function configureFirebase() {
  try {
    const firebaseConfig = JSON.parse(
      atob(process.env.REACT_APP_FIREBASE_CONFIG),
    );

    // Initialize Firebase
    initializeApp(firebaseConfig);
  } catch (e) {
    console.error('failed to load firebase', e);
  }
}
