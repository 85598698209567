import React from 'react';

interface Props {
  className?: string;
}

const IconClientDisableAudio: React.FC<Props> = () => {
  return (
    <svg
      width="46"
      height="28"
      viewBox="0 0 46 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.9105 26.5659C19.4788 23.8445 17.2038 19.7275 16.9432 15.0726C15.4189 14.7174 13.72 14.5191 11.9286 14.5191C5.33805 14.5191 0 17.202 0 20.5144V25.3224C0 26.0347 0.577363 26.612 1.28958 26.612H22.5676C22.6864 26.612 22.8013 26.596 22.9105 26.5659ZM16.146 2.57642C15.0275 1.45208 13.5104 0.820435 11.9286 0.820435C10.3468 0.820435 8.82973 1.45208 7.71121 2.57642C6.59268 3.70076 5.9643 5.22569 5.9643 6.81574C5.9643 8.4058 6.59268 9.93073 7.71121 11.0551C8.82973 12.1794 10.3468 12.811 11.9286 12.811C13.5104 12.811 15.0275 12.1794 16.146 11.0551C17.2645 9.93073 17.8929 8.4058 17.8929 6.81574C17.8929 5.22569 17.2645 3.70076 16.146 2.57642Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.9998 14.3021C45.9998 21.6434 40.0485 27.5947 32.7072 27.5947C25.3659 27.5947 19.4146 21.6434 19.4146 14.3021C19.4146 6.96082 25.3659 1.00952 32.7072 1.00952C40.0485 1.00952 45.9998 6.96082 45.9998 14.3021ZM36.1223 9.46597C36.1223 7.8334 34.7989 6.50995 33.1663 6.50995C31.5338 6.50995 30.2103 7.8334 30.2103 9.46596V13.7984C30.2103 14.5375 30.4816 15.2132 30.93 15.7315L36.1223 10.5391V9.46597ZM33.1852 16.7544L32.2785 17.6611C32.5679 17.7277 32.866 17.762 33.1675 17.762C34.2345 17.762 35.2577 17.3319 36.0121 16.5663C36.5636 16.0066 36.8278 15.3012 36.93 14.5392C36.9933 14.0674 37.3693 13.6794 37.8454 13.6794C38.3215 13.6794 38.7135 14.0665 38.6636 14.54C38.5343 15.7684 38.1071 16.9146 37.2313 17.8035C36.3619 18.6858 35.2426 19.2562 34.0423 19.4438V21.2614H36.0829C36.5661 21.2614 36.9578 21.653 36.9578 22.1362C36.9578 22.6194 36.5661 23.011 36.0829 23.011H33.1731L33.1676 23.0111L33.1621 23.011H30.2518C29.7686 23.011 29.3769 22.6194 29.3769 22.1362C29.3769 21.653 29.7686 21.2614 30.2518 21.2614H32.2929V19.4438C31.8122 19.3687 31.3445 19.2322 30.9007 19.0389L27.1617 22.7779C26.8574 23.0822 26.364 23.0822 26.0597 22.7779C25.7554 22.4736 25.7554 21.9802 26.0597 21.6759L39.4793 8.25629C39.7836 7.95199 40.277 7.95199 40.5813 8.25629C40.8856 8.5606 40.8856 9.05397 40.5813 9.35828L36.1223 13.8173C36.1121 15.4349 34.8028 16.7442 33.1852 16.7544ZM30.213 16.4485C29.7358 15.9091 29.5003 15.2487 29.4051 14.5392C29.3418 14.0674 28.9658 13.6794 28.4897 13.6794C28.0136 13.6794 27.6216 14.0665 27.6715 14.54C27.7948 15.7111 28.1887 16.8075 28.9841 17.6774L30.213 16.4485Z"
        fill="white"
      />
    </svg>
  );
};

export default IconClientDisableAudio;
