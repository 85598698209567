import React from 'react';

interface Props {
  className?: string;
}

const IconFullScreenOff: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.4875 18.0125L7.3625 20.8875L3.75 24.475L5.525 26.25L9.1125 22.6375L11.9875 25.5125V18.0125H4.4875ZM18.0125 25.5125L20.8875 22.6375L24.475 26.25L26.25 24.475L22.6375 20.8875L25.5125 18.0125H18.0125V25.5125ZM25.5125 11.9875L22.6375 9.1125L26.25 5.525L24.475 3.75L20.8875 7.3625L18.0125 4.4875V11.9875H25.5125ZM11.9875 4.4875L9.1125 7.3625L5.525 3.75L3.75 5.525L7.3625 9.1125L4.4875 11.9875H11.9875V4.4875Z"
        fill="white"
      />
    </svg>
  );
};

export default IconFullScreenOff;
