import { ScenarioStepButtonType } from '__generated__/types';
import { TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { ScenarioStepCtaButton } from 'components/scenario/types';
import translateInputError from 'i18n/helpers/translateInputError';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepButtonInfo } from '../StepButtonInfo';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      marginTop: spacing(),
    },
    iconInfo: {
      marginLeft: '5px',
    },
  }),
);

interface CtaButtonSettingsProps {
  button: ScenarioStepCtaButton;
  index: number;
}

export const CtaButtonSettings: React.FC<CtaButtonSettingsProps> = ({
  button,
  index,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { formState, control } = useFormContext();

  return (
    <div className={classes.root}>
      {button.type === ScenarioStepButtonType.FollowUrl && (
        <Controller
          control={control}
          name={`buttons[${index}].url`}
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              required
              placeholder={t(
                'components:scenario.dialog.step.button.ctaButtonInput.urlPlaceholder',
              )}
              variant="outlined"
              onChange={(e) => onChange(e.target.value)}
              {...translateInputError(
                t,
                formState.errors,
                `buttons[${index}].url`,
              )}
            />
          )}
        />
      )}

      {button.type === ScenarioStepButtonType.CssSelector && (
        <Controller
          control={control}
          name={`buttons[${index}].selector`}
          rules={{
            required: true,
          }}
          render={({ field: { value, onChange } }) => (
            <TextField
              value={value}
              required
              placeholder={t(
                'components:scenario.dialog.step.button.ctaButtonInput.selectorPlaceholder',
              )}
              variant="outlined"
              onChange={(e) => onChange(e.target.value)}
              {...translateInputError(
                t,
                formState.errors,
                `buttons[${index}].selector`,
              )}
            />
          )}
        />
      )}

      {button.type !== ScenarioStepButtonType.OpenVideochat && (
        <StepButtonInfo
          className={classes.iconInfo}
          text={
            button.type === ScenarioStepButtonType.CssSelector
              ? t(`components:scenario.dialog.step.button.info.CSS_SELECTOR`)
              : t(`components:scenario.dialog.step.button.info.FOLLOW_URL`)
          }
        />
      )}
    </div>
  );
};
