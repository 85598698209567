import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import getClaimsFromSession from './getClaimsFromSession';

export default async function refreshSession(): Promise<void> {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.userSession(user);
  await new Promise((resolve, reject) => {
    user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
      if (err) {
        console.error('failed to switch account', err);
        reject(err);
        return;
      }
      Hub.dispatch('auth', {
        // Default event 'tokenRefresh' will be dispatched, but it has no data, so we dispatch custom event
        event: 'manualTokenRefresh',
        data: { claims: getClaimsFromSession(session) },
      });
      resolve(undefined);
    });
  });
}
