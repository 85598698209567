import {
  CreateScenarioInput,
  ScenarioStepButtonType,
  ScenarioStepInput,
  ScenarioStepType,
  Video,
  VideoStatus,
} from '__generated__/types';
import { ScenarioStep, ScenarioStepButton } from 'components/scenario/types';
import * as yup from 'yup';

const videoSchema: yup.SchemaOf<Omit<Video, '__typename'>> = yup
  .object()
  .shape({
    createdAt: yup.string().notRequired(),
    duration: yup.number(),
    id: yup.string().required(),
    jpg: yup.string(),
    mp4: yup.string(),
    name: yup.string().nullable(true),
    resolution: yup.object().shape({
      height: yup.number().optional(),
      width: yup.number().optional(),
    }),
    status: yup.mixed().oneOf(Object.values(VideoStatus)),
  })
  .required();

export const buttonSchema: yup.SchemaOf<Omit<ScenarioStepButton, 'id'>> =
  yup.object({
    type: yup.mixed().oneOf(Object.values(ScenarioStepButtonType)).required(),
    name: yup.string().required().min(1),
    selector: yup.string().when('type', {
      is: (type: ScenarioStepButtonType) =>
        type === ScenarioStepButtonType.CssSelector,
      then: (schema) => schema.required(),
    }),
    step: yup.number().when('type', {
      is: (type: ScenarioStepButtonType) =>
        type === ScenarioStepButtonType.FollowStep,
      then: (schema) => schema.required(),
    }),
    url: yup.string().when('type', {
      is: (type: ScenarioStepButtonType) =>
        type === ScenarioStepButtonType.FollowUrl,
      then: (schema) => schema.required(),
    }),
  });

export const scenarioStepSchema: yup.SchemaOf<ScenarioStepInput> = yup.object({
  name: yup.string().required(),
  order: yup.number().required(),
  type: yup
    .mixed<ScenarioStepType>()
    .oneOf(Object.values(ScenarioStepType))
    .required(),
  buttons: yup.array().of(buttonSchema),
  videoId: yup.string().required(),
});

export const customScenarioStepSchema: yup.SchemaOf<ScenarioStep> = yup.object({
  name: yup.string().required(),
  order: yup.number().required(),
  type: yup
    .mixed<ScenarioStepType>()
    .oneOf(Object.values(ScenarioStepType))
    .required(),
  buttons: yup.array().of(buttonSchema).min(1).optional(),
  videoId: yup.string().required(),
  video: videoSchema.required(),
});

export const schema: yup.SchemaOf<CreateScenarioInput> = yup.object({
  name: yup.string().required(),
  steps: yup.array().of(scenarioStepSchema).min(1),
});
