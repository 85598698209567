import { Video } from '__generated__/types';
import { MutationResult } from '@apollo/client';
import useSnackbarError from 'components/useSnackbarError';
import useSnackbarSuccess from 'components/useSnackbarSuccess';
import React from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';

import {
  DeleteVideoMutation,
  useDeleteVideoMutation,
} from './deleteVideo.generated';

export default function useDeleteVideo(
  id: string,
  setSelectedId: React.Dispatch<React.SetStateAction<Video | undefined>>,
): [() => Promise<void>, MutationResult<DeleteVideoMutation>] {
  const displaySuccess = useSnackbarSuccess();
  const displayError = useSnackbarError();

  const [mutate, data] = useDeleteVideoMutation({
    variables: { id },
    refetchQueries: ['listVideos'],
  });

  const [, deleteVideo] = useAsyncFn(async () => {
    const result = await mutate();
    if (result.data?.deleteVideo === null) {
      setSelectedId(undefined);
      displaySuccess('routes:videoLibrary.deleting.displaySuccess');
    } else {
      displayError(result.data?.deleteVideo);
    }
  }, [mutate]);

  return [deleteVideo, data];
}
