import { ScenarioFormData } from 'components/scenario/mutations';
import { ScenarioDialogLayout } from 'components/scenario/ScenarioDialogLayout';
import { ScenarioStep } from 'components/scenario/types';
import React, { useEffect } from 'react';
import { FormProvider, useFieldArray, useFormContext } from 'react-hook-form';

import { ScenarioStepForm } from '../form';
import { useStepForm } from '../useStepForm';

interface UpdatingStepProps {
  step: ScenarioStep;
  onDone: () => void;
  setStepChanged: (nextValue?: any) => void;
}

export const UpdatingStep: React.FC<UpdatingStepProps> = ({
  onDone,
  step,
  setStepChanged,
}) => {
  const { control } = useFormContext<ScenarioFormData>();

  const { fields, update } = useFieldArray({
    control,
    name: 'steps',
  });

  const updateStep = (step: ScenarioStep) => update(step.order - 1, step);

  const { form, onSubmit } = useStepForm({
    defaultValue: step,
    stepsAction: updateStep,
    dialogAction: onDone,
  });

  const { formState } = form;

  useEffect(() => {
    if (formState.isDirty) {
      setStepChanged(true);
    } else {
      setStepChanged(false);
    }
  }, [formState, setStepChanged]);

  return (
    <ScenarioDialogLayout
      onSubmit={onSubmit}
      isSubmitting={formState.isSubmitting}
      isDirty={formState.isDirty}
    >
      <FormProvider {...form}>
        <ScenarioStepForm steps={fields} />
      </FormProvider>
    </ScenarioDialogLayout>
  );
};
