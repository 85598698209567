import { Conversation } from '@twilio/conversations';
import { useEffect, useRef, useState } from 'react';

import { useConversations } from '../ConversationsProvider';

export function useEnterConversationOperator(): {
  conversation: Conversation | null;
  enterConversation: (conversationId: string) => void;
} {
  const { conversations, initialized } = useConversations();
  const [conversation, setConversation] = useState<Conversation | null>(null);
  const conversationsRef = useRef(conversations);
  conversationsRef.current = conversations;

  useEffect(() => {
    if (!initialized) {
      setConversation(null);
    }
  }, [initialized]);

  const enterConversation = (conversationId: string) => {
    const selectedConversation = conversationsRef.current.find((c) => {
      return c.sid === conversationId;
    });

    if (selectedConversation) {
      setConversation(selectedConversation);
    }
  };

  return { conversation, enterConversation };
}
