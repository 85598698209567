export const getMinutes = (minutes: number): number => {
  return 1000 * 60 * minutes;
};

export const getHours = (hours: number): number => {
  return 1000 * 60 * 60 * hours;
};

export const getDays = (days: number): number => {
  return 1000 * 60 * 60 * 24 * days;
};

export const getMonths = (months: number): number => {
  return 1000 * 60 * 60 * 24 * 31 * months;
};
