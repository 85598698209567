import { Popover } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExitToApp from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import { useAuth } from 'auth';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import getUserAbbreviation from 'utils/getUserAbbreviation';

const useStyles = makeStyles(() =>
  createStyles({
    avatar: {
      cursor: 'pointer',
    },
    iconMobile: {
      minWidth: '35px',
    },
  }),
);

const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const { profile, signOut } = useAuth()!;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });

  const handleProfileNav = () => {
    popupState.close();
    navigate('/users/me');
  };

  return (
    <>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList>
          {isDesktop && (
            <MenuItem onClick={handleProfileNav}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>{t('components:userMenu.setting')}</ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={() => signOut()}>
            <ListItemIcon className={isMobile ? classes.iconMobile : undefined}>
              <ExitToApp />
            </ListItemIcon>
            <ListItemText>{t('components:userMenu.signOut')}</ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>

      <Avatar className={classes.avatar} {...bindTrigger(popupState)}>
        {getUserAbbreviation(profile.name)}
      </Avatar>
    </>
  );
};

export default UserMenu;
