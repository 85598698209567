import {
  CreateVideoError,
  CreateVideoErrorReason,
  ForbiddenError,
  LimitsError,
  SwitchPlanError,
} from '__generated__/types';
import { TFunction } from 'i18next';

interface MaybeError {
  __typename?: string;
  message?: string;
}

export default function translateGraphqlError(
  t: TFunction,
  err?: MaybeError | null,
) {
  if (!err?.__typename) {
    return t('errors:default');
  }
  const { __typename } = err;
  switch (__typename) {
    case 'LimitsError': {
      const { limit = 'default', value: count } = err as Partial<LimitsError>;
      return t(`errors:${__typename}.${limit}`, { count });
    }
    case 'ForbiddenError': {
      const { reason = 'default' } = err as Partial<ForbiddenError>;
      return t(`errors:${__typename}.${reason}`);
    }
    case 'SwitchPlanError': {
      const { type = 'default' } = err as Partial<SwitchPlanError>;
      return t(`errors:${__typename}.${type}`);
    }
    case 'CreateVideoError': {
      const { reason = CreateVideoErrorReason.BadRequest } =
        err as Partial<CreateVideoError>;
      return t(`errors:${__typename}.${reason}`);
    }
    default:
      return t(`errors:${__typename}`);
  }
}
