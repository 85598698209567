import { Video } from '__generated__/types';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import BlockTitles from '../../BlockTitles';
import ConfirmationDialog from '../../ConfirmationDialog';
import VideoCard from '../list/VideoCard';
import UploadVideoDialog from '../upload-dialog/UploadVideoDialog';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      marginBottom: spacing(2),
    },
  }),
);

export interface SelectVideoParams {
  onDone: () => void;
  video: Video;
  widgetId?: string;
}

export enum VideoSelectStart {
  USER = 'USER',
  WIDGET = 'WIDGET',
  SCENARIO = 'SCENARIO',
}

interface VideoSelectProps {
  widgetId?: string;
  video?: Video | null;
  onDelete?: () => Promise<void> | void;
  selectVideo?: (params: SelectVideoParams) => Promise<void> | void;
  selectFrom: VideoSelectStart;
  className?: string;
  isTextButton?: boolean;
}

export const VideoSelect: React.FC<VideoSelectProps> = ({
  widgetId,
  video,
  onDelete,
  selectVideo,
  selectFrom,
  className,
  isTextButton,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [dialogOpened, toggleDialogOpened] = useToggle(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {(video || selectFrom !== VideoSelectStart.WIDGET) && (
        <BlockTitles className={classes.header}>
          {t('components:video.select.title')}
        </BlockTitles>
      )}

      {video && (
        <VideoCard
          video={video}
          setShowDeleteDialog={onDelete ? setShowDeleteDialog : undefined}
        />
      )}

      <Button
        variant={isTextButton ? 'text' : 'contained'}
        color={video ? 'default' : 'primary'}
        size="small"
        onClick={toggleDialogOpened}
      >
        {video
          ? t('components:video.select.replaceVideo')
          : t('components:video.select.openUploadDialog')}
      </Button>

      {dialogOpened && (
        <UploadVideoDialog
          widgetId={widgetId}
          onClose={toggleDialogOpened}
          selectVideo={selectVideo}
          initialState={{ state: 'complete', video: null }}
          initialAudioValue={selectFrom === VideoSelectStart.WIDGET}
        />
      )}

      <ConfirmationDialog
        open={showDeleteDialog}
        title={t('components:video.select.deleteVideo')}
        onConfirm={onDelete}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};

export default VideoSelect;
