/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmployerFragment = { __typename?: 'User', id: string, name: string, accountName: string, email?: string | null, blockReason?: Types.BlockReason | null, plan: { __typename?: 'Plan', id: string, type: Types.PlanType, features: Array<Types.PlanFeature>, price: { __typename?: 'MonetaryValue', amount: number, currency: Types.Currency } } };

export type UserSettingsFragment = { __typename?: 'User', locale: string, timezone: string, video?: { __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, duration?: number | null, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null } | null, businessHours?: { __typename?: 'BusinessHours', enabled: boolean, simple?: Array<Array<string> | null> | null } | null };

export type AlertPromoActivatedDetailsFragment = { __typename?: 'AlertPromoActivated', id: string, trialEnds: string, promocode: { __typename?: 'Promocode', id: string, trialDays?: number | null, plan: { __typename?: 'Plan', id: string, name: string, features: Array<Types.PlanFeature>, limits: { __typename?: 'PlanLimits', streamerPerWidget: number, callDuration: number, widgets: { __typename?: 'WidgetLimits', total: number } } } } };

export type MyProfileQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MyProfileQuery = { __typename?: 'Query', getUser?: { __typename: 'Error', id: string, message: string } | { __typename: 'ForbiddenError', id: string, message: string } | { __typename: 'User', completedTours: Array<Types.Tour>, isUnavailable: boolean, enableMessageSoundNotification: boolean, enableMessageBrowserNotification: boolean, id: string, name: string, accountName: string, email?: string | null, blockReason?: Types.BlockReason | null, locale: string, timezone: string, alerts: Array<{ __typename?: 'AlertPromoActivated', id: string, closed?: boolean | null, trialEnds: string, promocode: { __typename?: 'Promocode', id: string, trialDays?: number | null, plan: { __typename?: 'Plan', id: string, name: string, features: Array<Types.PlanFeature>, limits: { __typename?: 'PlanLimits', streamerPerWidget: number, callDuration: number, widgets: { __typename?: 'WidgetLimits', total: number } } } } }>, employedAt: Array<{ __typename?: 'User', id: string, name: string, accountName: string, email?: string | null, blockReason?: Types.BlockReason | null, plan: { __typename?: 'Plan', id: string, type: Types.PlanType, features: Array<Types.PlanFeature>, price: { __typename?: 'MonetaryValue', amount: number, currency: Types.Currency } } }>, integrations: Array<{ __typename?: 'ShopifyIntegration', id: string, exclusive: boolean, appSubscriptionId?: string | null }>, plan: { __typename?: 'Plan', id: string, type: Types.PlanType, features: Array<Types.PlanFeature>, price: { __typename?: 'MonetaryValue', amount: number, currency: Types.Currency } }, video?: { __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, duration?: number | null, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null } | null, businessHours?: { __typename?: 'BusinessHours', enabled: boolean, simple?: Array<Array<string> | null> | null } | null } | null };

export const EmployerFragmentDoc = gql`
    fragment Employer on User {
  id
  name
  accountName
  email
  blockReason
  plan {
    id
    type
    price {
      amount
      currency
    }
    features
  }
}
    `;
export const UserSettingsFragmentDoc = gql`
    fragment UserSettings on User {
  locale
  timezone
  video {
    id
    name
    status
    resolution {
      width
      height
    }
    mp4
    jpg
    duration
  }
  businessHours {
    enabled
    simple
  }
}
    `;
export const AlertPromoActivatedDetailsFragmentDoc = gql`
    fragment AlertPromoActivatedDetails on AlertPromoActivated {
  id
  promocode {
    id
    trialDays
    plan {
      id
      name
      limits {
        widgets {
          total
        }
        streamerPerWidget
        callDuration
      }
      features
    }
  }
  trialEnds
}
    `;
export const MyProfileDocument = gql`
    query myProfile {
  getUser {
    __typename
    ... on IError {
      id
      message
    }
    ... on User {
      ...Employer
      ...UserSettings
      completedTours
      alerts {
        id
        closed
        ...AlertPromoActivatedDetails
      }
      employedAt {
        ...Employer
      }
      integrations {
        ... on IIntegration {
          id
          exclusive
        }
        ... on ShopifyIntegration {
          appSubscriptionId
        }
      }
      isUnavailable
      enableMessageSoundNotification
      enableMessageBrowserNotification
    }
  }
}
    ${EmployerFragmentDoc}
${UserSettingsFragmentDoc}
${AlertPromoActivatedDetailsFragmentDoc}`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
      }
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;