import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Markdown } from 'components/Markdown';
import { Env } from 'config/env';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    actions: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      margin: spacing(0, 2, 2),
      '& * + *': {
        marginTop: spacing(2),
      },
    },
  }),
);

export const ShopifyWelcomeDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const closeDialog = () => {
    navigate({ ...location, search: undefined }, { replace: true });
  };

  return (
    <Dialog open>
      <DialogTitle>{t('components:shopifyModal.welcome.title')}</DialogTitle>

      <DialogContent>
        <Markdown>{t('components:shopifyModal.welcome.description')}</Markdown>
      </DialogContent>

      <div className={classes.actions}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={closeDialog}
          href={`https://${Env.REACT_APP_DOMAIN}/shopify-start`}
          target="_blank"
        >
          {t('components:shopifyModal.welcome.helpButton')}
        </Button>

        <Button fullWidth onClick={closeDialog}>
          {t('components:shopifyModal.welcome.skipButton')}
        </Button>
      </div>
    </Dialog>
  );
};
