import React from 'react';

interface Props {
  className?: string;
}

const IconCall: React.FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 26 18" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V15C0 16.6569 1.34315 18 3 18H17C18.6569 18 20 16.6569 20 15V12.2L23.563 15.0504C24.5451 15.8361 26 15.1368 26 13.8791L26 4.12094C26 2.86318 24.5451 2.16392 23.563 2.94964L20 5.8V3C20 1.34315 18.6569 0 17 0H3Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconCall;
