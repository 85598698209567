import { Language } from './languages';

export enum WidgetPosition {
  LEFT = 'LEFT',
  BOTTOM_LEFT = 'BOTTOM_LEFT',
  BOTTOM_RIGHT = 'BOTTOM_RIGHT',
  RIGHT = 'RIGHT',
}

export enum CtaVariant {
  Link = 'LINK',
  None = 'NONE',
  Click = 'CLICK',
}

export enum OpenLobbyButtonVariant {
  On = 'ON',
  Off = 'OFF',
  Custom = 'CUSTOM',
}

export enum AnalyticsEventType {
  /** When user seen 10% of recorded video */
  Vf1_10Seen = 'VF1_10_SEEN',
  /** When user seen 20% of recorded video */
  Vf1_20Seen = 'VF1_20_SEEN',
  /** When user see 30% of recorded video */
  Vf1_30Seen = 'VF1_30_SEEN',
  /** When user seen 40% of recorded video */
  Vf1_40Seen = 'VF1_40_SEEN',
  /** When user seen 50% of recorded video */
  Vf1_50Seen = 'VF1_50_SEEN',
  /** When user seen 60% of recorded video */
  Vf1_60Seen = 'VF1_60_SEEN',
  /** When user seen 70% of recorded video */
  Vf1_70Seen = 'VF1_70_SEEN',
  /** When user seen 80% of recorded video */
  Vf1_80Seen = 'VF1_80_SEEN',
  /** When user seen 90% of recorded video */
  Vf1_90Seen = 'VF1_90_SEEN',
  /** When user close widget */
  Vf1Close = 'VF1_CLOSE',
  /** When user clicked on widget button */
  Vf1CtaClick = 'VF1_CTA_CLICK',
  /** When user hide widget */
  Vf1DisFocus = 'VF1_DIS_FOCUS',
  /** When user open widget */
  Vf1Focus = 'VF1_FOCUS',
  /** When user fully seen recorded video */
  Vf1FullSeen = 'VF1_FULL_SEEN',
  /** When call start */
  VfCalling = 'VF_CALLING',
  /** When consultant decline call */
  VfCallConsDismiss = 'VF_CALL_CONS_DISMISS',
  /** When user dislined */
  VfCallUserDismiss = 'VF_CALL_USER_DISMISS',
  /** When user open lobby */
  VfChooseConsultant = 'VF_CHOOSE_CONSULTANT',
  /** When consultant accept call */
  VfConversation = 'VF_CONVERSATION',
  /** When call end */
  VfEndcall = 'VF_ENDCALL',
  /** When user seen widget(full size) */
  VfFullWidgetStart = 'VF_FULL_WIDGET_START',
  /** When opened greeting element  */
  VfInvitation = 'VF_INVITATION',
  /** When widget loaded */
  VfLoad = 'VF_LOAD',
  /** When user seen widget(mini size) */
  VfMiniWidgetStart = 'VF_MINI_WIDGET_START',
}

// can't use enum here, because we want to use it on frontend side
// but this package is cjs and esbuild won't tree-shake it on frontend
export type DeviceType = 'DESKTOP' | 'MOBILE';

export enum VisibilityRuleContentType {
  Video = 'VIDEO',
  Scenario = 'SCENARIO',
  None = 'NONE',
}

export interface Video {
  /**
   * Full video URL
   */
  mp4: string;
  /**
   * Thumbnail URL.
   * Can be null for old migrated user videos
   */
  jpg: string | null;
  /**
   * Video resolution in pixels. Jpg has the same resolution
   */
  resolution: { width: number; height: number };
  /**
   * Video duration in milliseconds
   */
  duration: number;
}

export interface User {
  id: string; // cognito userId for authenticated users (streamers), random uuid fir viewers
  name?: string;
}

export interface BusinessHours {
  enabled: boolean;
  simple: (string[] | null)[];
}

export interface MetricAction {
  goalId: string;
  type: AnalyticsEventType;
}

export interface StreamerInfo {
  id: string;
  name: string;
  video: { mp4: string } | null;
  isUnavailable: boolean;
  businessHours: BusinessHours | null;
  timezone: string;
}

export interface CtaSettings {
  blank: boolean | null;
  label: string | null;
  value: string | null;
  variant: CtaVariant;
}

export type VisibilityRuleOrConditionValue =
  | { text: string }
  | { querySelector: string };

export interface VisibilityRuleOrCondition {
  or: VisibilityRuleOrConditionValue[];
}

type GlobValue = { var: string } | string;
export interface VisibilityRuleConditionGlob {
  glob: GlobValue[];
}

export type VisibilityRuleConditionResult =
  | { '!': VisibilityRuleOrCondition }
  | VisibilityRuleOrCondition;

export interface VisibilityRuleJsonLogicCondition {
  jsonlogic: { if: VisibilityRuleCondition[] };
}

export type VisibilityRuleCondition =
  | VisibilityRuleConditionGlob
  | VisibilityRuleConditionResult
  | boolean;

export type VisibilityRule = {
  /**
   * JSON logic (https://jsonlogic.com/) that returns true if widget should be displayed
   */
  condition: VisibilityRuleJsonLogicCondition;
} & (
  | {
      /*
       * Visibility rule may contain only video(or scenario)
       */
      contentType: VisibilityRuleContentType.Video;
      videoId?: string;
      video?: Video | null;
    }
  | {
      /*
       * Visibility rule may contain only scenario(or video)
       */
      contentType: VisibilityRuleContentType.Scenario;
      scenarioId?: string;
      scenario?: Scenario | null;
    }
  | {
      contentType: VisibilityRuleContentType.None;
    }
);

enum ScenarioStepButtonType {
  CssSelector = 'CSS_SELECTOR',
  FinishScenario = 'FINISH_SCENARIO',
  FollowStep = 'FOLLOW_STEP',
  FollowUrl = 'FOLLOW_URL',
  OpenVideochat = 'OPEN_VIDEOCHAT',
}

export interface ScenarioStepCssSelectorButtonSettings {
  type: ScenarioStepButtonType.CssSelector;
  selector: string;
}

export interface ScenarioStepFollowStepButtonSettings {
  type: ScenarioStepButtonType.FollowStep;
  step: number;
}

export interface ScenarioStepOpenVideochatButtonSettings {
  type: ScenarioStepButtonType.OpenVideochat;
}

export interface ScenarioStepFollowUrlButtonSettings {
  type: ScenarioStepButtonType.FollowUrl;
  url: string;
}

export interface ScenarioStepFinishScenarioButtonSettings {
  type: ScenarioStepButtonType.FinishScenario;
}

export type ScenarioStepButton = {
  name: string;
} & (
  | ScenarioStepCssSelectorButtonSettings
  | ScenarioStepFollowStepButtonSettings
  | ScenarioStepOpenVideochatButtonSettings
  | ScenarioStepFollowUrlButtonSettings
  | ScenarioStepFinishScenarioButtonSettings
);

export interface ScenarioStep {
  id: string;
  name: string;
  order: number;
  type: 'SHOW_CONTACT_FORM' | 'CTA' | 'ANSWER_BUTTONS';
  video: Video | null;
  buttons: ScenarioStepButton[] | null;
}

export interface Scenario {
  id: string;
  name: string;
  steps: ScenarioStep[];
}

export interface OpenLobbyButtonSettings {
  variant: OpenLobbyButtonVariant;
  selector: string | null;
}

/**
 * Widget settings that owner can change in admin panel
 */
export interface WidgetSettings {
  /** the corner of the screen where to display the widget initially */
  position: WidgetPosition;
  /** which widget design to use */
  flavour: 'CLASSIC' | 'MARKTSTEIN' | 'HAUSDORF' | 'E1';
  /** current theme, default to light */
  theme: 'LIGHT' | 'DARK';
  /** widget accent color */
  accentColor: string;
  /** text and elements color */
  textColor: string;
  /** Should widget be displayed as miniature or button. Default to miniature. */
  preferMiniature: boolean;
  /** completely hide widget when there are no available streamers */
  hideWhenNoStreamers: boolean;
  /** widget locale, represented as a BCP47 [RFC5646] language tag */
  locale: Language;
  /**
   * Timeout in seconds, after which widget should do something to attract user's attention
   * Null if this behaviour is disabled
   */
  greetingTimeout: number | null;
  /** If true, widget will be displayed in impersonal call mode */
  enableImpersonalCall: boolean | null;
  /** optional jivo widget id */
  jivoId: string | null;
  /**
   * In case of Jivo integration, this settings determines which part of button (chat/video) should be emphasized
   * Without Jivo integration this defaults to null
   */
  jivoButtonVariant: 'DEFAULT' | 'PREFER_VIDEO' | 'PREFER_CHAT' | null;

  /*
   * Rules of widget visibility on different site pages/pathes
   */
  visibilityRules: VisibilityRule[];

  /**
   * Call to action settings, for prerecorded widgets
   */
  cta: CtaSettings | null;

  /**
   * String displayed in offer block, or null
   * @see issue #354
   */
  offer: string | null;

   /**
   * String displayed in policy block, or null
   * @see issue #354
   */
   policy: string | null;

  /**
   * Setting this prevents widget from being loaded in desktop or mobile browsers
   */
  disabledOn: DeviceType[];

  /**
   * If true, hide Videoforce label from all widget screens
   */
  hideLabel: boolean;

  /**
   * Yandex.Metrica counter ID
   */
  metricId: string | null;

  /**
   * Videoforce action and Yandex.Metrika goal id pair array,
   * When action happens, counter of goal increases
   */
  metricActions: MetricAction[];

  /*
   * Show/hide widget on pages without visibility rule
   */
  defaultVisibility: boolean;
  /*
   * Open widget lobby button settings, for prerecorded widgets
   */
  openLobbyButton: OpenLobbyButtonSettings | null;

  /*
   * If true, widget can receive rating and review
   */
  ratingEnabled: boolean;

  /*
   *If true, widget calls can save calls recoding
   */
  saveRecording: Boolean;

  /*
   * If true, display text chat buttons in widget
   */
  displayTextChatButton: boolean;

  /*
   * If true, activate call confirmation mechanism in widget
   */
  enableCallConfirmation: boolean;

  /**
   * Timeout in seconds, after which call with confirmation should be declined
   * Null if this behaviour is disabled
   */
  callConfirmationTimeout: number | null;
}

/**
 * Part of configuration that is received by admin app from API's /env endpoint
 */
export interface RuntimeEnv {
  region: string;
  userPoolId: string;
  identityPoolId: string;
  webClientId: string;
  wsApiURL: string;
  httpApiURL: string;
}

/**
 * Part of configuration that is received by widgets app from API's /env endpoint
 */
export interface WidgetRuntimeEnv extends RuntimeEnv {
  /**
   * Id of the user who's viewing the widget
   * Initially generated server-side and stored in localstorage
   */
  viewerId: string;
  widgetURL: string;
  settings: WidgetSettings;
  video: Video | null;
  scenario: Scenario | null;
  /**
   * Twilio conversations token for current user.
   * Undefined for recorded widgets
   */
  conversationsToken?: string;
  operators: StreamerInfo[];
  /**
   * Are calls enabled in the admin panel
   */
  callsEnabled: boolean;
}

/*
 * Варианты событий, отправляемых при иницилизации звонка через API
 */
export enum WidgetApiActions {
  INIT_PERSONAL_CALL = 'INIT_PERSONAL_CALL',
  INIT_IMPERSONAL_CALL = 'INIT_IMPERSONAL_CALL',
  OPEN_CHAT = 'OPEN_CHAT',
  CLOSE_CHAT = 'CLOSE_CHAT',
}
