import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack } from 'twilio-video';

interface AudioTrackProps {
  track: IAudioTrack;
}

export function AudioTrack({ track }: AudioTrackProps) {
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (track) {
      audioEl.current = track.attach();
      document.body.appendChild(audioEl.current);
      return () => {
        track.detach().forEach((el) => el.remove());
      };
    }
  }, [track]);

  return null;
}
