import { Tour } from '__generated__/types';
import React, {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react';

import { useCompleteTourMutation } from '../completeTour.generated';
import { IntroTourStep } from './constants';
import {
  INTRO_TOUR_DEFAULT_STATE,
  IntroTourAction,
  introTourActions,
  introTourReducer,
  IntroTourState,
} from './reducer';

const IntroTourStateCtx = createContext<IntroTourState>(
  INTRO_TOUR_DEFAULT_STATE,
);
const IntroTourActionsCtx = createContext<Dispatch<IntroTourAction>>(() => {});

export const IntroTourProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(
    introTourReducer,
    INTRO_TOUR_DEFAULT_STATE,
  );
  return (
    <IntroTourStateCtx.Provider value={state}>
      <IntroTourActionsCtx.Provider value={dispatch}>
        {children}
      </IntroTourActionsCtx.Provider>
    </IntroTourStateCtx.Provider>
  );
};

export const useIntroTourState = () => useContext(IntroTourStateCtx);
export const useIntroTourActions = () => useContext(IntroTourActionsCtx);

/**
 * Hook that is used to indicate that next step of intro tour is being loaded and rendered
 * and react-joyride should pause rendering (set run=false)
 * @param step Step that is being loaded, we cannot jump over steps
 * @param onMount If true (default) it is dispatched immediately, otherwise callback to dispatch manually is returned
 */
export function useIntroStepLoading(step: IntroTourStep, onMount = true) {
  const dispatch = useIntroTourActions();

  useEffect(() => {
    if (onMount) {
      dispatch(introTourActions.loading(step));
    }
  }, [dispatch, step, onMount]);

  return useCallback(() => {
    dispatch(introTourActions.loading(step));
  }, [dispatch, step]);
}

/**
 * Hook that is used to indicate that next step of intro tour is loaded and rendered
 * and react-joyride should resume rendering (set run=true)
 * @param step Step that is being loaded, we cannot jump over steps
 * @param onMount If true (default) it is dispatched immediately, otherwise callback to dispatch manually is returned
 * @param skipLoading If true, skip loading phase check
 */
export function useIntroStepReady(
  step: IntroTourStep,
  onMount = false,
  skipLoading?: true,
) {
  const dispatch = useIntroTourActions();
  useEffect(() => {
    if (onMount) {
      dispatch(introTourActions.ready(step, skipLoading));
    }
  }, [dispatch, step, skipLoading, onMount]);

  return useCallback(() => {
    dispatch(introTourActions.ready(step, skipLoading));
  }, [dispatch, step, skipLoading]);
}

export function useEndIntroTour() {
  const dispatch = useIntroTourActions();
  const [mutate] = useCompleteTourMutation();

  return useCallback(
    (skipped?: boolean) => {
      dispatch(introTourActions.end());
      mutate({
        variables: { tour: Tour.OwnerIntro, skipped },
      });
    },
    [dispatch, mutate],
  );
}
