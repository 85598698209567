import { Video } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SelectVideoParams, VideosList } from 'components/video';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DialogLayout from './DialogLayout';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      minHeight: '57px',
      width: '100%',
      borderBottom: '1px solid #DBDBDB',
      marginBottom: '18px',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
      '& p': {
        marginLeft: '10px',
      },
    },
    dialogOverrides: {
      justifyContent: 'flex-start',
      width: '600px',
      height: '480px',
      overflow: 'hidden',
    },
    listOverrides: {
      overflowY: 'auto',
      justifyContent: 'flex-start',
      paddingLeft: '10px',
      width: '100%',
    },
  }),
);

interface StepCompleteProps {
  widgetId: string | undefined;
  onDone: () => void;
  onAddNewVideo: () => void;
  onRecordingVideo: () => void;
  selectVideo?: (params: SelectVideoParams) => Promise<void> | void;
}

const StepComplete: React.FC<StepCompleteProps> = ({
  onDone,
  onAddNewVideo,
  onRecordingVideo,
  selectVideo,
  widgetId,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [selectedVideo, setSelectedVideo] = useState<Video | undefined>(
    undefined,
  );

  const mutate = () =>
    selectedVideo?.id
      ? selectVideo?.({ onDone, video: selectedVideo, widgetId })
      : undefined;

  return (
    <DialogLayout
      onCancel={onDone}
      onSubmit={mutate}
      onAddNewVideo={onAddNewVideo}
      onRecordingVideo={onRecordingVideo}
      className={classes.dialogOverrides}
    >
      <div className={classes.header}>
        <p>{t('components:video.uploadDialog.header')}</p>
      </div>

      <VideosList
        className={classes.listOverrides}
        selectedVideo={selectedVideo}
        setSelectedVideo={setSelectedVideo}
      />
    </DialogLayout>
  );
};

export default StepComplete;
