import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { ErrorBoundary } from '@sentry/react';
import initApolloClient from 'apollo/initApolloClient';
import { JivoProvider } from 'components/JivoProvider';
import Loading from 'components/Loading';
import { SnackbarProvider } from 'notistack';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { ServiceWorkerProvider, ServiceWorkerUpdateDialog } from 'sw';
import theme from 'theme';

import { AuthProvider } from './auth';
import { initI18next } from './i18n';
import RootRouter from './routes/RootRouter';

initI18next();

const App: React.FC = () => {
  const [apolloClient] = useState(initApolloClient);
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={theme}>
      <ServiceWorkerProvider>
        <>
          <React.Suspense
            fallback={<Loading title={t('components:app.loading')} />}
          >
            <ErrorBoundary>
              <BrowserRouter>
                <JivoProvider>
                  <ApolloProvider client={apolloClient}>
                    <AuthProvider>
                      <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{
                          horizontal: 'center',
                          vertical: 'bottom',
                        }}
                      >
                        <CssBaseline />
                        <RootRouter />
                      </SnackbarProvider>
                    </AuthProvider>
                  </ApolloProvider>
                </JivoProvider>
              </BrowserRouter>
            </ErrorBoundary>
          </React.Suspense>

          {/* This dialog should be on top, and everything else behind error boundary */}
          <ServiceWorkerUpdateDialog />
        </>
      </ServiceWorkerProvider>
    </ThemeProvider>
  );
};

export default App;
