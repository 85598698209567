import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ButtonProgress from './ButtonProgress';

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    modal: {
      width: '390px',
    },
    subtitle: {
      color: palette.grey[600],
    },
    actions: {
      padding: spacing(0, 2, 3),
      marginTop: spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
    },
    progress: {
      flex: 1,
      marginLeft: spacing(1),
    },
    cancelButton: {
      flex: 1,
      marginRight: spacing(1),
    },
    confirmButton: {
      '&.MuiButton-root': {
        color: palette.common.white,
        background: '#F2554E',
        '&:hover': {
          backgroundColor: '#CD4E49',
        },
        '&:active': {
          backgroundColor: '#B43C37',
        },
      },
    },
    confirmButtonBlue: {
      '&.MuiButton-root': {
        color: palette.common.white,
        background: '#3198E3',
        '&:hover': {
          backgroundColor: '#2C81BE',
        },
        '&:active': {
          backgroundColor: '#2471A9',
        },
      },
    },
  }),
);

export interface ConfirmationDialogProps {
  title: string;
  subtitle?: string;
  confirming?: boolean;
  cancelButton?: string;
  confirmButton?: string;
  open?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  color?: 'blue';
  className?: string;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const {
    confirming,
    open,
    onCancel,
    onConfirm,
    title,
    subtitle,
    cancelButton,
    confirmButton,
    color,
    className,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      classes={{
        paper: clsx(classes.modal, className),
      }}
      onClose={onCancel}
      open={!!open}
    >
      <DialogContent>
        <Typography variant="h3">{title}</Typography>
      </DialogContent>

      <DialogContent>
        <Typography variant="h5" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </DialogContent>

      <DialogActions className={classes.actions} onClick={onCancel}>
        <div className={classes.cancelButton}>
          <Button fullWidth variant="contained" disabled={confirming}>
            {cancelButton ?? t('commons:cancel')}
          </Button>
        </div>

        <ButtonProgress loading={confirming} className={classes.progress}>
          <Button
            fullWidth
            variant="contained"
            className={clsx(classes.confirmButton, {
              [classes.confirmButtonBlue]: color,
            })}
            onClick={onConfirm}
            disabled={confirming}
          >
            {confirmButton ?? t('commons:delete')}
          </Button>
        </ButtonProgress>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
