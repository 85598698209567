import { useApolloClient } from '@apollo/client';
import { Hub, HubCallback } from '@aws-amplify/core';
import { useState } from 'react';
import useAsync from 'react-use/lib/useAsync';

import getClaims from './getClaims';
import { Claims } from './types';

export default function useAuthEvents() {
  const [claims, setClaims] = useState<Claims | null>(null);
  const [acting, setActing] = useState(false);

  const apollo = useApolloClient();

  const state = useAsync(async () => {
    const resetAppState = async (refetch: boolean) => {
      setActing(true);
      window.location.href = '/';
      try {
        if (refetch) {
          await apollo.resetStore();
        } else {
          await apollo.clearStore();
        }
        const claims = await getClaims();
        setClaims(claims);
      } finally {
        setActing(false);
      }
    };

    const listener: HubCallback = ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          resetAppState(true);
          break;
        case 'signOut':
          resetAppState(false);
          break;
        case 'switchAccount':
          setClaims(data.claims);
          resetAppState(true);
          break;
        // Default event is 'tokenRefresh', but it has no data, so we dispatch custom event
        case 'manualTokenRefresh':
          setClaims(data.claims);
          break;
      }
    };
    Hub.listen('auth', listener);

    const currentUser = await getClaims();
    setClaims(currentUser);

    return () => {
      Hub.remove('auth', listener);
    };
  }, [setClaims, setActing, apollo]);

  return { claims, loading: state.loading || acting };
}
