import { useMemo } from 'react';

import { HTTPApi } from '../../api';
import logger from '../logger';
import { ActiveCall } from '../types';
import {
  CallOperatorAction,
  CallOperatorActions,
  IncomingRequest,
} from './types';

export const useCallOperatorActions = (
  dispatch: React.Dispatch<CallOperatorAction>,
  httpApiURL: string,
): CallOperatorActions => {
  return useMemo(() => {
    const getApi = () => {
      return new HTTPApi(httpApiURL);
    };

    return {
      acceptCall: async (callId: string, widgetName?: string) => {
        try {
          dispatch({
            type: 'incoming_call',
            callId,
            accepted: true,
            widgetName,
          });
          await getApi().acceptCall(callId);
        } catch (e) {
          logger.error('operator accept call failed', e);
        }
      },

      acceptRequestedCall: async (callId: string) => {
        try {
          await getApi().acceptCall(callId);
        } catch (e) {
          logger.error('operator accept call failed', e);
        }
      },

      declineCall: async (callId: string) => {
        const res = await getApi().declineCall(callId);
        if (res) {
          dispatch({ type: 'inactivated_call' });
        } else {
          logger.error('operator decline call failed');
        }
      },

      endCall: async (activeCall: ActiveCall) => {
        dispatch({
          type: 'ending_call',
          token: activeCall.token,
          callId: activeCall.callId,
          ending: true,
        });
        try {
          await getApi().endCall(activeCall.callId);
        } catch (e) {
          logger.error('operator end call failed', e);
        }
      },

      endedCallOperator: () => {
        dispatch({ type: 'inactivated_call' });
      },

      acceptRequest: async (incomingRequest: IncomingRequest) => {
        const {
          callId,
          callConfirmationTimeout,
          sourcePage,
          isImpersonalCall,
        } = incomingRequest;
        try {
          dispatch({
            type: 'incoming_request',
            callId,
            callConfirmationTimeout,
            sourcePage,
            isImpersonalCall,
            accepted: true,
          });

          await getApi().acceptRequest(callId);
        } catch (e) {
          logger.error('operator accept request failed', e);
        }
      },

      declineRequest: async (callId: string) => {
        const res = await getApi().declineRequest(callId);
        if (res === null) {
          dispatch({ type: 'inactivated_call' });
        } else {
          logger.error('operator decline request failed');
        }
      },
    };
  }, [dispatch, httpApiURL]);
};
