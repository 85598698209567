import { yupResolver } from '@hookform/resolvers/yup';
import { customScenarioStepSchema } from 'components/scenario/mutations/shemas';
import { ScenarioStep } from 'components/scenario/types';
import { Resolver, useForm } from 'react-hook-form';

interface StepFormProps {
  defaultValue: ScenarioStep;
  stepsAction: (step: ScenarioStep) => void;
  dialogAction: () => void;
}

export const useStepForm = ({
  defaultValue,
  stepsAction,
  dialogAction,
}: StepFormProps) => {
  const form = useForm<ScenarioStep>({
    defaultValues: defaultValue,
    resolver: yupResolver(customScenarioStepSchema) as Resolver<ScenarioStep>,
  });

  return {
    form,
    onSubmit: form.handleSubmit((step) => {
      stepsAction(step);
      dialogAction();
    }),
  };
};
