import { ScenarioStepType } from '__generated__/types';
import { ErrorMessage } from '@hookform/error-message';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  ScenarioStep,
  ScenarioStepAnswerButton,
  ScenarioStepCtaButton,
} from 'components/scenario/types';
import { Reorder } from 'framer-motion';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { MAX_COUNT_STEP_BUTTONS } from '../constants';
import { AddNewButton } from './AddNewButton';
import { AnswerButton, CtaButton } from './button';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    reorderWrapper: {
      paddingLeft: 0,
      '& > li': {
        listStyle: 'none',
      },
    },
    error: {
      color: '#F2554E',
      marginTop: spacing(),
    },
  }),
);

interface StepButtonProps {
  steps: ScenarioStep[];
}

export const StepButtons: React.FC<StepButtonProps> = ({ steps }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { watch, setValue, formState } = useFormContext<ScenarioStep>();
  const selectedStepType = watch('type');

  const buttons = watch('buttons');

  return (
    <>
      <Reorder.Group
        className={classes.reorderWrapper}
        axis="y"
        values={buttons}
        onReorder={(data) =>
          setValue('buttons', data, {
            shouldDirty: true,
            shouldValidate: true,
          })
        }
      >
        {buttons.map((button, index) => {
          return selectedStepType === ScenarioStepType.AnswerButtons ? (
            <AnswerButton
              key={button.id}
              index={index}
              button={button as ScenarioStepAnswerButton}
              steps={steps}
            />
          ) : (
            <CtaButton
              key={button.id}
              index={index}
              button={button as ScenarioStepCtaButton}
            />
          );
        })}
      </Reorder.Group>

      {buttons.length < MAX_COUNT_STEP_BUTTONS && (
        <AddNewButton type={selectedStepType} />
      )}

      {buttons.length === 0 && (
        <ErrorMessage
          errors={formState.errors}
          name="buttons"
          render={() => (
            <span className={classes.error}>
              {t('components:scenario.dialog.errors.buttons')}
            </span>
          )}
        />
      )}
    </>
  );
};
