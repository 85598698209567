import { ErrorMessage } from '@hookform/error-message';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  SelectVideoParams,
  VideoSelect,
  VideoSelectStart,
} from 'components/video';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    title: {
      marginBottom: spacing(),
    },
    videoSelect: {
      marginTop: spacing(3),
    },
    typeSelect: {
      marginTop: spacing(3),
      width: '272px',
    },
    error: {
      color: '#F2554E',
      marginTop: spacing(),
    },
  }),
);

export const ScenarioStepVideo: React.FC = () => {
  const classes = useStyles();
  const {
    watch,
    formState: { errors },
    setValue,
  } = useFormContext();
  const { t } = useTranslation();
  const video = watch('video');

  const selectScenarioStepVideo = (params: SelectVideoParams) => {
    setValue('video', params.video, {
      shouldValidate: true,
      shouldDirty: true,
    });
    setValue('videoId', params.video.id, {
      shouldValidate: true,
      shouldDirty: true,
    });
    params.onDone();
  };

  return (
    <>
      <VideoSelect
        className={classes.videoSelect}
        selectFrom={VideoSelectStart.SCENARIO}
        selectVideo={selectScenarioStepVideo}
        video={video}
      />

      <ErrorMessage
        errors={errors}
        name="video"
        render={() => (
          <span className={classes.error}>
            {t('components:scenario.dialog.errors.videoId')}
          </span>
        )}
      />
    </>
  );
};
