import { Typography } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Popover from '@material-ui/core/Popover';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { EmployerProfile, useAuth } from 'auth';
import uniqBy from 'lodash/uniqBy';
import { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    accountMenu: {
      cursor: 'pointer',
      marginTop: spacing(1),
      alignSelf: 'stretch',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& span': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    accountMenuList: {
      maxWidth: 300,
    },
    arrow: {
      color: palette.text.secondary,
    },
  }),
);

const AccountMenu: React.FC = () => {
  const classes = useStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });
  const { profile, claims, switchAccount } = useAuth()!;
  const { t } = useTranslation();

  const handleSwitchAccount = (user: EmployerProfile) => {
    popupState.close();
    if (user.id !== claims.accountId) {
      switchAccount(user.id);
    }
  };

  // Если пользователь создатель аккаунта - список профилей будет состоят только из его аккаунта
  // Если пользователь не создатель аккаунта, значит приглашенный оператор - профиль того, кто пригласил (пригласить можно только в один аккаунт)
  // Если пользователь глобальный админ - показываем его профиль и возможность переключится на аккаунты, в которые приглашали
  const profiles = useMemo(() => {
    if (claims.owner && !claims.admin) {
      return [profile];
    }

    return claims.admin
      ? uniqBy([profile, ...(profile.employedAt ?? [])], 'id')
      : profile.employedAt.filter((item) => item.id !== claims.id);
  }, [claims.admin, claims.id, claims.owner, profile]);

  const triggerProps = profiles.length > 1 ? bindTrigger(popupState) : {};

  return (
    <>
      <div
        className={classes.accountMenu}
        {...triggerProps}
        title={t('components:accountMenu.tooltip')}
      >
        <Typography variant="caption" color="textSecondary">
          {profiles.find((p) => p.id === claims.accountId)?.accountName}
        </Typography>
        {profiles.length > 1 && (
          <ArrowDropDownIcon fontSize="small" className={classes.arrow} />
        )}
      </div>

      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuList className={classes.accountMenuList}>
          {profiles.map((user) => (
            <MenuItem
              key={user.id}
              selected={user.id === claims.accountId}
              onClick={() => handleSwitchAccount(user)}
            >
              <Typography variant="inherit" noWrap>
                {user.accountName}
              </Typography>
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
};

export default AccountMenu;
