import { Conversation, Message } from '@videoforce/client';
import React, { Dispatch, SetStateAction } from 'react';
import { isMobile } from 'react-device-detect';

import { ChatHeader } from './ChatHeader';
import { ChatInput } from './ChatInput';
import { Messages } from './Messages';

export interface ChatProps {
  className?: string;
  myId?: string;
  messages: Message[];
  setChatVisible?: Dispatch<SetStateAction<boolean>>;
  conversation?: Conversation;
}

export const Chat: React.FC<ChatProps> = ({
  className,
  myId,
  messages,
  setChatVisible,
  conversation,
}) => {
  if (!conversation) {
    return null;
  }

  return (
    <div className={className}>
      {isMobile && <ChatHeader setChatVisible={setChatVisible} />}
      <Messages myId={myId} messages={messages} />
      <ChatInput conversation={conversation} />
    </div>
  );
};
