import { HTTPApi } from '../../api';

export const useClaimConversation = (): ((
  sid: string,
  httpApiURL: string,
) => Promise<boolean>) => {
  const initClaimConversation = async (sid: string, httpApiURL: string) => {
    const api = new HTTPApi(httpApiURL);
    const { success } = await api.claimConversation(sid);
    return success;
  };

  return initClaimConversation;
};
