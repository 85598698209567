import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  CallOperatorProvider,
  ConversationOperatorProvider,
  ConversationsClientProvider,
  ConversationsProvider,
} from '@videoforce/client';
import { useAuth } from 'auth';
import AccountBlockAlert from 'components/AccountBlockAlert';
import Alert from 'components/alert';
import {
  AcceptedRequestDialog,
  CallModal,
  DeclineViewerDialog,
  IncomingCallDialog,
  IncomingRequestDialog,
} from 'components/call';
import Loading from 'components/Loading';
import { OperatorStateProvider } from 'components/OperatorStateProvider';
import { ShopifyModal } from 'components/shopify-modal';
import { Sidebar } from 'components/sidebar';
import { IntroTourProvider } from 'components/tours/intro';
import { UnreadProvider } from 'components/unread-provider';
import { useUpdateConversationsToken } from 'components/useUpdateConversationsToken';
import { Env } from 'config/env';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { firebasePushNotification } from 'utils/firebasePushNotification';

import UserRouterDesktop from './UserRouterDesktop';
import UserRouterMobile from './UserRouterMobile';

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    userRouterRoot: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
    userRouterMain: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
    },
  }),
);

const UserRouterInternal: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const auth = useAuth();
  const token = auth?.claims.conversationsToken;
  const conversationsToken = token!;
  const userName = auth?.profile.name;
  const id = auth?.claims.id;
  const myId = id!;
  const { updateConversationsToken, updatedConversationsToken } =
    useUpdateConversationsToken();

  return (
    <ConversationsClientProvider
      token={updatedConversationsToken ?? conversationsToken}
      operatorName={userName}
      enabled={true}
      firebasePushNotification={firebasePushNotification}
      updateConversationsToken={updateConversationsToken}
    >
      <ConversationsProvider>
        <ConversationOperatorProvider>
          <CallOperatorProvider httpApiURL={Env.httpApiURL} myId={myId}>
            <UnreadProvider>
              <IntroTourProvider>
                <div className={classes.userRouterRoot}>
                  <Sidebar />

                  <React.Suspense
                    fallback={
                      <Loading title={t('components:userRouter.loading')} />
                    }
                  >
                    {isMobile ? <UserRouterMobile /> : <UserRouterDesktop />}
                  </React.Suspense>
                </div>

                <AccountBlockAlert />
                <IncomingCallDialog />
                <IncomingRequestDialog />
                <AcceptedRequestDialog />
                <DeclineViewerDialog />
                <CallModal />
                <Alert />
                <ShopifyModal />
              </IntroTourProvider>
            </UnreadProvider>
          </CallOperatorProvider>
        </ConversationOperatorProvider>
      </ConversationsProvider>
    </ConversationsClientProvider>
  );
};

const UserRouter: React.FC = () => {
  return (
    <OperatorStateProvider>
      <UserRouterInternal />
    </OperatorStateProvider>
  );
};

export default UserRouter;
