import { ApolloLink } from '@apollo/client';

function omitTypename(key: string, value: any) {
  return key === '__typename' ? undefined : value;
}

const cleanTypenameLink = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename,
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

export default cleanTypenameLink;
