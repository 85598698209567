import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useReducer,
} from 'react';

import { TwilioVideoProvider } from '../../twilio';
import { CALL_DEFAULT_STATE, callOperatorReducer } from './callOperatorReducer';
import { CallOperatorActions, CallOperatorState } from './types';
import { useCallOperatorActions } from './useCallOperatorActions';
import { useOperatorSystemMessages } from './useOperatorSystemMessages';

const CallStateCtx = createContext<CallOperatorState>(CALL_DEFAULT_STATE);
const CallActionsCtx = createContext<CallOperatorActions>({
  acceptCall: () => {},
  acceptRequestedCall: () => {},
  declineCall: () => {},
  endCall: () => {},
  endedCallOperator: () => {},
  acceptRequest: () => {},
  declineRequest: () => {},
});

interface CallOperatorProvider {
  httpApiURL: string;
  myId: string;
  children?: ReactNode;
}

export const CallOperatorProvider: FC<CallOperatorProvider> = ({
  children,
  httpApiURL,
  myId,
}) => {
  const [state, dispatch] = useReducer(callOperatorReducer, CALL_DEFAULT_STATE);
  const callOperatorActions = useCallOperatorActions(dispatch, httpApiURL);
  const { endedCallOperator } = callOperatorActions;

  useOperatorSystemMessages(dispatch, myId);

  return (
    <CallStateCtx.Provider value={state}>
      <CallActionsCtx.Provider value={callOperatorActions}>
        <TwilioVideoProvider endCallAction={endedCallOperator}>
          {children}
        </TwilioVideoProvider>
      </CallActionsCtx.Provider>
    </CallStateCtx.Provider>
  );
};

export const useOperatorCallState = () => useContext(CallStateCtx);
export const useOperatorCallActions = () => useContext(CallActionsCtx);
