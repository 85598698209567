import { AuthCtx, useAuth } from 'auth';
import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react';

import useUpdateUnavailableForCalls from './is-unavailable/useUpdateUnavailableForCalls';

function useOperatorEnabled(user: AuthCtx | null) {
  const userId = user?.profile.id;

  const updateUnavailableForCalls = useUpdateUnavailableForCalls(userId);

  return useMemo((): OperatorStateCtx => {
    const isOperator = !!user?.claims.streamer?.length;
    const isUnavailable = user?.profile.isUnavailable;

    return {
      enabled: isOperator && !isUnavailable,
      setDisabled: isOperator ? updateUnavailableForCalls : undefined,
    };
  }, [user, updateUnavailableForCalls]);
}

interface OperatorStateCtx {
  // undefined if user is not a streamer
  enabled?: boolean;
  setDisabled?: (value: boolean) => void;
}

const OperatorStateContext = createContext<OperatorStateCtx>({});

/**
 * This provider provides adds streamer online/offline state
 */
export const OperatorStateProvider: FC<PropsWithChildren> = ({ children }) => {
  const user = useAuth();
  const value = useOperatorEnabled(user);

  return (
    <OperatorStateContext.Provider value={value}>
      {children}
    </OperatorStateContext.Provider>
  );
};

export const useOperatorState = () => useContext(OperatorStateContext);
