import {
  ParticipantConnectType,
  useConnect,
  useOperatorCallActions,
  useOperatorCallState,
} from '@videoforce/client';
import React, { useCallback, useEffect } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { CallView } from './CallView';

const OperatorCallView: React.FC = () => {
  const { endCall } = useOperatorCallActions();
  const fullscreenHandle = useFullScreenHandle();
  const { activeCall } = useOperatorCallState();
  useConnect(activeCall, ParticipantConnectType.OPERATOR);

  const endCallBeforeUnload = useCallback(() => {
    if (activeCall) {
      endCall(activeCall);
    }
  }, [activeCall, endCall]);

  useEffect(() => {
    window.addEventListener('beforeunload', endCallBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', endCallBeforeUnload);
    };
  }, [endCallBeforeUnload]);

  return (
    <FullScreen handle={fullscreenHandle}>
      <CallView onEndCall={endCall} fullscreenHandle={fullscreenHandle} />
    </FullScreen>
  );
};

export default OperatorCallView;
