// The user's locale, represented as a BCP47 [RFC5646] language tag.
// Each language tag is composed of one or more "subtags" separated by hyphens (-)
export enum Language {
  ru = 'ru',
  en = 'en',
  tr = 'tr',
}
export const LANGUAGES = Object.values(Language);
export const LANGUAGE_NAMES: Record<Language, string> = {
  ru: 'Русский',
  en: 'English',
  tr: 'Turkey',
};
