import Dialog from '@material-ui/core/Dialog';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useConversationOperator,
  useOperatorCallState,
} from '@videoforce/client';
import { ChatCompact } from 'components/chat';
import React, { useEffect } from 'react';

import { SimpleCallControl } from './SimpleCallControl';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      gap: '40px',
      '& .MuiDialog-paper': {
        background: 'transparent',
        display: 'flex',
        gap: '40px',
        maxWidth: 'fit-content',
        flexDirection: 'row',
        boxShadow: 'none',
      },
    },
  }),
);

export const AcceptedRequestDialog: React.FC = () => {
  const classes = useStyles();
  const { acceptedRequest } = useOperatorCallState();
  const { conversation, enterConversation } = useConversationOperator();

  useEffect(() => {
    if (acceptedRequest?.conversationSid) {
      enterConversation(acceptedRequest.conversationSid);
    }
  }, [enterConversation, acceptedRequest?.conversationSid]);

  if (!acceptedRequest) {
    return null;
  }

  return (
    <Dialog open className={classes.dialog}>
      <SimpleCallControl callId={acceptedRequest.callId} />
      <ChatCompact conversation={conversation} />
    </Dialog>
  );
};
