import { useCallback, useEffect, useRef, useState } from 'react';
import Video, { createLocalTracks, LocalTrack, Room } from 'twilio-video';

import { ParticipantConnectType } from '../../types';
import { isMobile } from '../../utils';
import { connectionOptions } from '../../utils/connectionOptions';
import { getVideoParams } from '../../utils/getVideoParams';

export default function useRoom(
  setAudioAndVideoTracks: (localTracks: LocalTrack[]) => void,
) {
  const [room, setRoom] = useState<Room | null>(null);
  const [isConnecting, setIsConnecting] = useState(false);
  const options = connectionOptions();
  const optionsRef = useRef(options);
  useEffect(() => {
    // This allows the connect function to always access the most recent version of the options object. This allows us to
    // reliably use the connect function at any time.
    optionsRef.current = options;
  }, [options]);

  const connect = useCallback(
    (token: string, participantType: ParticipantConnectType) => {
      setIsConnecting(true);
      createLocalTracks({
        audio: true,
        video: getVideoParams(participantType),
      })
        .then((localTracks) => {
          setAudioAndVideoTracks(localTracks);
          return Video.connect(token, {
            ...optionsRef.current,
            tracks: localTracks,
            region: 'de1',
          });
        })
        .then(
          (newRoom) => {
            setRoom(newRoom);
            const disconnect = () => newRoom.disconnect();

            // This app can add up to 13 'participantDisconnected' listeners to the room object, which can trigger
            // a warning from the EventEmitter object. Here we increase the max listeners to suppress the warning.
            newRoom.setMaxListeners(20);

            newRoom.once('disconnected', () => {
              // Reset the room only after all other `disconnected` listeners have been called.
              setTimeout(() => setRoom(null));
              window.removeEventListener('beforeunload', disconnect);

              if (isMobile) {
                window.removeEventListener('pagehide', disconnect);
              }
            });

            newRoom.localParticipant.videoTracks.forEach((publication) =>
              // All video tracks are published with 'standard' priority
              publication.setPriority('standard'),
            );

            setIsConnecting(false);

            // Add a listener to disconnect from the room when a user closes their browser
            window.addEventListener('beforeunload', disconnect);

            if (isMobile) {
              // Add a listener to disconnect from the room when a mobile user closes their browser
              window.addEventListener('pagehide', disconnect);
            }
          },
          (error) => {
            console.error(error);
            setIsConnecting(false);
          },
        );
    },
    [setAudioAndVideoTracks],
  );

  return { room, isConnecting, connect };
}
