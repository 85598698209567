import { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useUnreadConversations } from './useUnreadConversations';
import { unreadMessagesObject, useUnreadMessages } from './useUnreadMessages';

interface UnreadContext {
  unreadMessages: unreadMessagesObject;
  countUnreadMyConversations: number;
  countUnreadIncomingConversations: number;
  countUnreadCallsOnlyConversations: number;
}

const UnreadCtx = createContext<UnreadContext>({
  unreadMessages: {
    sid: {
      count: 0,
      type: null,
    },
  },
  countUnreadMyConversations: 0,
  countUnreadIncomingConversations: 0,
  countUnreadCallsOnlyConversations: 0,
});

export const UnreadProvider: FC<PropsWithChildren> = ({ children }) => {
  const unreadMessages = useUnreadMessages();
  const {
    countUnreadMyConversations,
    countUnreadIncomingConversations,
    countUnreadCallsOnlyConversations,
  } = useUnreadConversations(unreadMessages);

  return (
    <UnreadCtx.Provider
      value={{
        unreadMessages,
        countUnreadMyConversations,
        countUnreadIncomingConversations,
        countUnreadCallsOnlyConversations,
      }}
    >
      {children}
    </UnreadCtx.Provider>
  );
};

export const useUnread = () => useContext(UnreadCtx);
