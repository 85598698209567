import { ErrorMessage } from '@hookform/error-message';
import { Button, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BlockTitles from 'components/BlockTitles';
import registerInput from 'components/forms/registerInput';
import { ScenarioFormData } from 'components/scenario/mutations';
import { ScenarioIntention, ScenarioStep } from 'components/scenario/types';
import translateInputError from 'i18n/helpers/translateInputError';
import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ScenarioDialogLayout } from '../../ScenarioDialogLayout';
import { CreatedStepsList } from '../scenario-steps';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    title: {
      marginBottom: spacing(),
    },
    inputName: {
      width: 300,
    },
    wrapperSteps: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: spacing(3),
      overflowX: 'hidden',
      overflowY: 'scroll',
      width: '480px',
    },
    error: {
      color: '#F2554E',
      marginTop: spacing(),
    },
  }),
);

interface CreatingScenarioProps {
  createStep: (intention: ScenarioIntention) => void;
  updateStep: (step: ScenarioStep, intention: ScenarioIntention) => void;
  onSubmit: (onDone: () => void) => void;
  onDone: () => void;
  intention: ScenarioIntention;
}

export const CreatingScenario: React.FC<CreatingScenarioProps> = ({
  createStep,
  updateStep,
  onSubmit,
  onDone,
  intention,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, formState, register } = useFormContext<ScenarioFormData>();
  const { fields, replace } = useFieldArray({
    control,
    name: 'steps',
  });

  return (
    <ScenarioDialogLayout
      onSubmit={() => onSubmit(onDone)}
      isSubmitting={formState.isSubmitting}
      isDirty={formState.isDirty}
    >
      <BlockTitles className={classes.title}>
        {t(`components:scenario.dialog.scenario.name.title`)}
      </BlockTitles>

      <TextField
        id="name"
        {...registerInput(register, 'name', { required: true })}
        required
        className={classes.inputName}
        placeholder={t(
          'components:scenario.dialog.scenario.name.inputPlaceholder',
        )}
        variant="outlined"
        autoComplete="off"
        {...translateInputError(t, formState.errors, 'name')}
      />

      <div className={classes.wrapperSteps}>
        <BlockTitles className={classes.title}>
          {t('components:scenario.dialog.scenario.stepsList.title')}
        </BlockTitles>

        <CreatedStepsList
          stepsList={fields}
          updateStep={updateStep}
          intention={intention}
          replace={replace}
        />
      </div>

      <Button
        variant="contained"
        onClick={() => createStep(intention)}
        size="small"
      >
        {t(`components:scenario.dialog.scenario.stepsList.addNewStepButton`)}
      </Button>

      {fields.length === 0 && (
        <ErrorMessage
          errors={formState.errors}
          name="steps"
          render={() => (
            <span className={classes.error}>
              {t('components:scenario.dialog.errors.steps')}
            </span>
          )}
        />
      )}
    </ScenarioDialogLayout>
  );
};
