import { ParticipantConnectType } from '../types';
import { isMobile } from '.';

export const getVideoParams = (
  participantType?: ParticipantConnectType,
):
  | false
  | {
      height: number;
      frameRate: number;
      width: number;
      name: string;
    } => {
  if (participantType === ParticipantConnectType.VIEWER) {
    return false;
  }

  // Mobile Browser in P2P Rooms: Recommended Settings
  if (isMobile) {
    return { name: 'camera', height: 480, frameRate: 24, width: 640 };
  }

  // Desktop Browser in P2P Rooms: Recommended Settings
  return { name: 'camera', height: 720, frameRate: 24, width: 1280 };
};
