import { createStyles, makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Dispatch, SetStateAction } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    header: {
      background: '#FFFFFF',
      height: '64px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderBottom: '1px solid #C9CACB',
    },
  }),
);

interface Props {
  setChatVisible?: Dispatch<SetStateAction<boolean>>;
}

export const ChatHeader: React.FC<Props> = ({ setChatVisible }) => {
  const classes = useStyles();

  return (
    <div className={classes.header} onClick={() => setChatVisible?.((v) => !v)}>
      <ExpandMoreIcon />
    </div>
  );
};
