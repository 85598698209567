import { VideoFilter } from '@videoforce/client';
import { useEffect, useState } from 'react';

export function useFilterStream(
  filter: VideoFilter,
  constraints: MediaStreamConstraints,
): { stream: MediaStream; isPermissionDeniedVideo: boolean } {
  const [stream, setStream] = useState(filter.stream);
  const [isPermissionDeniedVideo, setIsPermissionDeniedVideo] = useState(false);

  useEffect(() => {
    filter
      .setup(constraints)
      .then(() => setStream(filter.stream))
      .catch((reason) => {
        if (reason.message === 'Permission denied') {
          setIsPermissionDeniedVideo(true);
        }
      });
    return () => {
      filter.destroy();
      setStream(filter.stream);
    };
  }, [filter, constraints, setStream]);

  return { stream, isPermissionDeniedVideo };
}
