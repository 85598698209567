import { Client } from '@twilio/conversations';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import logger from './logger';

function createClient(
  token: string,
  operatorName?: string,
  firebasePushNotification?: (client: Client) => void,
): Client {
  let client = new Client(token);

  client.once('stateChanged', (state) => {
    logger.debug('state changed', state);

    if (state === 'initialized') {
      if (operatorName) {
        client.user.updateFriendlyName(operatorName);
      }
      firebasePushNotification?.(client);
    }

    if (state === 'failed') {
      logger.error('conversations client initialization failed');
    }
  });
  return client;
}

interface ConversationsClientContext {
  client: Client | null;
  ensureClient: (newToken?: string) => Client;
  clearClient: () => void;
}

const ConversationsClientCtx = createContext<ConversationsClientContext>({
  client: null,
  ensureClient: () => {
    throw new Error('ConversationsClientContext is not set');
  },
  clearClient: () => {},
});

interface ConversationsClientProviderProps {
  token: string;
  operatorName?: string;
  enabled?: boolean;
  firebasePushNotification?: (client: Client) => void;
  updateConversationsToken?: () => void;
  children?: ReactNode;
}

export const ConversationsClientProvider: React.FC<
  ConversationsClientProviderProps
> = ({
  token,
  operatorName,
  enabled,
  children,
  firebasePushNotification,
  updateConversationsToken,
}) => {
  const [client, setClient] = useState<Client | null>(null);
  const opNameRef = useRef(operatorName);
  opNameRef.current = operatorName;

  useEffect(() => {
    if (enabled) {
      setClient(
        createClient(token, opNameRef.current, firebasePushNotification),
      );
    } else {
      setClient(null);
    }
  }, [enabled, setClient, token, opNameRef, firebasePushNotification]);

  useEffect(() => {
    return () => {
      logger.debug('shutting down conversations client');
      client?.shutdown().then(() => {
        logger.debug('conversations client shutdown complete');
      });
    };
  }, [client]);

  useEffect(() => {
    client?.on('connectionStateChanged', (state) => {
      logger.debug(`connectionStateChanged ${state}`);
    });
  }, [client]);

  const updateTokenClient = useCallback(() => {
    if (updateConversationsToken) {
      updateConversationsToken();
      logger.debug('conversations token has been updated');
    }
  }, [updateConversationsToken]);

  useEffect(() => {
    client?.on('tokenAboutToExpire', updateTokenClient);
    return () => {
      client?.off('tokenAboutToExpire', updateTokenClient);
    };
  }, [client, updateTokenClient]);

  useEffect(() => {
    if (operatorName) {
      client?.user.updateFriendlyName(operatorName);
    }
  }, [operatorName, client]);

  const ensureClient = useCallback(
    (newToken?: string) => {
      if (client) {
        return client;
      }
      const _client = createClient(
        newToken ? newToken : token,
        opNameRef.current,
        firebasePushNotification,
      );
      setClient(_client);
      return _client;
    },
    [client, token, firebasePushNotification],
  );

  const clearClient = useCallback(() => {
    setClient(null);
  }, []);

  return (
    <ConversationsClientCtx.Provider
      value={{ client, ensureClient, clearClient }}
    >
      {children}
    </ConversationsClientCtx.Provider>
  );
};

export const useConversationsClient = () =>
  useContext(ConversationsClientCtx).client;
export const useLazyConversationsClient = () =>
  useContext(ConversationsClientCtx);
