import { setContext } from '@apollo/client/link/context';
import { Auth } from '@aws-amplify/auth';

const authLink = setContext(async (_, { headers }) => {
  let token = '';
  try {
    const session = await Auth.currentSession();
    token = session.getIdToken().getJwtToken();
  } catch {}
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default authLink;
