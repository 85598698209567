/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListVideosQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ListVideosQuery = { __typename?: 'Query', listVideos: { __typename: 'Error', id: string, message: string } | { __typename: 'ForbiddenError', id: string, message: string } | { __typename: 'ListVideosSuccess', count: number, videos: Array<{ __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, duration?: number | null, createdAt: string, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null }> } };


export const ListVideosDocument = gql`
    query listVideos($take: Int, $skip: Int) {
  listVideos(take: $take, skip: $skip) {
    __typename
    ... on IError {
      id
      message
    }
    ... on ListVideosSuccess {
      videos {
        id
        name
        status
        resolution {
          width
          height
        }
        mp4
        jpg
        duration
        createdAt
      }
      count
    }
  }
}
    `;

/**
 * __useListVideosQuery__
 *
 * To run a query within a React component, call `useListVideosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListVideosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListVideosQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useListVideosQuery(baseOptions?: Apollo.QueryHookOptions<ListVideosQuery, ListVideosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListVideosQuery, ListVideosQueryVariables>(ListVideosDocument, options);
      }
export function useListVideosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListVideosQuery, ListVideosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListVideosQuery, ListVideosQueryVariables>(ListVideosDocument, options);
        }
export type ListVideosQueryHookResult = ReturnType<typeof useListVideosQuery>;
export type ListVideosLazyQueryHookResult = ReturnType<typeof useListVideosLazyQuery>;
export type ListVideosQueryResult = Apollo.QueryResult<ListVideosQuery, ListVideosQueryVariables>;