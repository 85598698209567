import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

import { TabsVariant } from './VideoLibraryPage';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
    },
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
  }),
);

interface VideoLibraryTabPanelProps {
  children: ReactNode;
  index: TabsVariant;
  selectedTab: TabsVariant;
}

export const VideoLibraryTabPanel: React.FC<VideoLibraryTabPanelProps> = ({
  index,
  selectedTab,
  children,
}) => {
  const classes = useStyles();

  if (selectedTab !== index) {
    return null;
  }

  return (
    <div
      role="tabpanel"
      id={`library-tabpanel-${index}`}
      aria-labelledby={`library-tab-${index}`}
      className={classes.root}
    >
      {selectedTab === index && <>{children}</>}
    </div>
  );
};
