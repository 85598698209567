import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useParticipant,
  useParticipantTracks,
  useTimer,
} from '@videoforce/client';
import clsx from 'clsx';
import React from 'react';
import { isDesktop, isMobile, isMobileOnly } from 'react-device-detect';

import NotificationAudioDisable from './NotificationAudioDisable';

const useStyles = makeStyles((theme) =>
  createStyles({
    show: {
      visibility: 'visible',
    },
    showMobile: {
      visibility: 'visible',
    },
    hideMobile: {
      visibility: 'hidden',
    },
    texts: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      padding: theme.spacing(2),
      margin: '27px 0 27px 0',
      color: theme.palette.common.white,
    },
    textMobile: {
      padding: `55px 0 0 0`,
      margin: '0',
    },
    timer: {
      position: 'absolute',
      top: '20px',
      left: '15px',
    },
    timerMobile: {
      position: 'static',
      alignSelf: 'center',
    },
    textMobileWithAudio: {
      padding: `30px 0 0 0`,
    },
  }),
);

interface Props {
  hideTimer?: boolean;
  title?: string;
  subtitle?: string;
  durationLimit?: number;
  toggleShowDurationWarning?: () => void;
  isShowHeader: boolean;
}

const CallViewHeader: React.FC<Props> = ({
  hideTimer,
  title,
  subtitle,
  durationLimit,
  toggleShowDurationWarning,
  isShowHeader,
}) => {
  const classes = useStyles();
  const timer = useTimer(
    durationLimit ? durationLimit * 60 - 30 : undefined,
    toggleShowDurationWarning,
  );
  const viewer = useParticipant();
  const { remoteAudioTrack } = useParticipantTracks(viewer);

  return (
    <div
      className={clsx(classes.texts, {
        [classes.textMobile]: isMobileOnly,
        [classes.textMobileWithAudio]: !remoteAudioTrack?.isEnabled,
        // данные css классы определяют - показывать / скрывать данный компонент
        // на декстопе класс show - по умолчанию данный компонент показываем
        // класс showMobile - отображает данный компонент, если мобильный и был сделан тап по экрану (mobileClick из CallView)
        // класс hideMobile - скрывает данный компонент, если мобильный и был сделан тап по экрану (mobileClick из CallView)
        [classes.show]: isDesktop,
        [classes.showMobile]: isMobile && isShowHeader,
        [classes.hideMobile]: isMobile && !isShowHeader,
      })}
    >
      {title && <Typography variant="h6">{title}</Typography>}
      {subtitle && <Typography>{subtitle}</Typography>}
      <div
        className={clsx(classes.timer, {
          [classes.timerMobile]: isMobileOnly,
        })}
      >
        {!hideTimer && <Typography>{timer}</Typography>}
      </div>

      {!remoteAudioTrack?.isEnabled && <NotificationAudioDisable />}
    </div>
  );
};

export default CallViewHeader;
