import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      border: '4px solid #f3f3f3',
      borderTop: '4px solid #3498db',
      borderRadius: '50%',
      width: '20px',
      height: '20px',
      animation: '$spin 1s linear infinite',
    },
    red: {
      borderTop: '4px solid #f53d31',
    },
    gray: {
      borderTop: '4px solid rgba(203, 203, 203, 0.2)',
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  }),
);

export enum SpinnerColorEnum {
  RED = 'red',
  GRAY = 'gray',
}

export interface SpinnerColor {
  color: SpinnerColorEnum;
}

const Spinner: React.FC<SpinnerColor> = ({ color }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes[color]]: color,
      })}
    ></div>
  );
};

export default Spinner;
