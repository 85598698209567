import { Scenario } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { ScenarioList } from '../components';
import { ScenarioDialogLayout } from '../ScenarioDialogLayout';

const useStyles = makeStyles(() =>
  createStyles({
    dialogOverrides: {
      padding: '20px',
      justifyContent: 'flex-start',
      minWidth: '516px',
      maxWidth: '516px',
      height: '260px',
      overflowY: 'scroll',
    },
    listOverrides: {
      justifyContent: 'flex-start',
      paddingLeft: '0',
      width: '100%',
      '& > div:nth-child(even)': {
        margin: 0,
      },
    },
  }),
);

interface SelectingScenarioProps {
  onDone?: () => void;
  selectScenario?: (
    onDone: () => void,
    scenario: Scenario,
  ) => Promise<void> | void;
  onAddNewScenario: (fromSelecting: boolean) => void;
}

export const SelectingScenario: React.FC<SelectingScenarioProps> = ({
  onDone,
  selectScenario,
  onAddNewScenario,
}) => {
  const classes = useStyles();

  const [selectedScenario, setSelectedScenario] = useState<
    Scenario | undefined
  >(undefined);

  const onSelectScenario =
    selectedScenario?.id && !!onDone
      ? () => selectScenario?.(onDone, selectedScenario)
      : undefined;

  return (
    <ScenarioDialogLayout
      className={classes.dialogOverrides}
      onSelectScenario={onSelectScenario}
      onCancel={onDone}
      onAddNewScenario={onAddNewScenario}
    >
      <ScenarioList
        className={classes.listOverrides}
        selectedScenario={selectedScenario}
        setSelectedScenario={setSelectedScenario}
      />
    </ScenarioDialogLayout>
  );
};
