import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import SidebarIcon from './SidebarIcon';

const useStyles = makeStyles(() =>
  createStyles({
    link: {
      outline: 'none',
      '&.disabled': {
        pointerEvents: 'none',
        opacity: '0.5',
      },
      textDecoration: 'none',
    },
  }),
);

interface Props {
  path: string;
  label: string;
  disabled?: boolean;
  className?: string;
  children?: ReactNode;
}

const SidebarItem: React.FC<Props> = (props) => {
  const { path, disabled } = props;

  const classes = useStyles();

  if (path.startsWith('http')) {
    return (
      <a
        href={path}
        target="_blank"
        rel="noreferrer"
        className={clsx(classes.link, { disabled })}
      >
        <SidebarIcon {...props} />
      </a>
    );
  }

  return (
    <Link
      to={disabled ? '#' : path}
      className={clsx(classes.link, { disabled })}
    >
      <SidebarIcon {...props} />
    </Link>
  );
};

export default SidebarItem;
