import { useEffect } from 'react';

import { ActiveCall } from '../../../calls';
import { ParticipantConnectType } from '../..';
import { useTwilioVideo } from '../TwilioVideoProvider';

export const useConnect = (
  activeCall: ActiveCall | null,
  participantConnect: ParticipantConnectType,
) => {
  const { connect } = useTwilioVideo();

  useEffect(() => {
    if (activeCall?.token && !activeCall.ending) {
      connect(activeCall.token, participantConnect);
    }
  }, [activeCall?.token, activeCall?.ending, connect, participantConnect]);
};
