import React, { memo, useEffect, useRef, VideoHTMLAttributes } from 'react';

import { VideoNode } from './types';

const mirrorStyle = {
  transform: 'rotateY(180deg)',
  WebkitTransform: 'rotateY(180deg)',
};

export type VideoProps = Omit<
  VideoHTMLAttributes<HTMLVideoElement>,
  'src' | 'onCanPlay'
> & {
  node?: Pick<VideoNode, 'stream' | 'video'>;
  handleCanPlay?: (value: boolean) => void;
  mirror?: boolean;
};

export const Video = memo<VideoProps>((props) => {
  const { node, muted, mirror, handleCanPlay, ...videoProps } = props;
  const stream = node?.stream;
  const video = node?.video;
  const ref = useRef<HTMLVideoElement>(null);

  // muted property is broken in react: https://github.com/facebook/react/issues/10389
  // workaround below:
  useEffect(() => {
    if (ref.current) {
      if (muted) {
        ref.current.setAttribute('muted', '');
        ref.current.muted = true; // some mobile browser require this to be explicit
      } else {
        ref.current.removeAttribute('muted');
      }
    }
  }, [ref, muted]);

  // react team is lazy and didn't implement srcObject prop
  // https://github.com/facebook/react/issues/11163
  useEffect(() => {
    if (!ref.current) {
      return;
    }
    if (stream) {
      ref.current.oncanplay = () => {
        handleCanPlay?.(true);
      };
      ref.current.srcObject = stream;
    } else if (video?.mp4?.startsWith('blob')) {
      ref.current.srcObject = null;
      ref.current.oncanplay = null;
      ref.current.src = video.mp4;
      handleCanPlay?.(false);
    } else {
      ref.current.srcObject = null;
      ref.current.oncanplay = null;
      handleCanPlay?.(false);
    }
  }, [ref, stream, video, handleCanPlay]);

  return (
    <video
      ref={ref}
      autoPlay
      loop
      playsInline
      disablePictureInPicture
      style={mirror ? mirrorStyle : undefined}
      {...videoProps}
    >
      {!stream && !video?.mp4?.startsWith('blob') && (
        <source
          src={video?.mp4}
          onCanPlay={() => handleCanPlay?.(false)}
          type="video/mp4"
        />
      )}
    </video>
  );
});

Video.displayName = 'Video';
