/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import { LogLevel, LogSettings } from './types';

export class VideoforceLogger {
  public static STORAGE_KEY = '@videoforce/logz';
  private static root: VideoforceLogger;

  readonly #name?: string;
  readonly #children = new Map<string, VideoforceLogger>();
  #level: LogLevel;
  #parent?: VideoforceLogger;

  constructor(name?: string, level = LogLevel.INFO) {
    this.#name = name;
    this.#level = level;
    if (!VideoforceLogger.root) {
      VideoforceLogger.root = this;
      this.#loadSettings();
    }
  }

  /**
   * Creates child of current logger with given name and level.
   * If child with this name already exists, then its level is not changed
   * @param name Logger name
   * @param level optional, if not provided, the parent's level is used
   * @returns
   */
  public child = (name: string, level?: LogLevel): VideoforceLogger => {
    const logger =
      this.#children.get(name) ??
      new VideoforceLogger(name, level ?? this.#level);
    logger.#parent = this;
    this.#children.set(name, logger);
    this.#saveSettings();
    return logger;
  };

  /**
   * Sets logger level.
   * If name is provided, the level will be set on named logged and all its descendants
   * Otherwise, it's set on all loggers in tree
   * @param level
   * @param name
   */
  public setLevel = (level: LogLevel | string, name?: string): void => {
    if (this.#parent) {
      throw new Error('use global logger to set log level');
    }
    this.#setLevel(level, name);
    this.#saveSettings();
  };

  #setLevel = (level: LogLevel | string, name?: string): void => {
    let lvl = LogLevel.ERROR;
    if (typeof level === 'string') {
      if (!Object.keys(LogLevel).includes(level.toUpperCase())) {
        console.error(
          this.#getPrefix(),
          'attempt to set invalid log level',
          level,
        );
        return;
      }
      lvl = LogLevel[level.toUpperCase() as any] as any;
    } else {
      lvl = level;
    }

    if (name) {
      if (this.#name === name) {
        this.#level = lvl;
        this.#children.forEach((chld) => chld.#setLevel(lvl));
      } else {
        this.#children.forEach((chld) => chld.#setLevel(lvl, name));
      }
    } else {
      this.#level = lvl;
      this.#children.forEach((chld) => chld.#setLevel(lvl, name));
    }
  };

  /**
   * Returns log level of logger or one of it's descendants
   * @param name
   * @returns
   */
  public getLevel = (name?: string): LogLevel | undefined => {
    if (!name || this.#name === name) {
      return this.#level;
    }
    for (const [_, chld] of this.#children) {
      const lvl = chld.getLevel(name);
      if (lvl) {
        return lvl;
      }
    }
    return undefined;
  };

  /**
   * Prefixed console.debug
   * @param args
   */
  public debug = (...args: any[]) => {
    if (this.#level <= LogLevel.DEBUG) {
      console.debug(this.#getPrefix(), ...args);
    }
  };

  /**
   * Prefixed console.log
   * @param args
   */
  public log = (...args: any[]) => {
    if (this.#level <= LogLevel.DEBUG) {
      console.log(this.#getPrefix(), ...args);
    }
  };

  /**
   * Prefixed console.info
   * @param args
   */
  public info = (...args: any[]) => {
    if (this.#level <= LogLevel.INFO) {
      console.info(this.#getPrefix(), ...args);
    }
  };

  /**
   * Prefixed console.warn
   * @param args
   */
  public warn = (...args: any[]) => {
    if (this.#level <= LogLevel.WARN) {
      console.warn(this.#getPrefix(), ...args);
    }
  };

  /**
   * Prefixed console.error
   * @param args
   */
  public error = (...args: any[]) => {
    if (this.#level <= LogLevel.ERROR) {
      console.error(this.#getPrefix(), ...args);
    }
  };

  #getPrefix = (): string => {
    const parent = this.#parent ? this.#parent.#getPrefix() : undefined;
    const prefix = this.#name ? `[${this.#name}]` : '';
    return [parent, prefix].filter((p) => !!p).join('');
  };

  #getSettings = (): LogSettings => {
    const result: LogSettings = { level: this.#level, children: [] };
    this.#children.forEach((child, name) => {
      result.children.push([name, child.#getSettings()]);
    });
    return result;
  };

  #setSettings = (settings: LogSettings): void => {
    this.#level = settings.level;
    settings.children.forEach(([name, childSettings]) => {
      const child =
        this.#children.get(name) ?? this.child(name, childSettings.level);

      child.#level = childSettings.level;
      this.#children.set(name, child);
      child.#setSettings(childSettings);
    });
  };

  #saveSettings = (): void => {
    // Traverse back to root and save settings there
    if (this.#parent) {
      this.#parent.#saveSettings();
      return;
    }
    const settings = this.#getSettings();
    if (typeof localStorage !== 'undefined') {
      localStorage?.setItem(
        VideoforceLogger.STORAGE_KEY,
        JSON.stringify(settings),
      );
    }
  };

  #loadSettings = (): void => {
    if (this.#parent) {
      throw new Error('only root logger can load settings');
    }
    if (typeof localStorage !== 'undefined') {
      const val = localStorage?.getItem(VideoforceLogger.STORAGE_KEY);
      if (val) {
        try {
          const settings = JSON.parse(val) as LogSettings;
          this.#setSettings(settings);
        } catch {
          // ignore
        }
      }
    }
  };
}
