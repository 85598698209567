import { VideoFilter } from '@videoforce/client';

import { FilterProps } from './types';

export default abstract class BaseFilter implements VideoFilter {
  protected readonly _videoInputId: string;
  protected readonly _canvasId: string;

  protected _videoInputEl!: HTMLVideoElement;
  protected _canvas!: HTMLCanvasElement;
  protected _ctx!: CanvasRenderingContext2D;
  protected _outputStream = new MediaStream();
  protected _inputStream!: MediaStream;
  protected _w!: number;
  protected _h!: number;

  private _enabled = false;
  private _active = false;

  constructor(props: FilterProps) {
    this._videoInputId = props.videoInputId || 'filter-input';
    this._canvasId = props.canvasId || 'filter-canvas';
    this._enabled = props.enabled || false;
    this.setup.bind(this);
    this.destroy.bind(this);
    this.tick.bind(this);
    this.loop.bind(this);
  }

  public async setup(source: MediaStreamConstraints | MediaStream) {
    // console.log('setup filter');
    let videoInputEl: HTMLVideoElement = document.getElementById(
      this._videoInputId,
    ) as any;
    if (!videoInputEl) {
      videoInputEl = document.createElement('video');
      document.body.appendChild(videoInputEl);
      videoInputEl.id = this._videoInputId;
    }
    videoInputEl.autoplay = true;
    videoInputEl.loop = true;
    videoInputEl.muted = true;
    videoInputEl.setAttribute('style', 'display: none');
    this._videoInputEl = videoInputEl;
    if ('id' in source) {
      this._inputStream = source;
    } else {
      this._inputStream = await navigator.mediaDevices.getUserMedia(source);
    }
    const { width, height } = this._inputStream
      .getVideoTracks()[0]
      .getSettings();

    this._videoInputEl.width = width!;
    this._videoInputEl.height = height!;
    this._videoInputEl.style.width = `${width}px`;
    this._videoInputEl.style.height = `${height}px`;
    this._w = width!;
    this._h = height!;
    this._videoInputEl.srcObject = this._inputStream;
    this._videoInputEl.play();

    let canvas: HTMLCanvasElement = document.getElementById(
      this._canvasId,
    ) as any;
    if (!canvas) {
      canvas = document.createElement('canvas');
      document.body.appendChild(canvas);
      canvas.id = this._canvasId;
    }
    canvas.width = width!;
    canvas.height = height!;
    canvas.setAttribute('style', 'display: none');
    this._canvas = canvas;

    this._ctx = canvas.getContext('2d')!;
    this._outputStream = new MediaStream([
      ...(canvas as any).captureStream().getVideoTracks(),
      ...this._inputStream.getAudioTracks(),
    ]);
    if (this._enabled) {
      await this.setupEngine();
      // await new Promise((resolve) => {
      //   videoInputEl.addEventListener('loadeddata', resolve);
      // });
    }
    this._active = true;
    // console.log('filter setup complete');
    this.loop();
  }

  public destroy() {
    // console.log('destroy filter');
    this._outputStream?.getTracks().forEach((t) => {
      t.stop();
    });
    this._inputStream?.getTracks().forEach((t) => {
      t.stop();
    });
    this._active = false;
  }

  protected abstract setupEngine(): Promise<void>;
  protected abstract tick(): Promise<void>;

  protected async loop() {
    if (!this._active) {
      return;
    }
    if (this._enabled) {
      try {
        await this.tick();
      } catch (e) {
        if (!e.message.includes('loadeddata')) {
          console.error(e.message);
        }
      }
    } else {
      this._ctx.drawImage(this._videoInputEl, 0, 0);
    }
    requestAnimationFrame(() => this.loop());
  }

  public get enabled() {
    return this._enabled;
  }

  public async setEnabled(value: boolean): Promise<boolean> {
    if (this._enabled === value) {
      return value;
    }
    if (value) {
      await this.setupEngine();
    }
    this._enabled = value;
    return value;
  }

  public get stream() {
    return this._outputStream;
  }
}
