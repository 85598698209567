/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateUnavailableForCallsMutationVariables = Types.Exact<{
  userId?: Types.InputMaybe<Types.Scalars['ID']>;
  isUnavailable: Types.Scalars['Boolean'];
}>;


export type UpdateUnavailableForCallsMutation = { __typename?: 'Mutation', updateProfile: { __typename?: 'Error', id: string, message: string } | { __typename?: 'ForbiddenError', id: string, message: string, reason: Types.ForbiddenReason } | { __typename?: 'NotFoundError', id: string, message: string } | { __typename?: 'User', id: string, isUnavailable: boolean } | { __typename?: 'ValidationError', id: string, message: string, validationErrors?: { [key: string]: any } | null } };


export const UpdateUnavailableForCallsDocument = gql`
    mutation updateUnavailableForCalls($userId: ID, $isUnavailable: Boolean!) {
  updateProfile(userId: $userId, settings: {isUnavailable: $isUnavailable}) {
    ... on IError {
      id
      message
    }
    ... on ValidationError {
      validationErrors
    }
    ... on ForbiddenError {
      reason
    }
    ... on User {
      id
      isUnavailable
    }
  }
}
    `;
export type UpdateUnavailableForCallsMutationFn = Apollo.MutationFunction<UpdateUnavailableForCallsMutation, UpdateUnavailableForCallsMutationVariables>;

/**
 * __useUpdateUnavailableForCallsMutation__
 *
 * To run a mutation, you first call `useUpdateUnavailableForCallsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnavailableForCallsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUnavailableForCallsMutation, { data, loading, error }] = useUpdateUnavailableForCallsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      isUnavailable: // value for 'isUnavailable'
 *   },
 * });
 */
export function useUpdateUnavailableForCallsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUnavailableForCallsMutation, UpdateUnavailableForCallsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUnavailableForCallsMutation, UpdateUnavailableForCallsMutationVariables>(UpdateUnavailableForCallsDocument, options);
      }
export type UpdateUnavailableForCallsMutationHookResult = ReturnType<typeof useUpdateUnavailableForCallsMutation>;
export type UpdateUnavailableForCallsMutationResult = Apollo.MutationResult<UpdateUnavailableForCallsMutation>;
export type UpdateUnavailableForCallsMutationOptions = Apollo.BaseMutationOptions<UpdateUnavailableForCallsMutation, UpdateUnavailableForCallsMutationVariables>;