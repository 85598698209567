// import './wdyr';

import '@videoforce/logz';
import './i18n/yupLocale';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { loadConfig } from 'config/env';
import configureFirebase from 'config/firebase';
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import configureAmplify from './config/amplify';
import * as sw from './sw';
import { useServiceWorkerState } from './sw';
import setWindowHeightCssVar from './utils/setWindowHeightCssVar';

sw.addApocalypseHandler();

if (process.env.REACT_APP_SENTRY_ADMIN_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_ADMIN_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_VERSION,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 0.0,
  });
}

setWindowHeightCssVar();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
sw.register({
  onUpdate: (registration) => {
    useServiceWorkerState.setState({
      isUpdateAvailable: true,
      waitingServiceWorker: registration.waiting,
    });
  },
  onWaiting: (registration) => {
    useServiceWorkerState.setState({
      isUpdateAvailable: true,
      waitingServiceWorker: registration.waiting,
    });
  },
});

loadConfig()
  .then(() => {
    configureAmplify();
    configureFirebase();
    const container = document.getElementById('root')!;
    createRoot(container).render(<App />);
  })
  .catch((e) => console.error('Failed to load config', e));
