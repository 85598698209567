import {
  Conversation,
  ConversationType,
  ConversationUpdateReason,
  identifyConversationType,
  Message,
  useConversations,
  useConversationsClient,
} from '@videoforce/client';
import { useAuth } from 'auth';
import { useCallback, useEffect, useState } from 'react';

export interface unreadMessagesObject {
  [U: string]: {
    count: number;
    type: ConversationType | null;
  };
}

export const useUnreadMessages = (): unreadMessagesObject => {
  const auth = useAuth();
  const myId = auth?.claims.id;
  const client = useConversationsClient();
  const { conversations } = useConversations();

  const enableMessageSoundNotification =
    auth?.profile.enableMessageSoundNotification ?? false;

  const [unreadMessages, setUnreadMessages] = useState<unreadMessagesObject>(
    {},
  );

  const updateUnreadMessageCount = useCallback((conversation: Conversation) => {
    const lastIndexReadMessage = Number(conversation.lastReadMessageIndex);
    conversation.getMessagesCount().then((countMessages) => {
      if (countMessages > lastIndexReadMessage) {
        setUnreadMessages((obj) => ({
          ...obj,
          [conversation.sid]: {
            count: countMessages - lastIndexReadMessage,
            type: identifyConversationType(conversation),
          },
        }));
      } else {
        setUnreadMessages((obj) => ({
          ...obj,
          [conversation.sid]: {
            count: 0,
            type: identifyConversationType(conversation),
          },
        }));
      }
    });
  }, []);

  const updateConversation = useCallback(
    (c: {
      conversation: Conversation;
      updateReasons: ConversationUpdateReason[];
    }) => {
      c.conversation.getMessages().then((message) => {
        const lastMessageAuthor =
          message.items[message.items.length - 1]?.author;
        if (lastMessageAuthor !== myId) {
          updateUnreadMessageCount(c.conversation);
        }
      });
    },
    [myId, updateUnreadMessageCount],
  );

  const playNotification = useCallback(
    (message: Message) => {
      if (![myId, 'system'].includes(message.author ?? '') ) {
        const audio = new Audio(
          process.env.PUBLIC_URL + 'notification_sound.mp3',
        );
        audio.play();
      }
    },
    [myId],
  );

  // Запускаем проверку сообщений updateUnreadMessageCount при формировании conversations
  useEffect(() => {
    conversations.forEach((c) => {
      updateUnreadMessageCount(c);
    });
  }, [conversations, updateUnreadMessageCount]);

  // Запускаем проверку сообщений updateUnreadMessageCount при обновлении каждой conversation
  useEffect(() => {
    client?.on('conversationUpdated', updateConversation);
    return () => {
      client?.off('conversationUpdated', updateConversation);
    };
  }, [client, updateConversation]);

  // Воспроизводить звук уведомления при получении нового соообщения
  useEffect(() => {
    if (enableMessageSoundNotification) {
      client?.on('messageAdded', (message) => playNotification(message));
    }
  }, [client, enableMessageSoundNotification, playNotification]);

  return unreadMessages;
};
