class UploadCache {
  private readonly _cache = new Map<string, string>();

  public add(location: string, recording: Blob) {
    const prev = this._cache.get(location);

    const value = URL.createObjectURL(recording);
    this._cache.set(location, value);
    if (prev && prev !== value) {
      URL.revokeObjectURL(prev);
    }
  }

  public get(location: string) {
    return this._cache.get(location);
  }
}

export default new UploadCache();
