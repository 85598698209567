import React from 'react';

interface Props {
  className?: string;
}

const IconScreenShareOn: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C1.79086 4 0 5.79086 0 8V23C0 25.2091 1.79086 27 4 27H26C28.2091 27 30 25.2091 30 23V8C30 5.79086 28.2091 4 26 4H4ZM9.38366 13.3553L14.1268 8.61217C14.6097 8.12928 15.3926 8.12928 15.8755 8.61217L20.6186 13.3553C21.1015 13.8382 21.1015 14.6211 20.6186 15.104C20.1357 15.5869 19.3528 15.5869 18.8699 15.104L16.2377 12.4718L16.2377 21.7635C16.2377 22.4464 15.6841 23 15.0011 23C14.3182 23 13.7646 22.4464 13.7646 21.7635L13.7646 12.4718L11.1324 15.104C10.6495 15.5869 9.86656 15.5869 9.38366 15.104C8.90076 14.6211 8.90076 13.8382 9.38366 13.3553Z"
        fill="white"
      />
    </svg>
  );
};

export default IconScreenShareOn;
