import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Message } from '@videoforce/client';
import { format } from 'date-fns';
import React from 'react';
import { Anchorme } from 'react-anchorme';
import useToggle from 'react-use/lib/useToggle';

interface StyleProps {
  mine?: boolean;
  isHover: boolean;
}

interface SimpleMessageProps {
  myId?: string;
  message: Message;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapper: ({ mine }: StyleProps) => ({
      display: 'flex',
      justifyContent: 'flex-start',
      textAlign: mine ? 'right' : 'left',
      width: '100%',
      wordWrap: 'break-word',
      flexDirection: mine ? 'row-reverse' : 'row',
      zIndex: 2,
    }),
    containerUserMessage: ({ mine }: StyleProps) => ({
      maxWidth: '400px',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: mine ? '#3198E3' : '#D8DEE7;',
      color: mine ? '#FFFFFF' : '#1F1F1F',
      borderRadius: theme.shape.borderRadius,
      wordWrap: 'break-word',
      textAlign: 'left',
    }),
    date: ({ mine, isHover }: StyleProps) => ({
      zIndex: 1,
      fontSize: '12px',
      color: mine ? '#3198E3' : '#666666',
      marginTop: '8px',
      padding: '2px 4px',
      background: mine ? 'rgba(49, 152, 227, 0.1)' : 'rgba(216, 222, 231, 0.5)',
      borderRadius: '4px',
      height: 'fit-content',
      visibility: isHover ? 'visible' : 'hidden',
    }),
  }),
);

export const SimpleMessage: React.FC<SimpleMessageProps> = ({
  message,
  myId,
}) => {
  const [isHoverRoot, toggleHoverRoot] = useToggle(false);
  const classes = useStyles({
    mine: message.author === myId,
    isHover: isHoverRoot,
  });

  return (
    <div
      className={classes.wrapper}
      onMouseEnter={toggleHoverRoot}
      onMouseLeave={toggleHoverRoot}
    >
      <div className={classes.containerUserMessage}>
        <Typography variant="body1">
          <Anchorme target="_blank">{message.body!}</Anchorme>
        </Typography>
      </div>
      <div className={classes.date}>
        {message.dateCreated && format(message.dateCreated, 'dd.MM.yy в HH:mm:ss')}
      </div>
    </div>
  );
};
