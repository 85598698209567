import { createStyles, makeStyles, Typography } from '@material-ui/core';
import { Message } from '@videoforce/client';
import { getSystemMessage } from 'components/getSystemMessage';
import format from 'date-fns/format';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: () => ({
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      width: '100%',
      wordWrap: 'break-word',
    }),
    containerSystemMessage: () => ({
      display: 'flex',
      alignItems: 'center',
      maxWidth: '300px',
      margin: theme.spacing(1),
      padding: theme.spacing(1),
      backgroundColor: 'rgba(255, 255, 255, 0.6)',
      color: '#666666',
      borderRadius: theme.shape.borderRadius,
      wordWrap: 'break-word',
    }),
    wrapper: {
      display: 'flex',
    },
    date: {
      marginLeft: '6px',
      fontSize: '16px',
      color: '#000000',
      opacity: '0.3',
    },
  }),
);

interface SystemMessageProps {
  message: Message;
  myId?: string;
}

const SystemMessage: React.FC<SystemMessageProps> = ({ message, myId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const attributes = message.attributes as any;
  const isMySystemMessage =
    attributes.recipients.includes(myId) ||
    attributes.payload.type === 'call_declined';

  const systemMessage = getSystemMessage(message);

  if (!isMySystemMessage) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.containerSystemMessage}>
        <Typography variant="body1" className={classes.wrapper}>
          {t(systemMessage)}
        </Typography>
        <div className={classes.date}>
          {format(message.dateCreated!, 'dd.MM.yy в HH:mm:ss')}
        </div>
      </div>
    </div>
  );
};

export default SystemMessage;
