import { useReducer } from 'react';

import { ScenarioIntention, ScenarioStep } from './types';

export type StateScenarioDialog =
  | { state: 'create_scenario'; fromSelecting?: boolean }
  | { state: 'create_step'; intention: ScenarioIntention }
  | { state: 'update_scenario' }
  | { state: 'update_step'; step: ScenarioStep; intention: ScenarioIntention }
  | { state: 'selecting_scenario' };

type Action =
  | { type: 'init' }
  | { type: 'creating_scenario'; fromSelecting?: boolean }
  | { type: 'updating_scenario' }
  | { type: 'creating_step'; intention: ScenarioIntention }
  | { type: 'updating_step'; step: ScenarioStep; intention: ScenarioIntention }
  | { type: 'created_step' }
  | { type: 'updated_step' }
  | { type: 'select_scenario' };

const reducer = (
  state: StateScenarioDialog,
  action: Action,
): StateScenarioDialog => {
  switch (action.type) {
    case 'init': {
      return { state: 'create_scenario' };
    }

    case 'creating_scenario': {
      return { state: 'create_scenario', fromSelecting: action?.fromSelecting };
    }

    case 'updating_scenario': {
      return { state: 'update_scenario' };
    }

    case 'creating_step': {
      return { state: 'create_step', intention: action.intention };
    }

    case 'updating_step': {
      return {
        state: 'update_step',
        step: action.step,
        intention: action.intention,
      };
    }

    case 'created_step': {
      return { state: 'create_scenario' };
    }

    case 'updated_step': {
      return { state: 'update_scenario' };
    }

    case 'select_scenario': {
      return { state: 'selecting_scenario' };
    }
    default:
      return state;
  }
};

export default (initialState: StateScenarioDialog) =>
  useReducer(reducer, initialState);
