import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import getClaimsFromSession from './getClaimsFromSession';
import preSignOut from './preSignOut';

export default async function switchAccount(accountId: string) {
  const user: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.userSession(user);

  await preSignOut();

  // preTokenGeneration lambda doesn't accept preferred account id in clientMetadata
  // the workaround is to first update preferredAccountId user attribute in cognito
  // and only then refresh token
  await new Promise((resolve, reject) => {
    user.updateAttributes(
      [
        {
          Name: 'custom:preferredAccountId',
          Value: accountId,
        },
      ],
      (err, result) => {
        if (err) {
          reject(err);
        } else {
          resolve(result);
        }
      },
    );
  });
  user.refreshSession(currentSession.getRefreshToken(), (err, session) => {
    if (err) {
      console.error('failed to switch account', err);
    } else {
      Hub.dispatch('auth', {
        event: 'switchAccount',
        data: { claims: getClaimsFromSession(session) },
      });
    }
  });
}
