import { VideoFilter } from '@videoforce/client';

import BaseSubtractor from './BaseFilter';

// let cvResolver: any;
// const initPromise = new Promise((resolve) => {
//   cvResolver = resolve;
// });

// cv.onRuntimeInitialized = () => {
//   cvResolver();
// };

export class OpenCVBackgroundSubtractor
  extends BaseSubtractor
  implements VideoFilter
{
  private _sub!: cv.BackgroundSubtractorMOG2;
  private _srcMat!: cv.Mat;
  private _srcMask!: cv.Mat;
  private _capture!: cv.VideoCapture;
  private _learningRate = 0.0; // 0.0 === processing, 0.5 = learning

  public async setupEngine() {
    // console.log('setting up cv');
    // await initPromise;
    this._sub = new cv.BackgroundSubtractorMOG2(500, 16, false);
    this._srcMat = new cv.Mat(this._h, this._w, cv.CV_8UC4);
    this._srcMask = new cv.Mat(this._h, this._w, cv.CV_8UC1);
    this._capture = new cv.VideoCapture(this._videoInputEl);
    // console.log('cv setup complete', this._w, this._h);
  }

  protected tick() {
    this._capture.read(this._srcMat);
    this._sub.apply(this._srcMat, this._srcMask, this._learningRate);
    cv.bitwise_not(this._srcMask, this._srcMask);

    if (this._learningRate === 0) {
      let M = cv.Mat.ones(5, 5, cv.CV_8U);
      let anchor = new cv.Point(-1, -1);
      cv.medianBlur(this._srcMask, this._srcMask, 7);
      cv.erode(
        this._srcMask,
        this._srcMask,
        M,
        anchor,
        1,
        // cv.BORDER_CONSTANT,
        // cv.morphologyDefaultBorderValue(),
      );
      cv.dilate(
        this._srcMask,
        this._srcMask,
        M,
        anchor,
        1,
        // cv.BORDER_CONSTANT,
        // cv.morphologyDefaultBorderValue(),
      );
    }
    let dst = new cv.Mat(this._h, this._w, cv.CV_8UC4);
    dst.copyTo(this._srcMat, this._srcMask);
    cv.imshow(this._canvas, this._srcMat);
    dst.delete();
    return Promise.resolve();
  }
}
