import { CircularProgress } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React, { FC } from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
);

interface ProgressProps {
  error?: string | null;
  label: string;
}

const Progress: FC<ProgressProps> = ({ error, label }) => {
  const classes = useStyles();

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <div className={classes.root}>
      <CircularProgress />
      <p>{label}</p>
    </div>
  );
};

export default Progress;
