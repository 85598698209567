import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export default function useSnackbarSuccess() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  return useCallback(
    (message: string) => {
      enqueueSnackbar(t(message), { variant: 'success' });
    },
    [enqueueSnackbar, t],
  );
}
