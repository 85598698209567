import React from 'react';

interface Props {
  className?: string;
}

const IconFullScreenOn: React.FC<Props> = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.75 3.75L21.625 6.625L18.0125 10.2125L19.7875 11.9875L23.375 8.375L26.25 11.25V3.75H18.75ZM3.75 11.25L6.625 8.375L10.2125 11.9875L11.9875 10.2125L8.375 6.625L11.25 3.75H3.75V11.25ZM11.25 26.25L8.375 23.375L11.9875 19.7875L10.2125 18.0125L6.625 21.625L3.75 18.75V26.25H11.25ZM26.25 18.75L23.375 21.625L19.7875 18.0125L18.0125 19.7875L21.625 23.375L18.75 26.25H26.25V18.75Z"
        fill="white"
      />
    </svg>
  );
};

export default IconFullScreenOn;
