import { useCallback } from 'react';

import { useTwilioVideo } from '../twilioVideoProvider';
import { useIsTrackEnabled } from './useIsTrackEnabled';

export function useLocalAudioToggle() {
  const { audioTrack } = useTwilioVideo();
  const isEnabled = useIsTrackEnabled(audioTrack);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack) {
      audioTrack.isEnabled ? audioTrack.disable() : audioTrack.enable();
    }
  }, [audioTrack]);

  return [isEnabled, toggleAudioEnabled] as const;
}
