import { createStyles, makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  bindHover,
  bindPopover,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import Popover from 'material-ui-popup-state/HoverPopover';
import React, { ReactNode } from 'react';

interface Props {
  className?: string;
  children?: ReactNode;
}

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    paper: {
      boxShadow: '0 0 5px 2px #DBDBDB',
      borderRadius: '10px',
    },
    popover: {
      border: '1px solid #DBDBDB',
      borderRadius: '10px',
      width: '320px',
      padding: '18px 20px 18px 30px',
      background: palette.background.default,
    },
    iconWrap: {
      padding: '0 5px',
      color: palette.grey[200],
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  }),
);

const PopoverInfo: React.FC<Props> = ({ className, children }) => {
  const classes = useStyles();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'popoverInfo',
  });
  return (
    <div className={className}>
      <div className={classes.iconWrap} {...bindHover(popupState)}>
        <InfoOutlinedIcon />
      </div>
      <Popover
        {...bindPopover(popupState)}
        classes={{
          paper: classes.paper,
        }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={classes.popover}>{children}</div>
      </Popover>
    </div>
  );
};

export default PopoverInfo;
