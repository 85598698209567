import { CognitoUser } from '@aws-amplify/auth';

// Save the fact that this user came with promocode;
// This is hacky solution to vary intro_tour for Jivo users
export function setFromPromo(user: CognitoUser): void {
  const key = `user:${user.getUsername()}`;
  const localData = JSON.parse(localStorage.getItem(key) ?? '{}');
  localStorage.setItem(key, JSON.stringify({ ...localData, fromPromo: true }));
}

export function isFromPromo(userId?: string | null): boolean {
  if (!userId) {
    return false;
  }
  const key = `user:${userId}`;
  const localData = JSON.parse(localStorage.getItem(key) ?? '{}');
  return !!localData.fromPromo;
}
