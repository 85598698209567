import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import ReactMarkdown from 'markdown-to-jsx';
import React, { FC, ReactElement } from 'react';

const options = {
  overrides: {
    p: { component: Typography, props: { paragraph: true } },
    span: { component: Typography, props: { paragraph: true } },
    a: {
      component: Link,
      props: { target: '_blank', rel: 'noopener noreferrer' },
    },
  },
};

interface Props {
  children: string;
}

export const Markdown: FC<Props> = ({ children }): ReactElement => {
  return <ReactMarkdown options={options}>{children}</ReactMarkdown>;
};
