import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';

import { useEndIntroTour, useIntroStepReady } from './IntroTourProvider';
import { RichStep } from './tour';

const useStyles = makeStyles(() =>
  createStyles({
    skip: {
      marginLeft: 'auto',
    },
    card: {
      maxWidth: 400,
    },
  }),
);

const JoyrideTooltip: React.FC<TooltipRenderProps> = ({
  index,
  step,
  tooltipProps,
  primaryProps,
  skipProps,
  isLastStep,
}) => {
  const { navHash } = step as RichStep;
  const { t } = useTranslation();
  const classes = useStyles();
  const endIntroTour = useEndIntroTour();
  const nextStep = useIntroStepReady(index + 1, false, true);
  const location = useLocation();
  const navigate = useNavigate();
  const onNext = useCallback(() => {
    if (navHash) {
      navigate(location.pathname + `#${navHash}`, { replace: true });
    }
    nextStep();
  }, [nextStep, navHash, navigate, location]);

  return (
    <Card {...tooltipProps} className={classes.card}>
      <CardContent>
        {!!step.title && (
          <Typography gutterBottom variant="h3">
            {t(step.title as string)}
          </Typography>
        )}

        <Typography variant="body2">{t(step.content as string)}</Typography>
      </CardContent>

      <CardActions disableSpacing>
        {(step as RichStep).nextButton && (
          <Button
            color="primary"
            variant="contained"
            {...primaryProps}
            onClick={onNext}
          >
            {t('components:tours.commons.nextButton')}
          </Button>
        )}

        {isLastStep ? (
          <Button
            color="primary"
            variant="contained"
            {...primaryProps}
            onClick={() => endIntroTour()}
          >
            {t('components:tours.commons.lastStep')}
          </Button>
        ) : (
          <Button
            variant="text"
            className={classes.skip}
            {...skipProps}
            onClick={() => endIntroTour(true)}
          >
            {t('components:tours.commons.skipButton')}
          </Button>
        )}
      </CardActions>
    </Card>
  );
};

export default JoyrideTooltip;
