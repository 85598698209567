import { ScenarioStep } from '../types';

export const convertSteps = (steps: ScenarioStep[]) => {
  return steps.map((step) => {
    return {
      name: step.name,
      order: step.order,
      type: step.type,
      videoId: step.video?.id,
      // eslint-disable-next-line unused-imports/no-unused-vars
      buttons: step.buttons.map(({ id, ...button }) => button),
    };
  });
};
