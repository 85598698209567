import { createStyles, makeStyles } from '@material-ui/core';
import ConfirmationDialog from 'components/ConfirmationDialog';
import React from 'react';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '420px',
      borderRadius: '16px',
    },
  }),
);

interface DeclineDialogProps {
  title: 'string';
  isOpened: boolean;
  onConfirm: () => void;
  onCancel: () => void;
  confirmButton?: string;
  cancelButton?: string;
}

export const DeclineDialog: React.FC<DeclineDialogProps> = (props) => {
  const { title, isOpened, onConfirm, onCancel, confirmButton, cancelButton } =
    props;
  const classes = useStyles();

  return (
    <ConfirmationDialog
      className={classes.root}
      open={isOpened}
      title={title}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButton={confirmButton}
      cancelButton={cancelButton}
    />
  );
};
