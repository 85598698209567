import React from 'react';

interface Props {
  className?: string;
}

const IconScreenShareOff: React.FC<Props> = () => {
  return (
    <svg
      width="35"
      height="32"
      viewBox="-5 0 40 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4C1.79086 4 0 5.79086 0 8V23C0 25.2091 1.79086 27 4 27H26C28.2091 27 30 25.2091 30 23V8C30 5.79086 28.2091 4 26 4H4ZM9.38366 13.3553L14.1268 8.61217C14.6097 8.12928 15.3926 8.12928 15.8755 8.61217L20.6186 13.3553C21.1015 13.8382 21.1015 14.6211 20.6186 15.104C20.1357 15.5869 19.3528 15.5869 18.8699 15.104L16.2377 12.4718L16.2377 21.7635C16.2377 22.4464 15.6841 23 15.0011 23C14.3182 23 13.7646 22.4464 13.7646 21.7635L13.7646 12.4718L11.1324 15.104C10.6495 15.5869 9.86656 15.5869 9.38366 15.104C8.90076 14.6211 8.90076 13.8382 9.38366 13.3553Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 32C31.4183 32 35 28.4183 35 24C35 19.5817 31.4183 16 27 16C22.5817 16 19 19.5817 19 24C19 28.4183 22.5817 32 27 32ZM24.109 21.546C24 21.7599 24 22.0399 24 22.6V25.4C24 25.9601 24 26.2401 24.109 26.454C24.2049 26.6422 24.3578 26.7951 24.546 26.891C24.7599 27 25.0399 27 25.6 27H28.4C28.9601 27 29.2401 27 29.454 26.891C29.6422 26.7951 29.7951 26.6422 29.891 26.454C30 26.2401 30 25.9601 30 25.4V22.6C30 22.0399 30 21.7599 29.891 21.546C29.7951 21.3578 29.6422 21.2049 29.454 21.109C29.2401 21 28.9601 21 28.4 21H25.6C25.0399 21 24.7599 21 24.546 21.109C24.3578 21.2049 24.2049 21.3578 24.109 21.546Z"
        fill="#FF5A44"
      />
      <rect x="24" y="21" width="6" height="6" rx="1" fill="white" />
    </svg>
  );
};

export default IconScreenShareOff;
