import { ScenarioStepType } from '__generated__/types';
import { FormControl, Select } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import BlockTitles from 'components/BlockTitles';
import { ScenarioStep } from 'components/scenario/types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepButtons } from '../buttons';
import { getDefaultAnswerButton, getDefaultCtaButton } from '../constants';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    title: {
      marginBottom: spacing(),
    },
    select: {
      width: '100%',
    },
    addButton: {
      marginTop: spacing(2),
    },
  }),
);

enum AccessScenarioStepType {
  AnswerButtons = 'ANSWER_BUTTONS',
  Cta = 'CTA',
}

interface SelectingStepTypeProps {
  className?: string;
  steps: ScenarioStep[];
}

export const SelectingStepType: React.FC<SelectingStepTypeProps> = ({
  className,
  steps,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { control, setValue } = useFormContext();

  return (
    <div className={clsx(classes.root, className)}>
      <BlockTitles className={classes.title}>
        {t(`components:scenario.dialog.step.type.title`)}
      </BlockTitles>

      <FormControl variant="outlined" className={classes.select}>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              native
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);

                if (e.target.value === ScenarioStepType.AnswerButtons) {
                  setValue('buttons', [getDefaultAnswerButton()]);
                }

                if (e.target.value === ScenarioStepType.Cta) {
                  setValue('buttons', [getDefaultCtaButton()]);
                }
              }}
            >
              {Object.values(AccessScenarioStepType).map((type, index) => {
                return (
                  <option key={index} value={type}>
                    {t(`components:scenario.dialog.step.type.${type}`)}
                  </option>
                );
              })}
            </Select>
          )}
        />
      </FormControl>

      <StepButtons steps={steps} />
    </div>
  );
};
