import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  AudioTrack,
  IAudioTrack,
  IVideoTrack,
  useConversationMessages,
  useOperatorCallState,
  useParticipant,
  useParticipantTracks,
  useTwilioVideo,
  VideoTrack,
} from '@videoforce/client';
import { useAuth } from 'auth';
import clsx from 'clsx';
import React from 'react';
import { isMobile } from 'react-device-detect';
import useToggle from 'react-use/lib/useToggle';

import { Chat } from '../chat';
import CallControls from './CallControls';
import CallDurationLimitWarning from './CallDurationLimitWarning';
import CallViewHeader from './CallViewHeader';
import { CallViewProps } from './types';

interface StyleProps {
  chatVisible: boolean;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    callView: {
      width: '100%',
      height: 'calc(var(--vh, 1vh) * 100);', // see setWindowHeightCssVar
      minHeight: '-webkit-fill-available',
      position: 'relative',
      backgroundColor: '#000',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'stretch',
    },
    videoCol: {
      position: 'relative',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    peerVideo: {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'contain',
    },
    selfWrapper: {
      position: 'absolute',
      bottom: '50px',
      right: theme.spacing(2),
      maxWidth: 200,
      maxHeight: 150,
      borderRadius: '10px',
      border: '2px solid rgba(74, 74, 74, 0.8)',
      [theme.breakpoints.down('sm')]: {
        maxWidth: 120,
        maxHeight: 120,
        bottom: '50px',
      },
      overflow: 'hidden',
    },
    selfWrapperMobile: {
      bottom: '120px',
    },
    hidden: {
      display: 'none',
    },
    selfVideo: {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'contain',
      maxHeight: 150,
      borderRadius: '10px',
      [theme.breakpoints.down('sm')]: {
        maxHeight: 120,
        display: 'flex',
      },
    },
    chat: ({ chatVisible }: StyleProps) => ({
      backgroundColor: '#F3F5F8',
      display: chatVisible ? 'flex' : 'none',
      flexDirection: 'column',
      height: '100%',
      maxHeight: '-webkit-fill-available',
      [theme.breakpoints.down('sm')]: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 80,
        right: 0,
      },
      [theme.breakpoints.up('md')]: {
        width: 450,
        alignSelf: 'stretch',
      },
    }),
  }),
);

export const CallView: React.FC<CallViewProps> = (props) => {
  const { className, title, subtitle, hideTimer, durationLimit } = props;
  const [chatVisible, setChatVisible] = useToggle(false);
  // mobileClick - состояние клика на мобильном устройстве. При клике первый раз - отображаем кнопки управления звонком, таймер, при повторном - скрываем
  const [mobileClick, toggleMobileClick] = useToggle(false);
  const [showDurationWarning, toggleShowDurationWarning] = useToggle(false);
  const classes = useStyles({ chatVisible });
  const { videoTrack } = useTwilioVideo();
  const viewer = useParticipant();
  const { remoteAudioTrack, remoteVideoTrack, remoteScreenTrack } =
    useParticipantTracks(viewer);
  const { activeCall } = useOperatorCallState();
  const messages = useConversationMessages(activeCall?.conversation);

  const auth = useAuth();
  const myId = auth?.claims.id;

  return (
    <div
      className={clsx(classes.callView, className)}
      onClick={() => {
        if (isMobile) {
          toggleMobileClick();
        }
      }}
    >
      <div className={classes.videoCol}>
        {(remoteScreenTrack || remoteVideoTrack) && (
          <div>
            <VideoTrack
              track={(remoteScreenTrack || remoteVideoTrack) as IVideoTrack}
              className={classes.peerVideo}
            />
          </div>
        )}

        <div
          className={clsx(videoTrack ? classes.selfWrapper : classes.hidden, {
            [classes.selfWrapperMobile]: mobileClick,
          })}
        >
          <VideoTrack
            track={videoTrack as IVideoTrack}
            className={classes.selfVideo}
          />
        </div>

        <AudioTrack track={remoteAudioTrack as IAudioTrack} />

        <CallViewHeader
          title={title}
          subtitle={subtitle}
          durationLimit={durationLimit}
          hideTimer={hideTimer}
          toggleShowDurationWarning={toggleShowDurationWarning}
          isShowHeader={mobileClick}
        />

        <CallControls
          {...props}
          chatVisible={chatVisible}
          setChatVisible={setChatVisible}
          isShowButtons={mobileClick}
        />
      </div>

      {chatVisible && (
        <Chat
          className={classes.chat}
          myId={myId}
          messages={messages}
          setChatVisible={setChatVisible}
          conversation={activeCall?.conversation}
        />
      )}

      {showDurationWarning && (
        <CallDurationLimitWarning onClose={toggleShowDurationWarning} />
      )}
    </div>
  );
};
