/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ListScenariosQueryVariables = Types.Exact<{
  take?: Types.InputMaybe<Types.Scalars['Int']>;
  skip?: Types.InputMaybe<Types.Scalars['Int']>;
}>;


export type ListScenariosQuery = { __typename?: 'Query', listScenarios: { __typename: 'Error', id: string, message: string } | { __typename: 'ForbiddenError', id: string, message: string } | { __typename: 'ListScenariosSuccess', count: number, scenarios: Array<{ __typename?: 'Scenario', id: string, name: string, createdAt: string, steps: Array<{ __typename?: 'ScenarioStep', id: string, name: string, order: number, type: Types.ScenarioStepType, video?: { __typename?: 'Video', id: string, mp4?: string | null, createdAt: string, status: Types.VideoStatus, duration?: number | null, jpg?: string | null } | null, buttons?: Array<{ __typename: 'ScenarioStepCssSelectorButton', name: string, selector: string } | { __typename: 'ScenarioStepFinishScenarioButton', name: string } | { __typename: 'ScenarioStepFollowStepButton', name: string, step: number } | { __typename: 'ScenarioStepFollowUrlButton', name: string, url: string } | { __typename: 'ScenarioStepOpenVideochatButton', name: string }> | null }> }> } };


export const ListScenariosDocument = gql`
    query listScenarios($take: Int, $skip: Int) {
  listScenarios(take: $take, skip: $skip) {
    __typename
    ... on IError {
      id
      message
    }
    ... on ListScenariosSuccess {
      scenarios {
        id
        name
        createdAt
        steps {
          id
          name
          video {
            id
            mp4
            createdAt
            status
            duration
            jpg
          }
          order
          type
          buttons {
            __typename
            ... on ScenarioStepCssSelectorButton {
              name
              selector
            }
            ... on ScenarioStepOpenVideochatButton {
              name
            }
            ... on ScenarioStepFollowStepButton {
              name
              step
            }
            ... on ScenarioStepFollowUrlButton {
              name
              url
            }
            ... on ScenarioStepFinishScenarioButton {
              name
            }
          }
        }
      }
      count
    }
  }
}
    `;

/**
 * __useListScenariosQuery__
 *
 * To run a query within a React component, call `useListScenariosQuery` and pass it any options that fit your needs.
 * When your component renders, `useListScenariosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListScenariosQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useListScenariosQuery(baseOptions?: Apollo.QueryHookOptions<ListScenariosQuery, ListScenariosQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListScenariosQuery, ListScenariosQueryVariables>(ListScenariosDocument, options);
      }
export function useListScenariosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListScenariosQuery, ListScenariosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListScenariosQuery, ListScenariosQueryVariables>(ListScenariosDocument, options);
        }
export type ListScenariosQueryHookResult = ReturnType<typeof useListScenariosQuery>;
export type ListScenariosLazyQueryHookResult = ReturnType<typeof useListScenariosLazyQuery>;
export type ListScenariosQueryResult = Apollo.QueryResult<ListScenariosQuery, ListScenariosQueryVariables>;