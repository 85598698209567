import { Conversation } from '@twilio/conversations';

import { CallOperatorAction, CallOperatorState } from './types';

const getInactiveCallState = () => ({
  incomingRequest: null,
  acceptedRequest: null,
  declinedRequest: false,
  incomingCall: null,
  activeCall: null,
});

const getIncomingRequestState = (
  callId: string,
  callConfirmationTimeout: number,
  sourcePage: string,
  isImpersonalCall: boolean,
  accepted: boolean,
) => ({
  incomingRequest: {
    callId,
    callConfirmationTimeout,
    sourcePage,
    isImpersonalCall,
    accepted,
  },
  acceptedRequest: null,
  declinedRequest: false,
  incomingCall: null,
  activeCall: null,
});

const getAcceptedRequestState = (
  callId: string,
  widgetName: string,
  sourcePage: string,
  conversationSid: string,
) => ({
  incomingRequest: null,
  acceptedRequest: {
    callId,
    widgetName,
    sourcePage,
    conversationSid,
  },
  declinedRequest: false,
  incomingCall: null,
  activeCall: null,
});

const getDeclinedRequestState = () => ({
  incomingRequest: null,
  acceptedRequest: null,
  declinedRequest: true,
  incomingCall: null,
  activeCall: null,
});

const getIncomingCallState = (
  callId: string,
  accepted: boolean,
  widgetName?: string,
) => ({
  incomingRequest: null,
  acceptedRequest: null,
  declinedRequest: false,
  incomingCall: {
    callId,
    accepted,
    widgetName,
  },
  activeCall: null,
});

const getActiveCallState = (
  token: string,
  callId: string,
  conversation: Conversation,
) => ({
  incomingRequest: null,
  acceptedRequest: null,
  declinedRequest: false,
  incomingCall: null,
  activeCall: { callId, token, conversation },
});

const getEndingCallState = (
  callId: string,
  token: string,
  ending?: boolean,
) => ({
  incomingRequest: null,
  acceptedRequest: null,
  declinedRequest: false,
  incomingCall: null,
  activeCall: { callId, token, ending },
});

export const CALL_DEFAULT_STATE: CallOperatorState = getInactiveCallState();

export const callOperatorReducer = (
  state: CallOperatorState,
  action: CallOperatorAction,
): CallOperatorState => {
  switch (action.type) {
    case 'inactivated_call': {
      return getInactiveCallState();
    }
    case 'incoming_request': {
      return getIncomingRequestState(
        action.callId,
        action.callConfirmationTimeout,
        action.sourcePage,
        action.isImpersonalCall,
        action.accepted,
      );
    }
    case 'accepted_request': {
      return getAcceptedRequestState(
        action.callId,
        action.widgetName,
        action.sourcePage,
        action.conversationSid,
      );
    }
    case 'viewer_declined_request': {
      return getDeclinedRequestState();
    }
    case 'incoming_call': {
      return getIncomingCallState(
        action.callId,
        action.accepted,
        action.widgetName,
      );
    }
    case 'activated_call': {
      return getActiveCallState(
        action.token,
        action.callId,
        action.conversation,
      );
    }
    case 'ending_call': {
      return getEndingCallState(action.callId, action.token, action.ending);
    }
    default:
      return state;
  }
};
