import { ErrorMessage } from '@hookform/error-message';
import { createStyles, makeStyles } from '@material-ui/core';
import { ScenarioStepButton } from 'components/scenario/types';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    input: {
      border: 'none',
      background: '#ECF0F7',
      fontFamily: 'Manrope',
      '&:focus': {
        border: 'none',
        background: '#ECF0F7',
        outline: 'none',
      },
    },
    error: {
      color: '#F2554E',
      marginTop: spacing(),
    },
  }),
);

interface StepButtonName {
  button: ScenarioStepButton;
  index: number;
}

export const StepButtonName: React.FC<StepButtonName> = ({ index }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control, formState } = useFormContext();

  return (
    <div className={classes.root}>
      <Controller
        control={control}
        name={`buttons[${index}].name`}
        rules={{
          required: true,
        }}
        render={({ field: { value, onChange } }) => (
          <input
            className={classes.input}
            type="text"
            value={value}
            maxLength={24}
            onChange={(e) => onChange(e.target.value)}
          />
        )}
      />

      <ErrorMessage
        errors={formState.errors}
        name={`buttons[${index}].name`}
        render={() => (
          <span className={classes.error}>
            {t('components:scenario.dialog.errors.buttonName')}
          </span>
        )}
      />
    </div>
  );
};
