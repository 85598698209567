import { createStyles, makeStyles } from '@material-ui/core/styles';
import SettingsIcon from '@material-ui/icons/Settings';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import { useAuth } from 'auth';
import OperatorStateSwitch from 'components/OperatorStateSwitch';
import { useUnreadMessages } from 'components/unread-provider/useUnreadMessages';
import isEmpty from 'lodash/isEmpty';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import IconCall from '../icons/IconCall';
import IconConversations from '../icons/IconConversations';
import IconMembers from '../icons/IconMembers';
import IconTariff from '../icons/IconTariff';
import IconWidget from '../icons/IconWidget';
import AccountMenu from './AccountMenu';
import HelpMenu from './HelpMenu';
import SidebarItem from './SidebarItem';
import UserMenu from './UserMenu';

const useStyles = makeStyles(({ breakpoints, spacing }) =>
  createStyles({
    sidebar: {
      width: '115px',
      padding: '60px 0px 30px',
      borderRight: '1px solid #DBDBDB',
      overflow: 'hidden',
      flexShrink: 0,
      [breakpoints.between('sm', 1400)]: {
        padding: '15px 0px 15px',
      },
      [breakpoints.down('sm')]: {
        borderRight: 'none',
        padding: '0 16px 30px',
        width: 'auto',
      },
      display: 'flex',
      flexDirection: 'column',
      '&:hover span:not(.MuiSwitch-track)': {
        visibility: 'visible',
        opacity: '1',
        transition: 'visibility 0.3s linear,opacity 0.3s linear',
      },
    },
    contentWrap: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      [breakpoints.down('sm')]: {
        paddingTop: '10px',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #DBDBDB',
        alignItems: 'center',
      },
    },
    items: {
      [breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    menus: {
      marginTop: 'auto',
      marginBottom: spacing(1),
      padding: spacing(0, 1),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        marginTop: 0,
        marginBottom: 0,
      },
    },
    unreadMessageCount: {
      color: 'white',
      backgroundColor: '#3198E3',
      borderRadius: '25px',
      width: '40%',
      textAlign: 'center',
      marginLeft: '65px',
      fontFamily: 'Manrope',
      fontSize: '12px',
      lineHeight: '17px',
      fontWeight: 'bold',
    },
  }),
);

export const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { claims } = useAuth()!;
  const { t } = useTranslation();
  const unreadMessages = useUnreadMessages();

  const unreadMessagesCount = useMemo(() => {
    let countOfUnreadMessages = 0;
    Object.values(unreadMessages).forEach((message) => {
      countOfUnreadMessages += message.count;
    });
    return countOfUnreadMessages > 99
      ? '99+'
      : countOfUnreadMessages.toString();
  }, [unreadMessages]);

  return (
    <div className={classes.sidebar}>
      <div className={classes.contentWrap}>
        <div className={classes.items}>
          <SidebarItem
            path="/conversations"
            label={t('components:sidebar.conversations')}
          >
            {unreadMessagesCount !== '0' && (
              <div className={classes.unreadMessageCount}>
                {unreadMessagesCount ? unreadMessagesCount : null}
              </div>
            )}
            <IconConversations />
          </SidebarItem>

          <SidebarItem path="/calls" label={t('components:sidebar.calls')}>
            <IconCall />
          </SidebarItem>

          <SidebarItem path="/widgets" label={t('components:sidebar.widgets')}>
            <IconWidget />
          </SidebarItem>

          {(claims.owner || claims.manager || claims.admin) && (
            <SidebarItem
              path="/employees"
              label={t('components:sidebar.employees')}
            >
              <IconMembers />
            </SidebarItem>
          )}

          {(claims.owner || claims.admin) && (
            <SidebarItem path="/plans" label={t('components:sidebar.plans')}>
              <IconTariff />
            </SidebarItem>
          )}

          <SidebarItem
            path="/video-library"
            label={t('components:sidebar.gallery')}
          >
            <VideoLibraryIcon />
          </SidebarItem>

          {claims.admin && (
            <SidebarItem path="/admin" label={t('components:sidebar.admin')}>
              <SettingsIcon />
            </SidebarItem>
          )}

          <HelpMenu />
        </div>

        <div className={classes.menus}>
          <UserMenu />
          <AccountMenu />
        </div>

        {!isEmpty(claims.streamer) && <OperatorStateSwitch />}
      </div>
    </div>
  );
};
