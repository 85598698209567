import { Video, VideoStatus } from '__generated__/types';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Loading from '../../Loading';
import { useListVideosQuery } from './listVideos.generated';
import VideoCard from './VideoCard';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  }),
);

interface VideosListProps {
  selectedVideo?: Video | undefined;
  setSelectedVideo?: React.Dispatch<React.SetStateAction<Video | undefined>>;
  className?: string;
}

export const VideosList: React.FC<VideosListProps> = ({
  selectedVideo,
  setSelectedVideo,
  className,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const filterVideos = (videos: Video[]): Video[] =>
    videos.filter(
      (video) =>
        video.status === VideoStatus.Complete &&
        video.duration &&
        video.resolution?.height &&
        video.resolution?.width,
    );

  const { data, loading, error } = useListVideosQuery({
    fetchPolicy: 'cache-and-network',
  });

  if (loading && !data?.listVideos) {
    return <Loading />;
  }

  if (error) {
    return <Alert severity="error">{t('errors:default')}</Alert>;
  }

  if (data?.listVideos.__typename !== 'ListVideosSuccess') {
    return <Alert severity="error">{t('errors:default')}</Alert>;
  }

  const filteredListVideos = filterVideos(data.listVideos.videos);

  if (!filteredListVideos) {
    return <div>{t('components:video.listVideos.none')}</div>;
  }

  return (
    <div className={clsx(classes.root, className)}>
      {filteredListVideos.map((video) => (
        <VideoCard
          key={video.id}
          video={video}
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      ))}
    </div>
  );
};
