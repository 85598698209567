import { InputBaseProps } from '@material-ui/core';
import { FieldValues, UseFormRegister } from 'react-hook-form';

export default function registerInput<
  TFieldValues extends FieldValues = FieldValues,
>(
  register: UseFormRegister<TFieldValues>,
  ...args: Parameters<UseFormRegister<TFieldValues>>
): Required<Pick<InputBaseProps, 'name' | 'onBlur' | 'onChange' | 'inputRef'>> {
  const { name, onBlur, onChange, ref } = register(...args);
  return {
    name,
    onBlur,
    onChange,
    inputRef: ref,
  };
}
