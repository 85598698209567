import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
} from 'react';
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  Room,
} from 'twilio-video';

import { ParticipantConnectType } from '../types';
import AttachVisibilityHandler from './AttachVisibilityHandler';
import useHandleRoomDisconnection from './useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import useScreenShareToggle from './useScreenShareToggle';

/*
 *  The hooks used by the VideoProvider component are different than the hooks found in the 'hooks/' directory. The hooks
 *  in the 'hooks/' directory can be used anywhere in a video application, and they can be used any number of times.
 *  the hooks in the 'VideoProvider/' directory are intended to be used by the VideoProvider component only. Using these hooks
 *  elsewhere in the application may cause problems as these hooks should not be used more than once in an application.
 */

export interface IVideoContext {
  room: Room | null;
  audioTrack?: LocalAudioTrack;
  videoTrack?: LocalVideoTrack;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string, participantType: ParticipantConnectType) => void;
  onError: ErrorCallback;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  removeLocalVideoTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  onError?: ErrorCallback;
  children: ReactNode;
  endCallAction: () => void;
}

export function TwilioVideoProvider({
  children,
  onError = () => {},
  endCallAction,
}: VideoProviderProps) {
  const onErrorCallback: ErrorCallback = useCallback(
    (error) => {
      onError(error);
    },
    [onError],
  );

  const {
    audioTrack,
    videoTrack,
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    setAudioAndVideoTracks,
  } = useLocalTracks();

  const { room, isConnecting, connect } = useRoom(setAudioAndVideoTracks);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(
    room,
    onError,
  );

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare,
    endCallAction,
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  return (
    <VideoContext.Provider
      value={{
        room,
        audioTrack,
        videoTrack,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        removeLocalVideoTrack,
        isSharingScreen,
        toggleScreenShare,
      }}
    >
      {children}
      {/* 
        The AttachVisibilityHandler component is using the useLocalVideoToggle hook
        which must be used within the VideoContext Provider.
      */}
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}

export const useTwilioVideo = () => useContext(VideoContext);
