import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
  useAudioLoop,
  useOperatorCallActions,
  useOperatorCallState,
} from '@videoforce/client';
import { clsx } from 'clsx';
import ButtonProgress from 'components/ButtonProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import { DeclineDialog } from '../decline';
import { useRequestLimit } from './useRequestLimit';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    dialog: {
      '& .MuiDialog-paper': {
        borderRadius: '16px',
      },
    },
    modal: {
      maxWidth: 530,
      padding: spacing(4),
      [breakpoints.up('sm')]: {
        minWidth: 360,
      },
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    headerItem: { fontWeight: 600, fontSize: '20px', color: '#171717' },
    controls: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: spacing(6),
      [breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    acceptLoading: {
      flex: 1,
      display: 'flex',
      marginRight: spacing(1),
      [breakpoints.down('sm')]: {
        marginRight: 0,
        marginBottom: spacing(1),
      },
    },
    controlButton: {
      flex: 1,
      height: '43px',
      fontWeight: 700,
      fontSize: '14px',
      [breakpoints.down('sm')]: {
        marginLeft: 0,
        marginTop: spacing(1),
      },
    },
    decline: {
      '&.MuiButton-root': {
        color: palette.primary.main,
        background: '#F5F5F5',
        '&:hover': {
          background: '#F5F5F5',
        },
      },
    },
    accept: {
      '&.MuiButton-root': {
        color: palette.common.white,
        backgroundColor: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.primary.main,
        },
      },
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    info: {
      padding: '12px 16px',
      background: '#F5F5F5',
      borderRadius: '8px',
      fontFamily: 'Manrope',
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '19px',
      color: '#787878',
    },
    rowsWrapper: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    pageUrl: {
      display: 'flex',
      gap: '3px',
    },
    pageUrlTitle: {
      color: '#A5A5A5',
    },
    pageUrlData: {
      color: palette.primary.main,
    },
    requestType: {
      display: 'flex',
      gap: '3px',
    },
    requestTypeTitle: {
      color: '#A5A5A5',
    },
    requestTypeData: {
      color: '#333333',
      margin: 0,
    },
  }),
);

export const IncomingRequestDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { incomingRequest } = useOperatorCallState();
  const { acceptRequest, declineRequest } = useOperatorCallActions();
  const timeInFormat = useRequestLimit(
    incomingRequest?.callConfirmationTimeout,
  );

  useAudioLoop(
    process.env.PUBLIC_URL + '/phone_ringing.mp3',
    !!incomingRequest,
  );

  const [isOpenDeclineDialog, toggleOpenDeclineDialog] = useToggle(false);

  if (!incomingRequest) {
    return null;
  }

  const onDeclineRequest = () => {
    declineRequest(incomingRequest.callId);
  };

  return (
    <>
      <Dialog open className={classes.dialog}>
        <DialogContent className={classes.modal}>
          <div className={classes.header}>
            <div className={classes.headerItem}>
              {t('components:requestCall.incoming.title')}
            </div>

            <div className={classes.headerItem}>{timeInFormat}</div>
          </div>

          <div className={classes.body}>
            <div className={classes.info}>
              {t('components:requestCall.incoming.info')}
            </div>

            <div className={classes.rowsWrapper}>
              <div className={classes.pageUrl}>
                <Typography variant="h5" className={classes.pageUrlTitle}>
                  {t('components:requestCall.incoming.details.pageTitle')}
                </Typography>

                <Typography variant="h5" className={classes.pageUrlData}>
                  {incomingRequest.sourcePage}
                </Typography>
              </div>

              <div className={classes.requestType}>
                <Typography variant="h5" className={classes.requestTypeTitle}>
                  {t('components:requestCall.incoming.details.requestType')}
                </Typography>

                <Typography variant="h5" className={classes.requestTypeData}>
                  {incomingRequest.isImpersonalCall
                    ? 'Безличностный вызов'
                    : 'Персональный вызов'}
                </Typography>
              </div>
            </div>
          </div>

          <div className={classes.controls}>
            <ButtonProgress className={classes.acceptLoading}>
              <Button
                variant="contained"
                className={clsx(classes.decline, classes.controlButton)}
                onClick={toggleOpenDeclineDialog}
                disabled={incomingRequest.accepted}
              >
                {t('components:requestCall.incoming.button.decline')}
              </Button>
            </ButtonProgress>

            <ButtonProgress
              className={classes.acceptLoading}
              loading={incomingRequest.accepted}
            >
              <Button
                variant="contained"
                className={clsx(classes.accept, classes.controlButton)}
                onClick={() => acceptRequest(incomingRequest)}
                disabled={incomingRequest.accepted}
              >
                {t('components:requestCall.incoming.button.accept')}
              </Button>
            </ButtonProgress>
          </div>
        </DialogContent>
      </Dialog>

      <DeclineDialog
        isOpened={isOpenDeclineDialog}
        onConfirm={onDeclineRequest}
        onCancel={toggleOpenDeclineDialog}
        title={t('components:requestCall.incoming.declineDialog.title')}
        confirmButton={t(
          'components:requestCall.incoming.declineDialog.button.confirm',
        )}
        cancelButton={t(
          'components:requestCall.incoming.declineDialog.button.return',
        )}
      />
    </>
  );
};
