import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';

import { useTwilioVideo } from '../twilioVideoProvider';

export function useParticipant(): RemoteParticipant | null {
  const { room } = useTwilioVideo();
  const [participant, setParticipant] = useState<RemoteParticipant | null>(
    null,
  );

  useEffect(() => {
    if (room) {
      room.participants.forEach((participant) => {
        setParticipant(participant);
      });

      const participantConnected = (participant: RemoteParticipant) =>
        setParticipant(participant);
      const participantDisconnected = () => setParticipant(null);

      room.on('participantConnected', participantConnected);
      room.on('participantDisconnected', participantDisconnected);
      return () => {
        room.removeListener('participantConnected', participantConnected);
        room.removeListener('participantDisconnected', participantDisconnected);
      };
    }
  }, [room]);

  return participant;
}
