import React from 'react';

interface Props {
  className?: string;
}

const IconWidget: React.FC<Props> = ({ className }) => {
  return (
    <svg viewBox="0 0 18 18" fill="none" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 9C8 9.55229 7.55228 10 7 10H1C0.447715 10 0 9.55228 0 9V1C0 0.447715 0.447715 0 1 0H7C7.55228 0 8 0.447715 8 1V9ZM8 17C8 17.5523 7.55228 18 7 18H1C0.447715 18 0 17.5523 0 17V13C0 12.4477 0.447715 12 1 12H7C7.55228 12 8 12.4477 8 13V17ZM10 17C10 17.5523 10.4477 18 11 18H17C17.5523 18 18 17.5523 18 17V9C18 8.44771 17.5523 8 17 8H11C10.4477 8 10 8.44772 10 9V17ZM11 6C10.4477 6 10 5.55228 10 5V1C10 0.447715 10.4477 0 11 0H17C17.5523 0 18 0.447715 18 1V5C18 5.55228 17.5523 6 17 6H11Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IconWidget;
