import { RuntimeEnv } from '@videoforce/commons';

interface InferredEnv {
  apiUrl: string;
  uploadsBucket: string;
}

const DOMAIN =
  process.env.REACT_APP_DOMAIN ||
  window.location.host.split('.').slice(-2).join('.');

export const Env: BuildTimeEnv & InferredEnv & RuntimeEnv = {
  region: '',
  userPoolId: '',
  identityPoolId: '',
  webClientId: '',
  REACT_APP_DOMAIN: DOMAIN,
  apiUrl: `https://api.${DOMAIN}/v1/`,
  uploadsBucket: `uploads.${DOMAIN}`,
  wsApiURL: '',
  httpApiURL: '',
};

export const TEST_MODE = process.env.REACT_APP_TEST_MODE === 'true';

export async function loadConfig() {
  const res = await fetch(`https://api.${DOMAIN}/v1/env`);
  const data: RuntimeEnv = await res.json();
  Object.assign(Env, data);
}
