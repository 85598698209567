import { Message } from '@twilio/conversations';
import { WSMessages } from '@videoforce/commons';
import { useCallback, useEffect } from 'react';

import { useConversationsClient } from '../../conversations';
import { CallOperatorAction } from './types';

export const useOperatorSystemMessages = (
  dispatch: React.Dispatch<CallOperatorAction>,
  myId: string,
) => {
  const client = useConversationsClient();

  const systemMessageActions = useCallback(
    (m: Message) => {
      if (m.author === 'system') {
        const attributes = m.attributes as any;
        const isMyMessage = attributes.recipients.includes(myId);
        const payload = attributes.payload;
        const typeMessage = payload.type;

        if (isMyMessage) {
          if (typeMessage === 'incoming_call') {
            const callId = payload.callId;
            const widgetName = payload.widgetName;

            dispatch({
              type: 'incoming_call',
              callId,
              accepted: false,
              widgetName,
            });
          }

          if (typeMessage === 'call_declined') {
            dispatch({ type: 'inactivated_call' });
          }

          if (typeMessage === 'call_accepted') {
            const callId = payload.callId;
            const token = payload.token;
            const conversation = m.conversation;
            dispatch({ type: 'activated_call', token, callId, conversation });
          }

          if (typeMessage === WSMessages.S_INCOMING_CALL_WITH_CONFIRMATION) {
            const callId = payload.callId;
            const callConfirmationTimeout = payload.callConfirmationTimeout;
            const sourcePage = payload.sourcePage;
            const isImpersonalCall = payload.isImpersonalCall;

            dispatch({
              type: 'incoming_request',
              callId,
              callConfirmationTimeout,
              sourcePage,
              isImpersonalCall,
              accepted: false,
            });
          }

          if (typeMessage === WSMessages.S_REQUEST_DECLINED) {
            dispatch({ type: 'inactivated_call' });
          }

          if (typeMessage === WSMessages.S_REQUEST_ACCEPTED) {
            const callId = payload.callId;
            const widgetName = payload.widgetName;
            const sourcePage = payload.sourcePage;

            dispatch({
              type: 'accepted_request',
              callId,
              widgetName,
              sourcePage,
              conversationSid: m.conversation.sid,
            });
          }

          if (typeMessage === WSMessages.S_CALL_WITH_CONFIRMATION_ACCEPTED) {
            const callId = payload.callId;
            const token = payload.token;
            const conversation = m.conversation;
            dispatch({ type: 'activated_call', token, callId, conversation });
          }

          if (typeMessage === WSMessages.S_CALL_WITH_CONFIRMATION_DECLINED) {
            const reason = payload.reason;
            if (reason === 'viewer_canceled') {
              dispatch({ type: 'viewer_declined_request' });
            } else {
              dispatch({ type: 'inactivated_call' });
            }
          }
        } else {
          // При безличностном вызове, если ответил другой оператор - сбрасываем звонок у себя
          if (
            typeMessage === 'call_accepted' &&
            payload.streamerId !== myId &&
            !attributes.recipients.includes(payload.viewerId)
          ) {
            dispatch({ type: 'inactivated_call' });
          }
          // Если оператор не успел ответить на звонок
          if (typeMessage === 'call_declined') {
            dispatch({ type: 'inactivated_call' });
          }

          if (
            typeMessage === WSMessages.S_REQUEST_ACCEPTED &&
            payload.streamerId !== myId &&
            !attributes.recipients.includes(payload.viewerId)
          ) {
            dispatch({ type: 'inactivated_call' });
          }
        }
      }
    },
    [dispatch, myId],
  );

  useEffect(() => {
    client?.on('messageAdded', systemMessageActions);
    return () => {
      client?.off('messageAdded', systemMessageActions);
    };
  }, [client, systemMessageActions]);
};
