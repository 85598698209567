import {
  ScenarioStepButtonType,
  ScenarioStepType,
  Video,
} from '__generated__/types';

export enum ScenarioIntention {
  CREATE_SCENARIO = 'create',
  UPDATE_SCENARIO = 'update',
}

export interface FinishScenarioButton {
  type: ScenarioStepButtonType.FinishScenario;
  id: string;
  name: string;
}

export interface FollowStepButton {
  type: ScenarioStepButtonType.FollowStep;
  id: string;
  name: string;
  step: number;
}

export interface CssSelectorButton {
  type: ScenarioStepButtonType.CssSelector;
  id: string;
  name: string;
  selector: string;
}

export interface FollowUrlButton {
  type: ScenarioStepButtonType.FollowUrl;
  id: string;
  name: string;
  url: string;
}

export interface OpenVideochatButton {
  type: ScenarioStepButtonType.OpenVideochat;
  id: string;
  name: string;
}

export type ScenarioStepButton =
  | CssSelectorButton
  | FinishScenarioButton
  | FollowStepButton
  | FollowUrlButton
  | OpenVideochatButton;

export interface ScenarioStep {
  name: string;
  order: number;
  type: ScenarioStepType;
  buttons: ScenarioStepButton[];
  videoId: string | undefined;
  video: Video | undefined;
}

export type ScenarioStepAnswerButton = FollowStepButton | FinishScenarioButton;

export type ScenarioStepCtaButton =
  | CssSelectorButton
  | FollowUrlButton
  | OpenVideochatButton;

export interface ScenarioStepsStore {
  steps: ScenarioStep[];
  setSteps: (steps: ScenarioStep[]) => ScenarioStep[];
  addStep: (step: ScenarioStep | null) => void;
  updateStep: (step: ScenarioStep) => void;
  removeStep: (id: number) => void;
}
