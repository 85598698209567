import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useTranslation } from 'react-i18next';

import ShopifyConfirmationButton from './ShopifyConfirmationButton';

const useStyles = makeStyles(() =>
  createStyles({
    actions: {
      justifyContent: 'center',
    },
  }),
);

export const RequestSubscriptionDialog: React.FC = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Dialog open>
      <DialogTitle>{t('components:shopifyModal.request.title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('components:shopifyModal.request.description')}
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <ShopifyConfirmationButton />
      </DialogActions>
    </Dialog>
  );
};
