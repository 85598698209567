import { Message } from '@videoforce/client';

export const getSystemMessage = (message: Message): string => {
  const attributes = message.attributes as any;
  const typeMessage = attributes.payload.type;

  const decinedCallReason = attributes.payload.reason;

  const isViewerDeclinedCall = decinedCallReason === 'viewer_canceled';

  switch (typeMessage) {
    case 'incoming_call':
      return 'components:chat.systemMessages.incomingCall';
    case 'call_declined':
      return isViewerDeclinedCall
        ? 'components:chat.systemMessages.declinedCallViewer'
        : 'components:chat.systemMessages.declinedCallOperator';
    case 'call_accepted':
      return 'components:chat.systemMessages.acceptCall';
    case 'end_call_success':
      return 'components:chat.systemMessages.end_call_success';
    case 'call_with_confirmation_accepted':
      return 'components:chat.systemMessages.call_with_confirmation_accepted';
    case 'accept_request':
      return 'components:chat.systemMessages.accept_request';
    case 'call_with_confirmation_declined':
      return 'components:chat.systemMessages.call_with_confirmation_declined';
    case 'incoming_call_with_confirmation':
      return 'components:chat.systemMessages.incoming_call_with_confirmation';
    case 'request_declined':
      return 'components:chat.systemMessages.request_declined';
    default:
      return '';
  }
};
