import { Button, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ListIcon from '@material-ui/icons/List';
import CloseButton from 'components/CloseButton';
import { ScenarioFormData } from 'components/scenario/mutations';
import { ScenarioIntention, ScenarioStep } from 'components/scenario/types';
import { VideoMiniature } from 'components/video';
import React from 'react';
import { UseFieldArrayReplace } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      border: '1px solid #EFEFEF',
      boxSizing: 'border-box',
      borderRadius: '12px',
      padding: spacing(),
      margin: '0 20px 20px 0',
      position: 'relative',
      '&:hover': {
        border: '1px solid #CACACA',
      },
      height: '165px',
      minWidth: '315px',
      maxWidth: '315px',
      '&:last-child': {
        margin: '0 0 20px 0',
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: spacing(3),
      overflow: 'hidden',
      cursor: 'default',
    },
    name: {
      fontSize: 20,
      lineHeight: '28px',
      fontWeight: 500,
      color: '#333333',
      marginBottom: spacing(),
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '85%',
    },
    type: {
      display: 'flex',
      alignItems: 'center',
      border: '1px solid #ECF0F6',
      borderRadius: 8,
      padding: '8px 12px 8px 6px',
      color: '#333333',
    },
    typeSvg: {
      fill: '#9C9C9C',
      marginRight: spacing(),
    },
    changeButton: {
      marginTop: 'auto',
    },
    closeButton: {
      top: '5px',
      right: '5px',
      backgroundColor: '#fff',
      width: '24px',
      height: '24px',
    },
    video: {
      minWidth: '102px',
      height: '100%',
    },
  }),
);

interface CreatedStepCardProps {
  step: ScenarioStep;
  index: number;
  updateStep: (step: ScenarioStep, intention: ScenarioIntention) => void;
  intention: ScenarioIntention;
  replace: UseFieldArrayReplace<ScenarioFormData, 'steps'>;
  stepsList: ScenarioStep[];
}

export const CreatedStepCard: React.FC<CreatedStepCardProps> = ({
  step,
  index,
  updateStep,
  intention,
  replace,
  stepsList,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { name, type, video } = step;

  return (
    <div className={classes.root}>
      <VideoMiniature video={video} className={classes.video} />

      <div className={classes.info}>
        <div className={classes.name}>{name}</div>
        <div className={classes.type}>
          <ListIcon className={classes.typeSvg} />
          <Typography variant="h5">
            {t(`components:scenario.dialog.step.type.${type}`)}
          </Typography>
        </div>

        <Button
          variant="contained"
          className={classes.changeButton}
          onClick={() => updateStep(step, intention)}
        >
          {t(`components:scenario.dialog.scenario.stepsList.item.changeButton`)}
        </Button>
      </div>

      <CloseButton
        className={classes.closeButton}
        onClick={() => {
          // При удалении какого-то шага, у других необходимо сдвинуть order
          const beforeItems = stepsList.filter(
            (_, indexStep) => indexStep < index,
          );

          const afterItems = stepsList
            .filter((_, indexStep) => indexStep > index)
            .map((step) => {
              return {
                ...step,
                order: step.order - 1,
              };
            });

          replace([...beforeItems, ...afterItems]);
        }}
      />
    </div>
  );
};
