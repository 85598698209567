import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import DialogLayout from '../DialogLayout';
import RecordingCheckboxAudio from './RecordingCheckboxAudio';
import RecordingIndicator from './RecordingIndicator';
import renderCountdown from './renderCountDown';
import useBackgroundFilter from './useBackgroundFilter';
import useRecording from './useRecording';
import VideoWrapper from './VideoWrapper';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      maxWidth: '320px',
    },
    controls: {
      marginTop: '10px',
      display: 'flex',
      flexDirection: 'row',
      overflowX: 'hidden',
      justifyContent: 'space-between',
      '& > * + *': {
        marginLeft: '10px',
      },
    },
    info: {
      position: 'absolute',
      top: 0,
      height: 32,
      left: 0,
      right: 0,
      display: 'flex',
      justifyContent: 'flex-end',
      padding: spacing(1),
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      color: '#fff',
      '& span ': {
        margin: '0 5px 0 5px',
      },
    },
    hint: {
      position: 'absolute',
      top: 'calc(50% - 5px)',
      left: '0',
      right: '0',
      height: '10px',
      textAlign: 'center',
      fontFamily: 'Manrope, sans-serif',
      fontSize: '16px',
      padding: '0 10px 0',
    },
    error: {
      fontFamily: 'Manrope, sans-serif',
      position: 'absolute',
    },
  }),
);

interface StepRecordingProps {
  onCancel: () => void;
  onSuccess: (file: File) => void;
  initialAudioValue?: boolean;
}

const StepRecording: React.FC<StepRecordingProps> = ({
  onCancel,
  onSuccess,
  initialAudioValue,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { stream, isPermissionDeniedVideo } = useBackgroundFilter();
  const [isAudioEnabled, setAudioEnabled] = useToggle(
    initialAudioValue ?? true,
  );

  const {
    status,
    counter,
    mediaBlobUrl,
    file,
    start,
    stop,
    reset,
    isPermissionDeniedAudio,
  } = useRecording(10, isAudioEnabled);

  const isPermissionDenied = isPermissionDeniedVideo || isPermissionDeniedAudio;

  return (
    <div>
      <DialogLayout onCancel={onCancel}>
        <div className={classes.wrapper}>
          <VideoWrapper src={mediaBlobUrl ?? stream}>
            <span className={classes.hint}>
              {!stream.active &&
                !isPermissionDeniedVideo &&
                t(`components:video.uploadDialog.recording.hint`)}
            </span>
          </VideoWrapper>

          {status === 'recording' && (
            <div className={classes.info}>
              {t(
                `components:video.uploadDialog.recording.videoStatus.recording`,
              )}
              <span>{renderCountdown(counter)}</span>
              <RecordingIndicator />
            </div>
          )}

          {mediaBlobUrl && (
            <div className={classes.info}>
              {t(`components:video.uploadDialog.recording.videoStatus.played`)}
            </div>
          )}

          <div className={classes.controls}>
            {status !== 'recording' && !mediaBlobUrl && (
              <Button
                onClick={start}
                variant="contained"
                color="primary"
                fullWidth
                disabled={!stream.active}
              >
                {t('components:video.uploadDialog.recording.recordingVideo')}
              </Button>
            )}
            {status === 'recording' && (
              <Button onClick={stop} variant="contained" fullWidth>
                {t('components:video.uploadDialog.recording.cancel')}
              </Button>
            )}
            {status === 'stopped' && file && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    onSuccess(file);
                  }}
                >
                  {t('components:video.uploadDialog.recording.save')}
                </Button>

                <Button variant="contained" fullWidth onClick={reset}>
                  {t('components:video.uploadDialog.recording.again')}
                </Button>
              </>
            )}
          </div>

          <RecordingCheckboxAudio
            isAudioEnabled={isAudioEnabled}
            setAudioEnabled={setAudioEnabled}
            isHidden={status === 'recording' || !!mediaBlobUrl}
          />

          {isPermissionDenied && (
            <Alert severity="error" className={classes.error}>
              {isPermissionDeniedVideo &&
                t(
                  `components:video.uploadDialog.recording.permissionDeniedVideo`,
                )}
              {isPermissionDeniedAudio &&
                t(
                  `components:video.uploadDialog.recording.permissionDeniedAudio`,
                )}
            </Alert>
          )}
        </div>
      </DialogLayout>
    </div>
  );
};

export default StepRecording;
