import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
} from '@apollo/client';
import {
  LocalStorageWrapper,
  SynchronousCachePersistor,
} from 'apollo3-cache-persist';
import { Env } from 'config/env';

import authLink from './authLink';
import cleanTypenameLink from './cleanTypenameLink';
import errorLink from './errorLink';

const SCHEMA_VERSION = '1';
const SCHEMA_VERSION_KEY = '@vf/apollo-schema-version';

let _client: ApolloClient<unknown> | undefined;

export default function initApolloClient(): ApolloClient<unknown> {
  if (!_client) {
    const cache = new InMemoryCache();

    const persistor = new SynchronousCachePersistor({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });

    // Read the current schema version.
    const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

    if (currentVersion === SCHEMA_VERSION) {
      // If the current version matches the latest version,
      // we're good to go and can restore the cache.
      persistor.restoreSync();
    } else {
      // Otherwise, we'll want to purge the outdated persisted cache
      // and mark ourselves as having updated to the latest version.
      // in case of window.localStorage purge is in fact synchronous
      persistor.purge();
      window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
    }

    const httpLink = createHttpLink({
      uri: `${Env.apiUrl}graphql`,
    });

    _client = new ApolloClient({
      link: from([errorLink, cleanTypenameLink, authLink, httpLink]),
      cache: new InMemoryCache(),
    });
  }
  return _client;
}
