import { Video } from '__generated__/types';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import CopyIcon from '@material-ui/icons/FileCopy';
import clsx from 'clsx';
import CopyButton from 'components/CopyButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { VideoMiniature } from '../miniature';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      border: '1px solid #EFEFEF',
      boxSizing: 'border-box',
      borderRadius: '12px',
      padding: spacing(),
      margin: '0 20px 20px 0',
      // width: '216px',
      width: '310px',
      height: '113px',
      alignItems: 'center',
      position: 'relative',
      '&:hover': {
        border: '1px solid #CACACA',
      },
    },
    rootSelected: {
      background: '#E8F4FD',
      border: '1px solid #3198E3',
      '&:hover': {
        border: '1px solid #3198E3',
      },
    },
    imageBlock: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      height: '100%',
      maxWidth: '72px',
      minWidth: '53px',
      '& img': {
        borderRadius: '4px',
        maxWidth: '100%',
        maxHeight: '100%',
        '&:hover': {
          cursor: 'pointer',
        },
      },
      '& video': {
        maxWidth: '100%',
        borderRadius: '4px',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
    duration: {
      zIndex: 0,
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      top: '50%',
      color: 'white',
      width: '100%',
      textShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
      pointerEvents: 'none',
      fontSize: '12px',
    },
    durationWithoutPhoto: {
      top: '65%',
      color: 'black',
    },
    play: {
      zIndex: 0,
      position: 'absolute',
      top: 'calc(50% - 12px)',
      left: '0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      pointerEvents: 'none',
      '& svg path': {
        fill: '#ffffff',
      },
    },
    playBlack: {
      '& svg path': {
        fill: '#000000',
      },
    },
    info: {
      // display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      marginLeft: spacing(2),
      overflow: 'hidden',
      cursor: 'default',
      flexWrap: 'wrap',
    },
    name: {
      marginBottom: '6px',
      color: '#333333',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      cursor: 'default',
    },
    videoId: {
      width: '140px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'start',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    videoIdCopyBtn: {
      width: '64px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'end',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    date: {
      color: '#CACACA',
    },
    checkIcon: {
      position: 'absolute',
      bottom: '0px',
      right: '6px',
      '& svg path': {
        fill: '#3198E3',
      },
    },
    close: {
      position: 'absolute',
      top: '5px',
      right: '5px',
      '& svg path': {
        fill: '#C4C4C4',
      },
      '&:hover': {
        cursor: 'pointer',
      },
    },
    video: {
      height: '100%',
      maxWidth: '72px',
      minWidth: '72px',
    },
  }),
);

interface VideoCard {
  video: Video;
  selectedVideo?: Video | undefined;
  setSelectedVideo?: React.Dispatch<React.SetStateAction<Video | undefined>>;
  setShowDeleteDialog?: React.Dispatch<React.SetStateAction<boolean>>;
}

const VideoCard: React.FC<VideoCard> = ({
  video,
  selectedVideo,
  setSelectedVideo,
  setShowDeleteDialog,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { id, mp4, name, createdAt } = video;

  const date = createdAt.split('T')[0];

  const isSelected = selectedVideo?.id === id;

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootSelected]: isSelected,
      })}
      onClick={() => {
        if (!selectedVideo) {
          setSelectedVideo?.(video);
        } else if (selectedVideo.id !== id) {
          setSelectedVideo?.(video);
        } else {
          setSelectedVideo?.(undefined);
        }
      }}
    >
      <VideoMiniature video={video} className={classes.video} />

      <div className={classes.info}>
        {/* <span className={classes.name}> */}
        <div className={classes.videoId}>
          <b>videoId:</b> {id ?? t('components:video.listVideos.card.videoIdIsMissing')}
        </div>
        <div className={classes.videoIdCopyBtn}>
          <CopyButton text={id} placement="right">
            <IconButton aria-label="copy">
              <CopyIcon />
            </IconButton>
          </CopyButton>
        </div>
        {/* </span> */}
        <span className={classes.name}>
          {name ?? t('components:video.listVideos.card.nameIsMissing')}
        </span>

        <Typography variant="h5" className={classes.date}>
          {date}
        </Typography>
      </div>

      {isSelected && (
        <div className={classes.checkIcon}>
          <CheckCircleIcon />
        </div>
      )}

      {!!setShowDeleteDialog && (
        <span
          className={classes.close}
          onClick={() => setShowDeleteDialog?.(true)}
        >
          <CloseIcon />
        </span>
      )}
    </div>
  );
};

export default VideoCard;
