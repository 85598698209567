import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import DialogLayout from './DialogLayout';
import FileInfo from './FileInfo';
import Progress from './Progress';
import uploadToS3 from './uploadToS3';

interface StepUploadingProps {
  file: File;
  onSuccess: (s3key: string, name: string) => void;
  onCancel: () => void;
}

const StepUploading: FC<StepUploadingProps> = (props) => {
  const { file, onSuccess, onCancel } = props;
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);

  useEffectOnce(() => {
    uploadToS3(file)
      .then((s3key) => onSuccess(s3key, file.name))
      .catch(() => setError(t('components:video.uploadDialog.uploadError')));
  });

  // TODO: advanced: abort upload on cancel button
  return (
    <DialogLayout onCancel={error ? onCancel : undefined}>
      <FileInfo file={file} />
      <Progress
        error={error}
        label={t('components:video.uploadDialog.uploadProgress')}
      />
    </DialogLayout>
  );
};

export default StepUploading;
