import { Scenario } from '__generated__/types';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { ScenarioList } from 'components/scenario/components';
import ScenarioDialog from 'components/scenario/ScenarioDialog';
import { StateScenarioDialog } from 'components/scenario/useScenario';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeleteScenario from './useDeleteScenario';

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    footer: {
      minHeight: '57px',
      borderTop: '1px solid #DBDBDB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: spacing(8),
    },
    deleteButton: {
      marginLeft: spacing(3),
      '&.MuiButton-root': {
        color: palette.common.white,
        background: '#F2554E',
        '&:hover': {
          backgroundColor: '#CD4E49',
        },
        '&:active': {
          backgroundColor: '#B43C37',
        },
      },
    },
    changeButton: {
      marginLeft: spacing(2),
    },
  }),
);

interface ScenarioTabProps {
  scenarioDialogOpened: boolean;
  toggleScenarioDialog: (nextValue?: any) => void;
  initialStateScenario: StateScenarioDialog;
  setInitialStateScenario: React.Dispatch<
    React.SetStateAction<StateScenarioDialog>
  >;
}

export const ScenarioTab: React.FC<ScenarioTabProps> = ({
  scenarioDialogOpened,
  toggleScenarioDialog,
  initialStateScenario,
  setInitialStateScenario,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedScenario, setSelectedScenario] = useState<
    Scenario | undefined
  >(undefined);

  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [deleteScenario, { loading: deleting }] = useDeleteScenario(
    selectedScenario?.id ?? '',
    setSelectedScenario,
  );

  const onCloseScenarioDialog = () => {
    setSelectedScenario(undefined);
    toggleScenarioDialog();
  };

  return (
    <>
      <div className={classes.content}>
        <ScenarioList
          selectedScenario={selectedScenario}
          setSelectedScenario={setSelectedScenario}
        />
      </div>

      {selectedScenario && (
        <div className={classes.footer}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={() => setSelectedScenario(undefined)}
          >
            {t('commons:cancel')}
          </Button>

          <Button
            className={classes.deleteButton}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              setShowDeleteDialog(true);
            }}
          >
            {t('commons:delete')}
          </Button>

          <Button
            className={classes.changeButton}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => {
              setInitialStateScenario({ state: 'update_scenario' });
              toggleScenarioDialog();
            }}
          >
            {t('commons:change')}
          </Button>
        </div>
      )}

      <ConfirmationDialog
        open={showDeleteDialog}
        title={t(
          'routes:videoLibrary.tabs.scenario.deleting.deleteConfirmation',
        )}
        confirming={deleting}
        onConfirm={deleteScenario}
        onCancel={() => setShowDeleteDialog(false)}
      />

      {scenarioDialogOpened && (
        <ScenarioDialog
          scenario={
            initialStateScenario.state === 'create_scenario'
              ? undefined
              : selectedScenario
          }
          initialState={initialStateScenario}
          onClose={onCloseScenarioDialog}
        />
      )}
    </>
  );
};
