import { Conversation, Message } from '@twilio/conversations';
import { useEffect, useState } from 'react';

export function useConversationMessages(
  conversation?: Conversation | null,
): Message[] {
  const [messages, setMessages] = useState<Message[]>([]);

  const handleMessageAdded = (message: Message) =>
    setMessages((oldMessages) => [...oldMessages, message]);

  useEffect(() => {
    if (conversation) {
      conversation
        .getMessages()
        .then((newMessages) => setMessages(newMessages.items));
    } else {
      setMessages([]);
    }
    conversation?.on('messageAdded', handleMessageAdded);

    return () => {
      conversation?.off('messageAdded', handleMessageAdded);
    };
  }, [conversation]);

  return messages;
}
