import { useEffect, useRef } from 'react';

export function useHideJivo(hidden: boolean) {
  const stylesSheetRef = useRef<StyleSheet | null>(null);

  // Create stylesheets that hides jivochats
  useEffect(() => {
    const jivoHiderStyle = document.createElement('style');
    jivoHiderStyle.id = 'videoforce-jivo-styles';
    document.head.appendChild(jivoHiderStyle);
    if (!jivoHiderStyle.sheet) {
      return;
    }
    const sheet = jivoHiderStyle.sheet;
    sheet.insertRule(`jdiv { display: none !important }`, 0);
    stylesSheetRef.current = sheet;
  }, [stylesSheetRef]);

  // Enable this sheet when videoforce widget is visible and disable otherwise
  useEffect(() => {
    if (stylesSheetRef.current) {
      stylesSheetRef.current.disabled = !hidden;
    }
  }, [hidden, stylesSheetRef]);
}
