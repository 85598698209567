import { ScenarioStepButtonType } from '__generated__/types';
import { Menu, MenuItem } from '@material-ui/core';
import {
  ScenarioStep,
  ScenarioStepAnswerButton,
} from 'components/scenario/types';
import { bindPopover } from 'material-ui-popup-state';
import { PopupState } from 'material-ui-popup-state/core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface AnswerButtonMenuProps {
  popupState: PopupState;
  index: number;
  button: ScenarioStepAnswerButton;
  steps: ScenarioStep[];
}

export const AnswerButtonMenu: React.FC<AnswerButtonMenuProps> = ({
  popupState,
  index,
  button,
  steps,
}) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<ScenarioStep>();
  const currentOrder = watch('order');

  return (
    <Menu
      getContentAnchorEl={undefined}
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {steps
        .filter((step) => step.order !== currentOrder)
        .map((step) => {
          return (
            <MenuItem
              key={step.order}
              onClick={() => {
                setValue(
                  `buttons.${index}`,
                  {
                    id: button.id,
                    type: ScenarioStepButtonType.FollowStep,
                    name: button.name,
                    step: step.order,
                  },
                  {
                    shouldDirty: true,
                    shouldValidate: true,
                  },
                );
                popupState.close();
              }}
            >
              {step.name}
            </MenuItem>
          );
        })}

      <MenuItem
        onClick={() => {
          setValue(
            `buttons.${index}`,
            {
              id: button.id,
              type: ScenarioStepButtonType.FinishScenario,
              name: button.name,
            },
            {
              shouldDirty: true,
              shouldValidate: true,
            },
          );
          popupState.close();
        }}
      >
        {t(`components:scenario.dialog.step.button.answerActionVaraint.finish`)}
      </MenuItem>
    </Menu>
  );
};
