import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { isDesktop, isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import IconClientDisableAudio from '../icons/IconClientDisableAudio';

const useStyles = makeStyles(() =>
  createStyles({
    notificationAudioDisable: {
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: '15px',
      },
    },
    notificationAudioDisableMobile: {
      position: 'fixed',
      top: '80px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '125px',
      textAlign: 'center',
      fontWeight: 500,
      fontSize: '14px',
      fontFamily: 'Manrope, sans-serif',
    },
  }),
);

const NotificationAudioDisable: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div
      className={clsx({
        [classes.notificationAudioDisable]: isDesktop,
        [classes.notificationAudioDisableMobile]: isMobile,
      })}
    >
      <IconClientDisableAudio />
      {t('components:modalCall.clientAudioDisable')}
    </div>
  );
};

export default NotificationAudioDisable;
