import { Language } from '@videoforce/commons';
import { useAuth } from 'auth';
import { useEffect } from 'react';

import { changeLanguage } from './index';

/**
 * This hook monitors locale of current user and changes UI language
 */
export default function useAuthLocale() {
  const auth = useAuth();
  const locale = auth?.profile.locale;

  useEffect(() => {
    if (locale) {
      changeLanguage(locale as Language);
    }
  }, [locale]);
}
