import * as bodyPix from '@tensorflow-models/body-pix';
import { VideoFilter } from '@videoforce/client';

import BaseFilter from './BaseFilter';
import { FilterProps } from './types';

const foregroundColor = { r: 0, g: 0, b: 0, a: 0 };
const backgroundColor = { r: 255, g: 255, b: 255, a: 255 };

export interface BodyPixSubtractorProps extends FilterProps {
  config: Parameters<typeof bodyPix.load>[0];
}

export class BodyPixSubtractor extends BaseFilter implements VideoFilter {
  private _net?: bodyPix.BodyPix;
  private readonly _config: BodyPixSubtractorProps['config'];

  constructor(props: BodyPixSubtractorProps) {
    super(props);
    this._config = props.config;
  }

  setupEngine = async () => {
    // console.log('setting up bodypix');
    if (!this._net) {
      this._net = await bodyPix.load(this._config);
    }
    // console.log('bodypix setup complete');
  };

  public destroy() {
    super.destroy();
    this._net?.dispose();
  }

  protected async tick() {
    const segmentation = await this._net!.segmentPerson(this._videoInputEl, {
      flipHorizontal: false,
      internalResolution: 'medium',
      segmentationThreshold: 0.7,
    });
    // bodyPix.drawBokehEffect(
    //   this._dstEl,
    //   this._srcEl,
    //   segmentation,
    //   6,
    //   2,
    //   false,
    // );

    // Convert the segmentation into a mask to darken the background.

    const backgroundDarkeningMask = bodyPix.toMask(
      segmentation,
      foregroundColor,
      backgroundColor,
    );

    const opacity = 1.0;
    const maskBlurAmount = 2;
    // Draw the mask onto the image on a canvas.  With opacity set to 0.7 and
    // maskBlurAmount set to 3, this will darken the background and blur the
    // darkened background's edge.
    bodyPix.drawMask(
      this._canvas,
      this._videoInputEl,
      backgroundDarkeningMask,
      opacity,
      maskBlurAmount,
      false,
    );
  }
}
