import { TFunction } from 'i18next';
import get from 'lodash/get';
import { ReactNode } from 'react';

interface TranslatedProps {
  helperText?: ReactNode;
  error?: boolean;
}

export default function translateInputError(
  t: TFunction,
  errors: any,
  errorKey: string,
  helperText?: string,
): TranslatedProps {
  const err: any = get(errors, errorKey);
  if (!err?.message) {
    return { helperText };
  }
  if (typeof err.message === 'string') {
    return {
      error: true,
      helperText: t(err.message) as string,
    };
  } else if ('key' in err.message && 'options' in err.message) {
    return {
      error: true,
      helperText: t(err.message.key, err.message.options) as string,
    };
  }
  return {};
}
