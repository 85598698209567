import { createStyles, makeStyles } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from '@material-ui/core/Switch';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import { useOperatorState } from './OperatorStateProvider';
import { PermissionAlert } from './permission';

const ColoredSwitch = withStyles(({ palette }) => ({
  switchBase: {
    color: palette.error.main,
    '&$checked + $track': {
      backgroundColor: '#64fa5f !important',
    },
  },
  checked: {
    color: '#64fa5f !important',
  },
  track: {
    backgroundColor: palette.error.light,
  },
}))(Switch);

const useStyles = makeStyles(({ palette, breakpoints }) =>
  createStyles({
    status: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      [breakpoints.down('sm')]: {
        paddingTop: '10px',
      },
    },
    statusText: {
      marginTop: '8px',
      fontSize: '12px',
      color: palette.grey['400'],
      [breakpoints.down('sm')]: {
        marginTop: '3px',
      },
    },
  }),
);

/**
 * This component is used to control online/offline status for streamers
 * The switch will be inactive when current user has no streamer permissions
 */
const OperatorStateSwitch: React.FC = () => {
  const { t } = useTranslation();
  const { enabled, setDisabled } = useOperatorState();
  const [showAlert, toggleShowAlert] = useToggle(false);
  const classes = useStyles();

  useEffect(() => {
    if (enabled) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((result) => {
          result.getTracks().forEach((t) => t.stop());
        })
        .catch(() => {
          toggleShowAlert();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: true })
          .then((result) => {
            result.getTracks().forEach((t) => t.stop());
            setDisabled?.(false);
          })
          .catch(() => {
            toggleShowAlert();
          });
      } else {
        setDisabled?.(true);
      }
    },
    [setDisabled, toggleShowAlert],
  );

  return (
    <div className={classes.status}>
      <ColoredSwitch
        checked={enabled}
        onChange={onChange}
        disabled={!setDisabled}
      />
      <span className={classes.statusText}>
        {enabled
          ? t('components:streamerStateSwitch.online')
          : t('components:streamerStateSwitch.offline')}
      </span>
      <PermissionAlert
        showAlert={showAlert}
        toggleShowAlert={toggleShowAlert}
      />
    </div>
  );
};

export default OperatorStateSwitch;
