export enum LogLevel {
  DEBUG,
  INFO,
  WARN,
  ERROR,
  SILENT,
}

export interface LogSettings {
  level: LogLevel;
  children: Array<[string, LogSettings]>;
}
