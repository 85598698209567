import { Client } from '@videoforce/client';
import { getApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

const logger = window.VideoforceLogger.child('firebase');

export const firebasePushNotification = (client: Client) => {
  const firebase = getApp();
  const messaging = getMessaging(firebase);

  if (firebase && messaging) {
    navigator.serviceWorker.ready.then((registration) => {
      getToken(messaging, {
        serviceWorkerRegistration: registration,
        vapidKey: process.env.REACT_APP_FCM_WEB_KEY,
      }).then((fcmToken) => {
        logger.debug(`FCM Token`, fcmToken);
        client.setPushRegistrationId('fcm', fcmToken);
      });
    });
  }

  onMessage(messaging, (payload) => {
    client.handlePushNotification(payload);
  });
};
