/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
export type VideoDetailsFragment = { __typename?: 'Video', id: string, name?: string | null, status: Types.VideoStatus, mp4?: string | null, jpg?: string | null, createdAt: string, duration?: number | null, resolution?: { __typename?: 'VideoResolution', width: number, height: number } | null };

export const VideoDetailsFragmentDoc = gql`
    fragment VideoDetails on Video {
  id
  name
  status
  resolution {
    width
    height
  }
  mp4
  jpg
  createdAt
  duration
}
    `;