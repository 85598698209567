import { useEffect, useRef } from 'react';

export const useAudioLoop = (src?: string, enabled = true) => {
  const audioRef = useRef(new Audio(src));
  audioRef.current.loop = true;

  useEffect(() => {
    audioRef.current.loop = true;
    if (enabled) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [audioRef, enabled]);

  useEffect(() => {
    return () => {
      // Totally what we want
      // eslint-disable-next-line react-hooks/exhaustive-deps
      audioRef.current.pause();
    };
  }, [audioRef]);
};
