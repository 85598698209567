import IconButton from '@material-ui/core/IconButton';
import Modal from '@material-ui/core/Modal';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import ReactPlayer from 'react-player';

import Loading from './Loading';

const useStyles = makeStyles((theme) =>
  createStyles({
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    content: {
      position: 'absolute',
      width: 640,
      height: 480,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000',
    },
  }),
);

interface VideoModalProps {
  url?: string | null;
  loading?: boolean;
  renderError?: React.ReactNode;
  onClose: () => void;
}

const VideoModal: React.FC<VideoModalProps> = (props) => {
  const { url, loading, renderError, onClose } = props;
  const classes = useStyles();

  return (
    <Modal open onClose={onClose}>
      <div className={classes.content}>
        {url && <ReactPlayer url={url} controls width="640px" height="480px" />}

        {loading && <Loading />}

        {renderError}

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </Modal>
  );
};

export default VideoModal;
