import { ScenarioStepButtonType, ScenarioStepType } from '__generated__/types';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import CloseButton from 'components/CloseButton';
import { ScenarioStep, ScenarioStepButton } from 'components/scenario/types';
import { DragControls } from 'framer-motion';
import React, { ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { StepButtonActionInfo } from './StepButtonActionInfo';
import { StepButtonInfo } from './StepButtonInfo';
import { StepButtonName } from './StepButtonName';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    wrapper: {
      display: 'flex',
      marginTop: spacing(2),
    },
    root: {
      display: 'flex',
      flexDirection: 'column',
      border: '2px solid #ECF0F6',
      borderRadius: '8px',
      boxSizing: 'border-box',
      width: '245px',
    },
    header: {
      position: 'relative',
      backgroundColor: '#ECF0F7',
      padding: '12px 20px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    closeButton: {
      position: 'static',
      width: '24px',
      height: '24px',
    },
    content: {
      padding: '14px 20px',
    },
    select: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    selectTitle: {
      display: 'flex',
      flexDirection: 'column',
    },
    selectTitleName: {
      color: '#333333',
      opacity: 0.4,
      fontSize: '12px',
      lineHeight: '16px',
      fontWeight: 500,
    },
    selectedButtonType: {
      width: '150px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  }),
);

interface StepButtonProps {
  button: ScenarioStepButton;
  index: number;
  actionTitle: ScenarioStepType;
  trigger: (e: React.MouseEvent) => void;
  dragControls: DragControls;
  children?: ReactNode;
  steps?: ScenarioStep[];
}

export const StepButton: React.FC<StepButtonProps> = ({
  button,
  index,
  actionTitle,
  trigger,
  dragControls,
  children,
  steps,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { watch, setValue } = useFormContext<ScenarioStep>();

  return (
    <div className={classes.wrapper}>
      <div className={classes.root}>
        <div className={classes.header}>
          <StepButtonName index={index} button={button} key={button.id} />
          <CloseButton
            className={classes.closeButton}
            onClick={() =>
              setValue(
                'buttons',
                [...watch('buttons')].filter((item) => item.id !== button.id),
              )
            }
          />
        </div>

        <div className={classes.content}>
          <div>
            <div className={classes.select} onClick={(e) => trigger(e)}>
              <div className={classes.selectTitle}>
                <div className={classes.selectTitleName}>
                  {t(
                    `components:scenario.dialog.step.button.title.${actionTitle}`,
                  )}
                </div>
                <Typography
                  variant="h5"
                  gutterBottom
                  className={classes.selectedButtonType}
                >
                  <StepButtonActionInfo button={button} steps={steps} />
                </Typography>
              </div>
              <ArrowDropDownIcon />
              {button.type === ScenarioStepButtonType.OpenVideochat && (
                <StepButtonInfo
                  text={t(
                    `components:scenario.dialog.step.button.info.OPEN_VIDEOCHAT`,
                  )}
                />
              )}
            </div>
            {children}
          </div>
        </div>
      </div>

      <DragIndicatorOutlinedIcon
        onPointerDown={(event) => dragControls.start(event)}
        style={{ color: '#EAEAEA' }}
      />
    </div>
  );
};
