import { Scenario } from '__generated__/types';
import { Button } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import ConfirmationDialog from 'components/ConfirmationDialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useToggle from 'react-use/lib/useToggle';

import ScenarioDialog from '../ScenarioDialog';
import { ScenarioCard } from './ScenarioCard';
const useStyles = makeStyles(() =>
  createStyles({
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

interface ScenarioSelectProps {
  scenario?: Scenario | null;
  className?: string;
  selectScenario?: (
    onDone: () => void,
    scenario: Scenario,
  ) => Promise<void> | void;
  unSelectWidgetScenario?: () => Promise<void> | void;
  isTextButton?: boolean;
}

export const ScenarioSelect: React.FC<ScenarioSelectProps> = ({
  scenario,
  className,
  selectScenario,
  unSelectWidgetScenario,
  isTextButton,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialogOpened, toggleDialogOpened] = useToggle(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  return (
    <div className={clsx(classes.wrapper, className)}>
      {scenario && (
        <ScenarioCard
          scenario={scenario}
          setShowUnselectDialog={
            unSelectWidgetScenario ? setShowDeleteDialog : undefined
          }
        />
      )}

      <Button
        onClick={toggleDialogOpened}
        variant={isTextButton ? 'text' : 'contained'}
        color={scenario ? 'default' : 'primary'}
        size="small"
      >
        {scenario
          ? t('components:scenario.selectingButtons.replace')
          : t('components:scenario.selectingButtons.select')}
      </Button>

      {dialogOpened && (
        <ScenarioDialog
          initialState={{ state: 'selecting_scenario' }}
          onClose={toggleDialogOpened}
          selectScenario={selectScenario}
        />
      )}

      <ConfirmationDialog
        open={showDeleteDialog}
        title={t('components:scenario.selectingButtons.deleteConfirmation')}
        onConfirm={unSelectWidgetScenario}
        onCancel={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};
