import CircularProgress from '@material-ui/core/CircularProgress';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    wrapper: {
      position: 'relative',
    },
    progress: {
      color: palette.secondary.main,
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);

interface Props {
  id?: string;
  loading?: boolean;
  className?: string;
  circleClassName?: string;
  children?: ReactNode;
}

const ButtonProgress: React.FC<Props> = ({
  id,
  loading,
  children,
  className,
  circleClassName,
}) => {
  const classes = useStyles();
  return (
    <div id={id} className={clsx(classes.wrapper, className)}>
      {children}
      {loading && (
        <CircularProgress
          size={24}
          className={classes.progress}
          classes={{ circle: circleClassName }}
        />
      )}
    </div>
  );
};

export default ButtonProgress;
