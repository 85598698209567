import { Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';

import useVideoNode from './useVideoNode';
import { Video } from './Video';

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    videoWrap: {
      position: 'relative',
      width: 320,
      height: 240,
      background: '#C4C4C4',
      borderRadius: '10px',
    },
    video: {
      width: 320,
      height: 240,
      objectFit: 'cover',
      borderRadius: '10px',
    },
    caption: {
      color: palette.grey[400],
      position: 'absolute',
      bottom: '10px',
      left: '20px',
    },
    btn: {
      marginTop: '10px',
    },
  }),
);

interface Props {
  src?: string | Blob | MediaStream | { mp4: string } | null;
  caption?: string;
  children?: ReactNode;
}

const VideoWrapper: React.FC<Props> = ({ caption, src, children }) => {
  const classes = useStyles();
  const videoSrc = useVideoNode(src);
  return (
    <div className={classes.videoWrap}>
      <Video node={videoSrc} className={classes.video} mirror />
      {!!caption && (
        <Typography variant="h5" className={classes.caption}>
          {caption}
        </Typography>
      )}
      {children}
    </div>
  );
};

export default VideoWrapper;
