import React, { useEffect, useRef } from 'react';

import { IVideoTrack } from '../types';

interface VideoTrackProps {
  track: IVideoTrack;
  className: string;
}

export const VideoTrack: React.FC<VideoTrackProps> = ({ track, className }) => {
  const ref = useRef<HTMLVideoElement>(null!);

  useEffect(() => {
    if (track) {
      const el = ref.current;
      el.muted = true;
      track.attach(el);
      return () => {
        track.detach(el);
      };
    }
  }, [track]);

  // The local video track is mirrored if it is not facing the environment.

  return <video ref={ref} className={className} />;
};
