import translateGraphqlError from 'i18n/helpers/translateGraphqlError';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEffectOnce from 'react-use/lib/useEffectOnce';

import { useCreateVideoMutation } from './createVideo.generated';
import DialogLayout from './DialogLayout';
import Progress from './Progress';

interface StepSavingProps {
  s3key: string;
  name: string;
  onCancel: () => void;
  onSuccess: (videoId: string) => void;
}

const StepSaving: FC<StepSavingProps> = (props) => {
  const { onCancel, onSuccess, s3key, name } = props;
  const { t } = useTranslation();
  const [error, setError] = useState<string | null>(null);
  const [mutate] = useCreateVideoMutation({
    variables: { s3key, name },
    refetchQueries: ['listVideos'],
  });

  useEffectOnce(() => {
    mutate()
      .then((resp) => {
        if (resp.data?.createVideo.__typename === 'Video') {
          onSuccess(resp.data.createVideo.id);
        } else {
          setError(translateGraphqlError(t, resp.data?.createVideo));
        }
      })
      .catch((_err) => {
        setError(t('components:video.uploadDialog.saveError'));
      });
  });

  // TODO: advanced: in some cases it's possible to retry (e.g. too many requests)
  return (
    <DialogLayout onCancel={error ? onCancel : undefined}>
      <Progress
        error={error}
        label={t('components:video.uploadDialog.saveProgress')}
      />
    </DialogLayout>
  );
};

export default StepSaving;
