/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Types from '../../__generated__/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteTourMutationVariables = Types.Exact<{
  tour: Types.Tour;
  skipped?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;


export type CompleteTourMutation = { __typename?: 'Mutation', completeTour?: { __typename: 'Error', id: string, message: string } | { __typename: 'ForbiddenError', id: string, message: string } | { __typename: 'NotFoundError', id: string, message: string } | { __typename: 'User', id: string, completedTours: Array<Types.Tour> } | null };


export const CompleteTourDocument = gql`
    mutation completeTour($tour: Tour!, $skipped: Boolean) {
  completeTour(tour: $tour, skipped: $skipped) {
    __typename
    ... on IError {
      id
      message
    }
    ... on User {
      id
      completedTours
    }
  }
}
    `;
export type CompleteTourMutationFn = Apollo.MutationFunction<CompleteTourMutation, CompleteTourMutationVariables>;

/**
 * __useCompleteTourMutation__
 *
 * To run a mutation, you first call `useCompleteTourMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTourMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeTourMutation, { data, loading, error }] = useCompleteTourMutation({
 *   variables: {
 *      tour: // value for 'tour'
 *      skipped: // value for 'skipped'
 *   },
 * });
 */
export function useCompleteTourMutation(baseOptions?: Apollo.MutationHookOptions<CompleteTourMutation, CompleteTourMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteTourMutation, CompleteTourMutationVariables>(CompleteTourDocument, options);
      }
export type CompleteTourMutationHookResult = ReturnType<typeof useCompleteTourMutation>;
export type CompleteTourMutationResult = Apollo.MutationResult<CompleteTourMutation>;
export type CompleteTourMutationOptions = Apollo.BaseMutationOptions<CompleteTourMutation, CompleteTourMutationVariables>;