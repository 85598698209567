import {
  Maybe,
  Scenario,
  ScenarioStepActionButton,
  ScenarioStepButtonType,
  Video,
} from '__generated__/types';
import { v4 } from 'uuid';

import { ScenarioStep, ScenarioStepButton } from '../types';

interface DefaultScenarioCreating {
  id: string;
  name: string;
  steps: never[];
}

interface DefaultScenarioUpdating {
  id: string;
  name: string;
  steps: ScenarioStep[];
}

const convertButtons = (
  stepButtons: Maybe<ScenarioStepActionButton[]> | undefined,
) => {
  const customButtons = stepButtons?.map(convertButton);
  return customButtons;
};

const convertButton = (
  button: ScenarioStepActionButton,
): ScenarioStepButton | false => {
  switch (button.__typename) {
    case 'ScenarioStepFinishScenarioButton':
      return {
        type: ScenarioStepButtonType.FinishScenario,
        id: v4(),
        name: button.name,
      };
    case 'ScenarioStepFollowStepButton':
      return {
        type: ScenarioStepButtonType.FollowStep,
        id: v4(),
        name: button.name,
        step: button.step,
      };
    case 'ScenarioStepCssSelectorButton':
      return {
        type: ScenarioStepButtonType.CssSelector,
        id: v4(),
        name: button.name,
        selector: button.selector,
      };
    case 'ScenarioStepFollowUrlButton':
      return {
        type: ScenarioStepButtonType.FollowUrl,
        id: v4(),
        name: button.name,
        url: button.url,
      };
    case 'ScenarioStepOpenVideochatButton':
      return {
        type: ScenarioStepButtonType.OpenVideochat,
        id: v4(),
        name: button.name,
      };
    default:
      return false;
  }
};

export const convertDefaultValues = (
  scenario: Scenario | undefined,
): DefaultScenarioCreating | DefaultScenarioUpdating => {
  if (scenario) {
    const customSteps: ScenarioStep[] = scenario.steps.map((step) => {
      return {
        name: step.name,
        type: step.type,
        order: step.order,
        videoId: step.video?.id,
        video: step.video as Video | undefined,
        buttons: step.buttons
          ? (convertButtons(step.buttons) as ScenarioStepButton[])
          : [],
      };
    });

    return {
      id: scenario.id,
      name: scenario.name,
      steps: [...customSteps],
    };
  } else {
    return {
      id: '',
      name: '',
      steps: [],
    };
  }
};
