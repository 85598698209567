import { VideoFilter } from '@videoforce/client';
import { useState } from 'react';

export function usePersistentFilter(
  factory: (enabled: boolean) => VideoFilter,
  storageKey: string,
) {
  const [filter] = useState<VideoFilter>(() => {
    const result = factory(localStorage.getItem(storageKey) === '1');
    const originalSetEnabled = result.setEnabled;
    result.setEnabled = async (enabled: boolean) => {
      await originalSetEnabled.apply(result, [enabled]);
      localStorage.setItem(storageKey, enabled ? '1' : '0');
      return enabled;
    };
    return result;
  });
  return filter;
}
