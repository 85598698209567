import { Video } from '__generated__/types';
import Button from '@material-ui/core/Button';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ConfirmationDialog from 'components/ConfirmationDialog';
import {
  StateUploadDialog,
  UploadVideoDialog,
  UploadVideoDialogStart,
  VideosList,
} from 'components/video';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import useDeleteVideo from './useDeleteVideo';

const useStyles = makeStyles(({ spacing, palette }) =>
  createStyles({
    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    footer: {
      minHeight: '57px',
      borderTop: '1px solid #DBDBDB',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: spacing(8),
    },
    deleteButton: {
      marginLeft: spacing(2),
      '&.MuiButton-root': {
        color: palette.common.white,
        background: '#F2554E',
        '&:hover': {
          backgroundColor: '#CD4E49',
        },
        '&:active': {
          backgroundColor: '#B43C37',
        },
      },
    },
    recordingButton: {
      marginLeft: 'auto',
      marginRight: '10px',
    },
  }),
);

interface VideoLibraryTabProps {
  initialState: StateUploadDialog;
  videoDialogOpened: boolean;
  toggleVideoDialogOpened: (nextValue?: any) => void;
}

export const VideoLibraryTab: React.FC<VideoLibraryTabProps> = ({
  initialState,
  videoDialogOpened,
  toggleVideoDialogOpened,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedVideo, setSelectedVideo] = useState<Video | undefined>(
    undefined,
  );
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const [deleteVideo, { loading: deleting }] = useDeleteVideo(
    selectedVideo?.id ?? '',
    setSelectedVideo,
  );

  return (
    <>
      <div className={classes.content}>
        <VideosList
          selectedVideo={selectedVideo}
          setSelectedVideo={setSelectedVideo}
        />
      </div>

      {selectedVideo && (
        <div className={classes.footer}>
          <Button
            variant="contained"
            disableElevation
            size="small"
            onClick={() => setSelectedVideo(undefined)}
          >
            {t('commons:cancel')}
          </Button>

          <Button
            className={classes.deleteButton}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => setShowDeleteDialog(true)}
          >
            {t('commons:delete')}
          </Button>
        </div>
      )}

      <ConfirmationDialog
        open={showDeleteDialog}
        title={t('routes:videoLibrary.tabs.video.deleting.deleteConfirmation')}
        confirming={deleting}
        onConfirm={deleteVideo}
        onCancel={() => setShowDeleteDialog(false)}
      />

      {videoDialogOpened && (
        <UploadVideoDialog
          onClose={toggleVideoDialogOpened}
          startFrom={UploadVideoDialogStart.LIST}
          initialState={initialState}
        />
      )}
    </>
  );
};
