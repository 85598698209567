import { VideoFilter } from '@videoforce/client';

import BaseFilter from './BaseFilter';

export class ScreenShareFilter extends BaseFilter implements VideoFilter {
  private _screen!: MediaStream;
  private _screenEl!: HTMLVideoElement;
  private _screenW!: number;
  private _screenH!: number;
  private _sw!: number;
  private _sh!: number;

  public async setup(source: MediaStreamConstraints | MediaStream) {
    super.setup(source);
    // @ts-ignore
    this._screen = await navigator.mediaDevices.getDisplayMedia();
    const { width, height } = this._screen.getVideoTracks()[0].getSettings();
    this._screenW = width!;
    this._screenH = height!;
    this._canvas.width = width!;
    this._canvas.height = height!;

    let screenEl: HTMLVideoElement = document.getElementById(
      'screen-share-video',
    ) as any;
    if (!screenEl) {
      screenEl = document.createElement('video');
      document.body.appendChild(screenEl);
      screenEl.id = 'screen-share-video';
    }
    screenEl.autoplay = true;
    screenEl.loop = true;
    screenEl.muted = true;
    screenEl.setAttribute('style', 'display: none');
    screenEl.width = width!;
    screenEl.height = height!;
    screenEl.style.width = `${width}px`;
    screenEl.style.height = `${height}px`;

    this._sw = Math.max(this._w, this._screenW / 5);
    this._sh = (this._sw * this._h) / this._w;

    this._screenEl = screenEl;
    this._screenEl.srcObject = this._screen;
    this._screenEl.play();
  }

  setupEngine = async () => {
    return Promise.resolve();
  };

  public destroy() {
    super.destroy();
    this._screen.getTracks().forEach((t) => t.stop());
  }

  protected async tick() {
    this._ctx.drawImage(this._screenEl, 0, 0, this._screenW, this._screenH);
    this._ctx.drawImage(this._videoInputEl, 0, 0, this._sw, this._sh);
  }
}
