import { Conversation } from '@twilio/conversations';
import React, { createContext, FC, PropsWithChildren, useContext } from 'react';

import { useEnterConversationOperator } from './useEnterConversationOperator';

export interface ConversationOperatorContext {
  enterConversation: (conversationId: string) => void;
  conversation: Conversation | null;
}

const ConversationOperatorCtx = createContext<ConversationOperatorContext>({
  enterConversation: () => {},
  conversation: null,
});

export const ConversationOperatorProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const { conversation, enterConversation } = useEnterConversationOperator();

  return (
    <ConversationOperatorCtx.Provider
      value={{ enterConversation, conversation }}
    >
      {children}
    </ConversationOperatorCtx.Provider>
  );
};

export const useConversationOperator = () =>
  useContext(ConversationOperatorCtx);
