import { VideoStatus } from '__generated__/types';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { VideoDetailsFragment } from '../videoDetails.generated';
import DialogLayout from './DialogLayout';
import { useGetVideoQuery } from './getVideo.generated';
import Progress from './Progress';

interface StepEncodingProps {
  videoId: string;
  onSuccess: (video: VideoDetailsFragment) => void;
  onCancel: () => void;
}

const StepEncoding: FC<StepEncodingProps> = (props) => {
  const { onCancel, onSuccess, videoId } = props;
  const [error, setError] = useState<string | null>(null);
  const { t } = useTranslation();
  const onSuccessRef = useRef(onSuccess);
  onSuccessRef.current = onSuccess;

  const { data, error: queryError } = useGetVideoQuery({
    variables: { id: videoId },
    pollInterval: 10500, // AWS updates every 10 seconds
  });

  useEffect(() => {
    if (queryError) {
      setError(t('components:video.uploadDialog.encodingError'));
      return;
    }
    if (!data?.getVideo) {
      setError(null);
      return;
    }
    const { status } = data.getVideo;
    if (status === VideoStatus.Complete) {
      onSuccessRef.current(data.getVideo);
    } else if (status === VideoStatus.Error) {
      setError(t('components:video.uploadDialog.encodingError'));
    } else {
      setError(null);
    }
  }, [queryError, data, setError, t, onSuccessRef]);

  // Once we got an error, it's impossible to retry. We have to start over and upload new video
  return (
    <DialogLayout onCancel={error ? onCancel : undefined}>
      <Progress
        error={error}
        label={t('components:video.uploadDialog.encodingProgress')}
      />
    </DialogLayout>
  );
};

export default StepEncoding;
