export interface ServiceWorkerState {
  waitingServiceWorker: ServiceWorker | null;
  isUpdateAvailable: boolean;
}

export enum SWIcoming {
  SKIP_WAITING = 'SKIP_WAITING',
  GET_VERSION = 'GET_VERSION',
}

export enum SWOutgoing {
  VERSION = 'VERSION',
}
