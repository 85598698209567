// workaround function for mobile address panel
// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
export default function setWindowHeightCssVar() {
  function setDocHeight() {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight / 100}px`,
    );
  }

  window.addEventListener('resize', setDocHeight);
  window.addEventListener('orientationchange', setDocHeight);

  setDocHeight();
}
