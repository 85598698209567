import { createStyles, makeStyles } from '@material-ui/core/styles';
import { motion } from 'framer-motion';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    indicator: {
      width: theme.spacing(2),
      height: theme.spacing(2),
      borderRadius: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
  }),
);

const RecordingIndicator: React.FC = () => {
  const classes = useStyles();
  return (
    <motion.div
      animate={{ scale: [1, 0.9, 0.9, 1], opacity: [1, 0, 0, 1] }}
      transition={{
        repeat: Infinity,
        times: [0, 0.3, 0.7, 1],
        type: 'keyframes',
      }}
    >
      <div className={classes.indicator} />
    </motion.div>
  );
};

export default RecordingIndicator;
