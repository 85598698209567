import getStreamerEnabledPersistenceKey from 'utils/getStreamerEnabledPersistenceKey';

import getClaims from './getClaims';

export default async function preSignOut() {
  // Then try to clean up persistent enabled state for streamer
  const claims = await getClaims();
  if (claims) {
    localStorage.removeItem(getStreamerEnabledPersistenceKey(claims?.id));
  }
}
