import { Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import ConfirmationDialog from 'components/ConfirmationDialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StateScenarioDialog } from '../useScenario';
import { getHeaderTitle } from './getHeaderTitle';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: '26px',
      borderBottom: '1px solid #DBDBDB',
    },
    wrapper: {
      flexDirection: 'column',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: spacing(),
      padding: 0,
      color: '#BEBEBE',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 600,
      '&:hover': {
        cursor: 'pointer',
      },
    },
    backIcon: {
      height: '16px',
      width: '16px',
      fill: '#CACACA',
    },
    closeButton: {
      marginLeft: 'auto',
    },
  }),
);

interface ScenarioDialogHeaderProps {
  state: StateScenarioDialog;
  onDone?: () => void;
  action: () => void;
  stepChanged: boolean;
  scenarioChanged: boolean;
}

export const ScenarioDialogHeader: React.FC<ScenarioDialogHeaderProps> = ({
  state,
  onDone,
  action,
  stepChanged,
  scenarioChanged,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [showBackDialog, setShowBackDialog] = useState(false);

  const isEditStep =
    state.state === 'create_step' || state.state === 'update_step';

  return (
    <>
      <MuiDialogTitle disableTypography className={classes.root}>
        <div className={classes.wrapper}>
          {isEditStep && (
            <div
              className={classes.backButton}
              onClick={() => {
                if (stepChanged) {
                  setShowBackDialog(true);
                } else {
                  action();
                }
              }}
            >
              <ArrowBackIosIcon className={classes.backIcon} />
              {t('routes:plans.selectPaymentMethod.backButton')}
            </div>
          )}

          <Typography variant="h2">{getHeaderTitle(state)}</Typography>
        </div>

        {onDone && !isEditStep ? (
          <IconButton
            aria-label="close"
            onClick={() => {
              if (scenarioChanged) {
                setShowBackDialog(true);
              } else {
                onDone();
              }
            }}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>

      <ConfirmationDialog
        open={showBackDialog}
        title={t('components:scenario.dialog.header.backDialog.text')}
        confirmButton={t(
          'components:scenario.dialog.header.backDialog.confirmButton',
        )}
        cancelButton={t(
          'components:scenario.dialog.header.backDialog.cancelButton',
        )}
        onConfirm={isEditStep ? action : onDone}
        onCancel={() => setShowBackDialog(false)}
      />
    </>
  );
};
