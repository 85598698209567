import { Error as DataError } from '__generated__/types';
import { ApolloLink, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const logger = window.VideoforceLogger.child('apollo');

/**
 * This function traverses graphql query result and return array of errors found in tree
 * @param input
 * @returns
 */
export function deepFindErrors(input: any): DataError[] {
  if (!input) {
    return [];
  }
  const result: any[] = [];
  for (const v of Object.values(input)) {
    if (typeof v === 'object') {
      if ((v as any)?.__typename?.includes('Error')) {
        result.push(v);
      } else {
        result.push(...deepFindErrors(v));
      }
    } else if (Array.isArray(v)) {
      v.forEach((i) => result.push(...deepFindErrors(i)));
    }
  }
  return result;
}

/**
 * This links handles errors that are correctly sent by server
 */
const dataErrorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    try {
      const { data } = result;
      const op = operation.operationName;
      if (data) {
        const foundErrors = deepFindErrors(data);
        foundErrors.forEach((e) => {
          const { id, __typename, message, ...rest } = e;
          // Generic errors have higher severity than concrete errors (they should be handled by the app)
          if (__typename === 'Error') {
            logger.error(`[${op}][${id}]`, message, rest);
          } else {
            logger.warn(`[${op}][${id}]`, message, rest);
          }
        });
      }
    } catch {}
    return result;
  });
});

/**
 * This link handles unexpected errors (malformed queries, network errors etc)
 */
const errorLink = onError(({ operation, graphQLErrors, networkError }) => {
  try {
    const op = operation.operationName;
    graphQLErrors?.forEach((e) => {
      logger.error(`[${op}][graphql_error]`, e);
    });
    if (networkError) {
      const { name, message, ...rest } = networkError;
      logger.error(`[${op}][${name}]`, message, rest);
    }
  } catch {}
});

export default from([errorLink, dataErrorLink]);
