import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { StatusMessages, useReactMediaRecorder } from 'react-media-recorder';
import useBoolean from 'react-use/lib/useBoolean';
import useInterval from 'react-use/lib/useInterval';

const useRecording = (
  duration: number,
  isAudioEnabled?: boolean,
): {
  status: StatusMessages;
  counter: number;
  mediaBlobUrl?: string | null;
  file: File | null;
  start: () => void;
  stop: () => void;
  reset: () => void;
  isPermissionDeniedAudio: boolean;
} => {
  const {
    status,
    startRecording,
    stopRecording,
    mediaBlobUrl,
    clearBlobUrl,
    error,
  } = useReactMediaRecorder({
    video: true,
    audio: isAudioEnabled,
    blobPropertyBag: {
      type: 'video/webm',
    },
    onStop: (blobUrl, blob) => {
      setFile(
        new File([blob], `${format(new Date(), 'dd_MM_yyyy')}_recording.webm`, {
          type: 'video/webm',
        }),
      );
    },
  });
  const [counter, setCounter] = useState(duration);
  const [isRunning, toggleIsRunning] = useBoolean(false);
  const [file, setFile] = useState<File | null>(null);
  const [isPermissionDeniedAudio, setIsPermissionDeniedAudio] = useState(false);

  useInterval(
    () => {
      if (counter === 0) {
        toggleIsRunning();
        stopRecording();
      }
      setCounter(counter - 1);
    },
    isRunning ? 1000 : null,
  );

  useEffect(() => {
    if (error === 'permission_denied') {
      setIsPermissionDeniedAudio(true);
    }
  }, [error]);

  return {
    status,
    counter,
    mediaBlobUrl,
    file,
    isPermissionDeniedAudio,
    start: () => {
      toggleIsRunning();
      startRecording();
    },
    stop: () => {
      toggleIsRunning();
      setCounter(duration);
      clearBlobUrl();
    },
    reset: () => {
      clearBlobUrl();
      setCounter(duration);
    },
  };
};

export default useRecording;
