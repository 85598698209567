import { ScenarioStepType } from '__generated__/types';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { ScenarioStep } from 'components/scenario/types';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getDefaultAnswerButton, getDefaultCtaButton } from '../constants';

interface AddNewButtonProps {
  type: ScenarioStepType;
}

export const AddNewButton: React.FC<AddNewButtonProps> = ({ type }) => {
  const { t } = useTranslation();
  const { watch, setValue } = useFormContext<ScenarioStep>();

  const addDefaultButton = () => {
    if (type === ScenarioStepType.AnswerButtons) {
      setValue('buttons', [...watch('buttons'), getDefaultAnswerButton()]);
    }

    if (type === ScenarioStepType.Cta) {
      setValue('buttons', [...watch('buttons'), getDefaultCtaButton()]);
    }
  };

  return (
    <Button
      color="default"
      size="small"
      startIcon={<AddCircleOutlineIcon htmlColor="#9C9C9C" />}
      onClick={addDefaultButton}
    >
      {t(`components:scenario.dialog.step.button.addButton`)}
    </Button>
  );
};
