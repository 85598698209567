import { createStyles, makeStyles } from '@material-ui/core/styles';
import Loading from 'components/Loading';
import { OperatorStateProvider } from 'components/OperatorStateProvider';
import useAuthLocale from 'i18n/useAuthLocale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import PrivateRoute from '../components/PrivateRoute';
import { AuthRoute, ShopifyRoute } from './auth';
import UserRouter from './UserRouter';

const useStyles = makeStyles(() =>
  createStyles({
    rootRouter: {
      width: '100%',
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    },
  }),
);

const RootRouter: React.FC = () => {
  const classes = useStyles();
  useAuthLocale();
  const { t } = useTranslation();
  return (
    <div className={classes.rootRouter}>
      <React.Suspense
        fallback={<Loading title={t('components:rootRouter.loading')} />}
      >
        <Routes>
          <Route path="/auth/*" element={<AuthRoute />} />
          <Route path="/integrations/shopify/auth" element={<ShopifyRoute />} />
          <Route
            path="*"
            element={
              <PrivateRoute>
                <OperatorStateProvider>
                  <UserRouter />
                </OperatorStateProvider>
              </PrivateRoute>
            }
          />
        </Routes>
      </React.Suspense>
    </div>
  );
};

export default RootRouter;
