import HelpIcon from '@material-ui/icons/Help';
import {
  IntroTourStep,
  introTourTarget,
  useEndIntroTour,
} from 'components/tours/intro';
import { bindTrigger } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import HelpPopover from './HelpPopover';
import SidebarIcon from './SidebarIcon';

const HelpMenu: React.FC = () => {
  const {
    i18n: { language },
  } = useTranslation();
  const endIntro = useEndIntroTour();

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'accountMenu',
  });
  const triggerProps = bindTrigger(popupState);

  const helpURL = useMemo(() => {
    if (language === 'ru' && process.env.NODE_ENV === 'production') {
      return 'https://videoforce.ru/help';
    } else {
      return `https://${process.env.REACT_APP_DOMAIN}/help`;
    }
  }, [language]);

  return (
    <>
      <SidebarIcon
        {...triggerProps}
        onClick={(e) => {
          endIntro();
          triggerProps.onClick(e);
        }}
        className={introTourTarget(IntroTourStep.SHOW_HELP)}
      >
        <HelpIcon />
      </SidebarIcon>

      <HelpPopover popupState={popupState} helpURL={helpURL} />
    </>
  );
};

export default HelpMenu;
