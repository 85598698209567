import { useConversationsClient } from '@videoforce/client';
import getClaims from 'auth/getClaims';
import { useCallback, useState } from 'react';

export const useUpdateConversationsToken = (): {
  updateConversationsToken: () => Promise<void>;
  updatedConversationsToken: string | null;
} => {
  const client = useConversationsClient();
  const [updatedConversationsToken, setUpdatedConversationsToken] = useState<
    string | null
  >(null);

  const updateConversationsToken = useCallback(async () => {
    await getClaims().then((claims) => {
      if (claims) {
        setUpdatedConversationsToken(claims.conversationsToken);
        client?.updateToken(claims.conversationsToken);
      }
    });
  }, [client]);

  return { updateConversationsToken, updatedConversationsToken };
};
