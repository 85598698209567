import { Button, Dialog, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'auth';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    modal: {
      maxWidth: 360,
    },
    content: {
      padding: spacing(4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
    },
    h3: {
      fontWeight: 600,
      marginBottom: spacing(3),
    },
    text: {
      margin: '0 0 10px',
    },
    button: {
      marginBottom: spacing(1),
      '&:last-child': {
        marginBottom: 0,
      },
    },
    buttonGroup: {
      marginTop: spacing(4),
    },
  }),
);

export interface Props {
  onClose: () => void;
}

const LatestCallLimitedDialog: React.FC<Props> = ({ onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();

  const onChangePlan = useCallback(() => {
    onClose();
    navigate('/plans');
  }, [onClose, navigate]);

  return (
    <Dialog
      open
      classes={{
        paper: classes.modal,
      }}
      onClose={onClose}
    >
      <div className={classes.content}>
        <Typography variant="h3" className={classes.h3}>
          {t('components:modalEndTime.title')}
        </Typography>

        <p className={classes.text}>{t('components:modalEndTime.text2')}</p>

        <div className={classes.buttonGroup}>
          {auth?.claims.owner && (
            <Button
              color="primary"
              variant="contained"
              className={classes.button}
              fullWidth
              onClick={onChangePlan}
            >
              {t('components:modalEndTime.btnLink')}
            </Button>
          )}

          <Button
            variant="contained"
            className={classes.button}
            onClick={onClose}
            fullWidth
          >
            {t('commons:closeWindow')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default LatestCallLimitedDialog;
