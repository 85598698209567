import { Button } from '@material-ui/core';
import translateGraphqlError from 'i18n/helpers/translateGraphqlError';
import { OptionsObject, useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface MaybeError {
  __typename?: string;
  message?: string;
}

export default function useSnackbarError() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const options = useMemo<OptionsObject>(
    () => ({
      action: (key: string) => (
        <Button
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
        >
          {t('commons:close')}
        </Button>
      ),
      variant: 'error',
    }),
    [closeSnackbar, t],
  );

  return useCallback(
    (err?: MaybeError | string) => {
      if (typeof err === 'string') {
        enqueueSnackbar(t(err), { variant: 'error' });
      } else {
        enqueueSnackbar(translateGraphqlError(t, err), options);
      }
    },
    [t, options, enqueueSnackbar],
  );
}
