import { createStyles, makeStyles } from '@material-ui/core/styles';
import PrivateRoute from 'components/PrivateRoute';
import { IntroTour } from 'components/tours/intro';
import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import AdminRoute from './admin';
import CallPage from './calls';
import ConversationsPage from './conversations';
import EmployeesRoute from './employees';
import PlansRoute from './plans';
import UsersRoute from './users';
import VideoLibraryRoute from './video-library/VideoLibraryRoute';
import WidgetsRoute from './widgets';

const useStyles = makeStyles(() =>
  createStyles({
    userRouterMain: {
      display: 'flex',
      flex: 1,
      overflow: 'hidden',
    },
  }),
);

const UserRouterDesktop: React.FC = () => {
  const classes = useStyles();
  const { search } = useLocation();
  return (
    <div className={classes.userRouterMain}>
      <IntroTour />
      <Routes>
        <Route path="conversations/*" element={<ConversationsPage />} />
        <Route path="calls/*" element={<CallPage />} />
        <Route path="widgets/*" element={<WidgetsRoute />} />

        <Route
          path="admin/*"
          element={
            <PrivateRoute>
              <AdminRoute />
            </PrivateRoute>
          }
        />

        <Route path="users/*" element={<UsersRoute />} />

        <Route
          path="employees/*"
          element={
            <PrivateRoute manager>
              <EmployeesRoute />
            </PrivateRoute>
          }
        />

        <Route path="video-library/*" element={<VideoLibraryRoute />} />

        <Route
          path="plans/*"
          element={
            <PrivateRoute owner>
              <PlansRoute />
            </PrivateRoute>
          }
        />

        {/* Preserve search string during redirect so we can use it later */}
        <Route path="/" element={<Navigate to={`/widgets${search}`} />} />
      </Routes>
    </div>
  );
};

export default UserRouterDesktop;
